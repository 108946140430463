define("client/routes/ar-cust-view/ar-site-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      console.log(params);
      return this.get('store').findRecord('site-address', params.siteAddress_id, {
        reload: true
      });
    },
    actions: {
      // async cancelEditSite() {
      //     let customer = this.get('controller.model.customer')
      //     this.transitionTo('ar-cust-view', customer);
      // }
      cancelEditSite: function cancelEditSite() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});