define("client/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.childOf('#explode'), this.use('explode', {
      pickOld: '.source-image',
      pickNew: '.product-screen',
      use: ['web-fly-to', {
        duration: 200
      }]
    }, {
      pickOld: '.source-card',
      pickNew: '.prod-card',
      use: ['web-fly-to', {
        duration: 200
      }]
    }, // {
    // 	pickOld: '.md-headline',
    // 	pickNew: '.md-headline',
    // 	use: ['cross-fade', { duration: 50 }]
    // }
    // {
    // 	pickOld: '.md-headline',
    // 	pickNew: '.md-headline',
    // 	use: ['web-fly-to', { duration: 300 }]
    // }
    {
      use: ['fade', {
        duration: 100
      }]
    }));
  }
});