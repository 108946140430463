define("client/controllers/ap-reports", ["exports", "client/utils/clone"], function (_exports, _clone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Controller$ext;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend((_Ember$Controller$ext = {
    isVendorRoster: false,
    is1099Report: false,
    isYearEndReport: false,
    isVendorMailingLabels: false,
    isAgedScheduleAP: false,
    isCashRequirements: false,
    suppressLt600: true,
    startId: "",
    columnDays: "10",
    autoPays: false,
    cutoffDate: "",
    endId: "",
    jobId: "",
    startDate: "",
    endDate: "",
    doubleSpaced: false,
    detailSummary: "D",
    holdOnly: false,
    byJob: false,
    reportDate: "",
    ageOrDue: "I",
    glCode: "",
    sortBy: "",
    termsCode: "",
    printer: "",
    activeOnly: false,
    resetFile: false,
    duplicatesOnly: false,
    latePostedOnly: false,
    vendorRecap: "N",
    glRecap: "N",
    jobRecap: false,
    numberOfLabels: "1",
    descriptor: Ember.computed('selected', 'flattenedFields.@each.value', function () {
      var descriptors = this.get('selected.descriptors');

      for (var i = 0; i < descriptors.get('length'); i++) {
        var constraint = descriptors.objectAt(i);
        var isValid = true;

        for (var j = 0; j < constraint.get('constraints.length'); j++) {
          var temp = constraint.get('constraints').objectAt(j);
          var param = this.get('flattenedFields').findBy('name', temp.get('name'));

          if (temp.get('value') != param.get('value')) {
            isValid = false;
          }
        }

        if (isValid) {
          return constraint.get('descriptor');
        }
      }

      return "";
    }),
    reportsArray: Ember.A([Ember.Object.create({
      reportName: "Vendor Roster"
    }), Ember.Object.create({
      reportName: "1099 Report"
    }), Ember.Object.create({
      reportName: "Year End Vendor Report"
    }), Ember.Object.create({
      reportName: "Vendor Mailing Labels"
    }), Ember.Object.create({
      reportName: "Aged Schedule of AP"
    }), Ember.Object.create({
      reportName: "Cash Requirements Report"
    }), Ember.Object.create({
      reportName: "Purchase Journal Report"
    })]),
    flattenedFields: Ember.computed('selected', function () {
      var arr = Ember.A([]);
      this.get('selected.parameters').forEach(function (x) {
        if (x.get('type') === 'subrecord') {
          arr.addObjects(x.get('items'));
        } else {
          arr.addObject(x);
        }
      });
      return arr;
    }),
    reports: Ember.inject.service()
  }, _defineProperty(_Ember$Controller$ext, "reportsArray", function () {
    return this.store.findAll('report', {
      reload: true
    });
  }.property()), _defineProperty(_Ember$Controller$ext, "actions", {
    setReport: function setReport(report) {
      this.set('selected', (0, _clone.default)(report));
    },
    printReport: function printReport() {
      this.get('reports').printReport(this.get('descriptor'), this.get('selected'));
    },
    setCurrentReport: function setCurrentReport(report) {
      this.set('is1099Report', false);
      this.set('isVendorRoster', false);
      this.set('isYearEndReport', false);
      this.set('isVendorMailingLabels', false);
      this.set('isAgedScheduleAP', false);
      this.set('isCashRequirements', false);
      this.set('isPurchaseJournalReport', false);

      if (report.get('reportName') === "Vendor Roster") {
        this.set('isVendorRoster', true);
      } else if (report.get('reportName') === "1099 Report") {
        this.set('is1099Report', true);
      } else if (report.get('reportName') === "Year End Vendor Report") {
        this.set('isYearEndReport', true);
      } else if (report.get('reportName') === "Vendor Mailing Labels") {
        this.set('isVendorMailingLabels', true);
      } else if (report.get('reportName') === "Aged Schedule of AP") {
        this.set('isAgedScheduleAP', true);
      } else if (report.get('reportName') === "Cash Requirements Report") {
        this.set('isCashRequirements', true);
      } else if (report.get('reportName') === "Purchase Journal Report") {
        this.set('isPurchaseJournalReport', true);
      }

      this.set('currentReport', report);
    },
    printToScreen: function printToScreen() {
      this.get('reports').printToScreen(this.get('descriptor'), this.get('selected'));
    },
    submitReport: function submitReport() {
      var report = {};
      report['printer'] = this.get('printer');

      if (this.get('currentReport.reportName') === "Vendor Roster") {
        report['startId'] = this.get('startId');
        report['endId'] = this.get('endId');
        report['doubleSpaced'] = this.get('doubleSpaced') ? 'Y' : 'N';
        report['glCode'] = this.get('glCode');
        report['sortBy'] = this.get('sortBy');
        report['termsCode'] = this.get('termsCode');
        this.get('reports').vendorRoster(report);
      } else if (this.get('currentReport.reportName') === "1099 Report") {
        report['suppress'] = this.get('suppressLt600') ? 'Y' : 'N';
        this.get('reports').report1099(report);
      } else if (this.get('currentReport.reportName') === "Year End Vendor Report") {
        report['printer'] = this.get('printer');
        this.get('reports').yearEndVendorReport(report);
      } else if (this.get('currentReport.reportName') === "Vendor Mailing Labels") {
        report['printer'] = this.get('printer');
        report['startId'] = this.get('startId');
        report['endId'] = this.get('endId');
        report['numberOfLabels'] = this.get('numberOfLabels');
        report['activeOnly'] = this.get('activeOnly') ? 'Y' : 'N';
        this.get('reports').vendorMailingLabels(report);
      } else if (this.get('currentReport.reportName') === "Aged Schedule of AP") {
        report['printer'] = this.get('printer');
        report['startId'] = this.get('startId');
        report['endId'] = this.get('endId');
        report['doubleSpaced'] = this.get('doubleSpaced') ? 'Y' : 'N';
        report['detailSummary'] = this.get('detailSummary');
        report['reportDate'] = this.get('reportDate');
        report['ageOrDue'] = this.get('ageOrDue');
        report['holdOnly'] = this.get('holdOnly') ? 'Y' : 'N';
        report['jobId'] = this.get('jobId');
        report['byJob'] = this.get('byJob') ? 'Y' : 'N';
        this.get('reports').agedScheduleAP(report);
      } else if (this.get('currentReport.reportName') === "Cash Requirements Report") {
        report['printer'] = this.get('printer');
        report['startId'] = this.get('startId');
        report['endId'] = this.get('endId');
        report['doubleSpaced'] = this.get('doubleSpaced') ? 'Y' : 'N';
        report['detailSummary'] = this.get('detailSummary');
        report['reportDate'] = this.get('reportDate');
        report['ageOrDue'] = this.get('ageOrDue');
        report['cutoffDate'] = this.get('cutoffDate');
        report['columnDays'] = this.get('columnDays');
        report['autoPays'] = this.get('autoPays') ? 'Y' : 'N';
        report['jobId'] = this.get('jobId');
        this.get('reports').cashRequirementsReport(report);
      } else if (this.get('currentReport.reportName') === "Purchase Journal Report") {
        report['printer'] = this.get('printer');
        report['startDate'] = this.get('startDate');
        report['endDate'] = this.get('endDate');
        report['duplicatesOnly'] = this.get('duplicatesOnly') ? 'Y' : 'N';
        report['resetFile'] = this.get('resetFile') ? 'Y' : 'N';
        report['lastPostedOnly'] = this.get('lastPostedOnly') ? 'Y' : 'N';
        report['vendorRecap'] = this.get('vendorRecap');
        report['glRecap'] = this.get('glRecap');
        report['jobRecap'] = this.get('jobRecap') ? 'Y' : 'N';
        report['jobId'] = this.get('jobId');
        report['latePostedOnly'] = this.get('latePostedOnly') ? 'Y' : 'N';
        this.get('reports').purchaseJournalReport(report);
      }
    }
  }), _Ember$Controller$ext));

  _exports.default = _default;
});