define("client/helpers/on-enter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.onEnter = onEnter;

  function onEnter(params
  /*, hash*/
  ) {
    return function (action) {
      return function (value) {
        if (value.keyCode === 13 && action) {
          action(value);
        }
      };
    }(params[0]);
  }

  var _default = Ember.Helper.helper(onEnter);

  _exports.default = _default;
});