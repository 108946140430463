define("client/helpers/app-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appVersion = appVersion;
  _exports.default = void 0;

  function appVersion(params
  /*, hash*/
  ) {
    return window.Client.appVersion;
  }

  var _default = Ember.Helper.helper(appVersion);

  _exports.default = _default;
});