define("client/routes/jc-job-search", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    actions: {
      didTransition: function didTransition() {
        Ember.$('#isLoading').hide();
      },
      editJob: function editJob(job) {
        this.transitionTo('jc-job-edit', job.get('id'));
      },
      viewJob: function viewJob(job) {
        this.transitionTo('jc-job-view', job.get('id'));
      },
      createJob: function createJob() {
        this.transitionTo('jc-job-create');
      },
      deleteJob: function deleteJob() {}
    }
  });

  _exports.default = _default;
});