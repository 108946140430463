define("client/components/pr-employee-delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DjyYHhuH",
    "block": "{\"statements\":[[6,[\"paper-dialog\"],null,[[\"onClose\"],[[28,[\"onClose\"]]]],{\"statements\":[[6,[\"paper-toolbar\"],null,null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"md-toolbar-tools\"],[13],[0,\"\\n            \"],[11,\"h2\",[]],[13],[0,\"Delete Employee\"],[14],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n            \"],[6,[\"paper-button\"],null,[[\"iconButton\",\"onClick\",\"disabled\"],[true,[28,[\"onClose\"]],[28,[\"confirmDelete\",\"isRunning\"]]]],{\"statements\":[[1,[33,[\"paper-icon\"],null,[[\"icon\"],[\"close\"]]],false]],\"locals\":[]},null],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"paper-dialog-content\"],null,null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"style\",\"width:700px;max-height:810px;\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"confirmDelete\",\"isRunning\"]]],null,{\"statements\":[[0,\"                \"],[1,[26,[\"paper-progress-linear\"]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                Are you sure you want to delete this employee?\\n\"]],\"locals\":[]}],[0,\"        \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"paper-dialog-actions\"],null,[[\"class\"],[\"layout-row\"]],{\"statements\":[[0,\"        \"],[11,\"span\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n\"],[6,[\"unless\"],[[28,[\"confirmDelete\",\"isRunning\"]]],null,{\"statements\":[[0,\"            \"],[6,[\"paper-button\"],null,[[\"onClick\"],[[28,[\"onClose\"]]]],{\"statements\":[[0,\"Cancel\"]],\"locals\":[]},null],[0,\"\\n            \"],[6,[\"paper-button\"],null,[[\"warn\",\"onClick\"],[true,[33,[\"perform\"],[[28,[\"confirmDelete\"]]],null]]],{\"statements\":[[0,\"Delete\"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/pr-employee-delete/template.hbs"
    }
  });

  _exports.default = _default;
});