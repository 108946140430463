define("client/components/ledger-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y+A2A3Hq",
    "block": "{\"statements\":[[6,[\"md-autocomplete\"],null,[[\"label\",\"class\",\"selected\",\"options\",\"onSelectionChange\",\"labelPath\",\"hideAllMessages\",\"searchText\",\"onSearchTextChange\",\"searchField\",\"allowClear\",\"onBlur\",\"onClear\",\"onFocus\",\"noMatchesMessage\",\"loadingMessage\",\"required\",\"autofocus\",\"passThru\",\"dropdownWidth\",\"disabled\"],[[28,[\"label\"]],[28,[\"class\"]],[33,[\"if\"],[[28,[\"ignoreSelected\"]],null,[28,[\"selected\"]]],null],[33,[\"if\"],[[28,[\"accountsOverride\"]],[28,[\"accountsOverride\"]],[28,[\"glAccounts\",\"sortedAccounts\"]]],null],[33,[\"action\"],[[28,[null]],\"onSelectionChange\"],null],[33,[\"if\"],[[28,[\"labelPath\"]],[28,[\"labelPath\"]],\"label\"],null],[28,[\"hideAllMessages\"]],[28,[\"searchText\"]],[33,[\"action\"],[[28,[null]],\"onSearchTextChange\"],null],\"label\",true,[33,[\"action\"],[[28,[null]],\"onBlur\"],null],[33,[\"action\"],[[28,[null]],\"onClear\"],null],[33,[\"action\"],[[28,[null]],\"onFocus\"],null],[28,[\"noMatchesMessage\"]],[28,[\"loadingMessage\"]],[28,[\"required\"]],[28,[\"autofocus\"]],[33,[\"hash\"],null,[[\"tabindex\"],[[28,[\"tabindex\"]]]]],[28,[\"dropdownWidth\"]],[28,[\"disabled\"]]]],{\"statements\":[[0,\"    \"],[1,[33,[\"paper-autocomplete-highlight\"],null,[[\"label\",\"searchText\",\"flags\"],[[33,[\"get\"],[[28,[\"model\"]],\"label\"],null],[33,[\"readonly\"],[[28,[\"searchText\"]]],null],\"i\"]]],false],[0,\"\\n\"]],\"locals\":[\"model\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/ledger-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});