define("client/models/job-contract", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    jobId: _emberData.default.attr('trim'),
    chgNumber: _emberData.default.attr('int'),
    fill: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    date: _emberData.default.attr(),
    description: _emberData.default.attr(),
    description2: _emberData.default.attr(),
    amount: _emberData.default.attr('trim'),
    custId: _emberData.default.attr(),
    billed: _emberData.default.attr('trim'),
    received: _emberData.default.attr(),
    estAmount: _emberData.default.attr('trim'),
    estHours: _emberData.default.attr(),
    overhead: _emberData.default.attr(),
    profit: _emberData.default.attr(),
    job: _emberData.default.belongsTo('job', {
      async: true
    }),
    lead: _emberData.default.belongsTo('lead', {
      async: true
    }),
    label: Ember.computed('jobId', 'chgNumber', function () {
      return "".concat(this.get('jobId'), " - #").concat(this.get('chgNumber'), " - $").concat(this.get('amount'));
    })
  });

  _exports.default = _default;
});