define("client/react/components/typed/form-currency-text-field", ["exports", "react/jsx-runtime", "@Harris-Barrick-Software/use-form", "client/react/components/typed/currency-text-field"], function (_exports, _jsxRuntime, _useForm, _currencyTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function FormCurrencyTextField(_a) {
    var _b;

    var formProps = _a.formProps,
        autoNumericOptions = _a.autoNumericOptions,
        hideHelperText = _a.hideHelperText,
        restProps = __rest(_a, ["formProps", "autoNumericOptions", "hideHelperText"]);

    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });

    if (!controller) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field,
        fieldState = controller.fieldState;
    return (0, _jsxRuntime.jsx)(_currencyTextField.default, Object.assign({
      error: fieldState.invalid,
      helperText: hideHelperText ? null : (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message
    }, restProps, {
      onChange: function onChange(e, formattedVal) {
        field.onChange(formattedVal);

        if (restProps === null || restProps === void 0 ? void 0 : restProps.onChange) {
          restProps.onChange(e, formattedVal);
        }
      },
      onBlur: function onBlur(e, valAsNumber) {
        var needToZero = parseFloat(e.target.value) === 0.0;

        if (needToZero) {
          field.onChange(0);
        }

        if (restProps === null || restProps === void 0 ? void 0 : restProps.onBlur) {
          restProps.onBlur(e, valAsNumber);
        }

        field.onBlur();
      },
      value: field.value,
      autoNumericOptions: Object.assign({
        decimalPlaces: (autoNumericOptions === null || autoNumericOptions === void 0 ? void 0 : autoNumericOptions.decimalPlaces) || 2
      }, autoNumericOptions)
    }));
  }

  var _default = FormCurrencyTextField;
  _exports.default = _default;
});