define("client/components/dwo-resizer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROW_HEIGHT = 20;
  var LEFT_CLICK = 0;

  function findClosestRow(y) {
    var arr = $('.t-row');
    var closestRow = arr.first()[0];
    var closestRect = closestRow.getBoundingClientRect();
    var minDistance = Math.abs(y - closestRect.top);
    arr.each(function (i, element) {
      var temprect = element.getBoundingClientRect();
      var diff = Math.abs(y - temprect.top);

      if (diff < minDistance) {
        closestRect = temprect;
        closestRow = element;
        minDistance = diff;
      }
    });
    return closestRow.dataset.time;
  }

  var _default = Ember.Component.extend({
    currentRow: 0,
    classNames: ['dwo-resizer'],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('currentRow', 0);
    },
    mouseDown: function mouseDown(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.button !== LEFT_CLICK) {
        return;
      }

      this.set('newRows', this.get('rows'));
      this.set('isDragging', true);
      this.set('currentRow', findClosestRow(e.clientY));
      $(window).on('mousemove', this.globalMouseMove.bind(this));
      $(window).one('mouseup', this.globalMouseUp.bind(this));
    },
    globalMouseMove: function globalMouseMove(e) {
      e.preventDefault();
      e.stopPropagation();

      if (this.get('isDragging')) {
        var woElement = this.get('woElement');
        var clientY = e.clientY;
        var oldRow = this.get('currentRow');
        var newRow = findClosestRow(clientY);

        if (newRow > oldRow) {
          this.set('newRows', this.get('newRows') + 1);
          woElement.style.height = ROW_HEIGHT * this.get('newRows') + 'px';
          this.set('currentRow', newRow);
        } else if (newRow == oldRow) {} else {
          this.set('newRows', Math.max(this.get('newRows') - 1, 0));
          woElement.style.height = ROW_HEIGHT * this.get('newRows') + 'px';
          this.set('currentRow', newRow);
        }
      }
    },
    globalMouseUp: function globalMouseUp(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.button !== LEFT_CLICK) {
        return;
      }

      this.get('isDragging', false);
      $(window).off('mousemove');
      this.sendAction('onChangeRows', this.get('newRows'));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      $(window).off('mousemvoe');
    }
  });

  _exports.default = _default;
});