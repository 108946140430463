define("client/components/child-form-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+cicSjF8",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"state\"]]],null,{\"statements\":[[0,\"\\t\"],[18,\"default\",[[28,[\"state\"]]]],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"\\t\"],[18,\"default\"],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/child-form-state/template.hbs"
    }
  });

  _exports.default = _default;
});