define("client/helpers/sub-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.subHelper = subHelper;

  function subHelper(params
  /*, hash*/
  ) {
    var input1 = params[0];
    var input2 = params[1];
    if (input1 !== 0 && !input1 || input2 !== 0 && !input2) return "";
    return "$" + (input1 - input2).toFixed(2);
  }

  var _default = Ember.Helper.helper(subHelper);

  _exports.default = _default;
});