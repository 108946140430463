define("client/components/paper-color-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WMj/QSRu",
    "block": "{\"statements\":[[9,\"paper-swatch-picker\",{\"attrs\":[[16,\"color\",[34,[[26,[\"value\"]]]]]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"],[6,[\"if\"],[[28,[\"label\"]]],null,{\"statements\":[[11,\"div\",[]],[15,\"class\",\"md-label layout-column\"],[13],[11,\"div\",[]],[15,\"class\",\"flex\"],[13],[14],[11,\"span\",[]],[13],[1,[26,[\"label\"]],false],[14],[11,\"div\",[]],[15,\"class\",\"flex\"],[13],[14],[14]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/paper-color-input/template.hbs"
    }
  });

  _exports.default = _default;
});