define("client/react/custom-dashboards/metric-config", ["exports", "react", "@mui/styles/makeStyles", "client/react/utilities/styles", "client/react/components/spacer", "client/react/custom-dashboards/use-widget-config"], function (_exports, _react, _makeStyles, _styles, _spacer, _useWidgetConfig2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _makeStyles.default)(function (_) {
    return _objectSpread({}, _styles.styles);
  });

  function MetricConfig(_ref) {
    var dimensions = _ref.dimensions,
        metrics = _ref.metrics,
        saveRef = _ref.saveRef,
        config = _ref.config,
        onSave = _ref.onSave;
    var classes = useStyles();

    var _useWidgetConfig = (0, _useWidgetConfig2.default)({
      config: config,
      dimensionsList: dimensions,
      metricsList: metrics,
      saveRef: saveRef,
      onSave: onSave,
      metrics: {
        metric: {
          label: "Metric",
          required: true
        }
      },
      dimensions: {},
      meta: {}
    }),
        components = _useWidgetConfig.components;

    return _react.default.createElement(_react.default.Fragment, null, components.title, components.description, _react.default.createElement("div", {
      className: classes.row
    }, components.metric.autocomplete, _react.default.createElement(_spacer.default, null), components.metric.aggregationFnAutocomplete), _react.default.createElement(_spacer.default, null), components.filter, components.dynamicInputsDialog);
  }

  var _default = MetricConfig;
  _exports.default = _default;
});