define("client/components/number-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inValue: '0',
    inputValidation: Ember.observer('inValue', function () {
      var value = this.get('inValue');
      var length = this.get('inValue').length;

      if (value === '') {
        this.set('inValue', '0');
        return;
      }

      var regex = /^\d+(\.\d{1,2})?$/;
      var regex2 = /(\.+\w*\d*\.+)|(\.{2,})/;

      if (!regex.test(value) && !value.endsWith('.') || value === '.') {
        if (length > 1) value = value.slice(0, length - 1);else value = '0';
      }

      if (regex2.test(value)) {
        value = value.replace('.', '');
      }

      this.set('inValue', value);
      this.get('changeValue')(value);
    })
  });

  _exports.default = _default;
});