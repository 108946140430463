define("client/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MJ4uUB9d",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"layout-row\"],[15,\"style\",\"height: 100%\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"layout-column flex layout-align-center-center\"],[13],[0,\"\\n        \"],[1,[33,[\"paper-progress-circular\"],null,[[\"diameter\"],[196]]],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});