define("client/helpers/utc-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.utcTime = utcTime;

  function utcTime(params
  /*, hash*/
  ) {
    var t = params[0];
    var format = params[1] || 'hh:mm a';
    return moment.utc(t).local().format(format);
  }

  var _default = Ember.Helper.helper(utcTime);

  _exports.default = _default;
});