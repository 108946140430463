define("client/helpers/name-flip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.nameFlip = nameFlip;

  function nameFlip(params
  /*, hash*/
  ) {
    return params[0] ? params[0].split(',').reverse().map(function (x) {
      return x.trim();
    }).join(' ') : '';
  }

  var _default = Ember.Helper.helper(nameFlip);

  _exports.default = _default;
});