define("client/routes/jc-types", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      return Ember.RSVP.hash({
        types: this.store.findAll('job-gl-config', {
          reload: true
        }),
        glAccounts: this.store.findAll('ledger-account', {
          reload: true
        })
      });
    },
    actions: {
      didTransition: function didTransition() {
        Ember.$('#isLoading').hide();
      }
    }
  });

  _exports.default = _default;
});