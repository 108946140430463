define("client/helpers/model-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.modelName = modelName;

  function modelName(params
  /*, hash*/
  ) {
    return params[0].split(/(?=[A-Z])/).join(" ");
  }

  var _default = Ember.Helper.helper(modelName);

  _exports.default = _default;
});