define("client/templates/vendor-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8BYhek8G",
    "block": "{\"statements\":[[1,[33,[\"vendor-search\"],null,[[\"transition\",\"create\",\"setItem\"],[[33,[\"route-action\"],[\"transition\",\"vendor-view\"],null],[33,[\"route-action\"],[\"transitionNoParam\",\"vendor-create\"],null],[33,[\"route-action\"],[\"transition\",\"vendor-view\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/vendor-search.hbs"
    }
  });

  _exports.default = _default;
});