define("client/transforms/solicitation-options", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      serialized = serialized.split(',');
      serialized = serialized.reduce(function (prev, curr) {
        var splitArr = curr.split(':');
        prev.set(splitArr[0], splitArr[1]);
        return prev;
      }, Ember.Object.create({}));
      return serialized;
    },
    serialize: function serialize(deserialized) {
      var returnString = "",
          keys = Ember.keys(deserialized);

      for (var i = 0; i < keys.length; i++) {
        var key = keys[i],
            value = deserialized.get(key);
        returnString += ",".concat(key, ":").concat(value);
      }

      returnString = returnString.slice(1);
      return returnString;
    }
  });

  _exports.default = _default;
});