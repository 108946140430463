define("client/components/fancy-text-editor/component", ["exports", "ember-cli-simditor/components/simditor-editor"], function (_exports, _simditorEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _simditorEditor.default.extend({
    classNames: ['full-width']
  });

  _exports.default = _default;
});