define("client/components/child-form/component", ["exports", "ember-paper/mixins/parent-mixin", "ember-paper/mixins/child-mixin"], function (_exports, _parentMixin, _childMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      observer = Ember.observer,
      run = Ember.run;
  var CForm = Component.extend(_childMixin.default, _parentMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var parent = this.get('parent');

      if (parent) {
        this.get('parent').registerForm(this.get('name'), this);
      }
    },
    isValid: computed.not('isInvalid'),
    // These observers may seem hacky but they are necessary. Ember renders components in order they appear,
    // this means that in cases where the state is used in the template before the form itself, on every render
    // the state will be rendered before the validating components themselves which will trigger a double render assertion any time something changes
    // to get around this, the state of the form must be decided at least one render away from the time the validity state changed, hence the observer.
    // same logic applies to isTouched
    isInvalidObserver: observer('childComponents.@each.isInvalid', function () {
      var _this = this;

      run.scheduleOnce('afterRender', function () {
        _this.set('childrenInvalid', _this.get('childComponents').isAny('isInvalid'));
      });
    }),
    // for purposes of onSubmit on the parent form, the isInvalid should compute to be true if any of the children are invalid 
    // when is Invalid is set it should simply set it on all children
    isInvalid: computed('childrenInvalid', {
      get: function get() {
        return this.get('childrenInvalid');
      },
      set: function set(key, value) {
        this.get('childComponents').setEach('isInvalid', value);
        return value;
      }
    }),
    isTouchedObserver: observer('childComponents.@each.isTouched', function () {
      var _this2 = this;

      run.scheduleOnce('afterRender', function () {
        _this2.set('childrenTouched', _this2.get('childComponents').isAny('isTouched'));
      });
    }),
    isTouched: computed('childrenTouched', {
      get: function get() {
        return this.get('childrenTouched');
      },
      set: function set(key, value) {
        this.get('childComponents').setEach('isTouched', value);
        return value;
      }
    }),
    isInvalidAndTouched: computed.and('isInvalid', 'isTouched'),
    actions: {
      onValidityChange: function onValidityChange() {
        if (this.get('lastIsValid') !== this.get('isValid') || this.get('lastIsTouched') !== this.get('isTouched')) {
          this.sendAction('onValidityChange', this.get('isValid'), this.get('isTouched'), this.get('isInvalidAndTouched'));
          this.set('lastIsValid', this.get('isValid'));
          this.set('lastIsTouched', this.get('isTouched'));
        }
      }
    }
  });
  CForm.reopenClass({
    positionalParams: ['name']
  });
  var _default = CForm;
  _exports.default = _default;
});