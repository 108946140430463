define("client/services/tenant-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Service.extend({
    application: inject.controller(),
    tenantSettings: computed.alias('application.tenantSettings'),
    featureFlags: computed.alias('application.tenantSettings.featureFlags')
  });

  _exports.default = _default;
});