define("client/components/srvc-archive/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Zl5kXdZ",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"isViewing\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"srvc-archive-view\"],null,[[\"close\",\"isViewing\"],[[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isViewing\"]]],null],null],null],[28,[\"isViewing\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[1,[33,[\"srvc-archive-search\"],null,[[\"setWo\"],[[33,[\"action\"],[[28,[null]],\"setWo\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/srvc-archive/template.hbs"
    }
  });

  _exports.default = _default;
});