define("client/models/contract-chg-order-transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    jobId: _emberData.default.attr(),
    chgNumber: _emberData.default.attr(),
    fill: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    date: _emberData.default.attr(),
    description: _emberData.default.attr(),
    description2: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    custId: _emberData.default.attr(),
    billedFlag: _emberData.default.attr(),
    chgOrderRecd: _emberData.default.attr(),
    estimateAmt: _emberData.default.attr(),
    estimateHours: _emberData.default.attr(),
    timeMatlOverhead: _emberData.default.attr(),
    timeMatlProfit: _emberData.default.attr()
  });

  _exports.default = _default;
});