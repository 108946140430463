define("client/components/work-order-management/component", ["exports", "client/components/react-component/component", "client/react/service/work-order-management"], function (_exports, _component, _workOrderManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['full-height'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.reactRender(React.createElement(_workOrderManagement.default, null));
    }
  });

  _exports.default = _default;
});