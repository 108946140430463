define("client/components/required-text-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isValid:has-success:has-error'],
    isValid: function () {
      if (this.get('isInserted') && this.get('inValue') !== undefined) {
        var val = this.get('inValue').trim();

        if (val == '') {
          return false;
        } else {
          return true;
        }
      }
    }.property('inValue', 'isInserted'),
    didInsertElement: function didInsertElement() {
      this.set('isInserted', true);
    },
    inValue: "",
    isInserted: false
  });

  _exports.default = _default;
});