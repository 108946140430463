define("client/components/animated-pages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J/5Py2WF",
    "block": "{\"statements\":[[11,\"content\",[]],[15,\"id\",\"content\"],[13],[0,\"\\n\\t\"],[18,\"default\",[[33,[\"hash\"],null,[[\"page\"],[[33,[\"component\"],[\"animated-page\"],[[\"parent\",\"selected\"],[[28,[null]],[28,[\"selected\"]]]]]]]]]],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/animated-pages/template.hbs"
    }
  });

  _exports.default = _default;
});