define("client/components/job-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ptsB8H7r",
    "block": "{\"statements\":[[6,[\"md-autocomplete\"],null,[[\"required\",\"class\",\"openOnType\",\"searchText\",\"triggerClass\",\"selected\",\"labelPath\",\"matcher\",\"label\",\"options\",\"onSelectionChange\",\"onSearchTextChange\",\"onBlur\"],[[28,[\"required\"]],\"flex\",true,[28,[\"searchText\"]],\"flex\",[28,[\"selected\"]],\"jobId\",[28,[\"matcher\"]],[28,[\"label\"]],[28,[\"options\"]],[33,[\"action\"],[[28,[null]],\"selectJob\"],null],[33,[\"perform\"],[[28,[\"changeSearchText\"]]],null],[28,[\"onBlur\"]]]],{\"statements\":[[0,\"\\t\"],[1,[33,[\"paper-autocomplete-highlight\"],null,[[\"label\",\"searchText\",\"flags\"],[[33,[\"concat\"],[[28,[\"job\",\"jobId\"]],\" - \",[28,[\"job\",\"projectName\"]]],null],[28,[\"select\",\"searchText\"]],\"i\"]]],false],[0,\"\\n\"]],\"locals\":[\"job\",\"select\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/job-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});