define("client/mixins/paper-tab-hotkeys", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_emberKeyboard.EKMixin, _emberKeyboard.EKOnInsertMixin, {
    decreaseTab: Ember.on((0, _emberKeyboard.keyDown)('ctrl+ArrowLeft'), function () {
      var tab = this.get('selectedTab'),
          newTab = tab !== 0 ? tab - 1 : tab,
          changeTab = this.get('actions.changeTab');

      if (changeTab) {
        this.send('changeTab', newTab);
      } else {
        this.set('selectedTab', newTab);
      }
    }),
    increaseTab: Ember.on((0, _emberKeyboard.keyDown)('ctrl+ArrowRight'), function () {
      var tab = this.get('selectedTab'),
          tabCount = this.get('tabCount'),
          newTab = tab !== tabCount - 1 ? tab + 1 : tab,
          changeTab = this.get('actions.changeTab');

      if (changeTab) {
        this.send('changeTab', newTab);
      } else {
        this.set('selectedTab', newTab);
      }
    })
  });

  _exports.default = _default;
});