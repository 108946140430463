define("client/helpers/running-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.runningBalance = runningBalance;

  function runningBalance(params
  /*, hash*/
  ) {
    var items = params[0];
    var key = params[1];
    var index = params[2] + 1;
    var total = 0;

    for (var i = 0; i < index; i++) {
      total += items.objectAt(i).get(key);
    }

    return total;
  }

  var _default = Ember.Helper.helper(runningBalance);

  _exports.default = _default;
});