define("client/routes/ar-cust-create", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      var newCustomer = Ember.Object.create({
        billingName: '',
        custId: '',
        streetAddress: '',
        apartmentNumber: '',
        city: '',
        zip: '',
        state: '',
        homePhone: '',
        cellPhone: '',
        altPhone: '',
        shipName: '',
        shipName2: '',
        shipAddress: '',
        discountSpecialPrice: 0,
        shipCity: '',
        shipState: '',
        shipZip: '',
        creditPhone: '',
        salesContact: '',
        creditContact: '',
        salesYTD: 0,
        returnsYTD: 0,
        balance: 0,
        creditLine: 0,
        category: '',
        priceColumn: '',
        lastSaleDate: null,
        lastSaleAmt: 0,
        lastPayDate: null,
        lastPayAmt: 0,
        slmCustKey: '',
        upss: 0,
        financeChgFlag: 'N',
        statementFlag: 'N',
        commissionRate: 0,
        accountCreateDate: moment().format('YYYY-MM-DD'),
        faxNumber: '',
        country: '',
        shipCounty: '',
        custDiscCode: '',
        averagePayDays: 0,
        averagePayDaysAmount: 0,
        averagePayDaysFlag: '',
        custStatus: '',
        createdDate: null,
        referredBy: '',
        notUsed: '',
        reasonCode: '',
        terms: '',
        slm: '',
        salesTaxJurisdiction: ''
      });
      return newCustomer;
    },
    actions: {
      cancelCreate: function cancelCreate() {
        this.transitionTo('ar-cust-search');
      },
      selectCustomer: function selectCustomer(customer) {
        this.transitionTo('ar-cust-view', customer);
      }
    }
  });

  _exports.default = _default;
});