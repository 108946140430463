define("client/templates/disbursements-journal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EhN+AHV0",
    "block": "{\"statements\":[[1,[33,[\"ap-dis-jur\"],null,[[\"jobCategories\"],[[28,[\"model\",\"jobCats\"]]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/disbursements-journal.hbs"
    }
  });

  _exports.default = _default;
});