define("client/locations/hash-react", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HashLocation.extend({
    implementation: 'hash-react',
    pushState: function pushState(path) {
      console.log(path);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});