define("client/templates/srvc-work-order-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PFtUnW+Q",
    "block": "{\"statements\":[[1,[33,[\"srvc-work-order-create\"],null,[[\"wo\",\"cancel\",\"toView\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"cancel\"],null],[33,[\"route-action\"],[\"toView\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/srvc-work-order-create.hbs"
    }
  });

  _exports.default = _default;
});