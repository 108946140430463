define("client/helpers/service-month-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.serviceMonthDate = serviceMonthDate;

  function serviceMonthDate(params
  /*, hash*/
  ) {
    if (Ember.isBlank(params[0])) {
      return null;
    }

    if (params[0].length === 5) {} else {
      var decadeHash = {
        "A": 0,
        "B": 1,
        "C": 2,
        "D": 3,
        "E": 4,
        "F": 5,
        "G": 6,
        "H": 7,
        "I": 8,
        "J": 9
      };
      if (Ember.isBlank(params[0])) return null;
      var decadeLetter = params[0].substr(4, 1);
      var decadeDigit = decadeHash[decadeLetter];
      var date = params[0].replace(decadeLetter, decadeDigit);
      return moment(date, 'MMDDYY').format('MM/YY');
    }
  }

  var _default = Ember.Helper.helper(serviceMonthDate);

  _exports.default = _default;
});