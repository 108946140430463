define("client/models/payroll-hours", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    employeeId: _emberData.default.attr(),
    jobId: _emberData.default.attr(),
    date: _emberData.default.attr('citron-year'),
    category: _emberData.default.attr(),
    rate: _emberData.default.attr(),
    regHours: _emberData.default.attr(),
    otHours: _emberData.default.attr(),
    dtHours: _emberData.default.attr(),
    jobExpenseReimbursement: _emberData.default.attr(),
    travelPay: _emberData.default.attr(),
    postFlag: _emberData.default.attr(),
    trimmedEmployeeId: Ember.computed('employeeId', function () {
      return this.get('employeeId').trim();
    })
  });

  _exports.default = _default;
});