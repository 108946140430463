define("client/models/ledger-distribution", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    ledgerId: _emberData.default.attr('capitalize'),
    division1: _emberData.default.attr(),
    division2: _emberData.default.attr(),
    division3: _emberData.default.attr(),
    division4: _emberData.default.attr(),
    division5: _emberData.default.attr(),
    division6: _emberData.default.attr(),
    division7: _emberData.default.attr(),
    division8: _emberData.default.attr(),
    division9: _emberData.default.attr(),
    division10: _emberData.default.attr(),
    division11: _emberData.default.attr(),
    division12: _emberData.default.attr(),
    division13: _emberData.default.attr(),
    division14: _emberData.default.attr(),
    division15: _emberData.default.attr(),
    division16: _emberData.default.attr(),
    division17: _emberData.default.attr(),
    division18: _emberData.default.attr(),
    division19: _emberData.default.attr(),
    division20: _emberData.default.attr(),
    division21: _emberData.default.attr(),
    division22: _emberData.default.attr(),
    division23: _emberData.default.attr(),
    division24: _emberData.default.attr(),
    division25: _emberData.default.attr(),
    division26: _emberData.default.attr(),
    division27: _emberData.default.attr(),
    division28: _emberData.default.attr(),
    division29: _emberData.default.attr(),
    division30: _emberData.default.attr(),
    division31: _emberData.default.attr(),
    division32: _emberData.default.attr(),
    division33: _emberData.default.attr(),
    division34: _emberData.default.attr(),
    division35: _emberData.default.attr(),
    division36: _emberData.default.attr(),
    division37: _emberData.default.attr(),
    division38: _emberData.default.attr(),
    division39: _emberData.default.attr(),
    division40: _emberData.default.attr(),
    division41: _emberData.default.attr(),
    division42: _emberData.default.attr(),
    division43: _emberData.default.attr(),
    division44: _emberData.default.attr(),
    division45: _emberData.default.attr(),
    division46: _emberData.default.attr(),
    division47: _emberData.default.attr(),
    division48: _emberData.default.attr(),
    amount1: _emberData.default.attr(),
    amount2: _emberData.default.attr(),
    amount3: _emberData.default.attr(),
    amount4: _emberData.default.attr(),
    amount5: _emberData.default.attr(),
    amount6: _emberData.default.attr(),
    amount7: _emberData.default.attr(),
    amount8: _emberData.default.attr(),
    amount9: _emberData.default.attr(),
    amount10: _emberData.default.attr(),
    amount11: _emberData.default.attr(),
    amount12: _emberData.default.attr(),
    amount13: _emberData.default.attr(),
    amount14: _emberData.default.attr(),
    amount15: _emberData.default.attr(),
    amount16: _emberData.default.attr(),
    amount17: _emberData.default.attr(),
    amount18: _emberData.default.attr(),
    amount19: _emberData.default.attr(),
    amount20: _emberData.default.attr(),
    amount21: _emberData.default.attr(),
    amount22: _emberData.default.attr(),
    amount23: _emberData.default.attr(),
    amount24: _emberData.default.attr(),
    amount25: _emberData.default.attr(),
    amount26: _emberData.default.attr(),
    amount27: _emberData.default.attr(),
    amount28: _emberData.default.attr(),
    amount29: _emberData.default.attr(),
    amount30: _emberData.default.attr(),
    amount31: _emberData.default.attr(),
    amount32: _emberData.default.attr(),
    amount33: _emberData.default.attr(),
    amount34: _emberData.default.attr(),
    amount35: _emberData.default.attr(),
    amount36: _emberData.default.attr(),
    amount37: _emberData.default.attr(),
    amount38: _emberData.default.attr(),
    amount39: _emberData.default.attr(),
    amount40: _emberData.default.attr(),
    amount41: _emberData.default.attr(),
    amount42: _emberData.default.attr(),
    amount43: _emberData.default.attr(),
    amount44: _emberData.default.attr(),
    amount45: _emberData.default.attr(),
    amount46: _emberData.default.attr(),
    amount47: _emberData.default.attr(),
    amount48: _emberData.default.attr(),
    ledgerAccount: _emberData.default.belongsTo('ledger-account', {
      async: true
    })
  });

  _exports.default = _default;
});