define("client/models/dynamic-invoice", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr(),
    prefix: _emberData.default.attr(),
    tenant: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr(),
    filename: _emberData.default.attr(),
    xobject: _emberData.default.attr(),
    landscape: _emberData.default.attr(),
    pages: _emberData.default.attr(),
    mergeParts: _emberData.default.attr(),
    solutionLength: _emberData.default.attr(),
    extendedPartDescriptions: _emberData.default.attr(),
    dynamicFields: _emberData.default.hasMany('dynamic-field', {
      async: true
    }),
    configuration: _emberData.default.attr('configuration'),
    useOwnInvoiceNumber: _emberData.default.attr(),
    hidden: _emberData.default.attr()
  });

  _exports.default = _default;
});