define("client/components/mde-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H+kGwdcN",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"layout-row \",[26,[\"class\"]]]]],[13],[0,\"\\n\"],[6,[\"md-autocomplete\"],null,[[\"class\",\"disabled\",\"selected\",\"allowClear\",\"searchText\",\"labelPath\",\"matcher\",\"search\",\"label\",\"onSelectionChange\",\"onBlur\",\"onSearchTextChange\",\"hideAllMessages\",\"noMatchesMessage\",\"loadingMessage\",\"onClear\",\"required\",\"onFocus\",\"onCreate\",\"maxlength\"],[\"flex\",[28,[\"disabled\"]],[28,[\"selected\"]],true,[33,[\"readonly\"],[[28,[\"searchText\"]]],null],[33,[\"if\"],[[28,[\"labelPath\"]],[28,[\"labelPath\"]],[28,[\"primaryLabel\"]]],null],[28,[\"matcher\"]],[28,[\"search\"]],[28,[\"label\"]],[28,[\"onSelectionChange\"]],[28,[\"onBlur\"]],[28,[\"onSearchTextChange\"]],[28,[\"hideAllMessages\"]],[28,[\"noMatchesMessage\"]],[28,[\"loadingMessage\"]],[28,[\"onClear\"]],[28,[\"required\"]],[28,[\"onFocus\"]],[28,[\"onCreate\"]],[28,[\"maxlength\"]]]],{\"statements\":[[0,\"\\t\\t\"],[18,\"default\",[[28,[\"model\"]]]],[0,\"\\n\"]],\"locals\":[\"model\"]},null],[6,[\"if\"],[[28,[\"onEdit\"]]],null,{\"statements\":[[6,[\"liquid-if\"],[[33,[\"or\"],[[28,[\"canEdit\"]],[28,[\"selected\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[11,\"div\",[]],[15,\"style\",\"margin-top:14px;\"],[13],[0,\"\\n\"],[6,[\"paper-button\"],null,[[\"iconButton\",\"primary\",\"onClick\"],[true,true,[33,[\"action\"],[[28,[null]],[28,[\"onEdit\"]]],null]]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[6,[\"paper-tooltip\"],null,null,{\"statements\":[[0,\"Edit\"]],\"locals\":[]},null],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[33,[\"paper-icon\"],[\"create\"],null],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\t\\t\\t\"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/mde-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});