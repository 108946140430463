define("client/helpers/generic-table-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.genericTableHeading = genericTableHeading;

  function genericTableHeading(item) {
    if (item.get('type') === "currency") {
      return new Ember.String.htmlSafe('<th style="text-align:right">' + item.get('heading') + '</th>');
    } else if (item.get('type') === "int") {
      return new Ember.String.htmlSafe('<th style="text-align:center">' + item.get('heading') + '</th>');
    } else {
      return new Ember.String.htmlSafe('<th>' + item.get('heading') + '</th>');
    }
  }

  var _default = Ember.Handlebars.makeBoundHelper(genericTableHeading);

  _exports.default = _default;
});