define("client/react/components/typed/titled-data", ["exports", "react/jsx-runtime", "tss-react/mui", "@mui/material", "client/react/components/styles/styles", "remeda"], function (_exports, _jsxRuntime, _mui, _material, _styles, _remeda) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _mui.makeStyles)()(function (theme) {
    return Object.assign(Object.assign({}, _styles.styles), {
      marginBottom: {
        marginBottom: '0.75em'
      },
      linkEnabled: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
      }
    });
  });

  function TitledData(_ref) {
    var containerClasses = _ref.containerClasses,
        data = _ref.data,
        subtitle = _ref.subtitle,
        _onClick = _ref.onClick,
        dataTextVariant = _ref.dataTextVariant;

    var _useStyles = useStyles(),
        cx = _useStyles.cx,
        classes = _useStyles.classes;

    var isBool = (0, _remeda.isBoolean)(data);
    var isStr = (0, _remeda.isString)(data);
    var isNull = (0, _remeda.isNil)(data);
    var isUndefined = data === undefined;
    var isEmpty = isStr && data.trim() === "";
    return (0, _jsxRuntime.jsxs)("div", Object.assign({
      className: cx(classes.column, classes.alignStartCenter, containerClasses || [])
    }, {
      children: [(0, _jsxRuntime.jsx)(_material.Typography, Object.assign({
        align: 'center',
        variant: dataTextVariant || 'body1',
        className: cx(_defineProperty({}, classes.linkEnabled, !!_onClick && !!data)),
        onClick: function onClick() {
          if (!!_onClick) {
            _onClick();
          }
        }
      }, {
        children: isNull || isUndefined || isEmpty ? (0, _jsxRuntime.jsx)("span", {
          children: "\u2014"
        }) : (0, _jsxRuntime.jsx)("span", {
          children: isBool ? data ? "Yes" : "No" : data
        })
      })), (0, _jsxRuntime.jsx)(_material.Typography, Object.assign({
        className: cx(classes.marginBottom),
        align: 'center',
        color: 'textSecondary',
        variant: 'subtitle2'
      }, {
        children: subtitle
      }))]
    }));
  }

  var _default = TitledData;
  _exports.default = _default;
});