define("client/templates/equipment-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1TgH5v42",
    "block": "{\"statements\":[[1,[33,[\"srvc-equipment-edit\"],null,[[\"model\",\"equipment\",\"close\"],[[28,[\"model\"]],[33,[\"changeset\"],[[28,[\"model\"]]],null],[33,[\"route-action\"],[\"back\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/equipment-edit.hbs"
    }
  });

  _exports.default = _default;
});