define("client/helpers/po-item-total", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.poItemTotal = poItemTotal;

  function poItemTotal(params
  /*, hash*/
  ) {
    var qty = params[0];
    var cost = params[1];
    var uom = params[2];
    var disc = params[3] ? parseFloat(params[3]) : 0;
    var total;
    if (!qty || !cost || !uom) return '0.00';

    if (uom === "E") {
      total = parseFloat(qty) * parseFloat(cost);
    } else if (uom === "P") {
      total = parseFloat(qty) * parseFloat(cost) / 2;
    } else if (uom === "C") {
      total = parseFloat(qty) * parseFloat(cost) / 100;
    } else if (uom === "M") {
      total = parseFloat(qty) * parseFloat(cost) / 1000;
    } else if (uom === "T") {
      total = parseFloat(qty) * parseFloat(cost) / 2000;
    } else if (uom === "D") {
      total = parseFloat(qty) * parseFloat(cost) / 12;
    } else if (uom === "G") {
      total = parseFloat(qty) * parseFloat(cost) / 144;
    }

    return (total * (1 - disc / 100)).toFixed(2);
  }

  var _default = Ember.Helper.helper(poItemTotal);

  _exports.default = _default;
});