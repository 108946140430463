define("client/helpers/rsvp-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.rsvpHash = rsvpHash;

  function rsvpHash(params
  /*, hash*/
  ) {
    return Ember.RSVP.hash(params[0]);
  }

  var _default = Ember.Helper.helper(rsvpHash);

  _exports.default = _default;
});