define("client/components/tree-left-block/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ember-table-table-block', 'ember-table-left-table-block'],
    attributeBindings: ['style'],
    style: "display:flex; flex-direction: column; flex:1;"
  });

  _exports.default = _default;
});