define("client/components/inv-po-history/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      viewItem: function viewItem(item) {
        this.set('isViewing', item);
      },
      closeViewItem: function closeViewItem() {
        this.set('isViewing', null);
      }
    }
  });

  _exports.default = _default;
});