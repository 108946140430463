define("client/components/my-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ful2azq3",
    "block": "{\"statements\":[[11,\"select\",[]],[16,\"class\",[34,[\"form-control \",[33,[\"if\"],[[28,[\"small\"]],\"input-sm\"],null]]]],[5,[\"action\"],[[28,[null]],\"change\"],[[\"on\"],[\"change\"]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"prompt\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[11,\"option\",[]],[15,\"value\",\"\"],[13],[1,[26,[\"prompt\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"each\"],[[28,[\"content\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"    \"],[11,\"option\",[]],[16,\"value\",[34,[[28,[\"item\"]]]]],[16,\"selected\",[33,[\"is-equal\"],[[28,[\"item\"]],[28,[\"selectedValue\"]]],null],null],[13],[0,\"\\n      \"],[1,[28,[\"item\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[\"item\"]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/my-select/template.hbs"
    }
  });

  _exports.default = _default;
});