define("client/components/customer-site-potential-duplicates/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var d = this.get('duplicates'),
          arr = Ember.A([]),
          sortedCustomers = d.get('customers').sortBy('billingName'),
          sortedSites = d.get('sites').sortBy('siteName');
      arr.pushObjects(sortedCustomers);
      arr.pushObjects(sortedSites);
      this.set('formattedDuplicates', arr);
    }
  });

  _exports.default = _default;
});