define("client/helpers/camel-to-space", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.camelToSpace = camelToSpace;
  _exports.default = void 0;

  function camelToSpace(params
  /*, hash*/
  ) {
    var str = params[0];
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (s) {
      return s.toUpperCase();
    });
  }

  var _default = Ember.Helper.helper(camelToSpace);

  _exports.default = _default;
});