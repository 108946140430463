define("client/routes/srvc-work-order-edit", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model(params) {
      return this.store.findRecord('work-order', params.workOrder_id, {
        reload: true
      });
    },
    actions: {
      cancel: function cancel() {
        if (window.history.length && window.history.length > 1) {
          window.history.back();
        } else {
          this.send('toView');
        }
      },
      toView: function toView() {
        this.transitionTo('srvc-work-order-view', this.get('controller.model'));
      }
    }
  });

  _exports.default = _default;
});