define("client/react/components/typed/form-gl-autocomplete", ["exports", "react/jsx-runtime", "@Harris-Barrick-Software/use-form", "client/react/utilities/typed/autocomplete", "@mui/material", "react", "client/react/utilities/typed/helpers"], function (_exports, _jsxRuntime, _useForm, _autocomplete, _material, _react, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function FormGlAutocomplete(props) {
    var autocompleteDefaults = (0, _autocomplete.useDefaults)();

    var formProps = props.formProps,
        renderInputParams = props.renderInputParams,
        restProps = __rest(props, ["formProps", "renderInputParams"]);

    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });

    if (!controller) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field;
    var renderInput = restProps.renderInput || (0, _autocomplete.formAutocompleteRenderInput)(Object.assign(Object.assign({}, renderInputParams), {
      control: formProps.control,
      onBlur: function onBlur(e) {
        var match = e.target.value !== null && e.target.value !== undefined ? restProps.options.find(function (itm) {
          return (restProps.getOptionLabel ? restProps.getOptionLabel(itm) : (0, _helpers.getLedgerAccountLabel)(itm)) === e.target.value || itm.humanId.toLowerCase() === e.target.value.toLowerCase();
        }) : null;

        if (restProps.useLedgerId) {
          formProps.control.setValue(formProps.name, match ? match.humanId : '');
        } else {
          formProps.control.setValue(formProps.name, match ? match : null);
        }

        formProps.control.setValue(renderInputParams.name, match ? restProps.getOptionLabel ? restProps.getOptionLabel(match) : (0, _helpers.getLedgerAccountLabel)(match) : '');
        field.onBlur();

        if (renderInputParams.onBlur) {
          renderInputParams.onBlur(e, match || null);
        }
      }
    })); // eslint-disable-next-line react-hooks/rules-of-hooks

    (0, _react.useEffect)(function () {
      var _a, _b;

      if (restProps.useLedgerId && restProps.options && restProps.options.length > 0) {
        var inputVal = (_b = (_a = formProps.control.getField(renderInputParams.name)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
        var match = inputVal !== null && inputVal !== undefined ? restProps.options.find(function (itm) {
          return (restProps.getOptionLabel ? restProps.getOptionLabel(itm) : (0, _helpers.getLedgerAccountLabel)(itm)) === inputVal || itm.humanId.toLowerCase() === inputVal.toLowerCase();
        }) : null;

        if (match) {
          formProps.control.setValue(renderInputParams.name, restProps.getOptionLabel ? restProps.getOptionLabel(match) : (0, _helpers.getLedgerAccountLabel)(match));
        }
      }
    }, [restProps.options]);

    function _getOptionLabel(option) {
      if (!option) {
        return "";
      } else {
        return (0, _helpers.getLedgerAccountLabel)(option);
      }
    }

    return (0, _jsxRuntime.jsx)(_material.Autocomplete, Object.assign({}, autocompleteDefaults, {
      getOptionLabel: function getOptionLabel(x) {
        return _getOptionLabel(x);
      }
    }, restProps, {
      ref: field.ref,
      value: field.value,
      onChange: function onChange(e, v, r) {
        if (restProps.useLedgerId) {
          field.onChange(v ? v.humanId : '');
        } else {
          field.onChange(v);
        }

        formProps.control.setValue(renderInputParams.name, v ? restProps.getOptionLabel ? restProps.getOptionLabel(v) : (0, _helpers.getLedgerAccountLabel)(v) : '');

        if (props.onChange) {
          props.onChange(e, v, r);
        }
      },
      renderInput: renderInput
    }));
  }

  var _default = FormGlAutocomplete;
  _exports.default = _default;
});