define("client/components/financial-calculator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function moneyHelper(params
  /*, hash*/
  ) {
    var input = params[0];
    if (input !== 0 && !input) return "0.00";
    if (Math.abs(input) < 0.01) return "0.00";

    function CommaFormatted(amount) {
      var delimiter = ","; // replace comma if desired

      var a = amount.split('.', 2);
      var d = a[1];
      var i = parseInt(a[0]);

      if (isNaN(i)) {
        return '';
      }

      var minus = '';

      if (i < 0) {
        minus = '-';
      }

      i = Math.abs(i);
      var n = new String(i);
      var a = [];

      while (n.length > 3) {
        var nn = n.substr(n.length - 3);
        a.unshift(nn);
        n = n.substr(0, n.length - 3);
      }

      if (n.length > 0) {
        a.unshift(n);
      }

      n = a.join(delimiter);

      if (d.length < 1) {
        amount = n;
      } else {
        amount = n + '.' + d;
      }

      amount = minus + amount;
      return amount;
    }

    return CommaFormatted(parseFloat(input).toFixed(2));
  }

  ;

  var _default = Ember.Component.extend({
    tagName: '',
    printThis: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.send('clear');
      this.set('solvingFor', 'payment');
    },
    sums: function sums(tempArr, month) {
      var length = tempArr.get('length'),
          lowerBound = length <= 12 ? -1 : length - (month ? month + 2 : 13),
          obj = Ember.Object.create({
        payments: 0,
        interest: 0,
        principal: 0
      });

      for (var i = length - 1; i > lowerBound; i--) {
        var itm = tempArr.objectAt(i);
        obj.setProperties({
          payments: obj.get('payments') + parseFloat(itm.get('payment')),
          interest: obj.get('interest') + parseFloat(itm.get('interest')),
          principal: obj.get('principal') + parseFloat(itm.get('principal'))
        });
      }

      return obj;
    },
    totals: function totals(tempArr) {
      var obj = Ember.Object.create({
        payments: 0,
        interest: 0
      });
      tempArr.forEach(function (item) {
        if (item.get('year') && item.get('year').includes('Totals')) {
          obj.setProperties({
            payments: obj.get('payments') + parseFloat(item.get('payment'))
          });
        }
      });
      obj.set('interest', obj.get('payments') - parseFloat(this.get('principal')));
      return obj;
    },
    actions: {
      clear: function clear() {
        this.setProperties({
          principal: "0.00",
          interestRate: "0.0000",
          payment: "0.00",
          term: 0,
          startDate: moment().format('YYYY-MM-DD')
        });
        this.set('schedule', null);
      },
      generateTable: function generateTable() {
        var term = parseInt(this.get('term')),
            payment = parseFloat(this.get('payment')),
            principal = parseFloat(this.get('principal')),
            interest = parseFloat(this.get('interestRate')) / 1200,
            startDate = this.get('startDate'),
            cumulativeInterest = 0,
            cumulativePrincipal = 0,
            cumulativeTotal = 0,
            tempArr = Ember.A([]),
            i = 0;

        while (i < term) {
          var interestPaid = principal * interest,
              principalPaid = i === term - 1 ? principal : payment - interestPaid,
              date = startDate ? moment(startDate, 'YYYY-MM-DD').add(i, 'months') : null,
              formattedDate = date ? date.format('YYYY-MM-DD') : null,
              month = date ? date.month() : null;
          cumulativeInterest += interestPaid;
          cumulativePrincipal += principalPaid;
          cumulativeTotal += interestPaid + principalPaid;
          var obj = Ember.Object.create({
            term: i + 1,
            payment: i === term - 1 ? (interestPaid + principalPaid).toFixed(2) : this.get('payment'),
            interest: interestPaid.toFixed(2),
            principal: principalPaid.toFixed(2),
            principalRemaining: (principal - principalPaid).toFixed(2),
            cumulativeInterest: cumulativeInterest.toFixed(2),
            cumulativePrincipal: cumulativePrincipal.toFixed(2),
            cumulativeTotal: cumulativeTotal.toFixed(2),
            date: formattedDate
          });
          principal -= principalPaid;
          tempArr.pushObject(obj);

          if (month === 11) {
            var sums = this.sums(tempArr);
            var sumObj = Ember.Object.create({
              term: "",
              payment: sums.get('payments').toFixed(2),
              interest: sums.get('interest').toFixed(2),
              principal: sums.get('principal').toFixed(2),
              principalRemaining: 0,
              cumulativeInterest: 0,
              cumulativePrincipal: 0,
              cumulativeTotal: 0,
              date: null,
              year: date.year() + " Totals"
            });
            tempArr.pushObject(sumObj);
          }

          if (i === term - 1 && date) {
            var _sums = this.sums(tempArr, date.month());

            var _sumObj = Ember.Object.create({
              term: "",
              payment: _sums.get('payments').toFixed(2),
              interest: _sums.get('interest').toFixed(2),
              principal: _sums.get('principal').toFixed(2),
              principalRemaining: 0,
              cumulativeInterest: 0,
              cumulativePrincipal: 0,
              cumulativeTotal: 0,
              date: null,
              year: date.year() + " Totals"
            });

            tempArr.pushObject(_sumObj);
            var totals = this.totals(tempArr);
            var totalsObj = Ember.Object.create({
              term: "",
              payment: totals.get('payments').toFixed(2),
              interest: totals.get('interest').toFixed(2),
              principal: this.get('principal'),
              principalRemaining: 0,
              cumulativeInterest: 0,
              cumulativePrincipal: 0,
              cumulativeTotal: 0,
              date: null,
              year: "Totals"
            });
            tempArr.pushObject(totalsObj);
          }

          i++;
        }

        this.set('schedule', tempArr);
      },
      onBlur: function onBlur(initialValue) {
        var _this = this;

        var defaultValues = [],
            keys = ['principal', 'interestRate', 'payment', 'term'];

        if (initialValue && keys.contains(initialValue)) {
          defaultValues.push(initialValue);
        }

        keys.forEach(function (item) {
          if (_this.get(item) === "0.0000" || _this.get(item) === "0.00" || Ember.isBlank(_this.get(item)) || _this.get(item) === 0) {
            if (!defaultValues.contains(item)) {
              defaultValues.push(item);
            }
          }
        });

        if (defaultValues.length <= 1) {
          var solvingFor = this.get('solvingFor'),
              principal = parseFloat(this.get('principal')),
              interestRate = parseFloat(this.get('interestRate')) / 1200,
              payment = parseFloat(this.get('payment')),
              term = parseInt(this.get('term')),
              digits = 2,
              value = null;

          if (solvingFor === "principal") {
            value = payment * ((1 - Math.pow(1 + interestRate, -1 * term)) / interestRate);
          } else if (solvingFor === "interestRate") {
            var q = Math.log(1 + 1 / term) / Math.log(2);
            payment = principal < 0 ? payment < 0 ? payment : payment * -1 : payment > 0 ? payment : payment * -1;
            value = Math.pow(Math.pow(1 + payment / principal, 1 / q) - 1, q) - 1;
            value *= 1200;
            digits = 6;
          } else if (solvingFor === "payment") {
            value = interestRate * principal / (1 - Math.pow(1 + interestRate, -1 * term));
          } else {
            payment = principal < 0 ? payment < 0 ? payment : payment * -1 : payment > 0 ? payment : payment * -1;
            value = Math.log(payment / (payment - interestRate * principal)) / Math.log(interestRate + 1);
            value = Math.ceil(value);
            digits = 0;
          }

          this.set(solvingFor, value.toFixed(digits));
        }
      },
      changeSolvingFor: function changeSolvingFor(val) {
        this.set('solvingFor', val);
        this.send('onBlur', val);
      },
      printTable: function printTable() {
        var date = moment(this.get('startDate')).format("MM/DD/YY");
        this.get('printThis').print('.floating-header-table-container', {
          header: "<div style=\"margin-top: 20px;\" class=\"layout-row layout-align-space-between\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<div></div>\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<p><b>Start Date: </b> ".concat(date, " </p> \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<p><b>Principal: </b> ").concat(moneyHelper([this.get('principal')]), " </p>\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<p><b>Interest Rate: </b> ").concat(this.get('interestRate'), " </p>\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<p><b>Term: </b>").concat(this.get('term'), "</p>   \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<div></div>\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t</div>")
        });
      }
    }
  });

  _exports.default = _default;
});