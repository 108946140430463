define("client/models/inventory-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var InventoryItem = _emberData.default.Model.extend({
    model: _emberData.default.attr(),
    partNumber: _emberData.default.attr(),
    itemName: _emberData.default.attr(),
    code: _emberData.default.attr(),
    category: _emberData.default.attr(),
    type: _emberData.default.attr(),
    description: _emberData.default.attr(),
    price: _emberData.default.attr(),
    priceYTD: _emberData.default.attr(),
    quantity: _emberData.default.attr(),
    location: _emberData.default.attr(),
    subLocation: _emberData.default.attr(),
    picture: _emberData.default.attr(),
    unitMeasurement: _emberData.default.attr(),
    costingMethod: _emberData.default.attr(),
    vendor: _emberData.default.belongsTo('vendor', {
      async: true
    })
  });

  InventoryItem.reopenClass({
    FIXTURES: []
  });
  var _default = InventoryItem;
  _exports.default = _default;
});