define("client/helpers/compute-last-followup", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computeLastFollowup = computeLastFollowup;
  _exports.default = void 0;

  function computeLastFollowup(params
  /*, hash*/
  ) {
    var solicitation = params[0],
        mailed = solicitation.get('mailedDate'),
        called = solicitation.get('calledDate'),
        emailed = solicitation.get('emailedDate'),
        manually = solicitation.get('manuallyContactedDate'),
        sortArr = [mailed, called, emailed, manually];
    sortArr = sortArr.sort(function (a, b) {
      if (a == null) {
        return 9999999;
      } else if (b == null) {
        return -999999;
      } else {
        return moment(a).isSameOrBefore(moment(b)) ? 1 : -1;
      }
    });
    return sortArr[0];
  }

  var _default = Ember.Helper.helper(computeLastFollowup);

  _exports.default = _default;
});