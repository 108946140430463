define("client/components/list-entry/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ember-select-multi'],
    list: Ember.A(['aardvark', 'test']),
    placeholder: "",
    entry: "",
    keyDown: function keyDown(e) {
      if (e.keyCode === 8) {
        this.send('backspaceLast');
      }
    },
    actions: {
      remove: function remove(item) {
        this.get('list').removeObject(item);
      },
      onEnter: function onEnter(fromSpace) {
        if (this.get('enter') && !fromSpace) {
          this.get('enter')();
          return;
        }

        if (!this.get('entry').trim()) return;
        this.get('list').pushObject(this.get('entry'));
        this.set('entry', "");
        if (this.get('onUpdate')) this.get('onUpdate')();
        return false;
      },
      onKey: function onKey(e) {
        if (e && e.length !== e.trim().length) {
          this.send('onEnter', true);
        }
      },
      backspaceLast: function backspaceLast() {
        if (this.get('list.length') > 0 && !(this.get('entry').length > 0)) {
          this.get('list').removeAt(this.get('list.length') - 1);
        }
      }
    }
  });

  _exports.default = _default;
});