define("client/controllers/rec-login", ["exports", "client/react/store", "client/react/data/reducer", "client/react/utilities/snackbar"], function (_exports, _store, _reducer, _snackbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    actions: {
      landing: function landing() {
        this.transitionToRoute('landing');
      },
      login: function login() {
        //              client.send("/app/hello", {}, JSON.stringify("world"));
        // undefined.get('dfs');
        console.log(Stripe);

        if (this.get('username') && this.get('password')) {
          Ember.$('#isLoading').show();
          Ember.$.ajax({
            url: "http://" + this.get('controllers.application.host') + ":8080/fsm/token",
            type: 'post',
            data: this.getProperties('username', 'password'),
            success: function (response) {
              this.set('access_token', response.access_token);
              this.set('isLoggedIn', true);
              this.set('activeUser', this.get('username'));
              var model = Ember.RSVP.hash({
                tenantSettings: this.store.findAll('tenant-settings', {
                  reload: true
                })
              }).then(function (hash) {
                _store.store.dispatch((0, _reducer.addModel)(hash[0].toJSON({
                  includeId: true
                }), 'tenantSettings'));

                this.transitionToRoute('records');
              }.bind(this));
            }.bind(this),
            error: function (reason) {
              Ember.$('#isLoading').hide();
              (0, _snackbar.showSnackbar)("Invalid username or password", {
                variant: 'error'
              });
            }.bind(this),
            dataType: 'json',
            headers: {
              "X-Auth-Tenant": this.get('tenantId')
            }
          });
        } else {
          (0, _snackbar.showSnackbar)("Please fill in all required fields", {
            variant: 'error'
          });
        }
      }
    },
    access_token: "",
    tenantId: null,
    activeUser: null
  });

  _exports.default = _default;
});