define("client/models/contract-solicitation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dateCreated: _emberData.default.attr('date2'),
    custId: _emberData.default.attr(),
    shipToId: _emberData.default.attr(),
    type: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    effectiveDate: _emberData.default.attr(),
    expirationDate: _emberData.default.attr(),
    checkupMonths: _emberData.default.attr('csv'),
    salesman: _emberData.default.attr(),
    commissionRate: _emberData.default.attr(),
    prepaidFlag: _emberData.default.attr(),
    cycle: _emberData.default.attr(),
    billedThrough: _emberData.default.attr(),
    priceColumn: _emberData.default.attr(),
    note: _emberData.default.attr(),
    building: _emberData.default.attr(),
    originalDate: _emberData.default.attr(),
    parentContract: _emberData.default.attr(),
    renewalContract: _emberData.default.attr(),
    sold: _emberData.default.attr(),
    paid: _emberData.default.attr(),
    archived: _emberData.default.attr(),
    mailed: _emberData.default.attr(),
    called: _emberData.default.attr(),
    emailed: _emberData.default.attr(),
    manuallyContacted: _emberData.default.attr(),
    mailedDate: _emberData.default.attr('date2'),
    calledDate: _emberData.default.attr('date2'),
    emailedDate: _emberData.default.attr('date2'),
    manuallyContactedDate: _emberData.default.attr('date2'),
    invoiceNumber: _emberData.default.attr('capitalize'),
    followupDate: _emberData.default.attr(),
    billingName: _emberData.default.attr(),
    billingApartmentNumber: _emberData.default.attr(),
    billingAddress: _emberData.default.attr(),
    billingCity: _emberData.default.attr(),
    billingState: _emberData.default.attr(),
    billingZip: _emberData.default.attr(),
    custPhone: _emberData.default.attr('telephone'),
    phone: _emberData.default.attr('telephone'),
    siteName: _emberData.default.attr(),
    siteApartmentNumber: _emberData.default.attr(),
    siteAddress: _emberData.default.attr(),
    siteCity: _emberData.default.attr(),
    siteState: _emberData.default.attr(),
    siteZip: _emberData.default.attr(),
    contract: _emberData.default.belongsTo('contract', {
      async: true
    }),
    customer: _emberData.default.belongsTo('customer', {
      async: true,
      inverse: null
    }),
    site: _emberData.default.belongsTo('site-address', {
      async: true
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    communications: _emberData.default.hasMany('communication', {
      async: true
    }),
    equipment: _emberData.default.hasMany('equipment-item', {
      async: true
    }),
    checkups: _emberData.default.hasMany('solicitation-checkup', {
      async: true
    }),
    options: _emberData.default.hasMany('linked-solicitation-option', {
      async: true
    }),
    billings: _emberData.default.hasMany('solicitation-billing', {
      async: true
    }),
    label: Ember.computed('id', 'type', 'effectiveDate', 'expirationDate', function () {
      return "".concat(this.get('id'), " - ").concat(this.get('type'), "  Eff: ").concat(this.get('effectiveDate'), " Exp: ").concat(this.get('expirationDate'));
    }),
    mostRecentContact: Ember.computed('mailedDate', 'calledDate', 'emailedDate', 'manuallyContactedDate', function () {
      var mailed = this.get('mailedDate'),
          called = this.get('calledDate'),
          emailed = this.get('emailedDate'),
          manually = this.get('manuallyContactedDate'),
          sortArr = [mailed, called, emailed, manually];
      sortArr = sortArr.sort(function (a, b) {
        if (a == null) {
          return 9999999;
        } else if (b == null) {
          return -999999;
        } else {
          return moment(a).isSameOrBefore(moment(b)) ? 1 : -1;
        }
      });
      return sortArr[0];
    })
  });

  _exports.default = _default;
});