define("client/models/dynamic-field", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dynamicInvoice: _emberData.default.belongsTo('dynamic-invoice'),
    description: _emberData.default.attr(),
    formula: _emberData.default.attr(),
    defaultEnabled: _emberData.default.attr()
  });

  _exports.default = _default;
});