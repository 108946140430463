define("client/components/vr-paper-select/component", ["exports", "ember-paper/components/paper-select", "ember-power-select/utils/group-utils"], function (_exports, _paperSelect, _groupUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperSelect.default.extend({
    optionsComponent: 'vr-paper-select-options',
    vrScrollTo: 0,
    actions: {
      scrollTo: function scrollTo(option
      /*, e */
      ) {
        if (!self.document || !option) {
          return;
        } // let publicAPI = this.get('publicAPI');
        // let optionsList = self.document.getElementById('ember-power-select-options-' + publicAPI.uniqueId);
        // if (!optionsList) { return; }
        // let index = indexOfOption(publicAPI.results, option);
        // if (index === -1) { return; }
        // this.set('vrScrollTo',index);
        // let optionElement = optionsList.querySelectorAll('[data-option-index]').item(index);
        // let optionTopScroll = optionElement.offsetTop - optionsList.offsetTop;
        // let optionBottomScroll = optionTopScroll + optionElement.offsetHeight;
        // if (optionBottomScroll > optionsList.offsetHeight + optionsList.scrollTop) {
        //   optionsList.scrollTop = optionBottomScroll - optionsList.offsetHeight;
        // } else if (optionTopScroll < optionsList.scrollTop) {
        //   optionsList.scrollTop = optionTopScroll;
        // }

      }
    }
  });

  _exports.default = _default;
});