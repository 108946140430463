define("client/routes/sales-posting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        settings: this.store.findRecord('ar-setting', 1, {
          reload: true
        }),
        ledgerAccounts: this.store.findAll('ledger-account', {
          reload: true
        })
      });
    }
  });

  _exports.default = _default;
});