define("client/templates/vendor-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sk4aJmQ7",
    "block": "{\"statements\":[[1,[33,[\"vendor-view\"],null,[[\"model\",\"close\",\"create\",\"edit\",\"toPurchaseJournal\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"transitionNoParam\",\"vendor-search\"],null],[33,[\"route-action\"],[\"transitionNoParam\",\"vendor-create\"],null],[33,[\"route-action\"],[\"transition\",\"vendor-edit\",[28,[\"model\"]]],null],[33,[\"route-action\"],[\"transition\",\"purchase-journal\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/vendor-view.hbs"
    }
  });

  _exports.default = _default;
});