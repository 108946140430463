define("client/templates/landing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DgNpXKqP",
    "block": "{\"statements\":[[6,[\"ember-wormhole\"],null,[[\"to\"],[\"main-toolbar\"]],{\"statements\":[[0,\"    \"],[11,\"h4\",[]],[13],[0,\"\\n        Landing Page\\n    \"],[14],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"login-background layout-row layout-align-center-center\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"layout-column layout-align-center-center\"],[15,\"style\",\"margin-top: -48px;width:100%;\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"style\",\"background-image: url('g3.png');background-size: contain;width: 360px;height: 127.5px;background-repeat: no-repeat;background-position: center;\"],[13],[14],[0,\"\\n        \"],[11,\"span\",[]],[15,\"style\",\"font-size:18px\"],[13],[0,\"Welcome to ServiSuite, \"],[1,[28,[\"auth\",\"activeUser\"]],false],[0,\"!\"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/landing.hbs"
    }
  });

  _exports.default = _default;
});