define("client/helpers/is-main-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isMainStatus = isMainStatus;

  function isMainStatus(params
  /*, hash*/
  ) {
    return ["New Construction", "Auto-Generated", "Finished", "Renewal Auto-Generated", "Lunch", "Open", "Reschedule", "On Site", "Traveling", "Waiting", "Cancelled", "Pending Finish", "Emergency"].includes(params[0]);
  }

  var _default = Ember.Helper.helper(isMainStatus);

  _exports.default = _default;
});