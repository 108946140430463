define("client/components/report-field-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aFeE/OiY",
    "block": "{\"statements\":[[6,[\"paper-autocomplete\"],null,[[\"options\",\"selected\",\"searchText\",\"searchField\",\"labelPath\",\"triggerClass\",\"matcher\",\"labelPath\",\"label\",\"onSelectionChange\",\"onBlur\",\"onSearchTextChange\"],[[28,[\"filteredOptions\"]],[28,[\"selected\",\"content\"]],[33,[\"get\"],[[28,[\"selected\"]],[28,[\"primaryLabel\"]]],null],\"shipId\",[28,[\"primaryLabel\"]],\"flex\",[28,[\"matcher\"]],[28,[\"primaryLabel\"]],[28,[\"label\"]],[33,[\"action\"],[[28,[null]],\"setModel\"],null],[33,[\"action\"],[[28,[null]],\"blur\"],null],[33,[\"perform\"],[[28,[\"changeSearchText\"]]],null]]],{\"statements\":[[0,\"\\t\"],[1,[33,[\"paper-autocomplete-highlight\"],null,[[\"label\",\"searchText\",\"flags\"],[[33,[\"concat\"],[[33,[\"get\"],[[28,[\"model\"]],[28,[\"primaryLabel\"]]],null],\" - \",[33,[\"get\"],[[28,[\"model\"]],[28,[\"secondaryLabel\"]]],null]],null],[28,[\"select\",\"searchText\"]],\"i\"]]],false],[0,\"\\n\"]],\"locals\":[\"model\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/report-field-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});