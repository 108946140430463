define("client/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;

  function formatDate(params
  /*,hash*/
  ) {
    var input = params[0];
    var formatter = params[1] || 'MM/DD/YYYY';
    var date = input;
    date = moment(date, "YYYY-MM-DD").format(formatter);
    return date !== "Invalid date" ? date : '../../..';
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});