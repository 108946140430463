define("client/helpers/mmyy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mmyy = mmyy;

  function mmyy(params
  /*, hash*/
  ) {
    if (!params[0]) return "";
    return moment(params[0], 'MMYY').format('MM/YY');
  }

  var _default = Ember.Helper.helper(mmyy);

  _exports.default = _default;
});