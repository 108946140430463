define("client/models/contract-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    code: _emberData.default.attr(),
    desc: _emberData.default.attr('capitalize'),
    taxable: _emberData.default.attr('citron-bool'),
    labor: _emberData.default.attr('citron-bool'),
    parts: _emberData.default.attr('citron-bool'),
    deferredGl: _emberData.default.attr(),
    saleGl: _emberData.default.attr(),
    preferredCustomer: _emberData.default.attr('citron-bool'),
    partsDiscount: _emberData.default.attr('trim'),
    laborDiscount: _emberData.default.attr('trim'),
    preferredDiscount: _emberData.default.attr('trim'),
    plannedMaintenance: _emberData.default.attr('citron-bool'),
    excludedCostCenters: _emberData.default.attr('csv'),
    label: Ember.computed(function () {
      return this.get('code') + ' - ' + this.get('desc');
    })
  });

  _exports.default = _default;
});