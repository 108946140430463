define("client/templates/rec-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X68Be/US",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"login-background\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"absolute-center col-lg-3 form-group\"],[15,\"style\",\"height:100vh; width:584px;margin-top:30vh;\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"style\",\"background-image: url('g3.png');background-size: contain;width: 584px;height: 127.5px;background-repeat: no-repeat;background-position: center;\"],[13],[14],[0,\"\\n        \"],[11,\"form\",[]],[15,\"name\",\"login\"],[15,\"style\",\"width: 75%;transform: translate(50%);margin-left: -25%;\"],[13],[0,\"\\n\"],[0,\"            \"],[11,\"strong\",[]],[13],[0,\"User:\"],[14],[0,\"\\n          \"],[1,[33,[\"input\"],null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"text\",\"form-control login-input\",\"Username\",[28,[\"username\"]]]]],false],[0,\"\\n            \"],[11,\"strong\",[]],[13],[0,\"Password:\"],[14],[0,\"\\n          \"],[1,[33,[\"input\"],null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"password\",\"form-control login-input\",\"Password\",[28,[\"password\"]]]]],false],[0,\"\\n            \"],[11,\"div\",[]],[15,\"style\",\"width: 100%; text-align: center;\"],[13],[0,\"\\n            \\t\"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn btn-primary\"],[15,\"style\",\"margin-top: 5px;background-color:#00638D;border-color:#00638D\"],[5,[\"action\"],[[28,[null]],\"landing\"]],[13],[0,\"Back\"],[14],[0,\"\\n                \"],[11,\"button\",[]],[15,\"type\",\"submit\"],[15,\"class\",\"btn btn-primary\"],[15,\"style\",\"margin-top: 5px;background-color:#00638D;border-color:#00638D\"],[5,[\"action\"],[[28,[null]],\"login\"]],[13],[0,\"Login\"],[14],[0,\"\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/rec-login.hbs"
    }
  });

  _exports.default = _default;
});