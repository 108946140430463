define("client/react/utilities/typed/smart-each", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function smartEach(_ref) {
    var length = _ref.length,
        addRow = _ref.addRow;
    return function (idx) {
      return function (e) {
        if (idx === length - 1) {
          addRow();
        }

        e.stopPropagation();
      };
    };
  }

  var _default = smartEach;
  _exports.default = _default;
});