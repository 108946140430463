define("client/helpers/not-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.notHelper = notHelper;

  function notHelper(params
  /*, hash*/
  ) {
    return !params;
  }

  var _default = Ember.Helper.helper(notHelper);

  _exports.default = _default;
});