define("client/components/product-comparisons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+62wfNKO",
    "block": "{\"statements\":[[6,[\"ember-wormhole\"],null,[[\"to\"],[\"main-toolbar\"]],{\"statements\":[[0,\"\\t\"],[11,\"h4\",[]],[13],[0,\"\\n\\t\\tProduct Comparisons\\n\\t\"],[14],[0,\"\\n\\t\"],[11,\"span\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},null],[18,\"default\"],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/product-comparisons/template.hbs"
    }
  });

  _exports.default = _default;
});