define("client/react/components/typed/form-radio-group", ["exports", "react/jsx-runtime", "@mui/material", "@Harris-Barrick-Software/use-form"], function (_exports, _jsxRuntime, _material, _useForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function FormRadioGroup(_ref) {
    var label = _ref.label,
        formProps = _ref.formProps,
        _onChange = _ref.onChange,
        className = _ref.className,
        classes = _ref.classes,
        children = _ref.children;
    var name = formProps.name,
        control = formProps.control;
    var controller = (0, _useForm.useController)({
      name: name,
      control: control
    });

    if (!controller) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field;
    return (0, _jsxRuntime.jsxs)(_material.FormControl, Object.assign({
      className: className,
      component: "fieldset"
    }, {
      children: [(0, _jsxRuntime.jsx)(_material.FormLabel, Object.assign({
        className: classes === null || classes === void 0 ? void 0 : classes.label,
        component: "legend"
      }, {
        children: label
      })), (0, _jsxRuntime.jsx)(_material.RadioGroup, Object.assign({
        name: name,
        value: field.value,
        onChange: function onChange(e) {
          field.onChange(e.target.value);

          if (_onChange) {
            _onChange(e);
          }
        }
      }, {
        children: children
      }))]
    }));
  }

  var _default = FormRadioGroup;
  _exports.default = _default;
});