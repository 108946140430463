define("client/react/data/adapter", ["exports", "client/react/data/reducer"], function (_exports, _reducer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "addModel", {
    enumerable: true,
    get: function get() {
      return _reducer.addModel3;
    }
  });
  Object.defineProperty(_exports, "addModels", {
    enumerable: true,
    get: function get() {
      return _reducer.addModels3;
    }
  });
  Object.defineProperty(_exports, "removeModels", {
    enumerable: true,
    get: function get() {
      return _reducer.removeModels3;
    }
  });
});