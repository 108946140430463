define("client/components/summary-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isExpanded: Ember.computed.alias('data.isExpanded'),
    actions: {
      toggleExpand: function toggleExpand() {
        this.toggleProperty('isExpanded');
      }
    }
  });

  _exports.default = _default;
});