define("client/templates/srvc-contract-solicitation-convert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1PrDuMjo",
    "block": "{\"statements\":[[1,[33,[\"srvc-contract-solicitation-convert\"],null,[[\"solicitation\",\"types\",\"options\",\"toSearch\",\"arSettings\"],[[28,[\"model\",\"solicitation\"]],[28,[\"model\",\"types\"]],[28,[\"model\",\"options\"]],[33,[\"route-action\"],[\"toSearch\"],null],[28,[\"model\",\"arSettings\"]]]]],false],[0,\"\\n\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/srvc-contract-solicitation-convert.hbs"
    }
  });

  _exports.default = _default;
});