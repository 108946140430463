define("client/components/wysiwyg-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bfMkaJay",
    "block": "{\"statements\":[[6,[\"paper-dialog\"],null,[[\"origin\"],[[28,[\"origin\"]]]],{\"statements\":[[6,[\"paper-toolbar\"],null,null,{\"statements\":[[6,[\"paper-toolbar-tools\"],null,null,{\"statements\":[[0,\"            \"],[11,\"h4\",[]],[13],[1,[26,[\"title\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"paper-dialog-content\"],null,null,{\"statements\":[[0,\"        \"],[1,[33,[\"summer-note\"],null,[[\"height\",\"content\",\"onContentChange\"],[500,[33,[\"readonly\"],[[28,[\"tempNotes\"]]],null],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"tempNotes\"]]],null]],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"paper-dialog-actions\"],null,null,{\"statements\":[[0,\"        \"],[1,[33,[\"paper-button\"],null,[[\"label\",\"onClick\"],[\"Cancel\",[28,[\"close\"]]]]],false],[0,\"\\n        \"],[1,[33,[\"paper-button\"],null,[[\"label\",\"onClick\"],[\"Save\",[33,[\"action\"],[[28,[null]],[28,[\"save\"]],[28,[\"tempNotes\"]]],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/wysiwyg-notes/template.hbs"
    }
  });

  _exports.default = _default;
});