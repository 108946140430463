define("client/models/vendor-contract", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    vendId: _emberData.default.attr('trim'),
    jobId: _emberData.default.attr(),
    contractNumber: _emberData.default.attr('trim'),
    date: _emberData.default.attr(),
    contractAmount: _emberData.default.attr(),
    amountBooked: _emberData.default.attr(),
    vendor: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    job: _emberData.default.belongsTo('job', {
      async: true
    })
  });

  _exports.default = _default;
});