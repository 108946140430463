define("client/templates/srvc-contract-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TxLYXmUf",
    "block": "{\"statements\":[[1,[33,[\"srvc-contract-view\"],null,[[\"contract\",\"onSearch\",\"close\",\"create\",\"delete\",\"edit\",\"openPrevious\",\"openRenewal\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"transitionNoParam\",\"srvc-contract-search\"],null],[33,[\"route-action\"],[\"back\"],null],[33,[\"route-action\"],[\"transitionNoParam\",\"srvc-contract-create\"],null],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null]],null],[33,[\"route-action\"],[\"transition\",\"srvc-contract-edit\"],null],[33,[\"route-action\"],[\"openPrevious\",[33,[\"get\"],[[28,[\"model\"]],\"lastContractId\"],null]],null],[33,[\"route-action\"],[\"openRenewal\",[33,[\"get\"],[[28,[\"model\"]],\"nextContractId\"],null]],null]]]],false],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"isDeleting\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"srvc-contract-delete\"],null,[[\"model\",\"onClose\",\"onDelete\"],[[28,[\"isDeleting\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null],null],null],[33,[\"pipe\"],[[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null],null],null],[33,[\"route-action\"],[\"transitionNoParam\",\"srvc-contract-search\"],null]],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/srvc-contract-view.hbs"
    }
  });

  _exports.default = _default;
});