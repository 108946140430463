define("client/models/part-category", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    description: _emberData.default.attr('capitalize'),
    catId: _emberData.default.attr('capitalize'),
    trimmedCatId: Ember.computed(function () {
      return (this.get('catId') || "").trim();
    }),
    criteria: _emberData.default.attr('capitalize'),
    lead: _emberData.default.attr(),
    freight: _emberData.default.attr('capitalize'),
    multiplier1: _emberData.default.attr('price-multiplier'),
    multiplier2: _emberData.default.attr('price-multiplier'),
    multiplier3: _emberData.default.attr('price-multiplier'),
    multiplier4: _emberData.default.attr('price-multiplier'),
    multiplier5: _emberData.default.attr('price-multiplier'),
    multiplier6: _emberData.default.attr('price-multiplier'),
    oldMultiplier1: _emberData.default.attr('price-multiplier'),
    oldMultiplier2: _emberData.default.attr('price-multiplier'),
    oldMultiplier3: _emberData.default.attr('price-multiplier'),
    oldMultiplier4: _emberData.default.attr('price-multiplier'),
    oldMultiplier5: _emberData.default.attr('price-multiplier'),
    oldMultiplier6: _emberData.default.attr('price-multiplier'),
    update: _emberData.default.attr('yn-tf'),
    updateAmount: _emberData.default.attr(),
    breakType: _emberData.default.attr(),
    breakQty1: _emberData.default.attr(),
    breakQty2: _emberData.default.attr(),
    breakQty3: _emberData.default.attr(),
    breakQty4: _emberData.default.attr(),
    breakQty5: _emberData.default.attr(),
    breakQty6: _emberData.default.attr(),
    breakMultiplier1: _emberData.default.attr(),
    breakMultiplier2: _emberData.default.attr(),
    breakMultiplier3: _emberData.default.attr(),
    breakMultiplier4: _emberData.default.attr(),
    breakMultiplier5: _emberData.default.attr(),
    breakMultiplier6: _emberData.default.attr(),
    label: Ember.computed(function () {
      return this.get('catId') + '-' + this.get('description');
    })
  });

  _exports.default = _default;
});