define("client/utils/set-invoice-item-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setInvoiceItemDescription;

  function setInvoiceItemDescription(item) {
    var ld = item.get('longDescription') || "";

    if (ld.length > 15) {
      item.set('prodId', ld.substr(0, 15).toUpperCase());
      item.set('prodDescription', ld.substr(15, 45).toUpperCase());
    } else {
      item.set('prodId', ld.toUpperCase());
      item.set('prodDescription', '');
    }

    return item;
  }
});