define("client/react/components/typed/selectable-data-grid", ["exports", "react/jsx-runtime", "@mui/x-data-grid-premium", "react", "react-fast-compare"], function (_exports, _jsxRuntime, _xDataGridPremium, _react, _reactFastCompare) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function SelectableDataGrid(props) {
    var oldSelectionModel = (0, _react.useRef)([]);

    var defaultRowId = function defaultRowId(row) {
      return row.id;
    };

    var getRowId = props.getRowId || defaultRowId;
    var rowsById = (0, _react.useMemo)(function () {
      return props.rows.reduce(function (p, c) {
        p[getRowId(c)] = c;
        return p;
      }, {});
    }, [props.rows]);
    var incomingSelectionModel = props.selection ? props.selection.map(function (x) {
      return getRowId(x);
    }) : null;

    if (incomingSelectionModel && !(0, _reactFastCompare.default)(incomingSelectionModel, oldSelectionModel.current)) {
      oldSelectionModel.current = incomingSelectionModel;
    }

    return (0, _jsxRuntime.jsx)(_xDataGridPremium.DataGridPremium, Object.assign({
      checkboxSelection: true
    }, props, {
      selectionModel: incomingSelectionModel || undefined,
      getRowId: getRowId,
      onSelectionModelChange: function onSelectionModelChange(selectionModel) {
        var newSelection = selectionModel.map(function (id) {
          return rowsById[id];
        });
        var added = selectionModel.filter(function (x) {
          return !oldSelectionModel.current.includes(x);
        }).map(function (id) {
          return rowsById[id];
        });
        var removed = oldSelectionModel.current.filter(function (x) {
          return !selectionModel.includes(x);
        }).map(function (id) {
          return rowsById[id];
        });

        if (added.length) {
          props.onSelectionChange(newSelection, added, 'select');
        } else {
          props.onSelectionChange(newSelection, removed, 'deselect');
        }

        oldSelectionModel.current = selectionModel;
      }
    }));
  }

  var _default = SelectableDataGrid;
  _exports.default = _default;
});