define("client/components/ledger-system-defaults/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    style: "height:100%",
    willDestroy: function willDestroy() {
      this.get('settings').rollbackAttributes();
    },
    validGl: null,
    init: function init() {
      this._super.apply(this, arguments);

      var inputValidation = [{
        message: "Account doesn't exist!",
        validate: function (accounts) {
          return function (input) {
            if (input === '') return true;
            return accounts.findBy('ledgerId', input);
          };
        }(this.get('glAccounts'))
      }];
      this.set('validGl', inputValidation);
    },
    actions: {
      reportSubTotals: function reportSubTotals() {},
      save: function save() {
        Ember.$('#isLoading').show();
        this.get('settings').save().then(function (s) {
          Ember.$('#isLoading').hide();
        });
      },
      validateInputs: function validateInputs(status) {
        this.set('inputsInvalid', !status);
      }
    }
  });

  _exports.default = _default;
});