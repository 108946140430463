define("client/react/components/typed/currency-text-field", ["exports", "react/jsx-runtime", "autonumeric", "react", "@mui/material"], function (_exports, _jsxRuntime, _autonumeric, _react, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function CurrencyTextField(_a) {
    var _b;

    var autoNumericOptions = _a.autoNumericOptions,
        restProps = __rest(_a, ["autoNumericOptions"]);

    var internalTextFieldRef = (0, _react.useRef)(null);
    var autoNumeric = (0, _react.useRef)(null);
    (0, _react.useEffect)(function () {
      autoNumeric.current = new _autonumeric.default(internalTextFieldRef.current, (restProps === null || restProps === void 0 ? void 0 : restProps.value) || 0, Object.assign(Object.assign({
        modifyValueOnWheel: false,
        outputFormat: 'number',
        negativePositiveSignPlacement: 'p'
      }, autoNumericOptions), {
        currencySymbol: ''
      }));
      return function () {
        var _a;

        return (_a = autoNumeric.current) === null || _a === void 0 ? void 0 : _a.remove();
      }; // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (restProps.value !== undefined && internalTextFieldRef.current && document.activeElement !== internalTextFieldRef.current) {
      (_b = autoNumeric.current) === null || _b === void 0 ? void 0 : _b.set(restProps.value);
    }

    var adornmentPosition = (autoNumericOptions === null || autoNumericOptions === void 0 ? void 0 : autoNumericOptions.currencySymbolPlacement) ? (autoNumericOptions === null || autoNumericOptions === void 0 ? void 0 : autoNumericOptions.currencySymbolPlacement) === 's' ? 'end' : 'start' : 'start';
    return (0, _jsxRuntime.jsx)(_material.TextField, Object.assign({
      sx: {
        "& input": {
          textAlign: 'right'
        }
      },
      inputRef: internalTextFieldRef,
      size: 'small',
      variant: 'standard'
    }, restProps, {
      value: undefined,
      InputProps: (restProps === null || restProps === void 0 ? void 0 : restProps.InputProps) || _defineProperty({}, "".concat(adornmentPosition, "Adornment"), (autoNumericOptions === null || autoNumericOptions === void 0 ? void 0 : autoNumericOptions.currencySymbol) !== '' ? (0, _jsxRuntime.jsx)(_material.InputAdornment, Object.assign({
        position: adornmentPosition
      }, {
        children: (autoNumericOptions === null || autoNumericOptions === void 0 ? void 0 : autoNumericOptions.currencySymbol) || '$'
      })) : null),
      onChange: function onChange(e) {
        var valFromInput = e.target.value;
        var isString = !!(autoNumericOptions === null || autoNumericOptions === void 0 ? void 0 : autoNumericOptions.outputFormat) && autoNumericOptions.outputFormat === 'string';
        var isNullOrUndefined = valFromInput === undefined || valFromInput === null; // noinspection JSObjectNullOrUndefined

        var formattedVal = isNullOrUndefined ? '' : isString ? valFromInput.toString() : isNaN(parseFloat(valFromInput.replace(/([^\d.-])/g, ''))) ? '' : parseFloat(valFromInput.replace(/([^\d.-])/g, ''));

        if (restProps === null || restProps === void 0 ? void 0 : restProps.onChange) {
          restProps.onChange(e, formattedVal);
        }
      },
      onBlur: function onBlur(e) {
        var _a;

        if (!e.target.value) {
          var stringValue = 0 .toFixed(typeof (autoNumericOptions === null || autoNumericOptions === void 0 ? void 0 : autoNumericOptions.decimalPlaces) !== "number" ? 2 : autoNumericOptions.decimalPlaces);
          (_a = autoNumeric.current) === null || _a === void 0 ? void 0 : _a.set(stringValue);
        }

        var valAsNumber = isNaN(parseFloat(e.target.value.replace(/([^\d.-])/g, ''))) ? 0.0 : parseFloat(e.target.value.replace(/([^\d.-])/g, ''));

        if (restProps === null || restProps === void 0 ? void 0 : restProps.onBlur) {
          restProps.onBlur(e, valAsNumber);
        }
      }
    }));
  }

  var _default = CurrencyTextField;
  _exports.default = _default;
});