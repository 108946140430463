define("client/components/check-rec-balance/component", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    auth: Ember.inject.service(),
    balances: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.errors = [];
      Ember.$.ajax({
        url: _environment.default.APP.endpoint + "/fsm/checkRecBalances",
        type: 'get',
        success: function (success) {
          var newSuccess = success.map(function (item) {
            return Ember.Object.create({
              glCode: item.glcode,
              balance: item.balance
            });
          });
          var emberArr = Ember.A([]);
          newSuccess.forEach(function (item) {
            emberArr.pushObject(item);
          });
          this.set('balances', emberArr);
        }.bind(this),
        error: function (reason) {
          if (reason.status === 200) {
            console.log(reason);
          } else {
            console.log(reason);
          }
        }.bind(this),
        headers: {
          "X-Auth-Tenant": this.get('auth.tenantId'),
          "X-Auth-Token": this.get('auth.access_token')
        },
        dataType: 'json'
      });
    },
    actions: {
      close: function close() {
        this.get('closeDialog')();
      },
      selectGl: function selectGl(item) {
        var glAccount = this.get('glAccounts').findBy('ledgerId', item.get('glCode'));
        this.get('changeGl')(glAccount);
        this.get('closeDialog')();
      }
    }
  });

  _exports.default = _default;
});