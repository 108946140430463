define("client/react/auth/reducer", ["exports", "@reduxjs/toolkit"], function (_exports, _toolkit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeUser = _exports.changeToken = _exports.changeTenant = _exports.changeAuth = _exports.authReducer = _exports.authInitialState = void 0;
  var changeToken = (0, _toolkit.createAction)('auth/changeToken');
  _exports.changeToken = changeToken;
  var changeTenant = (0, _toolkit.createAction)('auth/changeTenant');
  _exports.changeTenant = changeTenant;
  var changeAuth = (0, _toolkit.createAction)('auth/changeAuth');
  _exports.changeAuth = changeAuth;
  var changeUser = (0, _toolkit.createAction)('auth/changeUser');
  _exports.changeUser = changeUser;
  var authInitialState = {
    access_token: null,
    tenant: null,
    auth: {},
    user: null
  };
  _exports.authInitialState = authInitialState;
  var authReducer = (0, _toolkit.createReducer)(authInitialState, function (builder) {
    builder.addCase(changeToken, function (state, action) {
      state.access_token = action.payload;
    }).addCase(changeTenant, function (state, action) {
      state.tenant = action.payload;
    }).addCase(changeAuth, function (state, action) {
      state.auth = action.payload;
    }).addCase(changeUser, function (state, action) {
      state.user = action.payload;
    });
  });
  _exports.authReducer = authReducer;
});