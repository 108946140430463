define("client/helpers/froala-method", ["exports", "ember-froala-editor/helpers/froala-method"], function (_exports, _froalaMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _froalaMethod.default;
    }
  });
  Object.defineProperty(_exports, "froalaMethod", {
    enumerable: true,
    get: function get() {
      return _froalaMethod.froalaMethod;
    }
  });
});