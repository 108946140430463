define("client/components/error-toast/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gdhjQmav",
    "block": "{\"statements\":[[0,\" \"],[11,\"div\",[]],[15,\"class\",\"alert alert-danger\"],[15,\"style\",\"display: inline-block;\"],[13],[11,\"strong\",[]],[13],[0,\"Error:\"],[14],[0,\" \"],[1,[26,[\"errorMessage\"]],false],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/error-toast/template.hbs"
    }
  });

  _exports.default = _default;
});