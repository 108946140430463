define("client/components/ledger-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    class: '',
    glAccountService: Ember.inject.service('gl-accounts'),
    glAccounts: Ember.computed.alias('glAccountService.sortedAccounts'),
    sortedGlAccounts: Ember.computed('overrideAccounts', 'glAccounts', function () {
      if (this.get('overrideAccounts')) {
        return this.get('overrideAccounts').sortBy('ledgerId');
      }

      return this.get('glAccounts');
    }),
    glSearchMatcher: function glSearchMatcher(account, term) {
      if (account === '') return -1;
      return (account.get('ledgerId') + ' ' + account.get('description').toLowerCase()).indexOf(term.toLowerCase());
    }
  });

  _exports.default = _default;
});