define("client/components/new-money-input/component", ["exports", "ember-paper/components/paper-input"], function (_exports, _paperInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperInput.default.extend({
    tempValue: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.send('onBlur', 'init');
    },
    didRender: function didRender() {
      var isFocused = this.$('input').is(':focus');

      if (!isFocused) {
        this.setValue(this.get('value'));
      }
    },
    click: function click(e) {
      e.stopPropagation();
    },
    actions: {
      onBlur: function onBlur(init) {
        if (this.get('value') === undefined) return;

        if (this.get('value') === '' && init !== "init") {
          if (this.get('onChange')) this.sendAction('onChange', this.get('tempValue'));else this.set('value', this.get('tempValue'));
          var valueForBlur = this.get('tempValue');
          this.set('tempValue', null);
          this.sendAction('blurNoMatterWhat', valueForBlur);
          return;
        }

        this.set('tempValue', null);
        var value = this.get('value'); // if (typeof value === "number") value = value.toFixed(2);

        var wholeNumber = /^(-?\d+)$/;
        var justDecimal = /^(-?\d+\.)$/;
        var onePlace = /^(-?\d+\.\d)$/;
        var twoPlacesWithoutLead = /^(-?\.\d{2})$/;
        var twoPlaces = /^(-?\d+\.\d{2})$/;
        var moreThanTwo = /^(-?\d+\.\d{3,})$/;
        var oneCent = /^(\.\d{1})$/;

        if (wholeNumber.test(value)) {
          value = value + ".00";
        } else if (justDecimal.test(value)) {
          value = value + "00";
        } else if (onePlace.test(value) || oneCent.test(value)) {
          value = value + "0";
        } else if (twoPlaces.test(value)) {} else if (twoPlacesWithoutLead.test(value)) {
          value = value.replace(".", "0.");
        } else if (moreThanTwo.test(value)) {
          value = parseFloat(value).toFixed(2);
        } else {
          value = '0.00';
        }

        if (this.get('onChange')) {
          if (init === 'init') {
            Ember.run.scheduleOnce('afterRender', this, function () {
              this.get('onChange')(value, "init"); // ADDED 10/18/17 so order entry didn't override tax/discount flags
            });
          } else {
            this.get('onChange')(value);
          }
        }

        if (this.get('extraBlur') && init !== 'init') {
          this.get('extraBlur')(value);
        }
      },
      onFocus: function onFocus() {
        this.set('tempValue', this.get('value'));
        this.set('value', '');
        this.setValue('');
        this.get('onChange')(this.get('value'));
        this.sendAction('onFocus2');
      },
      handleBlur: function handleBlur(e) {
        this.send('onBlur', e);
        this.set('isTouched', true);
        this.notifyValidityChange();
      }
    }
  });

  _exports.default = _default;
});