define("client/react/components/alt/form-date-text-field", ["exports", "client/react/utilities/use-form", "@mui/material"], function (_exports, _useForm, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["formProps"];

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function FormDateTextField(props) {
    var _fieldState$error;

    var formProps = props.formProps,
        restProps = _objectWithoutProperties(props, _excluded);

    var _useController = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    }),
        field = _useController.field,
        fieldState = _useController.fieldState;

    return React.createElement(_material.TextField, _extends({
      error: fieldState.invalid,
      helperText: !!props.hideHelperText ? null : (_fieldState$error = fieldState.error) === null || _fieldState$error === void 0 ? void 0 : _fieldState$error.message,
      type: 'date',
      InputLabelProps: {
        shrink: true
      }
    }, restProps, {
      inputRef: field.ref,
      onChange: function onChange(e) {
        field.onChange(e.target.value);

        if (restProps.onChange) {
          restProps.onChange(e);
        }
      },
      onBlur: function onBlur(e) {
        if (e.target.value && parseInt(e.target.value.substr(0, 4)) < 100) {
          field.onChange("20".concat(e.target.value.substr(2)));

          if (restProps.onBlur) {
            restProps.onBlur(e, "20".concat(e.target.value.substr(2)));
          }
        } else {
          if (restProps.onBlur) {
            restProps.onBlur(e, e.target.value);
          }
        }

        field.onBlur();
      },
      value: field.value
    }));
  }

  var _default = FormDateTextField;
  _exports.default = _default;
});