define("client/models/ledger-category", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    description: _emberData.default.attr('capitalize'),
    normalBalance: _emberData.default.attr()
  });

  _exports.default = _default;
});