define("client/components/image-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "67HYeGpz",
    "block": "{\"statements\":[[11,\"input\",[]],[15,\"id\",\"hashtag\"],[15,\"type\",\"file\"],[15,\"name\",\"file\"],[15,\"style\",\"display: inline-block;\"],[15,\"accept\",\"image/x-png, image/gif, image/jpeg\"],[15,\"multiple\",\"\"],[13],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/image-upload/template.hbs"
    }
  });

  _exports.default = _default;
});