define("client/helpers/is-undefined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isUndefined = isUndefined;

  function isUndefined(params
  /*, hash*/
  ) {
    return params[0] === undefined;
  }

  var _default = Ember.Helper.helper(isUndefined);

  _exports.default = _default;
});