define("client/react/payroll/payroll-settings", ["exports", "@mui/styles", "@mui/material", "react", "client/react/utilities/styles", "clsx", "react-router", "history", "client/react/payroll/pay-entry-type-management", "client/react/payroll/payroll-type-management", "client/react/payroll/payroll-settings-management", "client/react/payroll/payroll-earning-types-management", "client/react/payroll/payroll-deduction-type-management", "client/react/payroll/payroll-accrual-type-management", "client/react/utils"], function (_exports, _styles, _material, _react, _styles2, _clsx, _reactRouter, _history, _payEntryTypeManagement, _payrollTypeManagement, _payrollSettingsManagement, _payrollEarningTypesManagement, _payrollDeductionTypeManagement, _payrollAccrualTypeManagement, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _styles.makeStyles)(function (theme) {
    return _objectSpread(_objectSpread({}, _styles2.styles), {}, {
      containerCard: {
        margin: "10px",
        padding: "20px",
        display: "flex",
        flexDirection: "row"
      }
    });
  });
  var history = (0, _history.createHashHistory)();

  function PayrollSettings() {
    var classes = useStyles();

    var _useState = (0, _react.useState)(window.location.hash.substring(1)),
        _useState2 = _slicedToArray(_useState, 2),
        currentPath = _useState2[0],
        setCurrentPath = _useState2[1];

    (0, _react.useEffect)(function () {
      history.listen(function (update) {
        setCurrentPath(window.location.hash.substring(1));
      });
    }, []);
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_material.Portal, {
      container: (0, _utils.getToolbarElement)()
    }, _react.default.createElement("h4", null, "Payroll Settings")), _react.default.createElement(_reactRouter.Router, {
      history: history
    }, _react.default.createElement("div", {
      className: (0, _clsx.default)(classes.row, "flex")
    }, _react.default.createElement(_material.Card, {
      className: classes.containerCard
    }, _react.default.createElement(_material.List, null, _react.default.createElement(_material.ListItem, {
      button: true,
      selected: currentPath === '/pr-settings',
      onClick: function onClick() {
        return history.replace('/pr-settings');
      }
    }, _react.default.createElement(_material.ListItemText, {
      primary: "General"
    })), _react.default.createElement(_material.Divider, null), _react.default.createElement(_material.ListItem, {
      button: true,
      selected: currentPath === '/pr-settings/accrual-types',
      onClick: function onClick() {
        return history.push('/pr-settings/accrual-types');
      }
    }, _react.default.createElement(_material.ListItemText, {
      primary: "Accrual Types"
    })), _react.default.createElement(_material.Divider, null), _react.default.createElement(_material.ListItem, {
      button: true,
      selected: currentPath === '/pr-settings/deduction-types',
      onClick: function onClick() {
        return history.push('/pr-settings/deduction-types');
      }
    }, _react.default.createElement(_material.ListItemText, {
      primary: "Deduction Types"
    })), _react.default.createElement(_material.Divider, null), _react.default.createElement(_material.ListItem, {
      button: true,
      selected: currentPath === '/pr-settings/earning-types',
      onClick: function onClick() {
        return history.push('/pr-settings/earning-types');
      }
    }, _react.default.createElement(_material.ListItemText, {
      primary: "Earning Types"
    })), _react.default.createElement(_material.Divider, null), _react.default.createElement(_material.ListItem, {
      button: true,
      selected: currentPath === '/pr-settings/pay-entry-types',
      onClick: function onClick() {
        return history.push('/pr-settings/pay-entry-types');
      }
    }, _react.default.createElement(_material.ListItemText, {
      primary: "Timesheet Entry Types"
    })), _react.default.createElement(_material.Divider, null), _react.default.createElement(_material.ListItem, {
      button: true,
      selected: currentPath === '/pr-settings/payroll-types',
      onClick: function onClick() {
        return history.push('/pr-settings/payroll-types');
      }
    }, _react.default.createElement(_material.ListItemText, {
      primary: "Payroll Types"
    })), _react.default.createElement(_material.Divider, null))), _react.default.createElement(_reactRouter.Switch, null, _react.default.createElement(_reactRouter.Route, {
      exact: true,
      path: "/pr-settings"
    }, _react.default.createElement(_payrollSettingsManagement.default, null)), _react.default.createElement(_reactRouter.Route, {
      path: "/pr-settings/earning-types"
    }, _react.default.createElement(_payrollEarningTypesManagement.default, null)), _react.default.createElement(_reactRouter.Route, {
      path: "/pr-settings/deduction-types"
    }, _react.default.createElement(_payrollDeductionTypeManagement.default, null)), _react.default.createElement(_reactRouter.Route, {
      path: "/pr-settings/pay-entry-types"
    }, _react.default.createElement(_payEntryTypeManagement.default, null)), _react.default.createElement(_reactRouter.Route, {
      path: "/pr-settings/payroll-types"
    }, _react.default.createElement(_payrollTypeManagement.default, null)), _react.default.createElement(_reactRouter.Route, {
      path: "/pr-settings/accrual-types"
    }, _react.default.createElement(_payrollAccrualTypeManagement.default, null))))));
  }

  var _default = PayrollSettings;
  _exports.default = _default;
});