define("client/components/select-search-sm/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    optionPath: "",
    //name of key the select refereces by
    optionPath2: '',
    showDropdown: false,
    prompt: "",
    searchTerm: "",
    myTabIndex: 0,
    data: Ember.A([Ember.Object.create({
      name: "test",
      value: 5
    }), Ember.Object.create({
      name: "newTest",
      value: 6
    })]),
    //collection to search over
    defaultValue: Ember.computed('optionPath', 'prompt', function () {
      var obj = Ember.Object.create({});
      obj[this.get('optionPath')] = this.get('prompt');
      return obj;
    }),
    results: Ember.computed.filter('data', function (item) {
      var t = item.get(this.get('optionPath')).toLowerCase().indexOf(this.get('searchTerm').toLowerCase()) > -1; //search

      if (this.get('optionPath2')) {
        var tt = item.get(this.get('optionPath2')).toLowerCase().indexOf(this.get('searchTerm').toLowerCase()) > -1;
      } else tt = false;

      return t || tt;
    }).property('data', 'searchTerm'),
    isDefaultValue: Ember.computed('defaultValue', 'selection', function () {
      return this.get('defaultValue') === this.get('selection');
    }),
    actions: {
      clearSelection: function clearSelection() {
        this.set('selection', this.get('defaultValue'));
        this.get('change')(this.get('defaultValue'));
        this.set('showDropdown', false);
      },
      selectItem: function selectItem(item) {
        this.set('selection', item);
        this.get('change')(item);
        this.set('showDropdown', false);

        if (this.get('nextTabIndex')) {
          this.get('setNextFocus')(this.get('nextTabIndex'));
        }
      },
      toggleDropdown: function toggleDropdown() {
        this.set('showDropdown', true);
        Ember.run.debounce(this, function () {
          if (this.$("#input-holder :input")) this.$("#input-holder :input").focus();
        }.bind(this), 100);
      },
      preventClick: function preventClick() {},
      closeDropdown: function closeDropdown() {
        this.set('showDropdown', false);

        if (this.get('nextTabIndex')) {
          this.get('setNextFocus')(this.get('nextTabIndex'));
        }
      },
      selectFirstItem: function selectFirstItem() {
        if (this.get('results')) {
          this.set('selection', this.get('results').objectAt(0));
          this.get('change')(this.get('results').objectAt(0));
          this.set('showDropdown', false);

          if (this.get('nextTabIndex')) {
            this.get('setNextFocus')(this.get('nextTabIndex'));
          }
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this.$().on('keydown', function (e) {
        var keyCode = e.keyCode || e.which;

        if (keyCode == 9) {
          // e.preventDefault();
          this.send('closeDropdown');
        }
      }.bind(this));
    },
    clickOff: function clickOff() {
      if (!this.get('isDestroyed')) this.set('showDropdown', false);
    },
    clickObserver: function () {
      if (this.get('showDropdown')) {
        Ember.$(document).on('click', this.clickOff.bind(this));
      } else {
        Ember.$(document).off('click');
      }
    }.observes('showDropdown'),
    selectionObserver: function () {
      if (!this.get('selection.' + this.get('optionPath'))) {
        this.set('selection', this.get('defaultValue'));
      }
    }.observes('selection').on('init')
  });

  _exports.default = _default;
});