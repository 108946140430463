define("client/templates/pr-type-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lpbaNFfB",
    "block": "{\"statements\":[[6,[\"ember-wormhole\"],null,[[\"to\"],[\"main-toolbar\"]],{\"statements\":[[0,\"\\t\"],[11,\"h4\",[]],[13],[0,\"Type Management\"],[14],[0,\"\\n\"]],\"locals\":[]},null],[1,[26,[\"pr-type-management\"]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/pr-type-management.hbs"
    }
  });

  _exports.default = _default;
});