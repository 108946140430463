define("client/react/components/alt/form-switch", ["exports", "@mui/material", "client/react/utilities/use-form"], function (_exports, _material, _useForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["formProps", "color"];

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function FormSwitch(_ref) {
    var formProps = _ref.formProps,
        color = _ref.color,
        restProps = _objectWithoutProperties(_ref, _excluded);

    var _useController = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    }),
        field = _useController.field;

    return React.createElement(_material.FormControlLabel, _extends({}, restProps, {
      onChange: function onChange(e) {
        field.onChange(e.target.checked);

        if (restProps.onChange) {
          restProps.onChange(e);
        }
      },
      checked: !!field.value,
      inputRef: field.ref,
      control: React.createElement(_material.Switch, {
        color: color ? color : "primary"
      })
    }));
  }

  var _default = FormSwitch;
  _exports.default = _default;
});