define("client/templates/ap-tnn-processing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Zz6MH6V",
    "block": "{\"statements\":[[1,[33,[\"ap-tnn-processing\"],null,[[\"vendors\",\"hasBusinessSettings\"],[[28,[\"model\",\"vendors\"]],[28,[\"model\",\"hasBusinessSettings\"]]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/ap-tnn-processing.hbs"
    }
  });

  _exports.default = _default;
});