define("client/models/order-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    prodId: _emberData.default.attr('capitalize'),
    orderNumber: _emberData.default.attr(),
    lineNumber: _emberData.default.attr(),
    description: _emberData.default.attr('capitalize'),
    qtyOrdered: _emberData.default.attr('whole-number'),
    qtyShipped: _emberData.default.attr('whole-number'),
    unitSellAmt: _emberData.default.attr('number'),
    uom: _emberData.default.attr(),
    discountPercent: _emberData.default.attr('number'),
    extendedSellAmt: _emberData.default.attr('number'),
    cost: _emberData.default.attr(),
    weight: _emberData.default.attr(),
    flag: _emberData.default.attr(),
    taxable: _emberData.default.attr('yn-tf'),
    sellGlCode: _emberData.default.attr(),
    costGlCode: _emberData.default.attr(),
    changeFlag: _emberData.default.attr(),
    category: _emberData.default.attr(),
    amountFlag: _emberData.default.attr(),
    page: _emberData.default.attr(),
    discountPercent2: _emberData.default.attr(),
    longDescription: Ember.computed('prodId', 'description', {
      get: function get(key) {
        return this.get('prodId') + this.get('description');
      },
      set: function set(key, value) {
        if (value.length > 15) {
          this.set('prodId', value.substr(0, 15));
          this.set('description', value.substr(15, 30));
        } else {
          this.set('prodId', value.substr(0, 15));
          this.set('description', 0);
        }

        return this.get('prodId') + this.get('description');
      }
    }),
    order: _emberData.default.belongsTo('order', {
      async: true
    })
  });

  _exports.default = _default;
});