define("client/helpers/smart-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.smartAdd = smartAdd;

  function smartAdd(params
  /*, hash*/
  ) {
    var total = 0;

    for (var i = 0; i < params.length; i++) {
      total += typeof params[i] === "number" ? params[i] : 0;
    }

    return total;
  }

  var _default = Ember.Helper.helper(smartAdd);

  _exports.default = _default;
});