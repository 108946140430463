define("client/routes/lead-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('lead', params.id, {
        reload: true
      });
    },
    actions: {
      toSearch: function toSearch() {
        this.transitionTo('lead-search');
      },
      toCreate: function toCreate() {
        this.transitionTo('lead-create');
      },
      toEdit: function toEdit(val) {
        this.transitionTo('lead-edit', val);
      }
    }
  });

  _exports.default = _default;
});