define("client/templates/srvc-contract-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IuvaeT2E",
    "block": "{\"statements\":[[1,[33,[\"srvc-contract-create\"],null,[[\"close\",\"selectContract\"],[[33,[\"route-action\"],[\"back\"],null],[33,[\"route-action\"],[\"transition\",\"srvc-contract-view\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/srvc-contract-create.hbs"
    }
  });

  _exports.default = _default;
});