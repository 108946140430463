define("client/templates/examine-invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3urMwl75",
    "block": "{\"statements\":[[1,[33,[\"invoicing-view\"],null,[[\"invoice\",\"close\",\"create\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"close\"],null],[33,[\"route-action\"],[\"toCreate\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/examine-invoice.hbs"
    }
  });

  _exports.default = _default;
});