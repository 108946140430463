define("client/models/wo-color", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tenant: _emberData.default.attr(),
    status: _emberData.default.attr('capitalize'),
    description: _emberData.default.attr('capitalize'),
    color: _emberData.default.attr('capitalize'),
    label: Ember.computed('status', 'description', function () {
      return this.get('status') + ' - ' + this.get('description');
    })
  });

  _exports.default = _default;
});