define("client/models/vendor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Vendor = _emberData.default.Model.extend({
    vendorId: _emberData.default.attr(),
    alphaKey: _emberData.default.attr(),
    name: _emberData.default.attr('capitalize'),
    name2: _emberData.default.attr('capitalize'),
    street: _emberData.default.attr('capitalize'),
    city: _emberData.default.attr('capitalize'),
    state: _emberData.default.attr('capitalize'),
    zip: _emberData.default.attr(),
    acctNumber: _emberData.default.attr('capitalize'),
    telephone: _emberData.default.attr('telephone'),
    contact: _emberData.default.attr('trim'),
    purchasesYTD: _emberData.default.attr(),
    paymentsYTD: _emberData.default.attr(),
    balance: _emberData.default.attr(),
    cashDiscount: _emberData.default.attr(),
    discDays: _emberData.default.attr(),
    netDays: _emberData.default.attr(),
    autoPayFlag: _emberData.default.attr(),
    autoPayDay: _emberData.default.attr(),
    autoPayAmt: _emberData.default.attr(),
    primaryGLCode: _emberData.default.attr(),
    lastInvAmt: _emberData.default.attr(),
    lastInvDate: _emberData.default.attr(),
    lastPayDate: _emberData.default.attr(),
    lastPayAmt: _emberData.default.attr(),
    lastAutoPay: _emberData.default.attr(),
    notes: _emberData.default.attr('capitalize'),
    purchaseName: _emberData.default.attr('capitalize'),
    purchaseName2: _emberData.default.attr('capitalize'),
    purchaseAddress: _emberData.default.attr('capitalize'),
    purchaseCity: _emberData.default.attr('capitalize'),
    purchaseState: _emberData.default.attr('capitalize'),
    purchaseZip: _emberData.default.attr(),
    faxNumber: _emberData.default.attr('telephone'),
    purchaseContact: _emberData.default.attr('capitalize'),
    purchaseTelephone: _emberData.default.attr('telephone'),
    glDist1: _emberData.default.attr(),
    glDist2: _emberData.default.attr(),
    glDist3: _emberData.default.attr(),
    glDist4: _emberData.default.attr(),
    glDist5: _emberData.default.attr(),
    glDist6: _emberData.default.attr(),
    glPercent1: _emberData.default.attr(),
    glPercent2: _emberData.default.attr(),
    glPercent3: _emberData.default.attr(),
    glPercent4: _emberData.default.attr(),
    glPercent5: _emberData.default.attr(),
    glPercent6: _emberData.default.attr(),
    glDescription1: _emberData.default.attr(),
    glDescription2: _emberData.default.attr(),
    glDescription3: _emberData.default.attr(),
    glDescription4: _emberData.default.attr(),
    glDescription5: _emberData.default.attr(),
    glDescription6: _emberData.default.attr(),
    requires1099: _emberData.default.attr(),
    id1099: _emberData.default.attr('capitalize'),
    contracts: _emberData.default.hasMany('vendor-contract', {
      async: true
    }),
    ppTransactions: _emberData.default.hasMany('pp-transaction', {
      async: true
    }),
    apTransactions: _emberData.default.hasMany('ap-transaction', {
      async: true
    }),
    apHistory: _emberData.default.hasMany('ap-history', {
      async: true
    }),
    emails: _emberData.default.hasMany('vendor-email', {
      async: true
    }),
    checks: _emberData.default.hasMany('check', {
      async: true
    }),
    paidInvoices: _emberData.default.hasMany('paid-pj-invoice', {
      async: true
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    label: Ember.computed(function () {
      return this.get('vendorId').trim() + ' - ' + this.get('name') + ' - ' + this.get('name2');
    })
  });

  Vendor.reopenClass({
    FIXTURES: []
  });
  var _default = Vendor;
  _exports.default = _default;
});