define("client/react/components/ledger-account-cell", ["exports", "react-redux"], function (_exports, _reactRedux) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["ledgerId"];

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function LedgerAccountCell(_ref) {
    var _ledgerAccountsByLedg;

    var ledgerId = _ref.ledgerId,
        props = _objectWithoutProperties(_ref, _excluded);

    var ledgerAccountsByLedger = (0, _reactRedux.useSelector)(function (state) {
      return state.data.ledgerAccount.byLedger;
    });
    return !!ledgerId ? "".concat(ledgerId, " - ").concat(((_ledgerAccountsByLedg = ledgerAccountsByLedger[ledgerId]) === null || _ledgerAccountsByLedg === void 0 ? void 0 : _ledgerAccountsByLedg.description) || '') : '';
  }

  var _default = LedgerAccountCell;
  _exports.default = _default;
});