define("client/controllers/check-reconciliation", ["exports", "client/config/environment", "ember-concurrency", "client/utils/citron-date"], function (_exports, _environment, _emberConcurrency, _citronDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import InfiniteArray from 'ember-infinity/utils/array';
  // import ArrayMixin from 'ember-infinity/mixins/array';
  var inject = Ember.inject;

  var _default = Ember.Controller.extend({
    actions: {}
  });

  _exports.default = _default;
});