define("client/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var User = _emberData.default.Model.extend({
    username: _emberData.default.attr(),
    password: _emberData.default.attr(),
    email: _emberData.default.attr(),
    enabled: _emberData.default.attr(),
    accountExpired: _emberData.default.attr(),
    template: _emberData.default.attr(),
    accountLocked: _emberData.default.attr(),
    passwordExpired: _emberData.default.attr(),
    tech: _emberData.default.attr(),
    ext: _emberData.default.attr(),
    isReceptionist: _emberData.default.attr(),
    isCsr: _emberData.default.attr(),
    dirty: _emberData.default.attr(),
    authorization: _emberData.default.attr('json2'),
    type: _emberData.default.attr(),
    defaultScreen: _emberData.default.attr(),
    permissions: _emberData.default.attr('csv'),
    permissionOverrides: _emberData.default.attr('jsonArray'),
    linkedToNylas: _emberData.default.attr(),
    googleRefreshToken: _emberData.default.attr(),
    fullWidthReports: _emberData.default.attr(),
    employee: _emberData.default.attr()
  });

  var _default = User;
  _exports.default = _default;
});