define("client/routes/ledger-std-entries", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      var first = this.get('store').findAll('ledger-std-entry', {
        reload: true
      }).then(function (entries) {
        return entries;
      });
      var second = this.get('store').findAll('ledger-account', {
        reload: true
      }).then(function (entries) {
        return entries;
      });
      var promises = {
        entries: first,
        glAccounts: second
      };
      return Ember.RSVP.hash(promises);
    },
    actions: {
      didTransition: function didTransition() {
        Ember.$('#isLoading').hide();
      }
    }
  });

  _exports.default = _default;
});