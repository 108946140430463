define("client/components/autocomplete-clear-trigger/component", ["exports", "ember-paper/components/paper-autocomplete-trigger"], function (_exports, _paperAutocompleteTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperAutocompleteTrigger.default.extend({
    actions: {
      customBlur: function customBlur() {
        this.set('_innerText', '');
        this.set('searchText', '');
        this.sendAction('onBlur');
      }
    }
  });

  _exports.default = _default;
});