define("client/helpers/zero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.zero = zero;

  function zero(params
  /*, hash*/
  ) {
    return params[0] === 0;
  }

  var _default = Ember.Helper.helper(zero);

  _exports.default = _default;
});