define("client/components/list-entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "75uiIh+q",
    "block": "{\"statements\":[[11,\"ul\",[]],[15,\"class\",\"form-control-custom ember-select-choices\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"list\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[11,\"li\",[]],[15,\"class\",\"ember-select-search-choice\"],[13],[0,\"\\n\\t\\t\\t\"],[11,\"div\",[]],[13],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,[\"item\"]],false],[0,\"\\n\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\\t\"],[11,\"a\",[]],[15,\"class\",\"ember-select-search-choice-close\"],[15,\"style\",\"font-size: 12px;\"],[5,[\"action\"],[[28,[null]],\"remove\",[28,[\"item\"]]]],[13],[0,\"x\"],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\\t\\n\"]],\"locals\":[\"item\"]},null],[0,\"\\t\"],[11,\"li\",[]],[15,\"class\",\"ember-select-search-field\"],[13],[0,\"\\n\\t\\t\"],[11,\"span\",[]],[15,\"class\",\"invisible-placeholder\"],[13],[14],[0,\"\\n\\t\\t\"],[1,[33,[\"input\"],null,[[\"value\",\"enter\",\"key-up\",\"placeholder\"],[[28,[\"entry\"]],\"onEnter\",\"onKey\",[28,[\"placeholder\"]]]]],false],[0,\"\\n\\t\"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/list-entry/template.hbs"
    }
  });

  _exports.default = _default;
});