define("client/helpers/duration-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.duration = duration;

  function duration(params
  /*, hash*/
  ) {
    var ret = !!params[0] || params[0] === 0 ? moment("2015-01-01").startOf('day').seconds(params[0]).format('H:mm:ss') : "";
    return ret;
  }

  var _default = Ember.Helper.helper(duration);

  _exports.default = _default;
});