define("client/models/ar-cash-two", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    key: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    type: _emberData.default.attr(),
    arGl: _emberData.default.attr(),
    referenceNumber: _emberData.default.attr('capitalize'),
    amount: _emberData.default.attr('number'),
    jobId: _emberData.default.attr()
  });

  _exports.default = _default;
});