define("client/templates/inv-product-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oUun3elj",
    "block": "{\"statements\":[[1,[33,[\"inv-product-view\"],null,[[\"product\",\"create\",\"edit\",\"search\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"openCreate\"],null],[33,[\"route-action\"],[\"openEdit\"],null],[33,[\"route-action\"],[\"openSearch\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/inv-product-view.hbs"
    }
  });

  _exports.default = _default;
});