define("client/models/site-address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  function DestructionPromise(scope) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      Ember.run.schedule('destroy', scope, function () {
        resolve();
      });
    });
  }

  var SiteAddress = _emberData.default.Model.extend({
    siteName: _emberData.default.attr('capitalize'),
    name: computed.alias('siteName'),
    streetAddress: _emberData.default.attr('capitalize'),
    shipId: _emberData.default.attr(),
    custId: _emberData.default.attr(),
    apartmentNumber: _emberData.default.attr('capitalize'),
    city: _emberData.default.attr('capitalize'),
    zip: _emberData.default.attr(),
    state: _emberData.default.attr('capitalize'),
    homePhone: _emberData.default.attr('telephone'),
    cellPhone: _emberData.default.attr('telephone'),
    altPhone: _emberData.default.attr('telephone'),
    salesTaxJurisdiction: _emberData.default.attr('capitalize'),
    //  notes: DS.attr(),
    siteEmail: _emberData.default.attr(),
    createdFromMobile: _emberData.default.attr(),
    notes: _emberData.default.attr(),
    contracts: _emberData.default.hasMany('contract', {
      async: true,
      inverse: null
    }),
    equipment: _emberData.default.hasMany('equipment-item', {
      async: true
    }),
    workOrders: _emberData.default.hasMany('work-order', {
      async: true,
      inverse: null
    }),
    archivedWorkOrders: _emberData.default.hasMany('work-order', {
      async: true,
      inverse: null
    }),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    contractSolicitations: _emberData.default.hasMany('contract-solicitation', {
      async: true
    }),
    isFacade: _emberData.default.attr(),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    leads: _emberData.default.hasMany('lead', {
      async: true
    }),
    communications: _emberData.default.hasMany('communication', {
      async: true
    }),
    label: Ember.computed('shipId', 'siteName', 'streetAddress', function () {
      var apartmentNumber = this.get('apartmentNumber') ? ', ' + this.get('apartmentNumber') : '';
      return this.get('shipId') + ' - ' + this.get('siteName') + ' - ' + this.get('streetAddress') + apartmentNumber;
    }),
    otherLabel: Ember.computed('siteName', 'streetAddress', function () {
      var apartmentNumber = this.get('apartmentNumber') ? ', ' + this.get('apartmentNumber') : '';
      return this.get('siteName') + ' - ' + this.get('streetAddress') + apartmentNumber;
    })
  });

  var _default = SiteAddress;
  _exports.default = _default;
});