define("client/components/modal-dialog-lg/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    width: "width:62%;",
    height: "",
    actions: {
      close: function close() {
        return this.sendAction();
      }
    }
  });

  _exports.default = _default;
});