define("client/components/polymer-tabs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    children: Ember.computed(function () {
      return Ember.A([]);
    }),
    selected: 0,
    entryAnimation: '',
    exitAnimation: '',
    handler: function handler(e) {
      var _this = this;

      var selected = this.$('paper-tabs')[0].selected;
      var oldSelected = this.get('selected');
      console.log(selected < oldSelected);

      if (selected < oldSelected) {
        this.set('entryAnimation', 'slide-from-left-animation');
        this.set('exitAnimation', 'slide-right-animation');
      } else {
        this.set('entryAnimation', 'slide-from-right-animation');
        this.set('exitAnimation', 'slide-left-animation');
      }

      Ember.run.later(function () {
        _this.set('selected', selected);
      }, 20);
    },
    didInsertElement: function didInsertElement() {// this.$('paper-tabs')[0].addEventListener('iron-select', this.handler.bind(this));
    },
    register: function register(child) {
      var _this2 = this;

      Ember.run.schedule('afterRender', function () {
        _this2.get('children').pushObject(child);
      });
    },
    unregister: function unregister(child) {
      this.get('children').removeObject(child);
    },
    willDestroyElement: function willDestroyElement() {
      this.$('paper-tabs')[0].removeEventListener('iron-select', this.handler.bind(this)); //	this.$('paper-tabs').off('iron-select');

      this.get('children').clear();
    },
    actions: {
      test: function test() {
        console.log('WORKS');
      }
    }
  });

  _exports.default = _default;
});