define("client/helpers/type-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.typeOf = typeOf;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  function typeOf(params
  /*, hash*/
  ) {
    var obj = params[0];
    return obj === null ? "null" : _typeof(obj);
  }

  var _default = Ember.Helper.helper(typeOf);

  _exports.default = _default;
});