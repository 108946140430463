define("client/components/context-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    category: null,
    selector: null,
    mouseDown: function mouseDown(event) {
      if (event.button !== 0) {
        this.set('activeCategory', this.get('category'));
        return;
      }
    },
    didInsertElement: function didInsertElement() {
      this.$().contextMenu({
        admin: true,
        vertical: Ember.$("#translatepanel").height(),
        menuSelector: this.get('selector'),
        menuSelected: function (invocedOn, selectedMenu) {
          this.sendAction('action', this.get('category'));
        }.bind(this)
      });
    }
  });

  _exports.default = _default;
});