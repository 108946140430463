define("client/react/utilities/filter-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cyclesByKey = _exports.cycles = void 0;
  _exports.getDefaultValue = getDefaultValue;
  _exports.operatorsMap = void 0;
  _exports.operatorsSelector = operatorsSelector;
  var cycles = [{
    key: 'M',
    label: 'Monthly'
  }, {
    key: 'B',
    label: 'Bi-Monthly'
  }, {
    key: 'Q',
    label: 'Quarterly'
  }, {
    key: 'S',
    label: 'Semi-Annual'
  }, {
    key: 'A',
    label: 'Annual'
  }];
  _exports.cycles = cycles;
  var cyclesByKey = cycles.reduce(function (p, c) {
    p[c.key] = c;
    return p;
  }, {});
  _exports.cyclesByKey = cyclesByKey;
  var operatorsMap = {
    'money': [{
      operator: 'eq',
      label: 'Is equal to'
    }, {
      operator: 'neq',
      label: 'Is not equal to'
    }, {
      operator: 'gt',
      label: 'Is greater than'
    }, {
      operator: 'gte',
      label: 'Is greater than or equal to'
    }, {
      operator: 'lt',
      label: 'Is less than'
    }, {
      operator: 'lte',
      label: 'Is less than or equal to'
    }],
    'date': [{
      operator: 'eq',
      label: 'Is equal to'
    }, {
      operator: 'neq',
      label: 'Is not equal to'
    }, {
      operator: 'gt',
      label: 'Is greater than'
    }, {
      operator: 'gte',
      label: 'Is greater than or equal to'
    }, {
      operator: 'lt',
      label: 'Is less than'
    }, {
      operator: 'lte',
      label: 'Is less than or equal to'
    }],
    'string': [{
      operator: 'contains',
      label: 'Contains'
    }, {
      operator: 'ncontains',
      label: 'Does not contain'
    }, {
      operator: 'eq',
      label: 'Is equal to'
    }, {
      operator: 'neq',
      label: 'Is not equal to'
    }, {
      operator: 'startswith',
      label: 'Starts with'
    }, {
      operator: 'endswith',
      label: 'Ends with'
    }, {
      operator: 'empty',
      label: 'Is empty'
    }, {
      operator: 'nempty',
      label: 'Is not empty'
    }],
    'boolean': [{
      operator: 'istrue',
      label: 'Is true'
    }, {
      operator: 'isfalse',
      label: 'Is false'
    }],
    'number': [{
      operator: 'eq',
      label: 'Is equal to'
    }, {
      operator: 'neq',
      label: 'Is not equal to'
    }, {
      operator: 'gt',
      label: 'Is greater than'
    }, {
      operator: 'gte',
      label: 'Is greater than or equal to'
    }, {
      operator: 'lt',
      label: 'Is less than'
    }, {
      operator: 'lte',
      label: 'Is less than or equal to'
    }],
    'genericModel': [{
      operator: 'eq',
      label: 'Is equal to'
    }, {
      operator: 'neq',
      label: 'Is not equal to'
    }]
  };
  _exports.operatorsMap = operatorsMap;

  function operatorsSelector(type) {
    switch (type) {
      case 'date':
      case 'monthYear':
      case 'dateTime':
      case 'year':
      case 'citronTime':
        return 'date';

      case 'number':
      case 'float':
      case 'percent':
      case 'money':
        return 'number';

      case 'string':
        return 'string';

      case 'boolean':
        return 'boolean';

      default:
        return 'genericModel';
    }
  }

  function getDefaultValue(type) {
    switch (type) {
      case 'number':
      case 'money':
        return 0;

      case 'string':
        return '';

      case 'date':
        return null;

      default:
        return null;
    }
  }
});