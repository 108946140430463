define("client/react/service/work-order-management", ["exports", "react-router-dom", "client/react/service/work-order-crud"], function (_exports, _reactRouterDom, _workOrderCrud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function WorkOrderManagement() {
    return React.createElement(_reactRouterDom.HashRouter, null, React.createElement(_reactRouterDom.Switch, null, React.createElement(_reactRouterDom.Route, {
      path: "/srvc-billing/:id"
    }, React.createElement(_workOrderCrud.default, {
      mode: "bill"
    }))));
  }

  var _default = WorkOrderManagement;
  _exports.default = _default;
});