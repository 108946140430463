define("client/models/phone-call", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    dateTimeCreated: _emberData.default.attr(),
    called: _emberData.default.attr(),
    caller: _emberData.default.attr(),
    calledVia: _emberData.default.attr(),
    forwardedFrom: _emberData.default.attr(),
    to: _emberData.default.attr(),
    campaign: _emberData.default.attr(),
    srvcOrdId: _emberData.default.attr(),
    duration: _emberData.default.attr(),
    timeStampUpdated: _emberData.default.attr(),
    timeInitiated: _emberData.default.attr(),
    timeAnswered: _emberData.default.attr(),
    timeAcknowledged: _emberData.default.attr(),
    timeCompleted: _emberData.default.attr(),
    custId: _emberData.default.attr(),
    shipToId: _emberData.default.attr(),
    user: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    site: _emberData.default.belongsTo('site-address', {
      async: true
    }),
    status: computed('timeAnswered', 'timeCompleted', function () {
      if (this.get('timeAnswered') && this.get('timeCompleted')) {
        return 'Completed';
      } else if (this.get('timeAnswered') && !this.get('timeCompleted')) {
        return 'In Progress';
      } else {
        return 'Unknown Status';
      }
    })
  });

  _exports.default = _default;
});