define("client/components/md-form/component", ["exports", "ember-paper/mixins/parent-mixin"], function (_exports, _parentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed;
  /**
   * @class PaperForm
   * @extends Ember.Component
   * @uses ParentMixin
   */

  var _default = Component.extend(_parentMixin.default, {
    tagName: 'form',
    inputComponent: 'paper-input',
    submitButtonComponent: 'paper-button',
    selectComponent: 'paper-select',
    autocompleteComponent: 'paper-autocomplete',
    isValid: computed.not('isInvalid'),
    isInvalid: computed('childComponents.@each.isInvalid', function () {
      return this.get('childComponents').isAny('isInvalid');
    }),
    isTouched: computed('childComponents.@each.isTouched', function () {
      return this.get('childComponents').isAny('isTouched');
    }),
    isInvalidAndTouched: computed.and('isInvalid', 'isTouched'),
    submit: function submit() {
      this.send('onSubmit');
      return false;
    },
    actions: {
      onValidityChange: function onValidityChange() {
        if (this.get('lastIsValid') !== this.get('isValid') || this.get('lastIsTouched') !== this.get('isTouched')) {
          this.sendAction('onValidityChange', this.get('isValid'), this.get('isTouched'), this.get('isInvalidAndTouched'));
          this.set('lastIsValid', this.get('isValid'));
          this.set('lastIsTouched', this.get('isTouched'));
        }
      },
      onSubmit: function onSubmit() {
        if (this.get('isInvalid')) {
          this.get('childComponents').setEach('isTouched', true);
          this.sendAction('onInvalid');
        } else {
          this.sendAction('onSubmit');
          this.get('childComponents').setEach('isTouched', false);
        }
      }
    }
  });

  _exports.default = _default;
});