define("client/models/pjaccr-sequential-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr(),
    postingDate: _emberData.default.attr(),
    vendId: _emberData.default.attr(),
    vendName: _emberData.default.attr(),
    invDate: _emberData.default.attr(),
    invNum: _emberData.default.attr('capitalize'),
    invDueDate: _emberData.default.attr(),
    invAmount: _emberData.default.attr(),
    invDiscAmount: _emberData.default.attr(),
    invHold: _emberData.default.attr('yn-tf'),
    glCode1: _emberData.default.attr(),
    glCode2: _emberData.default.attr(),
    glCode3: _emberData.default.attr(),
    glCode4: _emberData.default.attr(),
    glCode5: _emberData.default.attr(),
    glCode6: _emberData.default.attr(),
    glAmount1: _emberData.default.attr(),
    glAmount2: _emberData.default.attr(),
    glAmount3: _emberData.default.attr(),
    glAmount4: _emberData.default.attr(),
    glAmount5: _emberData.default.attr(),
    glAmount6: _emberData.default.attr(),
    jobId1: _emberData.default.attr(),
    jobId2: _emberData.default.attr(),
    jobId3: _emberData.default.attr(),
    jobId4: _emberData.default.attr(),
    jobId5: _emberData.default.attr(),
    jobId6: _emberData.default.attr(),
    catId1: _emberData.default.attr(),
    catId2: _emberData.default.attr(),
    catId3: _emberData.default.attr(),
    catId4: _emberData.default.attr(),
    catId5: _emberData.default.attr(),
    catId6: _emberData.default.attr(),
    jobOh1: _emberData.default.attr(),
    jobOh2: _emberData.default.attr(),
    jobOh3: _emberData.default.attr(),
    jobOh4: _emberData.default.attr(),
    jobOh5: _emberData.default.attr(),
    jobOh6: _emberData.default.attr(),
    qty1: _emberData.default.attr(),
    qty2: _emberData.default.attr(),
    qty3: _emberData.default.attr(),
    qty4: _emberData.default.attr(),
    qty5: _emberData.default.attr(),
    qty6: _emberData.default.attr(),
    code1: _emberData.default.attr(),
    code2: _emberData.default.attr(),
    code3: _emberData.default.attr(),
    code4: _emberData.default.attr(),
    code5: _emberData.default.attr(),
    code6: _emberData.default.attr(),
    comment: _emberData.default.attr()
  });

  _exports.default = _default;
});