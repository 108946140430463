define("client/components/input-override/component", ["exports", "ember-paper/components/paper-input"], function (_exports, _paperInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperInput.default.extend({
    actions: {
      handleInput: function handleInput(e) {
        e.preventDefault();
        this.sendAction('onChange', e, e.target.value);
        this.growTextarea();
        var inputElement = this.$('input').get(0);
        this.set('isNativeInvalid', inputElement && inputElement.validity && inputElement.validity.badInput);
        this.notifyInvalid();
      }
    }
  });

  _exports.default = _default;
});