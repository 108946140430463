define("client/components/product-comparisons/component", ["exports", "client/components/react-component/component", "client/react/administration/product-comparisons"], function (_exports, _component, _productComparisons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['layout-column', 'flex'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.reactRender(React.createElement(_productComparisons.default, null));
    }
  });

  _exports.default = _default;
});