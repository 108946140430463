define("client/templates/srvc-billing-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cPPj2nYm",
    "block": "{\"statements\":[[1,[33,[\"srvc-billing-search\"],null,[[\"setModel\",\"searchTerm\",\"onSearchTermChange\",\"searchDate\",\"onSearchDateChange\"],[[33,[\"route-action\"],[\"openWorkOrder\"],null],[28,[\"searchTerm\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"searchTerm\"]]],null]],null],[28,[\"searchDate\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"searchDate\"]]],null]],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/srvc-billing-search.hbs"
    }
  });

  _exports.default = _default;
});