define("client/components/report-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JN7sFx3P",
    "block": "{\"statements\":[[6,[\"tree-table\"],null,null,{\"statements\":[[0,\"\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-table-container ember-table-fixed-container ember-table-header-container\"],[15,\"style\",\"display: flex; flex-direction: row;\"],[13],[0,\"\\n\\t\\t\\n\\t\\t\"],[1,[33,[\"component\"],[[28,[\"table\",\"headerLeft\"]]],[[\"headings\"],[[28,[\"headings\"]]]]],false],[0,\"\\n\\t\\t\"],[1,[33,[\"component\"],[[28,[\"table\",\"headerRight\"]]],[[\"headings\"],[[28,[\"summaries\"]]]]],false],[0,\"\\n\\t\"],[14],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-table-container ember-table-body-container\"],[15,\"style\",\"display:flex; flex-direction: row;\"],[13],[0,\"\\n\\t\\t\"],[1,[33,[\"component\"],[[28,[\"table\",\"left\"]]],[[\"data\",\"displayPath\",\"childrenPath\"],[[28,[\"data\"]],\"display\",\"data\"]]],false],[0,\"\\n\\t\\t\"],[1,[33,[\"component\"],[[28,[\"table\",\"right\"]]],[[\"data\",\"summaryPath\",\"childrenPath\"],[[28,[\"data\"]],\"summaries\",\"data\"]]],false],[0,\"\\n\\t\"],[14],[0,\"\\n\"]],\"locals\":[\"table\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/report-table/template.hbs"
    }
  });

  _exports.default = _default;
});