define("client/components/dwo-resizer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rssv6VQZ",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"layout-row layout-align-center\"],[15,\"style\",\"margin-top:-4px;\"],[13],[0,\"\\n    \"],[1,[33,[\"paper-icon\"],[\"drag_handle\"],[[\"size\"],[12]]],false],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/dwo-resizer/template.hbs"
    }
  });

  _exports.default = _default;
});