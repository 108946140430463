define("client/components/md-datepicker/component", ["exports", "ember-paper/components/paper-input", "ember-concurrency"], function (_exports, _paperInput, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      run = Ember.run,
      defineProperty = Ember.defineProperty;
  var MutObserver = self.window.MutationObserver || self.window.WebKitMutationObserver;

  var _default = _paperInput.default.extend({
    left: "",
    top: "",
    width: "",
    dropdownWidth: 300,
    classNames: ['md-show-clear-button'],
    click: function click(e) {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();
      return false;
    },
    calculatePosition: function calculatePosition() {
      if (this.get('isDestroying')) return;

      var _this$element$getBoun = this.element.getBoundingClientRect(),
          left = _this$element$getBoun.left,
          top = _this$element$getBoun.top,
          width = _this$element$getBoun.width,
          height = _this$element$getBoun.height;

      var input = this.$('input')[0];
      var $window = $(window);
      var $suggestions = $('.md-datepicker-container');
      var sHeight = $suggestions.height();
      var scroll = {
        left: $window.scrollLeft(),
        top: $window.scrollTop()
      };

      var _input$getBoundingCli = input.getBoundingClientRect(),
          iHeight = _input$getBoundingCli.height,
          iTop = _input$getBoundingCli.top;

      var viewportBottom = scroll.top + window.innerHeight;
      var triggerTopWithScroll = iTop + scroll.top;
      var enoughRoomBelow = triggerTopWithScroll + iHeight + 225.5 < viewportBottom;
      var adjustedTop = enoughRoomBelow ? top + iHeight + 2 : top - sHeight;

      if (this.get('dropdownWidth')) {
        left = left - 0; //((parseInt(this.get('dropdownWidth')) - width) / 2);

        width = this.get('dropdownWidth');
      }

      this.setProperties({
        left: "".concat(left, "px"),
        top: "".concat(adjustedTop, "px"),
        width: "".concat(width, "px")
      });
    },
    actions: {
      block: function block(e) {
        this.set('blockClick', true);
        var datepicker = this.get('datepicker');
      },
      test: function test(e) {
        console.log(e);
      },
      incrementDate: function incrementDate() {
        var date = moment(this.get('value'), 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
        this.sendAction('onChange', date);
      },
      decrementDate: function decrementDate() {
        var date = moment(this.get('value'), 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD');
        this.sendAction('onChange', date);
      },
      onFocus2: function onFocus2(e) {
        var _this = this;

        this.open(); //let {left, top, width, height} = this.element.getBoundingClientRect();
        //let input = this.$('input')[0];
        //let { height: iHeight } = input.getBoundingClientRect();
        //let adjustedTop = top + iHeight + 2;
        //this.setProperties({left: `${left}px`, top: `${adjustedTop}px`, width: `${width}px`});

        run.next(function () {
          if (_this.get('isDestroyed')) return;

          _this.calculatePosition();
        });
        Ember.$('.md-datepicker-container').on('mouseDown', function (e) {
          return console.log(e);
        }); //	Ember.$('.md-datepicker-container').on('click', (e) => e.stopPropagation());

        run.later(function () {
          if (!_this.get('isDestroyed')) _this.set('highlightedElement', _this.get('results.firstObject'));
        }, 100);
        document.body.addEventListener('mousedown', function () {
          if (!_this.get('isDestroyed')) {
            if (_this.get('blockClick')) {
              _this.set('blockClick', false);

              console.log('click blocked!');
              return;
            }

            console.log('closeing');

            _this.close(); // this.set('isOpen',false);

          }
        });
        this.sendAction('onFocus', e);
      }
    },
    datepicker: null,
    handler: function handler(e) {
      this.sendAction('onChange', e.detail.value);
    },
    funtest: function (e) {
      console.log('whatever');
    }.on('date-changed'),
    open: function open() {
      var _this2 = this;

      this.set('isOpen', true);
      run.next(function () {
        _this2.set('datepicker', $('#datepicker')[0]);

        if (_this2.get('datepicker')) {
          _this2.get('datepicker').addEventListener('date-changed', _this2.handler.bind(_this2));
        }

        _this2.startObservingDomMutations();
      });
    },
    close: function close() {
      console.log('close fired');
      this.stopObservingDomMutations(); //	this.set('isOpen', false);
    },
    mutationObserver: null,
    startObservingDomMutations: function startObservingDomMutations() {
      var _this3 = this;

      if (this.get('isDestroying')) return;
      var dropdown = $('.md-datepicker-container')[0];
      if (!dropdown) return;
      this.set('mutationObserver', new MutObserver(function (mutations) {
        if (mutations[0].addedNodes.length || mutations[0].removedNodes.length) {
          _this3.calculatePosition();
        }
      }));
      this.get('mutationObserver').observe(dropdown, {
        childList: true,
        subtree: true
      });
    },
    stopObservingDomMutations: function stopObservingDomMutations() {
      if (this.get('mutationObserver')) {
        this.get('mutationObserver').disconnect();
        this.get('mutationObserver', null);
      }
    }
  });

  _exports.default = _default;
});