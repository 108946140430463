define("client/routes/srvc-labor-rate", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      return Ember.RSVP.hash({
        defaultRate: this.store.findRecord('service-labor-rate', 1, {
          reload: true
        }),
        altRates: this.store.findAll('alt-labor-rate', {
          reload: true
        })
      });
    }
  });

  _exports.default = _default;
});