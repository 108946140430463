define("client/components/md-popover/component", ["exports", "ember-block-slots"], function (_exports, _emberBlockSlots) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var guidFor = Ember.guidFor;

  var _default = Ember.Component.extend(_emberBlockSlots.default, {
    tagName: "",
    isOpen: false,
    animationClass: 'scale-in-top-left',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('popoverId', 'popover' + guidFor(this));
    },
    actions: {
      open: function open(e) {
        var _this2 = this;

        this.set('isOpen', true);
        this.set('top', e.clientY + 'px');
        this.set('left', e.clientX + 'px');

        function fn(e) {
          var _this = this;

          var popover = $('#' + this.get('popoverId'))[0];

          if (popover && (e.clientX < popover.offsetLeft || e.clientX > popover.offsetLeft + popover.offsetWidth || e.clientY < popover.offsetTop || e.clientY > popover.offsetTop + popover.offsetHeight)) {
            this.set('isOpen', false);
          } else {
            Ember.run.next(function () {
              return $(window).one('mousedown', fn.bind(_this));
            });
          }
        }

        ;
        Ember.run.next(function () {
          return $(window).one('mousedown', fn.bind(_this2));
        });
        e.stopPropagation();
        e.preventDefault();
      },
      close: function close(e) {
        this.set('isOpen', false);
      },
      reposition: function reposition() {
        var popover = $('#' + this.get('popoverId'))[0];
        var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var isTooWide = popover.offsetLeft + popover.offsetWidth > viewportWidth;
        var isTooTall = popover.offsetTop + popover.offsetHeight > viewportHeight;
        var newLeft = isTooWide ? popover.offsetLeft - popover.offsetWidth : popover.offsetLeft;
        var newTop = Math.max(0, isTooTall ? popover.offsetTop - popover.offsetHeight : popover.offsetTop);
        var animationClass = '';

        if (isTooWide) {
          if (isTooTall) {
            animationClass = 'scale-in-bottom-right';
          } else {
            animationClass = 'scale-in-top-right';
          }
        } else {
          if (isTooTall) {
            animationClass = 'scale-in-bottom-left';
          } else {
            animationClass = 'scale-in-top-left';
          }
        }

        this.set('animationClass', animationClass);
        this.set('top', newTop + 'px');
        this.set('left', newLeft + 'px');
      }
    }
  });

  _exports.default = _default;
});