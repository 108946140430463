define("client/react/components/typed/card-title", ["exports", "react/jsx-runtime", "@mui/material"], function (_exports, _jsxRuntime, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function CardTitle(_ref) {
    var title = _ref.title;
    return (0, _jsxRuntime.jsx)(_material.Typography, Object.assign({
      sx: {
        marginLeft: 2,
        marginTop: 1
      },
      variant: 'subtitle2',
      color: 'text.secondary'
    }, {
      children: title
    }));
  }

  var _default = CardTitle;
  _exports.default = _default;
});