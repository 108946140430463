define("client/components/h-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dxJlAPSN",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"md-container\"],[13],[0,\"\\n  \"],[11,\"div\",[]],[15,\"class\",\"md-icon\"],[13],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[6,[\"if\"],[[29,\"default\"]],null,{\"statements\":[[0,\"  \"],[11,\"div\",[]],[15,\"class\",\"md-label\"],[13],[0,\"\\n    \"],[11,\"span\",[]],[13],[0,\"\\n      \"],[18,\"default\"],[0,\"\\n    \"],[14],[0,\"\\n  \"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"  \"],[11,\"div\",[]],[15,\"class\",\"md-label\"],[13],[0,\"\\n    \"],[11,\"span\",[]],[13],[0,\"\\n      \"],[1,[26,[\"label\"]],false],[0,\"\\n    \"],[14],[0,\"\\n  \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/h-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});