define("client/transforms/date-as-int", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      var singleDigitYear = serialized < 100000;

      if (!serialized) {
        return null;
      } else if (singleDigitYear) {
        return moment("0" + serialized.toString(), 'YYMMDD').format('YYYY-MM-DD');
      } else {
        return moment(serialized.toString(), 'YYMMDD').format('YYYY-MM-DD');
      }
    },
    serialize: function serialize(deserialized) {
      return deserialized ? moment(deserialized, 'YYYY-MM-DD').format('YYMMDD') : 0;
    }
  });

  _exports.default = _default;
});