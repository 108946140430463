define("client/models/flat-rate-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    category: _emberData.default.attr('capitalize'),
    itemId: _emberData.default.attr('capitalize'),
    description: _emberData.default.attr('capitalize'),
    time: _emberData.default.attr('currency'),
    totalPartsCost: _emberData.default.attr('currency'),
    totalPartsSell: _emberData.default.attr('currency'),
    totalLaborCost: _emberData.default.attr('currency'),
    totalLaborSell: _emberData.default.attr('currency'),
    addlPartSell: _emberData.default.attr('currency'),
    addlLaborSell: _emberData.default.attr('currency'),
    afterHoursLaborSell: _emberData.default.attr('currency'),
    addlAfterHoursLaborSell: _emberData.default.attr('currency'),
    primaryAgreementPartsSell: _emberData.default.attr('currency'),
    primaryAgreementLaborSell: _emberData.default.attr('currency'),
    addlAgreementPartsSell: _emberData.default.attr('currency'),
    addlAgreementLaborSell: _emberData.default.attr('currency'),
    primarySalesTax: _emberData.default.attr('currency'),
    primaryTotal: _emberData.default.attr('currency'),
    addlSalesTax: _emberData.default.attr('currency'),
    addlTotal: _emberData.default.attr('currency'),
    afterHoursTotal: _emberData.default.attr('currency'),
    afterHoursAddlTotal: _emberData.default.attr('currency'),
    primaryAgreementTax: _emberData.default.attr('currency'),
    primaryAgreementTotal: _emberData.default.attr('currency'),
    addlAgreementSalesTax: _emberData.default.attr('currency'),
    addlAgreementTotal: _emberData.default.attr('currency'),
    afterHoursSalesTax: _emberData.default.attr('currency'),
    afterHoursAddlTax: _emberData.default.attr('currency'),
    notes: _emberData.default.attr(),
    manualFlag: _emberData.default.attr('yn-tf'),
    inactiveFlag: _emberData.default.attr('yn-tf'),
    techDescription: _emberData.default.attr(),
    flrateNotes: _emberData.default.belongsTo('flat-rate-note', {
      async: true
    }),
    flrateParts: _emberData.default.hasMany('flat-rate-part', {
      async: true
    }),
    label: Ember.computed('itemId', 'description', function () {
      return this.get('itemId') + ' - ' + this.get('description');
    })
  });

  _exports.default = _default;
});