define("client/react/components/labeled-address", ["exports", "@mui/material", "client/react/utilities/helpers"], function (_exports, _material, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function LabeledAddress(_ref) {
    var label = _ref.label,
        name = _ref.name,
        streetAddress = _ref.streetAddress,
        apartmentNumber = _ref.apartmentNumber,
        city = _ref.city,
        state = _ref.state,
        zip = _ref.zip,
        phone = _ref.phone;
    return React.createElement("div", null, React.createElement(_material.Typography, {
      variant: "overline"
    }, label), name && React.createElement(_material.Typography, {
      variant: "h6"
    }, name), streetAddress && React.createElement(_material.Typography, {
      variant: "h6"
    }, streetAddress), apartmentNumber && React.createElement(_material.Typography, {
      variant: "h6"
    }, apartmentNumber), React.createElement(_material.Typography, {
      paragraph: !phone,
      variant: "h6"
    }, city ? "".concat(city, ", ") : '', "".concat(state, " ").concat(zip)), phone && React.createElement(_material.Typography, {
      paragraph: true,
      variant: "h6"
    }, (0, _helpers.formatPhoneNumber)(phone)));
  }

  var _default = LabeledAddress;
  _exports.default = _default;
});