define("client/routes/invoicing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      toView: function toView(item) {
        this.transitionTo('examine-invoice', item.get('id'));
      },
      close: function close() {
        this.transitionTo('landing');
      }
    }
  });

  _exports.default = _default;
});