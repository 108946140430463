define("client/routes/tenant-configuration", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    actions: {
      didTransition: function didTransition() {
        var allTechs = this.controllerFor('dispatch').get('allTechs');
        var assigned = this.controllerFor('application').get('userAssignment'); // bail if assigned is not set (means they probably refreshed, auth should push them to login)

        if (!assigned) {
          return;
        }

        this.controller.set('userAssignment', assigned);
        this.controller.get('techList').clear();
        this.controller.get('allTechs').clear();
        var user = this.controllerFor('login').get('activeUser');

        for (var i = 0; i < allTechs.get('length'); i++) {
          this.controller.get('allTechs').addObject(Ember.Object.create({
            tech: allTechs.objectAt(i),
            checked: false
          }));
        }

        if (assigned === undefined) {
          for (var l = 0; l < allTechs.get('length'); l++) {
            this.controller.get('allTechs').objectAt(l).set('checked', true);
          }

          var record = this.store.createRecord('user-assigned');
          record.set('username', user);
          record.set('techs', this.controller.get('allTechs').mapBy('tech.id'));
          record.save().then(function (rec) {
            this.controllerFor('application').set('userAssignment', rec);
            this.controller.set('userAssignment', rec);
          }.bind(this));
        } else {
          for (var m = 0; m < allTechs.get('length'); m++) {
            if (assigned.get('techs').includes(allTechs.objectAt(m).get('id'))) {
              this.controller.get('allTechs').findBy('tech.id', allTechs.objectAt(m).get('id')).set('checked', true);
            }
          }
        }

        var a = Ember.A([]);

        for (var d = 0; d < assigned.get('techs.length'); d++) {
          if (this.controller.get('allTechs').mapBy('tech.id').includes(assigned.get('techs').objectAt(d))) {
            a.addObject(this.controller.get('allTechs').findBy('tech.id', assigned.get('techs').objectAt(d)));
          }
        }

        this.controller.get('techList').pushObjects(a); //this.controller.set('techList', this.controller.get('techList').sortBy('number'));
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        assigned: this.store.findAll('user-assigned', {
          reload: true
        })
      }).then(function (model) {
        return model;
      }, function (reason) {
        if (reason.status === 403) {
          this.transitionTo('login');
          return "crabs";
        }
      }.bind(this));
    }
  });

  _exports.default = _default;
});