define("client/components/table-sort-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mN7RfcNC",
    "block": "{\"statements\":[[6,[\"if\"],[[33,[\"eq\"],[[28,[\"targetValue\"]],[28,[\"sortProp\"]]],null]],null,{\"statements\":[[6,[\"if\"],[[33,[\"eq\"],[\"asc\",[28,[\"sortDir\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"paper-icon\"],[\"arrow_upward\"],[[\"class\"],[\"table-sort-indicator-icon\"]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[1,[33,[\"paper-icon\"],[\"arrow_downward\"],[[\"class\"],[\"table-sort-indicator-icon\"]]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/table-sort-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});