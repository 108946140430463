define("client/models/contract-filter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    contractId: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    qty: _emberData.default.attr(),
    prodId: _emberData.default.attr('capitalize'),
    month1: _emberData.default.attr('asterisk-to-tf'),
    month2: _emberData.default.attr('asterisk-to-tf'),
    month3: _emberData.default.attr('asterisk-to-tf'),
    month4: _emberData.default.attr('asterisk-to-tf'),
    month5: _emberData.default.attr('asterisk-to-tf'),
    month6: _emberData.default.attr('asterisk-to-tf'),
    month7: _emberData.default.attr('asterisk-to-tf'),
    month8: _emberData.default.attr('asterisk-to-tf'),
    month9: _emberData.default.attr('asterisk-to-tf'),
    month10: _emberData.default.attr('asterisk-to-tf'),
    month11: _emberData.default.attr('asterisk-to-tf'),
    month12: _emberData.default.attr('asterisk-to-tf'),
    description: _emberData.default.attr('capitalize'),
    location: _emberData.default.attr('capitalize'),
    product: _emberData.default.belongsTo('part-item', {
      async: true
    }),
    contract: _emberData.default.belongsTo('contract', {
      async: true
    })
  });

  _exports.default = _default;
});