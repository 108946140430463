define("client/utils/row-to-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = rowToTime;

  function rowToTime(row, startTime, numPeriods) {
    //    var startTime = "8:00 am";
    var r = Number(row) - 1;
    var b = r % numPeriods * (60 / numPeriods);

    var calc = function calc(time) {
      var t = time;
      var t2 = t.split(':');
      var a = t2[0];
      var b = t2[1];
      a = Number(a);
      b = Number(b) / (60 / numPeriods) % numPeriods;
      var ret = numPeriods * a + b;
      return ret;
    };

    var a = calc(startTime) + (r - r % numPeriods);
    a = a / numPeriods;

    if (b < 10) {
      b = '0' + b.toString();
    }

    var retString = (a < 10 ? '0' + a.toString() : a.toString()) + ':' + b.toString();
    return retString;
  }
});