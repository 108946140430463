define("client/components/polymer-tab/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('parent')) {
        this.get('parent').register(this);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('parent')) {
        this.get('parent').unregister(this);
      }
    }
  });

  _exports.default = _default;
});