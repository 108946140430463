define("client/helpers/post-labor-required", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.postLaborRequired = postLaborRequired;

  function postLaborRequired(params
  /*, hash*/
  ) {
    var reg = params[0],
        ot = params[1],
        dt = params[2],
        jobExp = params[3],
        travel = params[4];
    return reg !== "0.00" || ot !== "0.00" || dt !== "0.00" || jobExp !== "0.00" || travel !== "0.00";
  }

  var _default = Ember.Helper.helper(postLaborRequired);

  _exports.default = _default;
});