define("client/app", ["exports", "client/resolver", "ember-load-initializers", "client/config/environment", "client/models/custom-inflector-rules", "@mui/x-data-grid-pro", "@sentry/react", "@sentry/tracing"], function (_exports, _resolver, _emberLoadInitializers, _environment, _customInflectorRules, _xDataGridPro, Sentry, _tracing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  _xDataGridPro.LicenseInfo.setLicenseKey('551b8576e52ce8a5ab056364ffb4e7bbTz00NzU1MCxFPTE2ODk3ODgyMDIyMDMsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

  if (_environment.default.environment === "production") {
    Sentry.init({
      dsn: "https://7244242a438b40e7898655d466471998@o903622.ingest.sentry.io/5843125",
      integrations: [new _tracing.Integrations.BrowserTracing()],
      tracesSampleRate: 0.1
    });
  } // IAN: This is a shim to prevent ES15 related errors when using polymer components


  (function () {
    if ( // No Reflect, no classes, no need for shim because native custom elements
    // require ES2015 classes or Reflect.
    window.Reflect === undefined || window.customElements === undefined || // The webcomponentsjs custom elements polyfill doesn't require
    // ES2015-compatible construction (`super()` or `Reflect.construct`).
    window.customElements.polyfillWrapFlushCallback) {
      return;
    }

    var BuiltInHTMLElement = HTMLElement;
    /**
     * With jscompiler's RECOMMENDED_FLAGS the function name will be optimized away.
     * However, if we declare the function as a property on an object literal, and
     * use quotes for the property name, then closure will leave that much intact,
     * which is enough for the JS VM to correctly set Function.prototype.name.
     */

    var wrapperForTheName = {
      'HTMLElement':
      /** @this {!Object} */
      function HTMLElement() {
        return Reflect.construct(BuiltInHTMLElement, [],
        /** @type {!Function} */
        this.constructor);
      }
    };
    window.HTMLElement = wrapperForTheName['HTMLElement'];
    HTMLElement.prototype = BuiltInHTMLElement.prototype;
    HTMLElement.prototype.constructor = HTMLElement;
    Object.setPrototypeOf(HTMLElement, BuiltInHTMLElement);
  })();

  var App;
  Ember.MODEL_FACTORY_INJECTIONS = true;
  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    customEvents: {
      ironSelect: 'iron-select'
    },
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  window.Client = App;
  window.Client.appVersion = "1.3.0";
  var _default = App;
  _exports.default = _default;
});