define("client/utils/set-overlaps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setOverlaps;

  function outerHeight(el) {
    var height = el.offsetHeight;
    var style = getComputedStyle(el);
    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
  }

  function outerWidth(el) {
    var width = el.offsetWidth;
    var style = getComputedStyle(el);
    width += parseInt(style.marginLeft) + parseInt(style.marginRight);
    return width;
  }

  function setOverlaps(comp) {
    console.log('RUNNING');
    console.log(comp);

    function doOverlap(a, b) {
      var x1 = a.getBoundingClientRect().left + document.body.scrollLeft; // x coordinat of first

      var y1 = a.getBoundingClientRect().top + document.body.scrollTop; // y coordianate of first

      var h1 = outerHeight(a) - 10; // height of first - 2 ? why...

      var w1 = outerWidth(a); // width of first

      var b1 = y1 + h1; // bottom side of first

      var r1 = x1 + w1; // right side of first

      var x2 = b.getBoundingClientRect().left + document.body.scrollLeft; // x coordinate of second

      var y2 = b.getBoundingClientRect().top + document.body.scrollTop; // y coordinate of second

      var h2 = outerHeight(b) - 10; // height of second - 10

      var w2 = outerWidth(b); // width of second

      var b2 = y2 + h2; // bottom of second

      var r2 = x2 + w2; // right of second
      //if the bottom of the first is less 

      if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2) return false;
      return true;
    }

    var list = comp ? comp.element.querySelectorAll('.draggable:not(.move-cursor)') : document.querySelectorAll('.draggable:not(.move-cursor)'); //comp.$('.draggable').not('.move-cursor') : Ember.$(".draggable").not('.move-cursor');

    var arr = [];

    for (var _i = list.length; _i--; arr.unshift(list[_i])) {
      ;
    }

    list = arr; //for (let i = 0; i < list.length; i++) {
    //	if (list[i].parentNode.tagName !== 'LI') {
    //		arr.push(list[i]);
    //	}
    //}

    list = list.filter(function (item) {
      return item.parentNode.tagName !== 'LI';
    }).toArray(); //	for (var i = 0; i < list.length; i++) {
    //		list[i] = Ember.$(list[i]);
    //	}

    for (var i = 0; i < list.length; i++) {
      list[i].style.left = "0px"; // .css({'left':0});
    }

    var groups = []; // create groups of overlapping work orders

    for (var i = 0; i < list.length; i++) {
      var group = [];
      group.push(list[i]);

      for (var j = i + 1; j < list.length; j++) {
        for (var k = 0; k < group.length; k++) {
          if (doOverlap(group[k], list[j])) {
            group.push(list[j]); // var index = list.indexOf(list[j]);

            list.splice(j, 1);
            j = i;
            break;
          }
        }
      }

      if (group.length > 1) {
        // for (var m = 0; m < group.length; m++) {
        // 	group[m].css({'left':0});
        // }
        groups.push(group);
      } else {
        group[0].style.width = "106px"; // .css({width: 106});
        // group[0].css({'left':0});
      }
    }

    for (var i = 0; i < groups.length; i++) {
      var sorted = groups[i].sort(function (a, b) {
        return a.getBoundingClientRect().top + document.body.scrollTop - b.getBoundingClientRect().top + document.body.scrollTop;
      }); //		var min = sorted[0][0].getBoundingClientRect().left + document.body.scrollLeft;
      //		for (var j = 1; j < sorted.length; j++) {
      //			if (sorted[j][0].getBoundingClientRect().left + document.body.scrollLeft < min) {
      //				min = sorted[j][0].getBoundingClientRect().left + document.body.scrollLeft;
      //			}
      //		}

      var wierdWidth = 1.7 / sorted.length * 108;
      var normWidth = 108 / sorted.length;
      var off = 0; // min;

      console.log('NORM WIDTH: ' + normWidth);
      console.log('WIERD WIDTH: ' + wierdWidth);
      var z = 5;
      var inc = normWidth;

      for (var j = 0; j < sorted.length; j++) {
        sorted[j].style.left = "0px"; //({'left':0});

        sorted[j].style.left = off + "px"; // .offset({left: off});

        sorted[j].style.zIndex = z; // .css({'z-index':z});

        if (j == sorted.length - 1) {
          sorted[j].style.width = normWidth + "px"; // ({width: normWidth});
        } else {
          sorted[j].style.width = wierdWidth + "px"; // ({width: wierdWidth});
        }

        off += inc;
        z++;
      }
    }
  }
});