define("client/models/temp-payroll", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    employeeName: _emberData.default.attr(),
    employeeId: _emberData.default.attr('capitalize'),
    trimmedEmployeeId: computed('employeeId', function () {
      return this.get('employeeId').trim();
    }),
    taxFreq: _emberData.default.attr(),
    regRate: _emberData.default.attr(),
    regHours: _emberData.default.attr(),
    regEarnings: _emberData.default.attr(),
    otRate: _emberData.default.attr(),
    otHours: _emberData.default.attr(),
    otEarnings: _emberData.default.attr(),
    dtRate: _emberData.default.attr(),
    dtHours: _emberData.default.attr(),
    dtEarnings: _emberData.default.attr(),
    sickRate: _emberData.default.attr(),
    sickHours: _emberData.default.attr(),
    sickEarnings: _emberData.default.attr(),
    vacationRate: _emberData.default.attr(),
    vacationHours: _emberData.default.attr(),
    vacationEarnings: _emberData.default.attr(),
    otherRate: _emberData.default.attr(),
    otherHours: _emberData.default.attr(),
    otherEarnings: _emberData.default.attr(),
    holidayRate: _emberData.default.attr(),
    holidayHours: _emberData.default.attr(),
    holidayEarnings: _emberData.default.attr(),
    commission: _emberData.default.attr(),
    salary: _emberData.default.attr(),
    travel: _emberData.default.attr(),
    ficaWithholding: _emberData.default.attr(),
    fedWithholding: _emberData.default.attr(),
    stateWithholding: _emberData.default.attr(),
    cityWh: _emberData.default.attr(),
    dblWh: _emberData.default.attr(),
    miscWh1: _emberData.default.attr(),
    miscWh2: _emberData.default.attr(),
    miscWh3: _emberData.default.attr(),
    miscWh4: _emberData.default.attr(),
    miscWh5: _emberData.default.attr(),
    miscWh6: _emberData.default.attr(),
    miscWh7: _emberData.default.attr(),
    miscWh8: _emberData.default.attr(),
    miscWh9: _emberData.default.attr(),
    otherWh: _emberData.default.attr(),
    vacDues: _emberData.default.attr(),
    totEarnings: _emberData.default.attr(),
    totDeduction: _emberData.default.attr(),
    totReimbursement: _emberData.default.attr(),
    netPay: _emberData.default.attr(),
    gl1: _emberData.default.attr(),
    gl2: _emberData.default.attr(),
    gl3: _emberData.default.attr(),
    gl4: _emberData.default.attr(),
    gl5: _emberData.default.attr(),
    gl6: _emberData.default.attr(),
    glDist1: _emberData.default.attr(),
    glDist2: _emberData.default.attr(),
    glDist3: _emberData.default.attr(),
    glDist4: _emberData.default.attr(),
    glDist5: _emberData.default.attr(),
    glDist6: _emberData.default.attr(),
    weeksWorked: _emberData.default.attr(),
    directDepositEmployee: _emberData.default.attr('yn-tf'),
    directDepositCheck: _emberData.default.attr('yn-tf'),
    eic: _emberData.default.attr(),
    otherCode1: _emberData.default.attr(),
    otherCode2: _emberData.default.attr(),
    otherCode3: _emberData.default.attr(),
    otherCode4: _emberData.default.attr(),
    otherCode5: _emberData.default.attr(),
    otherCode6: _emberData.default.attr(),
    otherHrs1: _emberData.default.attr(),
    otherHrs2: _emberData.default.attr(),
    otherHrs3: _emberData.default.attr(),
    otherHrs4: _emberData.default.attr(),
    otherHrs5: _emberData.default.attr(),
    otherHrs6: _emberData.default.attr(),
    otherAmount1: _emberData.default.attr(),
    otherAmount2: _emberData.default.attr(),
    otherAmount3: _emberData.default.attr(),
    otherAmount4: _emberData.default.attr(),
    otherAmount5: _emberData.default.attr(),
    otherAmount6: _emberData.default.attr(),
    socialSecurity: _emberData.default.attr()
  });

  _exports.default = _default;
});