define("client/models/ar-sale", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    custId: _emberData.default.attr(),
    key: _emberData.default.attr(),
    date: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr('capitalize'),
    amount: _emberData.default.attr(),
    contract: _emberData.default.attr(),
    changeOrder: _emberData.default.attr(),
    retention: _emberData.default.attr(),
    note: _emberData.default.attr('capitalize'),
    postingDate: _emberData.default.attr(),
    glAccount1: _emberData.default.attr(),
    glAccount2: _emberData.default.attr(),
    glAccount3: _emberData.default.attr(),
    glAccount4: _emberData.default.attr(),
    glAccount5: _emberData.default.attr(),
    glAccount6: _emberData.default.attr(),
    amount1: _emberData.default.attr(),
    amount2: _emberData.default.attr(),
    amount3: _emberData.default.attr(),
    amount4: _emberData.default.attr(),
    amount5: _emberData.default.attr(),
    amount6: _emberData.default.attr(),
    jobId1: _emberData.default.attr(),
    jobId2: _emberData.default.attr(),
    jobId3: _emberData.default.attr(),
    jobId4: _emberData.default.attr(),
    jobId5: _emberData.default.attr(),
    jobId6: _emberData.default.attr()
  });

  _exports.default = _default;
});