define("client/react/custom-dashboards/bar-chart-config", ["exports", "react", "yup", "@mui/styles/makeStyles", "client/react/utilities/styles", "client/react/components/spacer", "clsx", "client/react/components/alt/form-switch", "client/react/custom-dashboards/use-widget-config", "client/react/utilities/yup-validation-helpers", "client/react/components/alt/form-currency-text-field"], function (_exports, _react, yup, _makeStyles, _styles, _spacer, _clsx, _formSwitch, _useWidgetConfig2, _yupValidationHelpers, _formCurrencyTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _makeStyles.default)(function (_) {
    return _objectSpread({}, _styles.styles);
  });

  function BarChartConfig(_ref) {
    var dimensions = _ref.dimensions,
        metrics = _ref.metrics,
        saveRef = _ref.saveRef,
        config = _ref.config,
        onSave = _ref.onSave;
    var classes = useStyles();
    var sortedDimensions = dimensions.sort(function (a, b) {
      return a.fieldName.localeCompare(b.fieldName);
    });

    var _useWidgetConfig = (0, _useWidgetConfig2.default)({
      config: config,
      dimensionsList: sortedDimensions,
      metricsList: metrics,
      saveRef: saveRef,
      onSave: onSave,
      dimensions: {
        primaryDimension: {
          label: "Primary Dimension",
          required: true
        },
        secondaryDimension: {
          label: "Secondary Dimension"
        }
      },
      metrics: {
        metric: {
          label: "Metric",
          required: true
        }
      },
      meta: {
        enableLegend: {
          defaultValue: false,
          schema: yup.boolean()
        },
        enableXAxis: {
          defaultValue: false,
          schema: yup.boolean()
        },
        enableYAxis: {
          defaultValue: false,
          schema: yup.boolean()
        },
        groupedInsteadOfStacked: {
          defaultValue: false,
          schema: yup.boolean()
        },
        enableHorizontal: {
          defaultValue: false,
          schema: yup.boolean()
        },
        marginTop: {
          defaultValue: 50,
          schema: yup.number().typeError((0, _yupValidationHelpers.numberTypeError)())
        },
        marginRight: {
          defaultValue: 40,
          schema: yup.number().typeError((0, _yupValidationHelpers.numberTypeError)())
        },
        marginBottom: {
          defaultValue: 50,
          schema: yup.number().typeError((0, _yupValidationHelpers.numberTypeError)())
        },
        marginLeft: {
          defaultValue: 40,
          schema: yup.number().typeError((0, _yupValidationHelpers.numberTypeError)())
        },
        xAxisOffset: {
          defaultValue: 80,
          schema: yup.number().typeError((0, _yupValidationHelpers.numberTypeError)())
        },
        yAxisOffset: {
          defaultValue: 60,
          schema: yup.number().typeError((0, _yupValidationHelpers.numberTypeError)())
        }
      },
      normalizeConfig: function normalizeConfig(_ref2) {
        var _ref2$dimensions = _slicedToArray(_ref2.dimensions, 2),
            primaryDimension = _ref2$dimensions[0],
            _ref2$dimensions$ = _ref2$dimensions[1],
            secondaryDimension = _ref2$dimensions$ === void 0 ? null : _ref2$dimensions$,
            filters = _ref2.filters,
            meta = _ref2.meta,
            metric = _ref2.metric,
            title = _ref2.title,
            description = _ref2.description;

        return {
          primaryDimension: primaryDimension,
          secondaryDimension: secondaryDimension,
          metric: metric,
          filters: filters,
          meta: meta,
          title: title,
          description: description
        };
      },
      denormalizeConfig: function denormalizeConfig(_ref3) {
        var primaryDimension = _ref3.primaryDimension,
            secondaryDimension = _ref3.secondaryDimension,
            metric = _ref3.metric,
            filters = _ref3.filters,
            meta = _ref3.meta,
            title = _ref3.title,
            description = _ref3.description;
        return {
          dimensions: secondaryDimension ? [primaryDimension, secondaryDimension] : [primaryDimension],
          metric: metric,
          filters: filters,
          meta: meta,
          title: title,
          description: description
        };
      }
    }),
        components = _useWidgetConfig.components,
        _useWidgetConfig$form = _useWidgetConfig.form,
        control = _useWidgetConfig$form.control,
        watch = _useWidgetConfig$form.watch,
        setValue = _useWidgetConfig$form.setValue;

    var secondaryDimension = watch('secondaryDimension');
    var enableLegend = watch('enableLegend');

    if (!secondaryDimension && enableLegend) {
      setValue('enableLegend', false);
      setValue('groupedInsteadOfStacked', false);
    }

    return _react.default.createElement(_react.default.Fragment, null, components.title, components.description, _react.default.createElement("div", {
      className: classes.row
    }, components.primaryDimension.autocomplete, _react.default.createElement(_spacer.default, null), components.secondaryDimension.autocomplete), _react.default.createElement("div", {
      className: classes.row
    }, components.metric.autocomplete, _react.default.createElement(_spacer.default, null), components.metric.aggregationFnAutocomplete), _react.default.createElement("div", {
      className: (0, _clsx.default)(classes.alignSpaceAround, classes.row)
    }, _react.default.createElement(_formCurrencyTextField.default, {
      formProps: {
        name: 'marginTop',
        control: control
      },
      label: 'Margin Top',
      margin: "normal",
      className: 'flex',
      decimalPlaces: 2,
      currencySymbol: '',
      zeroIfEmpty: true
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_formCurrencyTextField.default, {
      formProps: {
        name: 'marginRight',
        control: control
      },
      label: 'Margin Right',
      margin: "normal",
      className: 'flex',
      decimalPlaces: 2,
      currencySymbol: '',
      zeroIfEmpty: true
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_formCurrencyTextField.default, {
      formProps: {
        name: 'marginBottom',
        control: control
      },
      label: 'Margin Bottom',
      margin: "normal",
      className: 'flex',
      decimalPlaces: 2,
      currencySymbol: '',
      zeroIfEmpty: true
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_formCurrencyTextField.default, {
      formProps: {
        name: 'marginLeft',
        control: control
      },
      label: 'Margin Left',
      margin: "normal",
      className: 'flex',
      decimalPlaces: 2,
      currencySymbol: '',
      zeroIfEmpty: true
    })), _react.default.createElement("div", {
      className: (0, _clsx.default)(classes.alignSpaceAround, classes.row)
    }, _react.default.createElement(_formCurrencyTextField.default, {
      formProps: {
        name: 'xAxisOffset',
        control: control
      },
      label: 'X-Axis Offset',
      margin: "normal",
      className: 'flex',
      decimalPlaces: 2,
      currencySymbol: '',
      zeroIfEmpty: true
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_formCurrencyTextField.default, {
      formProps: {
        name: 'yAxisOffset',
        control: control
      },
      label: 'Y-Axis Offset',
      margin: "normal",
      className: 'flex',
      decimalPlaces: 2,
      currencySymbol: '',
      zeroIfEmpty: true
    })), _react.default.createElement("div", {
      className: (0, _clsx.default)(classes.alignSpaceAround, classes.row)
    }, _react.default.createElement(_formSwitch.default, {
      formProps: {
        name: 'enableHorizontal',
        control: control
      },
      label: 'Enable Horizontal Layout'
    }), _react.default.createElement(_formSwitch.default, {
      formProps: {
        name: 'enableXAxis',
        control: control
      },
      label: 'Enable X-Axis'
    }), _react.default.createElement(_formSwitch.default, {
      formProps: {
        name: 'enableYAxis',
        control: control
      },
      label: 'Enable Y-Axis'
    })), _react.default.createElement("div", {
      className: (0, _clsx.default)(classes.alignSpaceAround, classes.row)
    }, _react.default.createElement(_formSwitch.default, {
      formProps: {
        name: 'enableLegend',
        control: control
      },
      label: 'Legend Enabled',
      disabled: !secondaryDimension
    }), _react.default.createElement(_formSwitch.default, {
      formProps: {
        name: 'groupedInsteadOfStacked',
        control: control
      },
      label: 'Grouped Instead Of Stacked',
      disabled: !secondaryDimension
    })), _react.default.createElement(_spacer.default, null), components.filter, components.dynamicInputsDialog);
  }

  var _default = BarChartConfig;
  _exports.default = _default;
});