define("client/helpers/get-by-key-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getByKeyValue = getByKeyValue;

  function getByKeyValue(params
  /*, hash*/
  ) {
    var object = params.objectAt(0);
    var key = params.objectAt(1);
    var value = params.objectAt(2);

    if (key && value) {
      return object.findBy(key, value) ? object.findBy(key, value) : '';
    } else {
      return '';
    }
  }

  var _default = Ember.Helper.helper(getByKeyValue);

  _exports.default = _default;
});