define("client/components/modal-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    style: '',
    actions: {
      close: function close() {
        return this.sendAction();
      },
      edit: function edit(id) {
        console.log(id);
        return this.sendAction(id);
      },
      cancel: function cancel() {
        return this.sendAction();
      }
    }
  });

  _exports.default = _default;
});