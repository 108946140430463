define("client/models/permission-override", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tenant: _emberData.default.attr(),
    permission: _emberData.default.belongsTo('permission'),
    overrides: _emberData.default.attr()
  });

  _exports.default = _default;
});