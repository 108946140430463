define("client/models/linked-solicitation-option", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    updated: _emberData.default.attr(),
    parentOption: _emberData.default.belongsTo('contract-option', {
      async: true
    }),
    solicitation: _emberData.default.belongsTo('contract-solicitation', {
      async: true
    }),
    description: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    isDiscount: _emberData.default.attr(),
    isPercent: _emberData.default.attr(),
    expiresOnRenewal: _emberData.default.attr()
  });

  _exports.default = _default;
});