define("client/components/summary-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U1ifCXU1",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"ember-table-table-row ember-table-row-style-\",[26,[\"depth\"]]]]],[15,\"style\",\"flex:1; flex-basis: 30px;\"],[13],[0,\"\\n\"],[6,[\"each\"],[[33,[\"get\"],[[28,[\"data\"]],[28,[\"summaryPath\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-cell\"],[15,\"style\",\"width: 100%; text-align: right;\"],[13],[0,\"\\n\\t\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-cell-container\"],[13],[0,\"\\n\\t\\t\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-content\"],[13],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,[\"summary\",\"summary\"]],false],[0,\"\\n\\t\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\"]],\"locals\":[\"summary\"]},null],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"isExpanded\"]]],null,{\"statements\":[[6,[\"each\"],[[33,[\"get\"],[[28,[\"data\"]],[28,[\"childrenPath\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[33,[\"summary-row\"],null,[[\"depth\",\"data\",\"summaryPath\",\"childrenPath\"],[[33,[\"inc\"],[[28,[\"depth\"]]],null],[28,[\"child\"]],[28,[\"summaryPath\"]],[28,[\"childrenPath\"]]]]],false],[0,\"\\n\"]],\"locals\":[\"child\"]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/summary-row/template.hbs"
    }
  });

  _exports.default = _default;
});