define("client/react/components/date-range-changer-dialog", ["exports", "@mui/material", "@mui/styles", "client/react/components/alt/form-date-text-field", "clsx", "react", "client/react/utilities/smart-form", "client/react/utilities/styles", "yup"], function (_exports, _material, _styles, _formDateTextField, _clsx, _react, _smartForm, _styles2, _yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _styles.makeStyles)(function (theme) {
    return _objectSpread(_objectSpread({}, _styles2.styles), {}, {
      dateField: {
        marginLeft: '5px',
        marginRight: '5px'
      }
    });
  });

  var schema = _yup.default.object().shape({
    startDate: _yup.default.string(),
    endDate: _yup.default.string()
  });

  function DateRangeChangerDialog(_ref) {
    var _errors$startDate, _errors$endDate;

    var onSubmit = _ref.onSubmit,
        onClose = _ref.onClose,
        startDate = _ref.startDate,
        endDate = _ref.endDate;
    var classes = useStyles();

    var _useState = (0, _react.useState)(true),
        _useState2 = _slicedToArray(_useState, 2),
        isOpen = _useState2[0],
        setIsOpen = _useState2[1];

    var _useSmartForm = (0, _smartForm.default)({
      schema: schema,
      defaultValues: {
        startDate: startDate,
        endDate: endDate
      }
    }),
        control = _useSmartForm.control,
        handleSubmit = _useSmartForm.handleSubmit,
        errors = _useSmartForm.errors;

    return React.createElement(_material.Dialog, {
      open: isOpen,
      TransitionProps: {
        onExited: function onExited() {
          return onClose();
        }
      }
    }, React.createElement(_material.DialogTitle, null, " Change Date Range"), React.createElement(_material.DialogContent, {
      className: classes.column
    }, React.createElement("div", {
      className: classes.row
    }, React.createElement(_formDateTextField.default, {
      formProps: {
        control: control,
        name: 'startDate'
      },
      className: classes.dateField,
      label: "Start Date",
      helperText: errors === null || errors === void 0 ? void 0 : (_errors$startDate = errors.startDate) === null || _errors$startDate === void 0 ? void 0 : _errors$startDate.message
    }), React.createElement(_formDateTextField.default, {
      formProps: {
        control: control,
        name: 'endDate'
      },
      className: classes.dateField,
      label: "End Date",
      helperText: errors === null || errors === void 0 ? void 0 : (_errors$endDate = errors.endDate) === null || _errors$endDate === void 0 ? void 0 : _errors$endDate.message
    }))), React.createElement(_material.DialogActions, {
      className: (0, _clsx.default)(classes.row, classes.alignEnd)
    }, React.createElement(_material.Button, {
      color: "inherit",
      onClick: function onClick() {
        return setIsOpen(false);
      }
    }, "CLOSE"), React.createElement(_material.Button, {
      color: "primary",
      onClick: handleSubmit(onSubmit)
    }, "Submit")));
  }

  var _default = DateRangeChangerDialog;
  _exports.default = _default;
});