define("client/react/components/typed/form-time-picker", ["exports", "react/jsx-runtime", "@mui/x-date-pickers-pro", "@Harris-Barrick-Software/use-form", "@mui/material"], function (_exports, _jsxRuntime, _xDatePickersPro, _useForm, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function FormTimePicker(_a) {
    var formProps = _a.formProps,
        includeSeconds = _a.includeSeconds,
        restProps = __rest(_a, ["formProps", "includeSeconds"]);

    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });

    if (!controller) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field,
        fieldState = controller.fieldState;
    return (0, _jsxRuntime.jsx)(_xDatePickersPro.DesktopTimePicker, Object.assign({
      views: includeSeconds ? ['hours', 'minutes', 'seconds'] : ['hours', 'minutes'],
      mask: includeSeconds ? '__:__:__ _M' : '__:__ _M',
      inputFormat: includeSeconds ? 'hh:mm:ss A' : 'hh:mm A',
      OpenPickerButtonProps: {
        tabIndex: -1
      }
    }, restProps, {
      inputRef: field.ref,
      onChange: function onChange(date, value) {
        field.onChange(date && date.toDate ? date.toDate() : null);

        if (restProps.onChange) {
          restProps.onChange(date, value);
        }
      },
      renderInput: function renderInput(params) {
        var _a;

        return (0, _jsxRuntime.jsx)(_material.TextField, Object.assign({}, params, {
          placeholder: includeSeconds ? '12:00:00 AM' : '12:00 AM',
          className: restProps.className,
          margin: restProps.margin,
          helperText: !!restProps.hideHelperText ? null : (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message,
          error: fieldState.invalid,
          onBlur: function onBlur(e) {
            if (restProps.onBlur) {
              restProps.onBlur(e);
            }

            field.onBlur();
          }
        }));
      },
      value: field.value
    }));
  }

  var _default = FormTimePicker;
  _exports.default = _default;
});