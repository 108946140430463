define("client/models/service-gl-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('capitalize'),
    billableLaborGl: _emberData.default.attr('capitalize'),
    nonBillableLaborGl: _emberData.default.attr('capitalize'),
    billableLaborCostGl: _emberData.default.attr('capitalize'),
    nonBillableLaborCostGl: _emberData.default.attr('capitalize'),
    billableMaterialGl: _emberData.default.attr('capitalize'),
    nonBillableMaterialGl: _emberData.default.attr('capitalize'),
    billableMaterialCostGl: _emberData.default.attr('capitalize'),
    nonBillableMaterialCostGl: _emberData.default.attr('capitalize'),
    billableExpenseGl: _emberData.default.attr('capitalize'),
    nonBillableExpenseGl: _emberData.default.attr('capitalize'),
    discountGl: _emberData.default.attr('capitalize'),
    freightGl: _emberData.default.attr('capitalize'),
    prtInvoiceOnLine: _emberData.default.attr('yn-tf'),
    serviceInvoicePrinter: _emberData.default.attr('capitalize'),
    serviceOrderPrinter: _emberData.default.attr('capitalize'),
    prtServiceOrderOnLine: _emberData.default.attr('yn-tf'),
    taxLabor: _emberData.default.attr('yn-tf'),
    taxMaterial: _emberData.default.attr('yn-tf'),
    accruedLaborGl: _emberData.default.attr('capitalize'),
    updateNextMaint: _emberData.default.attr(),
    printServiceLogOnLine: _emberData.default.attr(),
    serviceLogPrinter: _emberData.default.attr('capitalize'),
    costCenter: Ember.computed('id', function () {
      return this.get('id') > 9 ? this.get('id').toString() : '0' + this.get('id');
    }),
    label: Ember.computed('costCenter', 'description', function () {
      return this.get('costCenter') + ' - ' + this.get('description');
    })
  });

  _exports.default = _default;
});