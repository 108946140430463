define("client/helpers/main-status-checkboxes-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mainStatusCheckboxesDisabled = mainStatusCheckboxesDisabled;

  function mainStatusCheckboxesDisabled(params
  /*, hash*/
  ) {
    return ["New Construction", "Auto-Generated", "Finished", "Renewal Auto-Generated", "Lunch", "Open", "Reschedule", "On Site", "Traveling", "Waiting", "Pending Finish", "Emergency"].includes(params[0]);
  }

  var _default = Ember.Helper.helper(mainStatusCheckboxesDisabled);

  _exports.default = _default;
});