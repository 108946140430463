define("client/templates/service-estimates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jWkb5Kbt",
    "block": "{\"statements\":[[1,[33,[\"service-estimates\"],null,[[\"estimates\",\"refresh\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"refresh\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/service-estimates.hbs"
    }
  });

  _exports.default = _default;
});