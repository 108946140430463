define("client/utils/prepare-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareObject;

  function prepareObject(object, modelName, store) {
    var obj = object;
    var dsModel = store.modelFor(modelName);
    dsModel.eachTransformedAttribute(function (name, type) {
      var transform = Ember.getOwner(store).lookup('transform:' + type);
      obj.set(name, transform.serialize(obj.get(name)));
    });
    return obj;
  }
});