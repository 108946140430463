define("client/components/nsb-category/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNameBindings: ["computedOpen:nsb-category"],
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        open: false
      });
    },
    click: function click() {
      if (this.get('hasBlock')) {
        if (!this.get('menuOpen')) {
          this.sendAction('toggleMenu');
          Ember.run.later(this, function () {
            this.toggleProperty('open');
          }, 100);
        } else {
          this.toggleProperty('open');
        }
      } else {
        this.sendAction('onClick');
      }
    },
    computedOpen: Ember.computed('menuOpen', 'open', function () {
      if (!this.get('menuOpen')) this.set('open', false);
      return this.get('menuOpen') ? this.get('open') : false;
    }),
    actions: {}
  });

  _exports.default = _default;
});