define("client/models/contract-checkup", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    updated: _emberData.default.attr(),
    contract: _emberData.default.belongsTo('contract', {
      async: true
    }),
    workOrder: _emberData.default.belongsTo('work-order', {
      async: true
    }),
    monthYear: _emberData.default.attr('month-year'),
    forRenewal: _emberData.default.attr(),
    complaints: _emberData.default.hasMany('contract-checkup-complaint', {
      async: true
    }),
    originalMonthYear: Ember.computed('monthYear', function () {
      if (this.get('monthYear')) {
        return this.get('monthYear').substring(3) + "/" + this.get('monthYear').substring(0, 2);
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});