define("client/controllers/srvc-contract-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['isRenewal', 'isPrior'],
    isRenewal: false,
    isPrior: false
  });

  _exports.default = _default;
});