define("client/adapters/application", ["exports", "ember-data", "client/config/environment", "client/react/utilities/snackbar"], function (_exports, _emberData, _environment, _snackbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(Ember.ControllerMixin, {
    host: function () {
      return _environment.default.APP.endpoint;
    }.property(),
    auth: Ember.inject.service(),
    application: Ember.inject.controller(),
    update: Ember.inject.service(),
    namespace: 'fsm',
    headers: function () {
      return {
        "X-Auth-Token": this.get('auth.access_token'),
        "X-Auth-Tenant": this.get('auth.tenantId')
      };
    }.property('auth.access_token', 'auth.tenantId'),
    updateRecord: function updateRecord(store, type, snapshot) {
      return this._super(store, type, snapshot);
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 401) {
        Ember.$.ajax({
          url: _environment.default.APP.endpoint + "/fsm/checkToken",
          type: 'get',
          success: function (result) {
            if (result.isValid) {
              (0, _snackbar.showSnackbar)('Invalid username or password', {
                variant: 'error'
              });
            } else {
              this.get('auth').invalidateToken();
              this.get('update.client').disconnect();
              this.get('application').transitionToRoute('login');
            }
          }.bind(this),
          error: function (reason) {
            this.get('auth').invalidateToken();
            this.transitionToRoute('login');
          }.bind(this),
          dataType: 'json',
          headers: {
            "X-Auth-Tenant": this.get('auth.tenantId'),
            "X-Auth-Token": this.get('auth.access_token')
          }
        });
      }

      if (status === 403) {
        if (typeof payload == 'string') {
          var name = payload.replace(/[A-Z]/g, function (x) {
            return " " + x;
          }).replace(/^./, function (s) {
            return s.toUpperCase();
          });
          (0, _snackbar.showSnackbar)("Access Denied for: " + name, {
            variant: 'error'
          });
        } else {
          (0, _snackbar.showSnackbar)('Access Denied', {
            variant: 'error'
          });
        }
      }

      if (status === 404) {
        (0, _snackbar.showSnackbar)("Record not found", {
          variant: 'error'
        });
        return this._super(404, headers, {
          data: null
        }, requestData);
      }

      try {
        return this._super(status, headers, payload, requestData);
      } catch (e) {
        console.log(e);
      }
    }
  });

  _exports.default = _default;
});