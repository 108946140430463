define("client/helpers/is-invoice-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isInvoiceRecord = isInvoiceRecord;

  function isInvoiceRecord(params
  /*, hash*/
  ) {
    return params[0] ? params[0].trim().match(/INVOICE/) : false;
  }

  var _default = Ember.Helper.helper(isInvoiceRecord);

  _exports.default = _default;
});