define("client/transforms/citron-time", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.isBlank(serialized) || !serialized) return "";
      if (serialized.length !== 4) return "";
      return serialized.substr(0, 2) + ":" + serialized.substr(2, 2);
    },
    serialize: function serialize(deserialized) {
      if (Ember.isBlank(deserialized) || !deserialized) return "";
      if (deserialized.length !== 5) return "";
      return deserialized.replace(/\D/g, "");
    }
  });

  _exports.default = _default;
});