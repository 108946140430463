define("client/react/components/titled-data", ["exports", "clsx", "@mui/material", "@mui/styles", "client/react/utilities/styles"], function (_exports, _clsx2, _material, _styles, _styles2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _styles.makeStyles)(function (theme) {
    return _objectSpread(_objectSpread({}, _styles2.styles), {}, {
      marginBottom: {
        marginBottom: '0.75em'
      },
      linkEnabled: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
      }
    });
  });

  function TitledData(_ref) {
    var containerClasses = _ref.containerClasses,
        data = _ref.data,
        subtitle = _ref.subtitle,
        _onClick = _ref.onClick;
    var classes = useStyles();
    var isBool = typeof data === 'boolean';
    var isString = typeof data === 'string';
    var isNull = data === null;
    var isUndefined = data === undefined;
    var isEmpty = isString && data.trim() === "";
    return React.createElement("div", {
      className: (0, _clsx2.default)(classes.column, classes.alignStartCenter, containerClasses || [])
    }, React.createElement(_material.Typography, {
      align: 'center',
      variant: 'body1'
    }, isNull || isUndefined || isEmpty ? React.createElement("span", null, "\u2014") : React.createElement("span", null, isBool ? data ? "Yes" : "No" : data)), React.createElement(_material.Typography, {
      className: (0, _clsx2.default)(classes.marginBottom, _defineProperty({}, classes.linkEnabled, !!_onClick)),
      align: 'center',
      color: 'textSecondary',
      variant: 'subtitle2',
      onClick: function onClick() {
        if (!!_onClick) {
          _onClick();
        }
      }
    }, subtitle));
  }

  var _default = TitledData;
  _exports.default = _default;
});