define("client/models/job-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr(),
    description: _emberData.default.attr('capitalize'),
    matlFactor: _emberData.default.attr(),
    laborFactor: _emberData.default.attr(),
    label: Ember.computed(function () {
      return this.get('key') + ' - ' + this.get('description');
    })
  });

  _exports.default = _default;
});