define("client/templates/gl-bottom-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mZNvnGm1",
    "block": "{\"statements\":[[6,[\"ember-wormhole\"],null,[[\"to\"],[\"main-toolbar\"]],{\"statements\":[[0,\"\\t\"],[11,\"h4\",[]],[13],[0,\"\\n\\t\\tBusiness Overview\\n\\t\"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[1,[26,[\"gl-bottom-line\"]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/gl-bottom-line.hbs"
    }
  });

  _exports.default = _default;
});