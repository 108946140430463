define("client/models/sales-journal-secondary-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    saleGl: _emberData.default.attr(),
    saleAmount: _emberData.default.attr(),
    units: _emberData.default.attr(),
    costGl: _emberData.default.attr(),
    costAmount: _emberData.default.attr()
  });

  _exports.default = _default;
});