define("client/components/viewer-js/component", ["exports", "viewerjs", "client/config/environment"], function (_exports, _viewerjs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    classNames: ['flex', 'layout-row', 'layout-align-center'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var element = this.$("#viewerjs-" + this.get('elementId'))[0];

      if (element) {
        var viewer = new _viewerjs.default(element, {});
        this.set('viewer', viewer);
      }
    },
    computedUrl: Ember.computed('attachment', function () {
      if (this.get('attachment')) {
        var url = _environment.default.APP.endpoint + "/fsm/getDynamicAttachment?id=" + this.get('attachment.id');
        url += "&token=".concat(this.get('auth.access_token'));
        return url;
      } else {
        return "";
      }
    })
  });

  _exports.default = _default;
});