define("client/components/my-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    content: null,
    selectedValue: null,
    hover: false,
    init: function init() {
      this._super.apply(this, arguments);

      var content = this.get('content');

      if (!content) {
        this.set('content', []);
      }
    },
    actions: {
      change: function change() {
        var changeAction = this.get('action');
        var selectedEl = this.$('select')[0];
        var selectedIndex = selectedEl.selectedIndex;

        if (this.get('prompt')) {
          selectedIndex = selectedIndex - 1;
        }

        var content = this.get('content');
        var selectedValue = content[selectedIndex];
        this.set('selectedValue', selectedValue);

        if (changeAction) {
          changeAction(selectedValue);
        }
      }
    }
  });

  _exports.default = _default;
});