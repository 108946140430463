define("client/components/tel-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // tagName: "input",
    inputObserver: function () {
      if (this.get('isInserted')) {
        var val = this.get('inValue');
        var newVal;

        if (val !== undefined) {
          if (val.length > 0 && val[0] !== '(') {
            newVal = '(' + val;
            this.set('inValue', newVal);
          }

          if (val.length > 4 && val[4] !== ')') {
            newVal = val.substring(0, 4) + ')' + val.substring(4);
            this.set('inValue', newVal);
          }

          if (val.length > 5 && val[5] !== ' ') {
            newVal = val.substring(0, 5) + ' ' + val.substring(5);
            this.set('inValue', newVal);
          }

          if (val.length > 9 && val[9] !== '-') {
            newVal = val.substring(0, 9) + '-' + val.substring(9);
            this.set('inValue', newVal);
          }
        }
      }
    }.observes('inValue').on('init'),
    didInsertElement: function didInsertElement() {
      this.set('isInserted', true);
    },
    inValue: "",
    isInserted: false
  });

  _exports.default = _default;
});