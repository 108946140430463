define("client/transforms/json", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return Ember.Object.create({});
      }

      var json = JSON.parse(serialized);

      function parseJson(value) {
        if (value instanceof Array) {
          for (var i = 0; i < value.length; i++) {
            value[i] = parseJson(value[i]);
          }

          return Ember.A(value);
        } else if (value instanceof Object) {
          var keys = Object.keys(value);

          for (var _i = 0; _i < keys.length; _i++) {
            value[keys[_i]] = parseJson(value[keys[_i]]);
          }

          return Ember.Object.create(value);
        } else {
          return value;
        }
      }

      return parseJson(json);
    },
    serialize: function serialize(deserialized) {
      return JSON.stringify(deserialized);
    }
  });

  _exports.default = _default;
});