define("client/helpers/citron-year", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.citronYear = citronYear;
  _exports.default = void 0;

  function citronYear(params
  /*, hash*/
  ) {
    var input = params[0];
    if (!input) return "";
    return "" + (10000 - Number(input));
  }

  var _default = Ember.Helper.helper(citronYear);

  _exports.default = _default;
});