define("client/components/pr-notes-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TmSDg40m",
    "block": "{\"statements\":[[6,[\"paper-form\"],null,[[\"tagName\",\"submit\",\"onSubmit\"],[\"\",null,[33,[\"perform\"],[[28,[\"save\"]]],null]]],{\"statements\":[[6,[\"paper-dialog\"],null,null,{\"statements\":[[6,[\"paper-toolbar\"],null,null,{\"statements\":[[6,[\"paper-toolbar-tools\"],null,null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[11,\"h4\",[]],[13],[0,\"Employee Notes\"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[6,[\"paper-dialog-content\"],null,[[\"class\"],[\"layout-column min-width-700\"]],{\"statements\":[[6,[\"if\"],[[28,[\"save\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[26,[\"paper-progress-linear\"]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                \"],[1,[33,[\"paper-input\"],null,[[\"label\",\"value\",\"onChange\",\"textarea\",\"passThru\",\"lineHeight\"],[\"Notes\",[28,[\"employee\",\"notes\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"employee\",\"notes\"]]],null]],null],true,[33,[\"hash\"],null,[[\"rows\",\"maxrows\"],[12,12]]],29]]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},null],[6,[\"paper-dialog-actions\"],null,[[\"class\"],[\"layout-row\"]],{\"statements\":[[0,\"\\t\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n\"],[6,[\"unless\"],[[28,[\"save\",\"isRunning\"]]],null,{\"statements\":[[0,\"                \"],[6,[\"paper-button\"],null,[[\"onClick\"],[[28,[\"onClose\"]]]],{\"statements\":[[0,\"CLOSE\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"unless\"],[[28,[\"readOnly\"]]],null,{\"statements\":[[0,\"                    \"],[6,[\"paper-button\"],null,[[\"primary\",\"onClick\"],[true,[28,[\"form\",\"onSubmit\"]]]],{\"statements\":[[0,\"SAVE\"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[\"form\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/pr-notes-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});