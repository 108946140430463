define("client/components/view-screen-share/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sBIdw8br",
    "block": "{\"statements\":[[11,\"video\",[]],[15,\"style\",\"max-height: 100%; max-width: 100%;\"],[15,\"autoplay\",\"\"],[13],[14],[0,\"\\n\"],[6,[\"each\"],[[33,[\"-each-in\"],[[28,[\"webrtc\",\"streams\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[6,[\"paper-button\"],null,[[\"onClick\"],[[33,[\"perform\"],[[28,[\"viewStream\"]],[28,[\"stream\"]]],null]]],{\"statements\":[[1,[28,[\"user\"]],false],[0,\" \"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[\"user\",\"stream\"]},null],[6,[\"if\"],[[33,[\"or\"],[[33,[\"eq\"],[[28,[\"auth\",\"activeUser\"]],\"admin\"],null],[33,[\"eq\"],[[28,[\"auth\",\"activeUser\"]],\"user\"],null]],null]],null,{\"statements\":[[0,\"\\t\"],[6,[\"paper-button\"],null,[[\"onClick\",\"raised\",\"primary\"],[[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"sendVideo\"]]],null],true],null],true,true]],{\"statements\":[[0,\"VIDEO\"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"if\"],[[28,[\"sendVideo\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[33,[\"screen-share\"],null,[[\"onClose\",\"allowRequest\"],[[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"sendVideo\"]]],null],false],null],true]]],false],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"paper-button\"],null,[[\"fab\",\"onClick\",\"class\"],[true,[33,[\"action\"],[[28,[null]],\"fullscreen\"],null],\"create-product-item\"]],{\"statements\":[[1,[33,[\"paper-icon\"],[\"fullscreen\"],null],false]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/view-screen-share/template.hbs"
    }
  });

  _exports.default = _default;
});