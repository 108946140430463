define("client/templates/vendor-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T01EggeB",
    "block": "{\"statements\":[[1,[33,[\"vendor-create\"],null,[[\"setItem\"],[[33,[\"route-action\"],[\"toView\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/vendor-create.hbs"
    }
  });

  _exports.default = _default;
});