define("client/components/date-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    tagName: 'input',
    attributeBindings: ['type', 'value'],
    classNames: ['form-control'],
    value: "",
    type: "text",
    tabindex: 0,
    didInsertElement: function didInsertElement() {
      this.$().datepicker({
        showButtonPanel: true,
        changeMonth: true,
        changeYear: true,
        numberOfMonths: 3,
        dateFormat: "mm/dd/yy"
      });
    },
    click: function click() {
      Ember.run.later(function () {
        Ember.$('.ui-datepicker-current').on('click', function () {
          this.set('value', moment().format('MM/DD/YYYY'));
          this.$().datepicker('hide');
        }.bind(this));
      }.bind(this), 500);
    }
  });

  _exports.default = _default;
});