define("client/components/render-notifier/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.sendAction('onRender');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.sendAction('onDestroy');
    }
  });

  _exports.default = _default;
});