define("client/templates/srvc-contract-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JWcyTgCT",
    "block": "{\"statements\":[[1,[33,[\"srvc-contract-search\"],null,[[\"selectContract\",\"createContract\",\"editContract\",\"deleteContract\",\"cancelDelete\"],[[33,[\"route-action\"],[\"transition\",\"srvc-contract-view\"],null],[33,[\"route-action\"],[\"transitionNoParam\",\"srvc-contract-create\"],null],[33,[\"route-action\"],[\"transition\",\"srvc-contract-edit\"],null],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null]],null],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null],null],null]]]],false],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"isDeleting\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"srvc-contract-delete\"],null,[[\"model\",\"onClose\",\"onDelete\"],[[28,[\"isDeleting\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null],null],null],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null],null],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/srvc-contract-search.hbs"
    }
  });

  _exports.default = _default;
});