define("client/models/flat-rate-part", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    group: _emberData.default.attr('capitalize'),
    flrateId: _emberData.default.attr('capitalize'),
    vendorAbbrev: _emberData.default.attr('capitalize'),
    vendorPartNumber: _emberData.default.attr('capitalize'),
    mfgAbbrev: _emberData.default.attr('capitalize'),
    mfgPartNumber: _emberData.default.attr('capitalize'),
    description: _emberData.default.attr('capitalize'),
    cost: _emberData.default.attr('currency'),
    qty: _emberData.default.attr('trim'),
    flrate: _emberData.default.belongsTo('flat-rate-item', {
      async: true
    })
  });

  _exports.default = _default;
});