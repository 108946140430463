define("client/components/scheduled-work-orders/component", ["exports", "client/react/service/scheduled-work-orders", "client/components/react-component/component"], function (_exports, _scheduledWorkOrders, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.reactRender(React.createElement(_scheduledWorkOrders.default, {
        onClose: function onClose() {
          return _this.sendAction('onClose');
        }
      }));
    }
  });

  _exports.default = _default;
});