define("client/models/work-order-info", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var WorkOrderInfo = _emberData.default.Model.extend({
    srvcId: _emberData.default.attr(),
    lineNumber: _emberData.default.attr(),
    equipId: _emberData.default.attr('capitalize'),
    serialNumber: _emberData.default.attr('capitalize'),
    description: _emberData.default.attr('capitalize'),
    billableMaterial: _emberData.default.attr('currency'),
    billableHours: _emberData.default.attr(),
    billableLabor: _emberData.default.attr('currency'),
    nonBillableMaterial: _emberData.default.attr('currency'),
    nonBillableHours: _emberData.default.attr(),
    nonBillableLabor: _emberData.default.attr('currency'),
    laborFlag: _emberData.default.attr(),
    materialFlag: _emberData.default.attr(),
    brand: _emberData.default.attr('capitalize'),
    issue: _emberData.default.attr(),
    dateOfPurch: _emberData.default.attr(),
    primaryOverridden: _emberData.default.attr(),
    solution: _emberData.default.attr(),
    laborRate: _emberData.default.attr(),
    laborCode: _emberData.default.attr('capitalize'),
    dynamicInvoice: _emberData.default.attr(),
    workOrder: _emberData.default.belongsTo('work-order', {
      async: true
    }),
    numericLineNumber: Ember.computed('lineNumber', function () {
      var temp = parseInt(this.get('lineNumber'));
      return isNaN(temp) ? 0 : temp;
    })
  });

  var _default = WorkOrderInfo;
  _exports.default = _default;
});