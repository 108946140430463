define("client/transforms/citron-year", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var decadeHash = {
        "A": 0,
        "B": 1,
        "C": 2,
        "D": 3,
        "E": 4,
        "F": 5,
        "G": 6,
        "H": 7,
        "I": 8,
        "J": 9
      };
      if (Ember.isBlank(serialized)) return null;
      var decadeLetter = serialized.substr(0, 1);
      var decadeDigit = decadeHash[decadeLetter];
      var date = serialized.replace(decadeLetter, decadeDigit);
      return moment(date, 'YYMMDD').format('YYYY-MM-DD');
    },
    serialize: function serialize(deserialized) {
      var decadeArray = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
      if (Ember.isBlank(deserialized)) return '';
      var decadeDigit = deserialized.substr(2, 1);
      var decadeLetter = decadeArray[decadeDigit];
      var year = moment(deserialized, 'YYYY-MM-DD').format('YYMMDD');
      return decadeLetter + year.substr(1);
    }
  });

  _exports.default = _default;
});