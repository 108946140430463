define("client/models/ledger-control-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    glCode: _emberData.default.attr(),
    controlId: _emberData.default.attr(),
    ledgerAccount: _emberData.default.belongsTo('ledger-account', {
      async: true
    })
  });

  _exports.default = _default;
});