define("client/models/ledger-std-entry", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    key: _emberData.default.attr(),
    description: _emberData.default.attr('capitalize'),
    gl1: _emberData.default.attr(),
    gl2: _emberData.default.attr(),
    gl3: _emberData.default.attr(),
    gl4: _emberData.default.attr(),
    gl5: _emberData.default.attr(),
    gl6: _emberData.default.attr(),
    gl7: _emberData.default.attr(),
    gl8: _emberData.default.attr(),
    gl9: _emberData.default.attr(),
    gl10: _emberData.default.attr(),
    gl11: _emberData.default.attr(),
    gl12: _emberData.default.attr(),
    gl13: _emberData.default.attr(),
    gl14: _emberData.default.attr(),
    gl15: _emberData.default.attr(),
    gl16: _emberData.default.attr(),
    gl17: _emberData.default.attr(),
    gl18: _emberData.default.attr(),
    gl19: _emberData.default.attr(),
    gl20: _emberData.default.attr(),
    gl21: _emberData.default.attr(),
    gl22: _emberData.default.attr(),
    gl23: _emberData.default.attr(),
    gl24: _emberData.default.attr(),
    gl25: _emberData.default.attr(),
    gl26: _emberData.default.attr(),
    gl27: _emberData.default.attr(),
    gl28: _emberData.default.attr(),
    gl29: _emberData.default.attr(),
    gl30: _emberData.default.attr(),
    gl31: _emberData.default.attr(),
    gl32: _emberData.default.attr(),
    gl33: _emberData.default.attr(),
    gl34: _emberData.default.attr(),
    gl35: _emberData.default.attr(),
    gl36: _emberData.default.attr(),
    gl37: _emberData.default.attr(),
    gl38: _emberData.default.attr(),
    gl39: _emberData.default.attr(),
    gl40: _emberData.default.attr(),
    amount1: _emberData.default.attr(),
    amount2: _emberData.default.attr(),
    amount3: _emberData.default.attr(),
    amount4: _emberData.default.attr(),
    amount5: _emberData.default.attr(),
    amount6: _emberData.default.attr(),
    amount7: _emberData.default.attr(),
    amount8: _emberData.default.attr(),
    amount9: _emberData.default.attr(),
    amount10: _emberData.default.attr(),
    amount11: _emberData.default.attr(),
    amount12: _emberData.default.attr(),
    amount13: _emberData.default.attr(),
    amount14: _emberData.default.attr(),
    amount15: _emberData.default.attr(),
    amount16: _emberData.default.attr(),
    amount17: _emberData.default.attr(),
    amount18: _emberData.default.attr(),
    amount19: _emberData.default.attr(),
    amount20: _emberData.default.attr(),
    amount21: _emberData.default.attr(),
    amount22: _emberData.default.attr(),
    amount23: _emberData.default.attr(),
    amount24: _emberData.default.attr(),
    amount25: _emberData.default.attr(),
    amount26: _emberData.default.attr(),
    amount27: _emberData.default.attr(),
    amount28: _emberData.default.attr(),
    amount29: _emberData.default.attr(),
    amount30: _emberData.default.attr(),
    amount31: _emberData.default.attr(),
    amount32: _emberData.default.attr(),
    amount33: _emberData.default.attr(),
    amount34: _emberData.default.attr(),
    amount35: _emberData.default.attr(),
    amount36: _emberData.default.attr(),
    amount37: _emberData.default.attr(),
    amount38: _emberData.default.attr(),
    amount39: _emberData.default.attr(),
    amount40: _emberData.default.attr()
  });

  _exports.default = _default;
});