define("client/helpers/is-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEmpty = isEmpty;

  function isEmpty(params
  /*, hash*/
  ) {
    return Ember.isBlank(params[0]) ? '-' : params[0];
  }

  var _default = Ember.Helper.helper(isEmpty);

  _exports.default = _default;
});