define("client/models/communication", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    tenant: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer', {
      async: true,
      inverse: null
    }),
    site: _emberData.default.belongsTo('site-address', {
      async: true
    }),
    description: _emberData.default.attr(),
    outcome: _emberData.default.attr(),
    type: _emberData.default.attr(),
    eventDatetime: _emberData.default.attr(),
    job: _emberData.default.belongsTo('job', {
      async: true
    }),
    lead: _emberData.default.belongsTo('lead', {
      async: true
    }),
    task: _emberData.default.attr(),
    ticket: _emberData.default.attr(),
    quote: _emberData.default.attr(),
    workOrder: _emberData.default.belongsTo('work-order', {
      async: true
    }),
    contractSolicitation: _emberData.default.belongsTo('contract-solicitation', {
      async: true
    }),
    serviceEstimate: _emberData.default.belongsTo('service-estimate', {
      async: true
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    phoneCallId: _emberData.default.attr(),
    user: _emberData.default.attr(),
    computedDate: Ember.computed('eventDatetime', {
      get: function get(key) {
        var datetime = this.get('eventDatetime');

        if (datetime) {
          return moment(datetime, DATE_TIME_FORMAT).format('YYYY-MM-DD');
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          var time = this.get('computedTime');

          if (time && time.length < 5) {
            time += "0" + time;
          }

          this.set('eventDatetime', value + ' ' + (time ? time : '00:00') + ":00");
        } else {
          this.set('eventDatetime', null);
        }

        return value;
      }
    }),
    computedTime: Ember.computed('eventDatetime', {
      get: function get(key) {
        var datetime = this.get('eventDatetime');

        if (datetime) {
          return moment(datetime, DATE_TIME_FORMAT).format('HH:mm');
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        var date = this.get('computedDate');

        if (date) {
          if (value) {
            if (value.length < 5) {
              this.set('eventDatetime', date + ' ' + "0" + value + ":00");
            } else {
              this.set('eventDatetime', date + ' ' + value + ":00");
            }

            return value;
          } else {
            return '0:00';
          }
        } else {
          return null;
        }
      }
    })
  });

  _exports.default = _default;
});