define("client/controllers/srvc-billing-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['searchDate', 'searchTerm'],
    searchTerm: '',
    searchDate: ''
  });

  _exports.default = _default;
});