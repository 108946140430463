define("client/models/emailz", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inflector = Ember.Inflector.inflector;
  inflector.irregular('emailz', 'emailz');
  inflector.singular(/emailz/, 'emailz');
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr(),
    emailAddress: _emberData.default.attr(),
    label: computed('key', 'emailAddress', function () {
      return 'EM' + this.get('key') + ' - ' + this.get('emailAddress');
    })
  });

  _exports.default = _default;
});