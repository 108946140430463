define("client/templates/ar-cust-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/2vn1J2C",
    "block": "{\"statements\":[[1,[33,[\"ar-cust-edit\"],null,[[\"customer\",\"cancelEdit\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"cancelEditCustomer\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/ar-cust-edit.hbs"
    }
  });

  _exports.default = _default;
});