define("client/components/paper-menu2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SdWrDUuI",
    "block": "{\"statements\":[[18,\"default\",[[33,[\"hash\"],null,[[\"dropdown\",\"isOpen\",\"disabled\",\"actions\",\"trigger\",\"content\"],[[33,[\"readonly\"],[[28,[\"publicAPI\"]]],null],[28,[\"publicAPI\",\"isOpen\"]],[28,[\"publicAPI\",\"disabled\"]],[28,[\"publicAPI\",\"actions\"]],[33,[\"component\"],[[28,[\"triggerComponent\"]]],[[\"tagName\",\"dropdown\",\"hPosition\",\"onFocus\",\"renderInPlace\",\"vPosition\",\"tabindex\"],[\"md-menu\",[33,[\"readonly\"],[[28,[\"publicAPI\"]]],null],[33,[\"readonly\"],[[28,[\"hPosition\"]]],null],[33,[\"action\"],[[28,[null]],\"handleFocus\"],null],[33,[\"readonly\"],[[28,[\"renderInPlace\"]]],null],[33,[\"readonly\"],[[28,[\"vPosition\"]]],null],-1]]],[33,[\"component\"],[\"paper-menu-content\"],[[\"dropdown\",\"destination\",\"hPosition\",\"renderInPlace\",\"vPosition\",\"top\",\"left\",\"right\",\"width\",\"transform\",\"transformOrigin\"],[[33,[\"readonly\"],[[28,[\"publicAPI\"]]],null],[33,[\"readonly\"],[[28,[\"destination\"]]],null],[33,[\"readonly\"],[[28,[\"hPosition\"]]],null],[33,[\"readonly\"],[[28,[\"renderInPlace\"]]],null],[33,[\"readonly\"],[[28,[\"vPosition\"]]],null],[33,[\"readonly\"],[[28,[\"top\"]]],null],[33,[\"readonly\"],[[28,[\"left\"]]],null],[33,[\"readonly\"],[[28,[\"right\"]]],null],[33,[\"readonly\"],[[28,[\"width\"]]],null],[28,[\"transform\"]],[28,[\"transformOrigin\"]]]]]]]]]],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/paper-menu2/template.hbs"
    }
  });

  _exports.default = _default;
});