define("client/transforms/intcsv", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var arr = serialized.split(",");
      arr = arr.map(function (currentValue, index, array) {
        return Number(currentValue);
      });
      return arr;
    },
    serialize: function serialize(deserialized) {
      deserialized = deserialized.map(function (currentValue, index, array) {
        return currentValue.toString();
      });
      return deserialized.join(",");
    }
  });

  _exports.default = _default;
});