define("client/components/new-side-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "owPuBzoJ",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"layout-column\"],[15,\"style\",\"height:100%\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"style\",\"white-space: nowrap; cursor: pointer;\"],[15,\"class\",\"flex-none\"],[5,[\"action\"],[[28,[null]],\"toggleMenu\"]],[13],[0,\"\\n        \"],[6,[\"paper-tooltip\"],null,[[\"position\"],[\"right\"]],{\"statements\":[[0,\"Toggle Menu\"]],\"locals\":[]},null],[0,\"\\n        \"],[6,[\"paper-button\"],null,[[\"class\",\"iconButton\",\"onClick\"],[\"new-side-bar-button\",true,null]],{\"statements\":[[1,[33,[\"paper-icon\"],[\"menu\"],null],false]],\"locals\":[]},null],[0,\"\\n        \"],[11,\"span\",[]],[15,\"style\",\"color: #b8c7ce;margin-left:15px;\"],[13],[0,\"\\n            Toggle Menu\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"ul\",[]],[15,\"class\",\"new-side-bar-list flex\"],[13],[0,\"\\n        \"],[18,\"default\",[[28,[\"isOpen\"]],[33,[\"action\"],[[28,[null]],\"toggleMenu\"],null],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"closeOnMouseLeave\"]]],null],true],null]]],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/new-side-bar/template.hbs"
    }
  });

  _exports.default = _default;
});