define("client/models/invoice-flrate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    srvcId: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    complaintNumber: _emberData.default.attr(),
    qty: _emberData.default.attr('number'),
    flrateId: _emberData.default.attr('capitalize'),
    description: _emberData.default.attr(),
    cost: _emberData.default.attr('currency'),
    sell: _emberData.default.attr('currency'),
    primary: _emberData.default.attr('primary-flrate'),
    afterHours: _emberData.default.attr(),
    srvcAgreement: _emberData.default.attr(),
    labor: _emberData.default.attr('currency'),
    laborCost: _emberData.default.attr('currency')
  });

  _exports.default = _default;
});