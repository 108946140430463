define("client/models/equipment-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var EquipmentItem = _emberData.default.Model.extend({
    model: _emberData.default.attr('capitalize'),
    custId: _emberData.default.attr(),
    shipToId: _emberData.default.attr(),
    serial: _emberData.default.attr('capitalize'),
    description: _emberData.default.attr('capitalize'),
    notes: _emberData.default.attr('capitalize'),
    category: _emberData.default.attr('capitalize'),
    partsWarrantyExpires: _emberData.default.attr(),
    laborWarrantyExpires: _emberData.default.attr(),
    equipmentCondition: _emberData.default.attr(),
    manufacturer: _emberData.default.attr('capitalize'),
    installDate: _emberData.default.attr(),
    purchasedFromUs: _emberData.default.attr('yn-tf'),
    dateInService: _emberData.default.attr(),
    lastServiceDate: _emberData.default.attr(),
    contractCoversLabor: _emberData.default.attr('yn-tf'),
    contractCoversParts: _emberData.default.attr('yn-tf'),
    contractNumber: _emberData.default.attr(),
    seerRating: _emberData.default.attr(),
    capacity: _emberData.default.attr(),
    inAttic: _emberData.default.attr(),
    siteAddress: _emberData.default.belongsTo('site-address', {
      async: true
    }),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    contracts: _emberData.default.hasMany('contract', {
      async: true
    }),
    materials: _emberData.default.hasMany('equipment-associated-material', {
      async: true
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    note2: Ember.computed('seerRating', 'capacity', {
      get: function get(key) {
        return this.get('seerRating') + this.get('capacity');
      },
      set: function set(key, value) {
        this.set('seerRating', value ? value.substr(0, 6) : '');
        this.set('capacity', value ? value.substr(6, 10) : '');
        return value;
      }
    }),
    label: Ember.computed('manufacturer', 'model', 'serial', function () {
      return this.get('custId') + ' - ' + this.get('manufacturer') + ' - ' + this.get('model') + ' - ' + this.get('serial');
    }),
    altLabel: Ember.computed('model', 'serial', 'manufacturer', function () {
      return this.get('model') + ' - ' + this.get('serial') + ' - ' + this.get('manufacturer');
    })
  });

  EquipmentItem.reopenClass({
    FIXTURES: [{
      id: 1,
      model: 'TestModelData1',
      serial: 'TestSerialData1',
      description: 'This is a condenser',
      category: 'Condenser',
      condition: 'New',
      installDate: '2014-02-25',
      siteAddress: 1,
      contracts: [1]
    }, {
      id: 2,
      model: 'TestModelData2',
      serial: 'TestSerialData2',
      description: 'This is an air handler',
      category: 'Air Handler',
      condition: 'Like New',
      installDate: '2012-08-12',
      siteAddress: 1,
      contracts: [2]
    }, {
      id: 3,
      model: 'TestModelData3',
      serial: 'TestSerialData3',
      description: 'This is a thermostat',
      category: 'Thermostat',
      condition: 'Old',
      installDate: '2004-01-07',
      siteAddress: 1,
      contracts: [1]
    }]
  });
  var _default = EquipmentItem;
  _exports.default = _default;
});