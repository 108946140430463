define("client/components/full-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['show'],
    isExpanded: null,
    actions: {
      close: function close() {
        return this.sendAction();
      }
    }
  });

  _exports.default = _default;
});