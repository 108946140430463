define("client/components/vr-paper-select-options/component", ["exports", "ember-paper/components/paper-select-options"], function (_exports, _paperSelectOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperSelectOptions.default.extend({});

  _exports.default = _default;
});