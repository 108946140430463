define("client/components/animated-spacer/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    hasFired: false,
    growDuration: 250,
    virtualElement: null,
    previousHeight: 0,
    firstRender: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('firstRender', true);
    },
    didRender: function didRender() {
      if (this.get('firstRender')) {
        var element = Ember.ViewUtils.getViewBounds(this).firstNode; // this._renderNode.firstNode.nextElementSibling

        while (element && element.nodeName === "#text" && element.nextSibling) {
          element = element.nextSibling;
        }

        if (typeof element.getBoundingClientRect !== "function") {
          return;
        }

        var height = element.getBoundingClientRect().height;
        this.set('previousHeight', height);
        this.set('firstRender', false);
      }

      if (!this.get('hasFired')) {
        var currentNode = Ember.ViewUtils.getViewBounds(this).firstNode; // this._renderNode.firstNode.nextElementSibling;

        while (currentNode && currentNode.nodeName === "#text" && currentNode.nextSibling) {
          currentNode = currentNode.nextSibling;
        }

        this.observer = new MutationObserver(function (mutations) {
          var shouldCancel = true;

          for (var i = 0; i < mutations.length; i++) {
            var addedNodes = Array.prototype.slice.call(mutations[i].addedNodes).filter(function (node) {
              return !$(node).hasClass('md-ripple') && node.nodeName !== '#comment';
            });
            var removedNodes = Array.prototype.slice.call(mutations[i].removedNodes).filter(function (node) {
              return !$(node).hasClass('md-ripple') && node.nodeName !== '#comment';
            }); // if ripple was the only mutation, just bail

            if (!addedNodes.length && !removedNodes.length) {//
            } else {
              shouldCancel = false;
            }
          }

          if (shouldCancel) {
            return;
          }

          var element = this.get('virtualElement');
          var lastHeight = this.get('previousHeight');

          if (element) {
            var _height = element.getBoundingClientRect().height;
            console.log('height: ' + _height); //console.log(mutations);

            element.animate([{
              height: "".concat(lastHeight, "px")
            }, {
              height: "".concat(_height, "px")
            }], {
              duration: this.get('growDuration'),
              easing: 'ease-in-out'
            });

            if (!this.get('isDestroyed')) {
              this.set('previousHeight', _height);
            }
          }
        }.bind(this));

        if (currentNode) {
          this.observer.observe(currentNode, {
            attributes: false,
            subtree: true,
            childList: true,
            characterData: true
          });

          if (this.get('initialAnimation')) {
            var _height2 = this.get('previousHeight');

            currentNode.animate([{
              height: "0px"
            }, {
              height: "".concat(_height2, "px")
            }], {
              duration: this.get('growDuration'),
              easing: 'ease-in-out'
            });
          }

          this.set('hasFired', true);
          this.set('virtualElement', currentNode);
        } // for (var i = 0; i < children.length; i++) {
        // 	let element = children[i];
        // 	element.animate([{ opacity: 0, transform: 'scale(0)' }, { opacity: 1, transform: 'scale(1)' }], { duration: 300, easing: 'ease-in-out', fill: 'both', delay: i*50 })
        // 	this.set('hasFired', true);
        // }


        console.log('animation stuff fired!');
      } //this.set('didUpdateFired', false);

    }
  });

  _exports.default = _default;
});