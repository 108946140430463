define("client/transforms/yn-tf", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized === "Y") return true;else return false;
    },
    serialize: function serialize(deserialized) {
      if (deserialized) return "Y";else return "N";
    }
  });

  _exports.default = _default;
});