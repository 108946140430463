define("client/routes/disbursements-journal", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      return Ember.RSVP.hash({
        jobCats: this.store.findAll('job-category', {
          reload: true
        }),
        banks: this.store.query('bank', {
          key: true
        }).then(function (banks) {
          var arr = Ember.A([]);
          banks.forEach(function (b) {
            var row = b.get('row');

            if (b.get('dglCode' + row)) {
              var temp = Ember.Object.create({
                accountNumber: b.get('accountNumber'),
                checkNum: b.get('checkNum' + row),
                glCode: b.get('dglCode' + row),
                glDescription: b.get('glDescription' + row),
                routingNumber: b.get('routingNumber'),
                routingNumber2: b.get('routingNumber2'),
                row: row,
                bank: b,
                printChecks: b.get('accountNumber') && b.get('routingNumber')
              });
              arr.pushObject(temp);
            }
          }); // if (arr.get('length') === 1) this.set('controller.selectedBank', arr.objectAt(0)); //TODO Need this functionality with the newly implemented component

          return arr.sortBy('row');
        })
      });
    }
  });

  _exports.default = _default;
});