define("client/models/technician", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var Technician = _emberData.default.Model.extend({
    employeeNumber: _emberData.default.attr('capitalize'),
    payRate: _emberData.default.attr(),
    payType: _emberData.default.attr(),
    email: _emberData.default.attr('capitalize'),
    workPhone: _emberData.default.attr('telephone'),
    name: _emberData.default.attr('capitalize'),
    category: _emberData.default.attr('capitalize'),
    payrollId: _emberData.default.attr('capitalize'),
    warehouse: _emberData.default.attr('capitalize'),
    printerFlag: _emberData.default.attr(),
    workOrders: _emberData.default.hasMany('work-order', {
      async: true,
      inverse: 'tech'
    }),
    workOrders2: _emberData.default.hasMany('work-order', {
      async: true,
      inverse: 'tech2'
    }),
    techNumber: Ember.computed('employeeNumber', function () {
      var number = parseInt(this.get('employeeNumber'));
      return isNaN(number) ? 0 : number;
    }),
    fullTech: Ember.computed('employeeNumber', 'name', function () {
      if (this.get('currentState.isLoading')) return '';
      return this.get('employeeNumber').trim() + ' - ' + this.get('name').trim();
    }),
    label: computed.alias('fullTech')
  });

  Technician.reopenClass({
    FIXTURES: [{
      id: 1,
      employeeNumber: 1,
      email: "jdoe@gmail.com",
      workPhone: "555-555-1234",
      name: "John Doe",
      workOrders: [1]
    }, {
      id: 2,
      employeeNumber: 2,
      email: "venice@gmail.com",
      workPhone: "555-555-4321",
      name: "Venice",
      workOrders: []
    }]
  });
  var _default = Technician;
  _exports.default = _default;
});