define("client/helpers/phone-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.phoneNumber = phoneNumber;

  function phoneNumber(params
  /*, hash*/
  ) {
    var number = params.objectAt(0);
    if (!number) return "";

    if (number.length === 10) {
      var revised = '(' + number.substr(0, 3) + ') ' + number.substr(3, 3) + '-' + number.substr(6, 4);
      return revised;
    } else if (number.length === 12) {
      var revised = "".concat(number.substr(0, 2), " (").concat(number.substr(2, 3), ") ").concat(number.substr(5, 3), "-").concat(number.substr(8, 4));
      return revised;
    } else {
      return number;
    }
  }

  var _default = Ember.Helper.helper(phoneNumber);

  _exports.default = _default;
});