define("client/utils/serialize-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeContainer;

  function serializeContainer(container) {
    var cache = container.cache;
    var newCache = {};
    var memo = {};

    function recursiveParse(hash, depth) {
      var serializableCache = {};

      if (hash in memo) {
        return hash.toString(); //memo[hash];
      }

      if (depth === 5) return null;
      if (hash._internalModel) return hash.toJSON();

      for (var prop in hash) {
        if (hash.hasOwnProperty && hash.hasOwnProperty(prop) && prop.charAt(0) !== '_') {
          if (hash[prop] instanceof Object && hash[prop]._internalModel) {
            serializableCache[prop] = hash[prop].toJSON();
          } else if (hash[prop] instanceof Object && Ember.Array.detect(hash[prop])) {
            serializableCache[prop] = hash[prop].map(function (x) {
              return recursiveParse(x, depth + 1);
            }).toArray();
          } else if (!(hash[prop] instanceof Object) && !(hash[prop] instanceof Function)) {
            serializableCache[prop] = hash[prop];
          } else if (hash[prop] instanceof Ember.Component) {
            serializableCache[prop] = recursiveParse(hash[prop], depth + 1);
          } else {
            serializableCache[prop] = null;
          }
        }
      }

      if (hash.toString && hash.toString().charAt(0) == '<') memo[hash] = serializableCache;
      return serializableCache;
    }

    for (var prop in cache) {
      newCache[prop] = recursiveParse(cache[prop], 0);
    }

    newCache['cache'] = memo;
    return newCache;
  }
});