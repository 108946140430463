define("client/helpers/no-auth-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.noAuthChecked = noAuthChecked;

  function noAuthChecked(params
  /*, hash*/
  ) {
    var module = params[0];
    return !module.get('screens').any(function (x) {
      return x.get('permissions').any(function (y) {
        return y.get('enabled');
      });
    });
  }

  var _default = Ember.Helper.helper(noAuthChecked);

  _exports.default = _default;
});