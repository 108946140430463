define("client/react/components/typed/form-switch", ["exports", "react/jsx-runtime", "@mui/material", "@Harris-Barrick-Software/use-form"], function (_exports, _jsxRuntime, _material, _useForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function FormSwitch(_a) {
    var formProps = _a.formProps,
        color = _a.color,
        restProps = __rest(_a, ["formProps", "color"]);

    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });

    if (!controller) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field;
    return (0, _jsxRuntime.jsx)(_material.FormControlLabel, Object.assign({}, restProps, {
      onChange: function onChange(e) {
        field.onChange(e.target.checked);

        if (restProps.onChange) {
          restProps.onChange(e);
        }
      },
      checked: !!field.value,
      inputRef: field.ref,
      control: (0, _jsxRuntime.jsx)(_material.Switch, {
        color: color ? color : "primary"
      })
    }));
  }

  var _default = FormSwitch;
  _exports.default = _default;
});