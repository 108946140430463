define("client/templates/gl-ledgers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FMD6uUfy",
    "block": "{\"statements\":[[1,[33,[\"gl-ledgers\"],null,[[\"accounts\",\"onSelect\",\"onEdit\",\"onReload\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"transitionTo\",\"gl-ledgers.view\"],null],[33,[\"route-action\"],[\"transitionTo\",\"gl-ledgers.edit\"],null],[33,[\"route-action\"],[\"reloadModel\"],null]]]],false],[0,\"\\n\"],[1,[26,[\"outlet\"]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/gl-ledgers.hbs"
    }
  });

  _exports.default = _default;
});