define("client/templates/jc-post-job-expense", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NYbPWZjx",
    "block": "{\"statements\":[[1,[26,[\"jc-post-job-expense\"]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/jc-post-job-expense.hbs"
    }
  });

  _exports.default = _default;
});