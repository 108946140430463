define("client/templates/lead-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "njhZ8d6V",
    "block": "{\"statements\":[[1,[33,[\"lead-edit\"],null,[[\"lead\",\"toSearch\",\"toView\",\"toCreate\"],[[33,[\"changeset\"],[[28,[\"model\"]]],null],[33,[\"route-action\"],[\"toSearch\"],null],[33,[\"route-action\"],[\"toView\"],null],[33,[\"route-action\"],[\"toCreate\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/lead-edit.hbs"
    }
  });

  _exports.default = _default;
});