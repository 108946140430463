define("client/models/report", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr(),
    descriptors: _emberData.default.attr('json'),
    module: _emberData.default.attr(),
    parameters: _emberData.default.attr('json'),
    column: _emberData.default.attr(),
    image: _emberData.default.attr(),
    hidden: _emberData.default.attr(),
    useServisuite: _emberData.default.attr(),
    servisuiteEnabled: _emberData.default.attr()
  });

  _exports.default = _default;
});