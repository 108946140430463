define("client/react/utilities/use-customer-pricing", ["exports", "client/react/utilities/transforms"], function (_exports, _transforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function useCustomerPricing(customer, contracts, costCenter) {
    if (!customer) {
      return {
        specialLaborRate: 0,
        defaultPreferred: false,
        laborDiscount: 0,
        partsDiscount: 0,
        preferredDiscount: 0
      };
    }

    var specialLaborRate = customer.discountSpecialPrice ? customer.discountSpecialPrice : 0,
        preferred = false,
        laborDiscount = 0,
        partsDiscount = 0,
        preferredDiscount = 0;

    if (contracts) {
      preferred = contracts.some(function (item) {
        var effective = item.effectiveDate,
            expiration = item.expirationDate,
            cancellation = item.cancelationDate,
            preferredCustomer = item.preferredCustomer;
        var isPreferred = preferredCustomer === "Y",
            inBetween = moment().isSameOrBefore(expiration, "day") && moment().isSameOrAfter(effective, "day"),
            notCancelled = !cancellation || moment().isBefore(cancellation, "day");
        var isExcluded = (0, _transforms.deserializeCsv)(item.excludedCostCenters).some(function (itm) {
          return parseInt(itm) === parseInt(costCenter);
        });
        return isPreferred && inBetween && notCancelled && !isExcluded;
      });
      partsDiscount = contracts.reduce(function (prev, curr) {
        var cancellation = curr.cancelationDate,
            notCancelled = !cancellation || moment().isBefore(cancellation, "day"),
            active = moment().isSameOrBefore(curr.expirationDate, "day") && moment().isSameOrAfter(curr.effectiveDate, "day") && notCancelled,
            isExcluded = (0, _transforms.deserializeCsv)(curr.excludedCostCenters).some(function (itm) {
          return parseInt(itm) === parseInt(costCenter);
        });
        var discount = !!curr.partsDiscount ? parseFloat(curr.partsDiscount) : 0;
        discount = isNaN(discount) ? 0 : discount;

        if (active && discount && !isExcluded) {
          return discount > prev ? discount : prev;
        }

        return prev;
      }, 0);
      laborDiscount = contracts.reduce(function (prev, curr) {
        var cancellation = curr.cancelationDate,
            notCancelled = !cancellation || moment().isBefore(cancellation, "day"),
            active = moment().isSameOrBefore(curr.expirationDate, "day") && moment().isSameOrAfter(curr.effectiveDate, "day") && notCancelled,
            isExcluded = (0, _transforms.deserializeCsv)(curr.excludedCostCenters).some(function (itm) {
          return parseInt(itm) === parseInt(costCenter);
        });
        var discount = !!curr.laborDiscount ? parseFloat(curr.laborDiscount) : 0;
        discount = isNaN(discount) ? 0 : discount;

        if (active && discount && !isExcluded) {
          return discount > prev ? discount : prev;
        }

        return prev;
      }, 0);
      preferredDiscount = contracts.reduce(function (prev, curr) {
        var cancellation = curr.cancelationDate,
            notCancelled = !cancellation || moment().isBefore(cancellation, "day"),
            active = moment().isSameOrBefore(curr.expirationDate, "day") && moment().isSameOrAfter(curr.effectiveDate, "day") && notCancelled,
            isExcluded = (0, _transforms.deserializeCsv)(curr.excludedCostCenters).some(function (itm) {
          return parseInt(itm) === parseInt(costCenter);
        });
        var discount = !!curr.preferredDiscount ? parseFloat(curr.preferredDiscount) : 0;
        discount = isNaN(discount) ? 0 : discount;

        if (active && discount && !isExcluded) {
          return discount > prev ? discount : prev;
        }

        return prev;
      }, 0);
    }

    return {
      specialLaborRate: specialLaborRate,
      defaultPreferred: preferred,
      laborDiscount: laborDiscount,
      partsDiscount: partsDiscount,
      preferredDiscount: preferredDiscount
    };
  }

  var _default = useCustomerPricing;
  _exports.default = _default;
});