define("client/components/nsb-category/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C26QoTPw",
    "block": "{\"statements\":[[9,\"paper-tooltip\",{\"attrs\":[[15,\"position\",\"right\"],[15,\"animation-delay\",\"0\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[1,[26,[\"categoryName\"]],false]]}],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"nsb-category-header\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"icon\"]]],null,{\"statements\":[[0,\"        \"],[6,[\"paper-button\"],null,[[\"class\",\"iconButton\",\"onClick\"],[\"new-side-bar-button\",true,null]],{\"statements\":[[1,[33,[\"paper-icon\"],[[28,[\"icon\"]]],null],false]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null],[0,\"    \"],[11,\"span\",[]],[15,\"style\",\"margin-left:15px;\"],[13],[0,\"\\n        \"],[1,[26,[\"categoryName\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"computedOpen\"]]],null,{\"statements\":[[0,\"    \"],[11,\"ul\",[]],[15,\"class\",\"new-side-bar-child-category\"],[13],[0,\"\\n        \"],[18,\"default\"],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/nsb-category/template.hbs"
    }
  });

  _exports.default = _default;
});