define("client/models/warehouse", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    number: _emberData.default.attr('capitalize'),
    name: _emberData.default.attr('capitalize'),
    name2: _emberData.default.attr('capitalize'),
    address: _emberData.default.attr('capitalize'),
    cityStateZip: _emberData.default.attr('capitalize'),
    telephone: _emberData.default.attr('telephone'),
    label: Ember.computed('number', 'name', function () {
      return this.get('number') + ' - ' + this.get('name');
    })
  });

  _exports.default = _default;
});