define("client/react/components/typed/labeled-data", ["exports", "react/jsx-runtime", "@mui/material", "tss-react/mui", "remeda", "client/react/utilities/typed/styles/styles", "client/react/utilities/typed/general", "react-router-dom"], function (_exports, _jsxRuntime, _material, _mui, _remeda, _styles, _general, _reactRouterDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _mui.makeStyles)()(function (theme) {
    return Object.assign(Object.assign({}, _styles.styles), {
      container: {
        // border: '1px solid #e0e0e0',
        borderRadius: 4,
        padding: '8.5px 10px 8.5px 12px',
        position: 'relative',
        marginBottom: 12
      },
      defaultHeight: {
        height: 40
      },
      wrappedHeight: {
        height: 'auto'
      },
      preventTextWrap: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      allowTextWrap: {
        whiteSpace: 'break-spaces'
      },
      label: {
        position: 'absolute',
        top: -10,
        left: 6,
        padding: '0 6px 0 6px',
        background: theme.palette.background.default
      },
      linkEnabled: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
      }
    });
  });

  function LabeledData(_ref) {
    var _cx, _cx2;

    var label = _ref.label,
        data = _ref.data,
        addressModel = _ref.addressModel,
        shouldWrap = _ref.shouldWrap,
        _ref$dataAlign = _ref.dataAlign,
        dataAlign = _ref$dataAlign === void 0 ? 'left' : _ref$dataAlign,
        className = _ref.className,
        _onClick = _ref.onClick,
        toRouteOnClick = _ref.toRouteOnClick,
        userClasses = _ref.classes;

    var _useStyles = useStyles(),
        cx = _useStyles.cx,
        classes = _useStyles.classes; // const navigate = useNavigate();


    var history = (0, _reactRouterDom.useHistory)();
    var isAddress = !!addressModel;
    var isBool = (0, _remeda.isBoolean)(data);
    var isStr = (0, _remeda.isString)(data);
    var isNull = (0, _remeda.isNil)(data);
    var isUndefined = data === undefined;
    var isEmpty = isStr && data.trim() === "";
    var noData = isNull || isUndefined || isEmpty;
    return (// using an outer div here because flex doesn't handle growing/shrinking ideally when some children
      // have margin/padding and others don't because that gets factored into the growing/shrinking as a baseline
      // two options are: add same padding/margin to all children within a flex container or remove padding/margin
      // from all children in a flex container. We do the latter here by wrapping in a div with no padding/margin
      // problem further explained here: https://css-tricks.com/equal-columns-with-flexbox-its-more-complicated-than-you-might-think/
      (0, _jsxRuntime.jsx)("div", Object.assign({
        className: className
      }, {
        children: (0, _jsxRuntime.jsxs)("div", Object.assign({
          className: cx(classes.container, (_cx = {}, _defineProperty(_cx, classes.defaultHeight, !shouldWrap), _defineProperty(_cx, classes.wrappedHeight, shouldWrap), _cx))
        }, {
          children: [(0, _jsxRuntime.jsx)(_material.Typography, Object.assign({
            className: cx(classes.label, userClasses === null || userClasses === void 0 ? void 0 : userClasses.label),
            variant: 'caption'
          }, {
            children: label
          })), isAddress ? (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [addressModel.name && (0, _jsxRuntime.jsx)(_material.Typography, {
              children: addressModel.name
            }), addressModel.name2 && (0, _jsxRuntime.jsx)(_material.Typography, {
              children: addressModel.name2
            }), addressModel.address && (0, _jsxRuntime.jsx)(_material.Typography, {
              children: addressModel.address
            }), addressModel.address2 && (0, _jsxRuntime.jsx)(_material.Typography, {
              children: addressModel.address2
            }), (0, _jsxRuntime.jsxs)("div", Object.assign({
              className: classes.row
            }, {
              children: [addressModel.city && (0, _jsxRuntime.jsxs)(_material.Typography, {
                children: [addressModel.city, ",\xA0"]
              }), addressModel.state && (0, _jsxRuntime.jsxs)(_material.Typography, {
                children: [addressModel.state, ",\xA0"]
              }), addressModel.zip && (0, _jsxRuntime.jsx)(_material.Typography, {
                children: addressModel.zip
              })]
            })), addressModel.country && (0, _jsxRuntime.jsx)(_material.Typography, {
              children: addressModel.country
            })]
          }) : (0, _jsxRuntime.jsx)(_material.Typography, Object.assign({
            className: cx(userClasses === null || userClasses === void 0 ? void 0 : userClasses.data, (_cx2 = {}, _defineProperty(_cx2, classes.preventTextWrap, !shouldWrap), _defineProperty(_cx2, classes.allowTextWrap, shouldWrap), _defineProperty(_cx2, classes.linkEnabled, !!_onClick || !!toRouteOnClick && (toRouteOnClick.enableWithNoData || !noData)), _cx2)),
            sx: {
              textAlign: dataAlign
            },
            variant: 'body1',
            onClick: function onClick(e) {
              if (_onClick) {
                _onClick(e);
              }

              if (toRouteOnClick && (toRouteOnClick.enableWithNoData || !noData)) {
                if (toRouteOnClick.newTab) {
                  (0, _general.openInNewTab)(toRouteOnClick.to);
                } else {
                  // navigate(toRouteOnClick.to, toRouteOnClick.options);
                  history.push(toRouteOnClick.to);
                }
              }
            }
          }, {
            children: isNull || isUndefined || isEmpty ? (0, _jsxRuntime.jsx)("span", {
              children: "\xA0"
            }) : (0, _jsxRuntime.jsx)("span", {
              children: isBool ? data ? "Yes" : "No" : data
            })
          }))]
        }))
      }))
    );
  }

  var _default = LabeledData;
  _exports.default = _default;
});