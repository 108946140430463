define("client/mixins/printable-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = Ember.$;

  var _default = Ember.Mixin.create({
    print: function print() {
      var _this = this;

      this.set('isPrinting', true);
      Ember.run.next('afterRender', function () {
        $('md-dialog').last().css("font-size", "12px");
        $('.fancy-text').css("font-size", "12px");
        var width = $('md-dialog').last().css("width");
        var width2 = $('#staticwidth').css("width");
        $('md-dialog').last().css("max-width", "100%");
        $('md-dialog').last().css("width", "100%");
        $('#staticwidth').css("width", "100%");
        $('md-dialog').last().print({
          timeout: 1800
        });
        Ember.run.later(function () {
          this.set('isPrinting', false);
          $('md-dialog').css("font-size", "14px");
          $('md-dialog').last().css("width", width);
          $('#staticwidth').css("width", width2);
        }.bind(_this), 1800);
      });
    }
  });

  _exports.default = _default;
});