define("client/components/tooltip-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.$().tooltip({
        position: this.get('position') ? this.get('position') : {
          my: "left+17 center",
          at: "right center"
        }
      });
    },
    attributeBindings: ['title', 'style'],
    title: "Default",
    style: 'display:flex'
    /*mouseEnter: function(evt) {
     }*/

  });

  _exports.default = _default;
});