define("client/utils/normalize-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeObject;

  function normalizeObject(obj) {
    var keys = [];
    obj.eachAttribute(function (name) {
      keys.push(name);
    });
    var newObj = {};

    for (var i = 0; i < keys.length; i++) {
      var newKey = keys[i];
      newObj[newKey] = obj.get(newKey);
    }

    return newObj;
  }
});