define("client/controllers/select-checks", ["exports", "client/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({});

  _exports.default = _default;
});