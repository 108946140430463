define("client/models/job-gl-config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('capitalize'),
    key: _emberData.default.attr('capitalize'),
    method: _emberData.default.attr(),
    materialOverhead: _emberData.default.attr(),
    laborOverhead: _emberData.default.attr(),
    subsOverhead: _emberData.default.attr(),
    contractWipGl: _emberData.default.attr(),
    contractClosingGl: _emberData.default.attr(),
    laborWipGl: _emberData.default.attr(),
    laborClosingGl: _emberData.default.attr(),
    burdenWipGl: _emberData.default.attr(),
    burdenClosingGl: _emberData.default.attr(),
    materialWipGl: _emberData.default.attr(),
    materialClosingGl: _emberData.default.attr(),
    subsWipGl: _emberData.default.attr(),
    subsClosingGl: _emberData.default.attr(),
    otherWipGl: _emberData.default.attr(),
    otherClosingGl: _emberData.default.attr(),
    commissionWipGl: _emberData.default.attr(),
    commissionClosingGl: _emberData.default.attr(),
    inventoryCrGl: _emberData.default.attr(),
    overBillingGl: _emberData.default.attr(),
    underBillingGl: _emberData.default.attr(),
    subsCrGl: _emberData.default.attr(),
    otherCrGl: _emberData.default.attr(),
    warrantyCrGl: _emberData.default.attr(),
    salesTaxCrGl: _emberData.default.attr(),
    accruedLabor: _emberData.default.attr(),
    unappliedBurden: _emberData.default.attr(),
    warrantyCategory: _emberData.default.attr(),
    salesTaxCategory: _emberData.default.attr(),
    label: Ember.computed('key', 'description', function () {
      return this.get('key') + " - " + this.get('description');
    }),
    job: _emberData.default.hasMany('job', {
      async: true
    })
  });

  _exports.default = _default;
});