define("client/react/utilities/use-dynamic-columns", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function useDynamicColumns(gridApiRef, columns) {
    var columnsRef = (0, _react.useRef)(columns);
    (0, _react.useEffect)(function () {
      var _gridApiRef$current;

      if (typeof ((_gridApiRef$current = gridApiRef.current) === null || _gridApiRef$current === void 0 ? void 0 : _gridApiRef$current.updateColumns) === "function") {
        gridApiRef.current.updateColumns(columns);
      }
    });
    return {
      columns: columnsRef.current
    };
  }

  var _default = useDynamicColumns;
  _exports.default = _default;
});