define("client/react/utilities/snackbar", ["exports", "notistack", "react"], function (_exports, _notistack, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ReactSnackbar = ReactSnackbar;
  _exports.showSnackbar = _exports.closeSnackbar = void 0;
  var showSnackbar;
  _exports.showSnackbar = showSnackbar;
  var closeSnackbar;
  _exports.closeSnackbar = closeSnackbar;

  function ReactSnackbar() {
    var _useSnackbar = (0, _notistack.useSnackbar)(),
        enqueueSnackbar = _useSnackbar.enqueueSnackbar,
        localCloseSnackbar = _useSnackbar.closeSnackbar;

    (0, _react.useEffect)(function () {
      _exports.showSnackbar = showSnackbar = function showSnackbar(message, options) {
        return enqueueSnackbar(message, options);
      };
    }, [enqueueSnackbar]);
    (0, _react.useEffect)(function () {
      _exports.closeSnackbar = closeSnackbar = function closeSnackbar(key) {
        localCloseSnackbar(key);
      };
    }, [localCloseSnackbar]);
    return React.createElement("span", null);
  }
});