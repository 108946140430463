define("client/models/warehouse-part", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    prodId: _emberData.default.attr(),
    openingBalanceA: _emberData.default.attr('numberalize'),
    openingBalanceB: _emberData.default.attr('numberalize'),
    openingBalanceC: _emberData.default.attr('numberalize'),
    openingBalanceD: _emberData.default.attr('numberalize'),
    openingBalanceE: _emberData.default.attr('numberalize'),
    openingBalanceF: _emberData.default.attr('numberalize'),
    openingBalanceG: _emberData.default.attr('numberalize'),
    openingBalanceH: _emberData.default.attr('numberalize'),
    receivedYtdA: _emberData.default.attr('numberalize'),
    receivedYtdB: _emberData.default.attr('numberalize'),
    receivedYtdC: _emberData.default.attr('numberalize'),
    receivedYtdD: _emberData.default.attr('numberalize'),
    receivedYtdE: _emberData.default.attr('numberalize'),
    receivedYtdF: _emberData.default.attr('numberalize'),
    receivedYtdG: _emberData.default.attr('numberalize'),
    receivedYtdH: _emberData.default.attr('numberalize'),
    usedYtdA: _emberData.default.attr('numberalize'),
    usedYtdB: _emberData.default.attr('numberalize'),
    usedYtdC: _emberData.default.attr('numberalize'),
    usedYtdD: _emberData.default.attr('numberalize'),
    usedYtdE: _emberData.default.attr('numberalize'),
    usedYtdF: _emberData.default.attr('numberalize'),
    usedYtdG: _emberData.default.attr('numberalize'),
    usedYtdH: _emberData.default.attr('numberalize'),
    allocatedA: _emberData.default.attr('numberalize'),
    allocatedB: _emberData.default.attr('numberalize'),
    allocatedC: _emberData.default.attr('numberalize'),
    allocatedD: _emberData.default.attr('numberalize'),
    allocatedE: _emberData.default.attr('numberalize'),
    allocatedF: _emberData.default.attr('numberalize'),
    allocatedG: _emberData.default.attr('numberalize'),
    allocatedH: _emberData.default.attr('numberalize'),
    product: _emberData.default.belongsTo('part-item', {
      async: true
    })
  });

  _exports.default = _default;
});