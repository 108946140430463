define("client/models/accr-sales-journal-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr(),
    type: _emberData.default.attr(),
    date: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr(),
    custId: _emberData.default.attr(),
    slm: _emberData.default.attr(),
    slm2: _emberData.default.attr(),
    technician: _emberData.default.attr(),
    discount: _emberData.default.attr(),
    freight: _emberData.default.attr(),
    taxJurisdiction: _emberData.default.attr(),
    salesTax: _emberData.default.attr(),
    total: _emberData.default.attr(),
    cost: _emberData.default.attr(),
    commissionRate: _emberData.default.attr(),
    commission: _emberData.default.attr(),
    commissionRate2: _emberData.default.attr(),
    commission2: _emberData.default.attr(),
    zipCode: _emberData.default.attr(),
    postingDate: _emberData.default.attr(),
    category: _emberData.default.attr(),
    jobId: _emberData.default.attr(),
    laborSale: _emberData.default.attr(),
    laborCost: _emberData.default.attr()
  });

  _exports.default = _default;
});