define("client/routes/invoicing-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      viewItem: function viewItem(item) {
        this.transitionTo('examine-invoice', item.get('id'));
      },
      openCreate: function openCreate() {
        this.transitionTo('invoicing');
      }
    }
  });

  _exports.default = _default;
});