define("client/react/custom-dashboards/custom-dashboards", ["exports", "react", "react-router", "react-router-dom", "client/react/custom-dashboards/dashboard-list", "client/react/custom-dashboards/custom-dashboard", "client/react/utils", "@mui/material"], function (_exports, _react, _reactRouter, _reactRouterDom, _dashboardList, _customDashboard, _utils, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function CustomDashboards() {
    var _location$pathname;

    var location = (0, _reactRouterDom.useLocation)();
    return _react.default.createElement(_react.default.Fragment, null, (location === null || location === void 0 ? void 0 : (_location$pathname = location.pathname) === null || _location$pathname === void 0 ? void 0 : _location$pathname.indexOf('/dashboards/')) === -1 && _react.default.createElement(_material.Portal, {
      container: (0, _utils.getToolbarElement)()
    }, _react.default.createElement("h4", null, "Your Dashboards (Beta)")), _react.default.createElement(_reactRouterDom.HashRouter, null, _react.default.createElement(_reactRouter.Switch, null, _react.default.createElement(_reactRouter.Route, {
      path: "/dashboards/:id"
    }, _react.default.createElement(_customDashboard.default, null)), _react.default.createElement(_reactRouter.Route, {
      exact: true,
      path: "/dashboards"
    }, _react.default.createElement(_dashboardList.default, null)))));
  }

  var _default = CustomDashboards;
  _exports.default = _default;
});