define("client/components/srvc-contract-delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dHsbn5zm",
    "block": "{\"statements\":[[6,[\"paper-dialog\"],null,null,{\"statements\":[[6,[\"paper-toolbar\"],null,null,{\"statements\":[[6,[\"paper-toolbar-tools\"],null,null,{\"statements\":[[0,\"            \"],[11,\"h2\",[]],[13],[0,\"Delete Contract\"],[14],[0,\"\\n            \"],[11,\"span\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n            \"],[6,[\"paper-button\"],null,[[\"iconButton\",\"onClick\",\"disabled\"],[true,[28,[\"onClose\"]],[28,[\"confirmDelete\",\"isRunning\"]]]],{\"statements\":[[1,[33,[\"paper-icon\"],[\"close\"],null],false]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[6,[\"paper-dialog-content\"],null,null,{\"statements\":[[0,\"            \"],[11,\"div\",[]],[15,\"style\",\"width:450px;\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"confirmDelete\",\"isRunning\"]]],null,{\"statements\":[[0,\"                    \"],[1,[26,[\"paper-progress-linear\"]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                    Are you sure you want to delete this contract?\\n\"]],\"locals\":[]}],[0,\"            \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"paper-dialog-actions\"],null,[[\"class\"],[\"layout-row layout-align-end\"]],{\"statements\":[[0,\"            \"],[1,[33,[\"paper-button\"],null,[[\"label\",\"onClick\",\"disabled\"],[\"Cancel\",[28,[\"onClose\"]],[28,[\"confirmDelete\",\"isRunning\"]]]]],false],[0,\"\\n            \"],[1,[33,[\"paper-button\"],null,[[\"warn\",\"label\",\"onClick\",\"disabled\"],[true,\"Delete\",[33,[\"perform\"],[[28,[\"confirmDelete\"]]],null],[28,[\"confirmDelete\",\"isRunning\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/srvc-contract-delete/template.hbs"
    }
  });

  _exports.default = _default;
});