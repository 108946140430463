define("client/components/transfer-history/component", ["exports", "client/react/service/transfer-history", "client/components/react-component/component"], function (_exports, _transferHistory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.reactRender(React.createElement(_transferHistory.default, {
        onClose: function onClose() {
          return _this.sendAction('onClose');
        }
      }));
    }
  });

  _exports.default = _default;
});