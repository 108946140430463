define("client/components/select-cust-site/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "strgZg6D",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"customer\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[33,[\"md-site-search\"],null,[[\"customer\",\"setSite\",\"close\",\"closeWithoutSite\"],[[28,[\"customer\"]],[33,[\"action\"],[[28,[null]],\"setSite\"],null],[33,[\"action\"],[[28,[null]],\"clearCustomer\"],null],[33,[\"action\"],[[28,[null]],\"closeWithoutSite\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"\\t\"],[1,[33,[\"md-customer-search\"],null,[[\"setCustomer\",\"setCustomerAndSite\",\"close\"],[[33,[\"action\"],[[28,[null]],\"setCustomer\"],null],[33,[\"action\"],[[28,[null]],\"setCustomerAndSite\"],null],[33,[\"action\"],[[28,[null]],\"close\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/select-cust-site/template.hbs"
    }
  });

  _exports.default = _default;
});