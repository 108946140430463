define("client/components/money-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classes: "form-control",
    inputObserver: function () {
      if (this.get('isInserted')) {
        var val = this.get('inValue');

        if (typeof val === "number") {
          val = val.toFixed(2);
        }

        if (val !== undefined) {
          if (val.length === 0) {
            this.set('inValue', "0.00");
          } else if (val.length === 1) {
            if (!val.match(/[0-9]+\.[0-9][0-9]/g)) {
              this.set('inValue', val.substring(0, val.length - 1));
              return;
            }

            this.set('inValue', "0.0" + this.get('inValue'));
          } else if (val.length === 2) {
            if (!val.match(/[0-9]+\.[0-9][0-9]/g)) {
              this.set('inValue', val.substring(0, val.length - 1));
              return;
            }

            this.set('inValue', "0." + val);
          } else {
            if (!val.match(/[0-9]+\.[0-9][0-9]+/g) && !val.match(/^[0-9]+\.[0-9]$/g)) {
              this.set('inValue', val.substring(0, val.length - 1));
              return;
            } else if (val.match(/([1-9][0-9]* | [0])\.[0-9][0-9]$/g)) {
              return;
            }

            val = val.replace(/\./g, "");
            var start = val.substring(0, val.length - 2);
            start = start.replace(/^[0]+/, "");

            if (start.length === 0) {
              start = "0";
            }

            var end = val.substring(val.length - 2, val.length);
            var temp = start + "." + end;
            this.set('inValue', temp);
          }
        }
      }
    }.observes('inValue', 'isInserted').on('init'),
    didInsertElement: function didInsertElement() {
      this.set('isInserted', true);
    },
    inValue: "0.00",
    isInserted: false
  });

  _exports.default = _default;
});