define("client/components/required-text-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q0D1s1F2",
    "block": "{\"statements\":[[1,[33,[\"text-area\"],null,[[\"name\",\"placeholder\",\"value\",\"class\",\"maxlength\"],[\"no-resize\",[28,[\"placeholder\"]],[28,[\"inValue\"]],\"form-control\",[28,[\"maxlength\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/required-text-area/template.hbs"
    }
  });

  _exports.default = _default;
});