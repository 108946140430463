define("client/react/utilities/message-queue", ["exports", "react", "react-redux", "@stomp/stompjs"], function (_exports, _react, _reactRedux, _stompjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MessageQueueProvider = MessageQueueProvider;
  _exports.useUserMessageQueue = useUserMessageQueue;
  var STOMP_USER = 'desktop';
  var STOMP_PASS = 'Yrk7qrWJ6DQq';
  var MessageQueueContext = (0, _react.createContext)(null); // this is stored as a global instead of as a ref because Ember integration implies we will have more than one provider

  var queueState = {
    // map of event name to array of callbacks that should receive the message
    // this map only corresponds to user specific messages
    userEventHandlers: {},
    client: null
  };

  function MessageQueueProvider(_ref) {
    var children = _ref.children,
        url = _ref.url;
    var user = (0, _reactRedux.useSelector)(function (state) {
      return state.auth.user;
    });
    var tenant = (0, _reactRedux.useSelector)(function (state) {
      return state.auth.tenant;
    });
    (0, _react.useEffect)(function () {
      if (!user || !tenant) {
        return;
      }

      var socket = new WebSocket(url);

      var client = _stompjs.Stomp.over(socket);

      queueState.client = client;

      client.debug = function () {
        return null;
      };

      client.connect(STOMP_USER, STOMP_PASS, function () {
        client.heartbeat.incoming = 0;
        client.heartbeat.outgoing = 10000;
        client.reconnect_delay = 5000;
        client.subscribe("/topic/".concat(tenant, ".").concat(user.currentUser), function (msg) {
          var obj = JSON.parse(msg.body);
          var handlers = queueState.userEventHandlers[obj.messageType];

          if (handlers) {
            for (var i = 0; i < handlers.length; i++) {
              handlers[i](obj);
            }
          }
        });
      }, function (e) {}); // If the provider gets unmounted we should unsubscribe everyone

      return function () {
        queueState.userEventHandlers = {};
      };
    }, [user, tenant]);
    return React.createElement(MessageQueueContext.Provider, {
      value: queueState
    }, children);
  }

  function useUserMessageQueue(eventName, callback) {
    var queueService = (0, _react.useContext)(MessageQueueContext);
    (0, _react.useEffect)(function () {
      if (!queueService.userEventHandlers[eventName]) {
        queueService.userEventHandlers[eventName] = [];
      }

      queueService.userEventHandlers[eventName].push(callback);
      return function () {
        // remove handler when component using it is unmounted
        var idx = queueService.userEventHandlers[eventName].indexOf(callback);
        queueService.userEventHandlers[eventName].splice(idx, 1);
      };
    }, [eventName, callback]);
  }
});