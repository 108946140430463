define("client/react/components/text-tooltip", ["exports", "@mui/material"], function (_exports, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function TextTooltip(_ref) {
    var text = _ref.text,
        tooltip = _ref.tooltip,
        lineHeight = _ref.lineHeight;
    return React.createElement(_material.Tooltip, {
      title: tooltip || ""
    }, React.createElement("span", {
      style: {
        borderBottom: '1px dashed rgb(193,201,210)',
        lineHeight: lineHeight || 'normal'
      }
    }, text));
  }

  var _default = TextTooltip;
  _exports.default = _default;
});