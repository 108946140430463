define("client/react/components/typed/country-form-autocomplete", ["exports", "react/jsx-runtime", "@mui/material", "@Harris-Barrick-Software/use-form", "client/react/components/autocomplete", "client/react/components/geography"], function (_exports, _jsxRuntime, _material, _useForm, _autocomplete, _geography) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function CountryFormAutocomplete(_a) {
    var formProps = _a.formProps,
        renderInputParams = _a.renderInputParams,
        restProps = __rest(_a, ["formProps", "renderInputParams"]);

    var autocompleteDefaults = (0, _autocomplete.useDefaults)();
    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });
    var inputController = (0, _useForm.useController)({
      name: renderInputParams.name,
      control: formProps.control
    });

    if (!controller || !inputController) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field;
    var inputField = inputController.field;

    var getOptionLabel = restProps.getOptionLabel || function (o) {
      return o.name;
    };

    var renderInput = (0, _autocomplete.autocompleteRenderInput)(Object.assign(Object.assign({}, renderInputParams), {
      onBlur: function onBlur(e) {
        var match = _geography.countries.find(function (itm) {
          return getOptionLabel(itm).toLowerCase() === e.target.value.toLowerCase() || itm.code.toLowerCase() === e.target.value.toLowerCase();
        });

        if (!match) {
          formProps.control.setValue(formProps.name, null);
          formProps.control.setValue(renderInputParams.name, '');
        } else {
          formProps.control.setValue(formProps.name, match);
          formProps.control.setValue(renderInputParams.name, getOptionLabel(match));
        }

        field.onBlur();
        inputField.onBlur();

        if (renderInputParams.onBlur) {
          renderInputParams.onBlur(e);
        }
      }
    }));
    return (0, _jsxRuntime.jsx)(_material.Autocomplete, Object.assign({}, autocompleteDefaults, restProps, {
      options: _geography.countries,
      getOptionLabel: function getOptionLabel(o) {
        return o.name;
      },
      ref: field.ref,
      value: field.value,
      onChange: function onChange(e, v, r) {
        field.onChange(v);

        if (restProps.onChange) {
          restProps.onChange(e, v, r);
        }
      },
      inputValue: inputField.value,
      onInputChange: function onInputChange(e, v, r) {
        if (r !== "reset") {
          inputField.onChange(v);
        }

        if (restProps.onInputChange) {
          restProps.onInputChange(e, v, r);
        }
      },
      renderInput: renderInput
    }));
  }

  var _default = CountryFormAutocomplete;
  _exports.default = _default;
});