define("client/helpers/sorting-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sortingBy = sortingBy;

  function sortingBy(params
  /*, hash*/
  ) {
    var item = params.objectAt(0);
    var sortByData = params.objectAt(1);
    var sortByOrder = params.objectAt(2);

    if (item === sortByData) {
      if (sortByOrder === "D") {
        var span = "<span class='glyphicon glyphicon-triangle-bottom' style='left: 2px;font-size:8px'></span>";
        return new Ember.String.htmlSafe(span);
      } else {
        var span = "<span class='glyphicon glyphicon-triangle-top' style='left: 2px;font-size:8px'></span>";
        return new Ember.String.htmlSafe(span);
      }
    } else {
      var span = "<div style='display:inline-block;line-height:0px;'><span class='glyphicon glyphicon-triangle-top' style='left: 2px;font-size:8px'></span><br>";
      span += "<span class='glyphicon glyphicon-triangle-bottom' style='left: 2px;font-size:8px'></span></div>";
      return new Ember.String.htmlSafe(span);
    }
  }

  var _default = Ember.Helper.helper(sortingBy);

  _exports.default = _default;
});