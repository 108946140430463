define("client/models/service-estimate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    srvcOrdId: _emberData.default.attr(),
    creationDate: _emberData.default.attr(),
    brand: _emberData.default.attr(),
    modelNumber: _emberData.default.attr(),
    serialNumber: _emberData.default.attr(),
    brand2: _emberData.default.attr(),
    modelNumber2: _emberData.default.attr(),
    serialNumber2: _emberData.default.attr(),
    description: _emberData.default.attr(),
    total: _emberData.default.attr(),
    signed: _emberData.default.attr(),
    laborCode: _emberData.default.attr('capitalize'),
    laborHours: _emberData.default.attr(),
    laborSell: _emberData.default.attr(),
    epaFee: _emberData.default.attr(),
    file: _emberData.default.attr(),
    discount: _emberData.default.attr(),
    discountPercent: _emberData.default.attr(),
    newSrvcOrdId: _emberData.default.attr(),
    contractNumber: _emberData.default.attr(),
    dynamicInvoice: _emberData.default.attr(),
    salesTax: _emberData.default.attr('currency'),
    salesTaxFlag: _emberData.default.attr(),
    salesTaxJuris: _emberData.default.attr(),
    salesTaxRate: _emberData.default.attr('currency'),
    men: _emberData.default.attr(),
    hours: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    site: _emberData.default.belongsTo('site-address', {
      async: true
    }),
    parts: _emberData.default.hasMany('service-estimate-part', {
      async: true
    }),
    flrates: _emberData.default.hasMany('service-estimate-flrate', {
      async: true
    }),
    workOrder: _emberData.default.belongsTo('work-order', {
      async: true
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    communications: _emberData.default.hasMany('communication', {
      async: true
    }),
    label: Ember.computed('creationDate', 'id', 'total', function () {
      return "".concat(this.get('id'), " - ").concat(this.get('creationDate'), " - $").concat(this.get('total'));
    })
  });

  _exports.default = _default;
});