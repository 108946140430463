define("client/components/time-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P9GJ73ah",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"input-append bootstrap-timepicker\"],[13],[0,\"\\n    \"],[1,[33,[\"input\"],null,[[\"value\",\"type\",\"class\"],[[28,[\"output\"]],\"text\",\"form-control time-pick\"]]],false],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"add-on\"],[13],[11,\"i\",[]],[15,\"class\",\"glyphicon glyphicon-time\"],[13],[14],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/time-picker/template.hbs"
    }
  });

  _exports.default = _default;
});