define("client/helpers/create-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createArray = createArray;
  _exports.default = void 0;

  function createArray(params
  /*, hash*/
  ) {
    return Ember.A(params[0]);
  }

  var _default = Ember.Helper.helper(createArray);

  _exports.default = _default;
});