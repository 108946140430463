define("client/utils/time-string-to-rows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = timeStringToRows;

  function timeStringToRows(timeString, numPeriods) {
    numPeriods = Number(numPeriods);
    var dispatchIncrement = 60 / numPeriods;
    var splitString = timeString.split(' ');
    var hours;
    var minutes;

    if (timeString.indexOf('hours') !== -1 || timeString.indexOf('hour') !== -1) {
      hours = Number(splitString[0]);
      minutes = Number(splitString[2]);
    } else {
      hours = 0;
      minutes = Number(splitString[0]);
    }

    var rows = hours * numPeriods + minutes / dispatchIncrement;
    return rows;
  }
});