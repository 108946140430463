define("client/components/tree-table-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P4y6FIUM",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"ember-table-row ember-table-header-row\"],[15,\"style\",\"flex: 1;\"],[13],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-cell ember-table-header-cell ember-table-header-tree-cell text-align-left\"],[15,\"style\",\"width: 100%;\"],[13],[0,\"\\n\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-cell-container\"],[13],[0,\"\\n\\t\\t\\t\"],[11,\"span\",[]],[15,\"class\",\"ember-table-toggle-span ember-table-toggle ember-table-collapse\"],[13],[0,\"\\n\\t\\t\\t\\t\"],[11,\"i\",[]],[15,\"class\",\"glyphicon glyphicon-chevron-down\"],[13],[14],[0,\"\\n\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-header-content-container\"],[13],[0,\"\\n\\t\\t\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-content\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"headings\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[1,[28,[\"heading\",\"heading\"]],false],[6,[\"if\"],[[33,[\"not-eq\"],[[33,[\"get\"],[[28,[\"headings\"]],\"lastObject\"],null],[28,[\"heading\"]]],null]],null,{\"statements\":[[0,\" ▸ \"]],\"locals\":[]},null],[0,\" \\n\"]],\"locals\":[\"heading\"]},null],[0,\"\\t\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/tree-table-header/template.hbs"
    }
  });

  _exports.default = _default;
});