define("client/components/date-picker-sm/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    tagName: 'input',
    attributeBindings: ['type', 'value', 'autofocus'],
    classNames: ['form-control input-sm'],
    value: "",
    type: "text",
    autofocus: false,
    didInsertElement: function didInsertElement() {
      this.$().datepicker({
        showButtonPanel: true,
        changeMonth: true,
        changeYear: true,
        dateFormat: "mm/dd/yy"
      });
    }
  });

  _exports.default = _default;
});