define("client/helpers/time-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.timeHelper = timeHelper;

  function timeHelper(params
  /*, hash*/
  ) {
    if (params.objectAt(0)) {
      return moment(params.objectAt(0), 'HH:mm').format('hh:mm A');
    } else {
      return '--:--';
    }
  }

  var _default = Ember.Helper.helper(timeHelper);

  _exports.default = _default;
});