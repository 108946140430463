define("client/models/product-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ProdItem = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    prodId: _emberData.default.attr(),
    flrateId: _emberData.default.attr(),
    order: _emberData.default.attr(),
    images: _emberData.default.attr('array'),
    primaryImage: _emberData.default.attr(),
    video: _emberData.default.attr(),
    description: _emberData.default.attr(),
    dynamicInvoice: _emberData.default.attr(),
    discountEligible: _emberData.default.attr(),
    category: _emberData.default.belongsTo('product-category', {
      async: true
    }),
    flrate: _emberData.default.belongsTo('flat-rate-item', {
      async: true
    }),
    part: _emberData.default.belongsTo('part-item', {
      async: true
    }),
    additions: _emberData.default.hasMany('product-addition', {
      async: true
    })
  });

  var _default = ProdItem;
  _exports.default = _default;
});