define("client/extensions/servisuite-native/extension", [], function () {
  "use strict";

  function handleGetUserScreenRequest(sources, tab, sendResponse) {
    chrome.desktopCapture.chooseDesktopMedia(sources, tab, function (streamId) {
      // The user canceled our request.
      if (!streamId) {
        sendResponse({
          type: 'error',
          message: 'Failed to get stream ID'
        });
      } // The user accepted our request.


      sendResponse({
        type: 'success',
        streamId: streamId
      });
    });
  }

  function handleUnrecognizedRequest(sendResponse) {
    sendResponse({
      type: 'error',
      message: 'Unrecognized request'
    });
  }

  var port = chrome.runtime.connectNative('com.servisuite.nativehost');
  port.onDisconnect.addListener(function () {
    console.log("Native port disconnected!");
  });
  chrome.runtime.onMessage.addListener(function (message, sender, sendResponse) {
    switch (message && message.type) {
      // Our web app sent us a "getUserScreen" request.
      case 'getUserScreen':
        handleGetUserScreenRequest(message.sources, sender.tab, sendResponse);
        break;

      case 'native-message':
        port.postMessage(message.message);
        break;
      // Our web app sent us a request we don't recognize.

      default:
        handleUnrecognizedRequest(sendResponse);
        break;
    }

    return true;
  });
});