define("client/components/dynamic-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QEAS/3It",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"panel panel-default\"],[13],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"panel-heading\"],[13],[0,\"\\n\\t\\t\"],[1,[26,[\"header\"]],false],[0,\"\\n\\t\"],[14],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"panel-body\"],[13],[0,\"\\n\\t\\t\"],[11,\"table\",[]],[15,\"class\",\"table table-hover\"],[15,\"style\",\"margin-bottom:0;\"],[13],[0,\"\\n\\t\\t\\t\"],[11,\"thead\",[]],[13],[0,\"\\n\\t\\t\\t\\t\"],[11,\"tr\",[]],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"columnHeadings\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[1,[33,[\"generic-table-heading\"],[[28,[\"item\"]]],null],false],[0,\"\\n\"]],\"locals\":[\"item\"]},null],[0,\"\\t\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\\t\"],[11,\"tbody\",[]],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"values\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[11,\"tr\",[]],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"keysAndTypes\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t\"],[1,[33,[\"key-value-concat\"],[[28,[\"item\"]],[28,[\"value\"]]],null],false],[0,\"\\n\"]],\"locals\":[\"item\"]},null],[0,\"\\t\\t\\t\\t\\t\"],[14],[0,\"\\n\"]],\"locals\":[\"value\"]},null],[0,\"\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"background-add-row\"],[15,\"style\",\"width:100%;text-align:center;padding: 8px;border-style: solid;border-color: rgb(221, 221, 221);border-width: \\n\\t\\t1px;border-radius: 5px;\"],[5,[\"action\"],[[28,[null]],\"addRow\"]],[13],[0,\"\\n\\t\\t\\t\"],[11,\"strong\",[]],[13],[0,\"Click here to add a row\"],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\"],[14],[0,\"\\n\"],[14],[0,\"\\t\\t\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/dynamic-table/template.hbs"
    }
  });

  _exports.default = _default;
});