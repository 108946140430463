define("client/models/user-role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var UserRole = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    role: _emberData.default.belongsTo('role', {
      async: true
    })
  });

  var _default = UserRole;
  _exports.default = _default;
});