define("client/components/drag-resize/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workOrder: null,
    technician: null,
    dndService: null,
    //this is a sickening idea but it has to be done
    attributeBindings: ['draggable'],
    classNameBindings: ['dragy'],
    draggable: "false",
    dragy: false,
    startX: 0,
    startY: 0,
    inc: 0,
    //    style: "height: 15px;cursor: ns-resize;margin-top: -15px;",
    //    dragStart: function(event) {
    ////        this.set('startX',event.clientX);
    ////        event.preventDefault();
    //
    //        this.set('dndService.currentlyResizing',1)
    //        Ember.$("body").bind("dragover", function(e){
    //                  var dragX = e.pageX, dragY = e.pageY;
    //
    //                  console.log("X: "+dragX+" Y: "+dragY);
    //              });
    //         event.dataTransfer.setData('text/data','wtf');
    //         var helper = Ember.$("#hiddenDiv").get(0);
    ////         document.body.appendChild(elm);
    //        // event.dataTransfer.setDragImage(helper,0,0);
    ////         setTimeout(function() {
    ////                Ember.run.schedule('actions',function() {
    ////
    ////                    this.set('dragy',true);
    ////                }.bind(this))}.bind(this),0);
    //        event.effectAllowed='move';
    //        console.log('initDrag');
    //        this.prevY = event.originalEvent.clientY;
    //        event.stopPropagation();
    //
    //    },
    //    dragEnd: function(event) {
    //        //here we should reset the currently resizing work order property to null so that future dragEnter events
    //        // don't get any ideas
    //        this.set('dndService.currentlyResizing',null);
    //        Ember.run.debounce(this,function(){ this.set('dragy',false); }.bind(this),80);
    //        console.log('dragEnd in drag-resize');
    //        event.stopPropagation();
    //    },
    dragStart: function dragStart(event) {
      event.stopPropagation();
    },
    mouseDown: function mouseDown(event) {
      this.set('isDragging', true);
      Ember.$('body').on('mouseMove', this.mouseMover.bind(this));
      event.preventDefault();
    },
    mouseUp: function mouseUp(event) {
      this.set('isDragging', false);
      Ember.$('body').off('mouseMove.custom');
    },
    mouseMover: function mouseMover(event) {
      if (this.get('isDragging')) {
        if (event.clientY < this.prevY) {
          if (event.clientY % 20 === 0 || this.prevY - event.clientY > 20) {
            this.prevY = event.clientY; //              this.saveDebounce();
          }
        } else {
          if (event.clientY % 20 === 0 || event.clientY - this.prevY > 20) {
            this.prevY = event.clientY;
            this.set('workOrder.rows', this.get('workOrder.rows') + 1);
            Ember.run.scheduleOnce('actions', this.saveDebounce.bind(this)); //              this.saveDebounce();
          }
        }
      }
    },
    saveDebounce: function saveDebounce() {
      this.set('site', this.get('workOrder.assignedSite'));
      Ember.run.once(this, function () {
        this.get('workOrder').save().then(function (wo) {
          this.set('workOrder.assignedSite', this.get('site'));
        }.bind(this));
      }.bind(this));
    },
    site: null,
    isDragging: false,
    prevY: 0
  });

  _exports.default = _default;
});