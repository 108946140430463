define("client/helpers/equivalence-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.equivalenceHelper = equivalenceHelper;

  function equivalenceHelper(params
  /*, hash*/
  ) {
    if (params.objectAt(0) === params.objectAt(1)) return true;else return false;
  }

  var _default = Ember.Helper.helper(equivalenceHelper);

  _exports.default = _default;
});