define("client/templates/srvc-work-order-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lBgOEQmm",
    "block": "{\"statements\":[[1,[33,[\"srvc-work-order-view\"],null,[[\"isViewing\",\"editWo\",\"createWo\",\"editCust\",\"toSearch\",\"toBilling\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"editWo\"],null],[33,[\"route-action\"],[\"createWo\"],null],[33,[\"route-action\"],[\"editCust\"],null],[33,[\"route-action\"],[\"toSearch\"],null],[33,[\"route-action\"],[\"toBilling\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/srvc-work-order-view.hbs"
    }
  });

  _exports.default = _default;
});