define("client/components/tree-table-right-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YDMrJMO/",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"ember-table-table-row ember-table-header-row\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"headings\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-cell ember-table-header-cell\"],[13],[0,\"\\n\\t\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-cell-container\"],[13],[0,\"\\n\\t\\t\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-header-content-container\"],[13],[0,\"\\n\\t\\t\\t\\t\\t\"],[11,\"span\",[]],[15,\"class\",\"ember-table-content\"],[13],[1,[28,[\"heading\"]],false],[14],[0,\"\\n\\t\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\"]],\"locals\":[\"heading\"]},null],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/tree-table-right-header/template.hbs"
    }
  });

  _exports.default = _default;
});