define("client/react/custom-dashboards/widget-index", ["exports", "client/react/custom-dashboards/pie-chart-widget", "client/react/custom-dashboards/pie-chart-config", "client/react/custom-dashboards/line-chart-widget", "client/react/custom-dashboards/line-chart-config", "client/react/custom-dashboards/bar-chart-widget", "client/react/custom-dashboards/bar-chart-config", "client/react/custom-dashboards/metric-widget", "client/react/custom-dashboards/metric-config", "client/react/custom-dashboards/radial-bar-chart-widget", "client/react/custom-dashboards/radial-bar-chart-config", "client/react/custom-dashboards/calendar-chart-widget", "client/react/custom-dashboards/calendar-chart-config", "client/react/custom-dashboards/time-range-widget", "client/react/custom-dashboards/time-range-config", "client/react/custom-dashboards/heat-map-widget", "client/react/custom-dashboards/heat-map-config"], function (_exports, _pieChartWidget, _pieChartConfig, _lineChartWidget, _lineChartConfig, _barChartWidget, _barChartConfig, _metricWidget, _metricConfig, _radialBarChartWidget, _radialBarChartConfig, _calendarChartWidget, _calendarChartConfig, _timeRangeWidget, _timeRangeConfig, _heatMapWidget, _heatMapConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var widgetIndex = {
    'Pie Chart': {
      displayComponent: _pieChartWidget.default,
      configComponent: _pieChartConfig.default
    },
    'Line Chart': {
      displayComponent: _lineChartWidget.default,
      configComponent: _lineChartConfig.default
    },
    'Bar Chart': {
      displayComponent: _barChartWidget.default,
      configComponent: _barChartConfig.default
    },
    'Metric': {
      displayComponent: _metricWidget.default,
      configComponent: _metricConfig.default
    },
    'Radial Bar Chart': {
      displayComponent: _radialBarChartWidget.default,
      configComponent: _radialBarChartConfig.default
    },
    'Calendar Chart': {
      displayComponent: _calendarChartWidget.default,
      configComponent: _calendarChartConfig.default
    },
    'Time Range Chart': {
      displayComponent: _timeRangeWidget.default,
      configComponent: _timeRangeConfig.default
    },
    'Heat Map Chart': {
      displayComponent: _heatMapWidget.default,
      configComponent: _heatMapConfig.default
    }
  };
  var _default = widgetIndex;
  _exports.default = _default;
});