define("client/components/report-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    results: null,
    table: null,
    report: null,
    data: Ember.computed.alias('report.data'),
    headings: Ember.computed.alias('report.headings'),
    didInitAttrs: function didInitAttrs() {
      var data = this.get('data');

      function recur(node) {
        var a = Ember.A([]);
        a.addObjects(node.summaries ? node.summaries : []);

        if (!node.data.length) {
          return a;
        }

        a.addObjects(recur(node.data[0]));
        return a;
      }

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      this.set('summaries', recur(data[0]).map(function (x) {
        return x['summary-name'];
      }).filter(onlyUnique));
    },
    columns: computed(function () {
      return [{
        label: 'First Name',
        valuePath: 'firstName',
        width: '150px'
      }, {
        label: 'Last Name',
        valuePath: 'lastName',
        width: '150px'
      }, {
        label: 'Address',
        valuePath: 'address'
      }, {
        label: 'State',
        valuePath: 'state'
      }, {
        label: 'Country',
        valuePath: 'country'
      }];
    })
  });

  _exports.default = _default;
});