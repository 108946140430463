define("client/components/service-estimates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bxS2ZcCO",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"isCreating\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[33,[\"srvc-estimate-create\"],null,[[\"close\",\"refresh\"],[[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isCreating\"]]],null],false],null],[28,[\"refresh\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"isViewing\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[33,[\"srvc-estimate-view\"],null,[[\"close\",\"isViewing\",\"refresh\"],[[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isViewing\"]]],null],false],null],[28,[\"isViewing\"]],[28,[\"refresh\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"\\t\"],[1,[33,[\"srvc-estimate-search\"],null,[[\"create\",\"setSe\",\"initialEstimates\"],[[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isCreating\"]]],null],true],null],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isViewing\"]]],null]],null],[28,[\"estimates\"]]]]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/service-estimates/template.hbs"
    }
  });

  _exports.default = _default;
});