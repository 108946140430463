define("client/templates/pr-time-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AHCLchzM",
    "block": "{\"statements\":[[1,[26,[\"pr-time-entry\"]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/pr-time-entry.hbs"
    }
  });

  _exports.default = _default;
});