define("client/routes/pr-void-check", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model(params) {
      return this.store.findRecord('employee', params.id, {
        reload: true
      });
    },
    actions: {
      transition: function transition(routeName, param) {
        this.transitionTo(routeName, param);
      },
      transitionNoParam: function transitionNoParam(routeName) {
        this.transitionTo(routeName);
      },
      back: function back() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});