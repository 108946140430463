define("client/components/drag-resize/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dc4NYba5",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"style\",\"height: 10px; width: 100%; cursor: s-resize;position: absolute; bottom: 0;\"],[13],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/drag-resize/template.hbs"
    }
  });

  _exports.default = _default;
});