define("client/helpers/better-time-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.betterTimeHelper = betterTimeHelper;
  _exports.default = void 0;

  function betterTimeHelper(params
  /*, hash*/
  ) {
    if (params.objectAt(0)) {
      return moment(params.objectAt(0), 'HH:mm').format('hh:mm a');
    } else {
      return '--:--';
    }
  }

  var _default = Ember.Helper.helper(betterTimeHelper);

  _exports.default = _default;
});