define("client/helpers/to-fixed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toFixed = toFixed;

  function toFixed(params
  /*, hash*/
  ) {
    var number = params[0];
    var decimals = params[1];
    return parseFloat(number).toFixed(decimals);
  }

  var _default = Ember.Helper.helper(toFixed);

  _exports.default = _default;
});