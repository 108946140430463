define("client/react/components/form-select", ["exports", "@mui/material", "@mui/styles", "client/react/utilities/use-form"], function (_exports, _material, _styles, _useForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["options", "valuePath", "labelPath", "label", "control", "name", "rules", "defaultValue", "value", "onChange"],
      _excluded2 = ["ref", "onChange", "value"];

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var useStyles = (0, _styles.makeStyles)(function (theme) {
    return {
      formControl: {
        minWidth: 120
      },
      selectEmpty: {}
    };
  });

  function FormSelect(_ref) {
    var _fieldState$error;

    var options = _ref.options,
        _ref$valuePath = _ref.valuePath,
        valuePath = _ref$valuePath === void 0 ? "value" : _ref$valuePath,
        _ref$labelPath = _ref.labelPath,
        labelPath = _ref$labelPath === void 0 ? "label" : _ref$labelPath,
        label = _ref.label,
        control = _ref.control,
        name = _ref.name,
        rules = _ref.rules,
        defaultValue = _ref.defaultValue,
        value = _ref.value,
        _onChange = _ref.onChange,
        passThru = _objectWithoutProperties(_ref, _excluded);

    var classes = useStyles();

    var _useController = (0, _useForm.useController)({
      name: name,
      control: control,
      rules: rules,
      defaultValue: defaultValue
    }),
        _useController$field = _useController.field,
        ref = _useController$field.ref,
        formOnChange = _useController$field.onChange,
        formValue = _useController$field.value,
        inputProps = _objectWithoutProperties(_useController$field, _excluded2),
        fieldState = _useController.fieldState;

    return React.createElement(_material.FormControl, {
      required: !!passThru.required,
      className: classes.formControl,
      error: fieldState.invalid
    }, React.createElement(_material.InputLabel, null, label), React.createElement(_material.Select, _extends({}, passThru, {
      value: value || formValue || "",
      onChange: function onChange(e) {
        // If the user supplied an onChange we will default to that, otherwise use form control functions
        var value = e.target.value;

        if (_onChange) {
          _onChange(e, value);
        } else {
          formOnChange(value, value);
        }
      }
    }), options.map(function (option) {
      return React.createElement(_material.MenuItem, {
        value: option[valuePath]
      }, option[labelPath]);
    })), React.createElement(_material.FormHelperText, null, !!passThru.hideHelperText ? null : (_fieldState$error = fieldState.error) === null || _fieldState$error === void 0 ? void 0 : _fieldState$error.message));
  }

  var _default = FormSelect;
  _exports.default = _default;
});