define("client/react/components/alt/form-telephone-input", ["exports", "@mui/material", "client/react/utilities/use-form", "react-text-mask", "react"], function (_exports, _material, _useForm, _reactTextMask, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.conformedPhoneNumber = conformedPhoneNumber;
  _exports.default = void 0;
  var _excluded = ["formProps"];

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  var TextMask = (0, _react.forwardRef)(function (_ref, inputRef) {
    var other = _extends({}, _ref);

    return React.createElement(_reactTextMask.default, _extends({}, other, {
      ref: function ref(_ref2) {
        inputRef(_ref2 ? _ref2.inputElement : null);
      },
      guide: false,
      mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      placeholderChar: "\u2000",
      showMask: true
    }));
  });

  function conformedPhoneNumber(val) {
    return (0, _reactTextMask.conformToMask)(val, ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], {
      guide: false
    });
  }

  function FormTelephoneInput(props) {
    var _fieldState$error;

    var formProps = props.formProps,
        restProps = _objectWithoutProperties(props, _excluded);

    var _useController = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    }),
        field = _useController.field,
        fieldState = _useController.fieldState;

    return React.createElement(_material.TextField, _extends({
      error: fieldState.invalid,
      helperText: (_fieldState$error = fieldState.error) === null || _fieldState$error === void 0 ? void 0 : _fieldState$error.message
    }, restProps, {
      ref: field.ref,
      onChange: function onChange(e) {
        field.onChange(e.target.value);

        if (restProps.onChange) {
          restProps.onChange(e);
        }
      },
      onBlur: function onBlur(e) {
        if (restProps.onBlur) {
          restProps.onBlur(e);
        }

        field.onBlur();
      },
      value: field.value,
      InputProps: {
        inputComponent: TextMask
      }
    }));
  }

  var _default = FormTelephoneInput;
  _exports.default = _default;
});