define("client/helpers/to-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toString = toString;

  function toString(params
  /*, hash*/
  ) {
    var s = params[0] + '';
    console.log(s);
    return s;
  }

  var _default = Ember.Helper.helper(toString);

  _exports.default = _default;
});