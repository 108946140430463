define("client/transforms/one-to-many", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return Ember.A([]);
      }

      var arr = serialized.split(',');
      var ret = Ember.A([]);

      for (var i = 0; i < arr.length; i++) {
        var item = arr[i].split(':');
        var obj = Ember.Object.create({
          one: item[0],
          many: Ember.A(item[1].trim() ? item[1].split('|') : [])
        });
        ret.pushObject(obj);
      }

      return ret;
    },
    serialize: function serialize(deserialized) {
      var temp = Ember.A([]);

      for (var i = 0; i < deserialized.get('length'); i++) {
        var item = deserialized.objectAt(i).get('one') + ":" + deserialized.objectAt(i).get('many').toArray().join('|');
        temp.pushObject(item);
      }

      return temp.toArray().join(',');
    }
  });

  _exports.default = _default;
});