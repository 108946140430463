define("client/components/srvc-product-catalog/component", ["exports", "client/components/react-component/component", "client/react/service/srvc-product-catalog-management"], function (_exports, _component, _srvcProductCatalogManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ["flex", "full-height", "no-overflow-x", "overflow-y-hidden"],
    title: 'Product Catalog Management',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.reactRender(React.createElement(_srvcProductCatalogManagement.default, null));
    }
  });

  _exports.default = _default;
});