define("client/models/extended-site-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    note: _emberData.default.attr('capitalize'),
    custId: _emberData.default.attr(),
    shipId: _emberData.default.attr(),
    site: _emberData.default.belongsTo('site-address', {
      async: true
    }),
    seq: _emberData.default.attr('ascii')
  });

  _exports.default = _default;
});