define("client/components/error-toast/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['hidden', 'style'],
    classNameBindings: ['fClass'],
    //    classNames: ['alert','alert-danger'],
    style: "position: absolute; z-index: 100000;bottom: 0;margin-left: auto; margin-right: auto;width: 100%;text-align: center;",
    fClass: function () {
      return !this.get('showError');
    }.property('controller.showError')
  });

  _exports.default = _default;
});