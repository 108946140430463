define("client/components/authorization-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    utilities: inject.service(),
    tagName: '',
    file: true,
    modules: ['dispatch', 'records', 'serviceReports', 'dashboard', 'adminPanel', 'serviceEstimates', 'dispatchViewOnly', 'serviceFileMaintenance', 'serviceBilling', 'archiveFileMaintenance', 'contractFileMaintenance', 'scheduledTimeOff', 'scheduledWorkOrders', 'equipmentFileMaintenance', 'techFileMaintenance', 'flatRateFileMaintenance', 'workOrderTemplates', 'serviceEstimates', 'records', 'reports', 'salesTax', 'serviceGlAccounts', 'serviceLaborRate', 'servicePurgeXCalls', 'serviceTransferEquipment', 'serviceTransferHistory', 'auditTrail', ''],
    modelNames: computed('utilities.models', function () {
      return Ember.A(this.get('utilities.models'));
    }),
    actions: {
      setFile: function setFile(val) {
        this.set('file', val);
      },
      setAll: function setAll(obj, modelName) {
        var obj2 = obj;

        if (typeof obj.set !== 'function') {
          obj2 = Ember.Object.create(obj);
        }

        obj2.set('delete', true);
        this.sendAction('onChange', modelName + '.delete', true);
        obj2.set('create', true);
        this.sendAction('onChange', modelName + '.create', true);
        obj2.set('update', true);
        this.sendAction('onChange', modelName + '.update', true);
        obj2.set('read', true);
        this.sendAction('onChange', modelName + '.read', true);
      },
      onChange: function onChange(path, value) {
        this.sendAction('onChange', path, value);
      }
    }
  });

  _exports.default = _default;
});