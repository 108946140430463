define("client/helpers/file-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fileSize = fileSize;

  function fileSize(params
  /*, hash*/
  ) {
    var bytes = params[0],
        returnVal = bytes;

    if (bytes === 0) {
      return "0 B";
    }

    if (bytes >= 1e12) {
      returnVal = (bytes / 1e12).toString();
      var decimalLocation = returnVal.indexOf(".");
      return returnVal.substr(0, decimalLocation + 2) + " TB";
    } else if (bytes >= 1e9) {
      returnVal = (bytes / 1e9).toString();

      var _decimalLocation = returnVal.indexOf(".");

      return returnVal.substr(0, _decimalLocation + 2) + " GB";
    } else if (bytes >= 1e6) {
      returnVal = (bytes / 1e6).toString();

      var _decimalLocation2 = returnVal.indexOf(".");

      return returnVal.substr(0, _decimalLocation2 + 2) + " MB";
    } else if (bytes >= 1e3) {
      returnVal = (bytes / 1e3).toString();

      var _decimalLocation3 = returnVal.indexOf(".");

      return returnVal.substr(0, _decimalLocation3 + 2) + " KB";
    } else {
      returnVal = bytes + " B";
    }
  }

  var _default = Ember.Helper.helper(fileSize);

  _exports.default = _default;
});