define("client/react/components/typed/form-autocomplete", ["exports", "react/jsx-runtime", "@mui/material", "@Harris-Barrick-Software/use-form", "client/react/utilities/typed/autocomplete"], function (_exports, _jsxRuntime, _material, _useForm, _autocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function FormAutocomplete(_a) {
    var allowCreate = _a.allowCreate,
        onCreate = _a.onCreate,
        formProps = _a.formProps,
        renderInputParams = _a.renderInputParams,
        immuneValueKeys = _a.immuneValueKeys,
        fullClearOnBlur = _a.fullClearOnBlur,
        matchOnBlur = _a.matchOnBlur,
        onFullClear = _a.onFullClear,
        viewSelected = _a.viewSelected,
        restProps = __rest(_a, ["allowCreate", "onCreate", "formProps", "renderInputParams", "immuneValueKeys", "fullClearOnBlur", "matchOnBlur", "onFullClear", "viewSelected"]);

    var autocompleteDefaults = (0, _autocomplete.useDefaults)();
    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });
    var inputController = (0, _useForm.useController)({
      name: renderInputParams.name,
      control: formProps.control
    });

    if (!controller || !inputController) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field;
    var inputField = inputController.field;
    var createObject = {
      _createNew: true
    };
    var renderInput = restProps.renderInput || (0, _autocomplete.autocompleteRenderInput)(Object.assign(Object.assign({}, renderInputParams), {
      viewSelected: viewSelected,
      autocompleteValue: field.value,
      onBlur: function onBlur(e) {
        if (restProps.options) {
          var match = restProps.options.find(function (itm) {
            return (restProps.getOptionLabel ? restProps.getOptionLabel(itm) : itm).toLowerCase() === e.target.value.toLowerCase();
          });

          if (!match && fullClearOnBlur) {
            if (onFullClear) {
              onFullClear();
            } else {
              formProps.control.setValue(formProps.name, null);
              formProps.control.setValue(renderInputParams.name, '');
            }
          } else if (match && matchOnBlur) {
            formProps.control.setValue(formProps.name, match);
            formProps.control.setValue(renderInputParams.name, restProps.getOptionLabel ? restProps.getOptionLabel(match) : match);
          }
        }

        field.onBlur();
        inputField.onBlur();

        if (renderInputParams.onBlur) {
          renderInputParams.onBlur(e);
        }
      }
    }));
    return (0, _jsxRuntime.jsx)(_material.Autocomplete, Object.assign({}, autocompleteDefaults, restProps, {
      options: !allowCreate ? restProps.options : [createObject].concat(_toConsumableArray(restProps.options)),
      getOptionLabel: !allowCreate ? restProps.getOptionLabel : function (v) {
        if (v['_createNew']) {
          return "Create New";
        } else {
          return restProps.getOptionLabel(v);
        }
      },
      renderOption: !allowCreate ? restProps.renderOption : function (props, option, state) {
        if (option['_createNew']) {
          return "Create New";
        } else if (restProps.renderOption) {
          return restProps.renderOption(props, option, state);
        } else {
          return restProps.getOptionLabel(option);
        }
      },
      ref: field.ref,
      value: field.value,
      onChange: function onChange(e, v, r) {
        if (allowCreate && v && v['_createNew'] && onCreate) {
          onCreate(e, v, r);
          return;
        } // we use this so we can add arbitrary options via filterOptions for things like creating new objects that don't match an actual option
        // and can have their own unique structure


        var hasImmuneKey = !immuneValueKeys ? false : immuneValueKeys.find(function (k) {
          return v ? !!v[k] : false;
        });

        if (!v || !hasImmuneKey) {
          field.onChange(v);
        }

        if (restProps.onChange) {
          restProps.onChange(e, v, r);
        }
      },
      inputValue: inputField.value,
      onInputChange: function onInputChange(e, v, r) {
        if (r !== "reset") {
          inputField.onChange(v);
        }

        if (restProps.onInputChange) {
          restProps.onInputChange(e, v, r);
        }
      },
      renderInput: renderInput
    }));
  }

  var _default = FormAutocomplete;
  _exports.default = _default;
});