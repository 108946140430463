define("client/helpers/pad-space", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.padSpace = padSpace;

  function padSpace(params
  /*, hash*/
  ) {
    var string = params[0];
    var length = params[1];

    while (params < length) {
      string += "&nbsp;";
    }

    return string;
  }

  var _default = Ember.Helper.helper(padSpace);

  _exports.default = _default;
});