define("client/models/contract-billing", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    updated: _emberData.default.attr(),
    contract: _emberData.default.belongsTo('contract', {
      async: true
    }),
    monthYear: _emberData.default.attr('month-year'),
    amount: _emberData.default.attr(),
    billedOn: _emberData.default.attr(),
    invoice: _emberData.default.belongsTo('invoice', {
      async: true
    })
  });

  _exports.default = _default;
});