define("client/components/manage-templates/component", ["exports", "client/react/utilities/snackbar"], function (_exports, _snackbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    templateTypeMap: Ember.Object.create({
      workOrder: "work-order-template-maintenance"
    }),
    templateTypes: Ember.A([Ember.Object.create({
      label: "Work Order",
      modelName: "workOrder"
    })]),
    actions: {
      edit: function edit(item) {
        this.setProperties({
          isEditing: item,
          componentName: this.get('templateTypeMap.' + item.get('modelName'))
        });
      },
      create: function create() {
        if (!this.get('selectedTemplateType')) {
          (0, _snackbar.showSnackbar)('You must select a template before proceeding', {
            variant: 'error'
          });
          return;
        }

        this.setProperties({
          isCreating: true,
          componentName: this.get('templateTypeMap.' + this.get('selectedTemplateType')),
          promptTemplateType: false
        });
      },
      promptCreate: function promptCreate() {
        this.setProperties({
          promptTemplateType: true,
          selectedTemplateType: this.get('templateTypes.firstObject.modelName')
        });
      },
      setSelectedTemplateType: function setSelectedTemplateType(val) {
        this.set('selectedTemplateType', val ? val.get('modelName') : null);
      },
      closeCreate: function closeCreate() {
        this.setProperties({
          isCreating: false,
          componentName: null,
          promptTemplateType: false
        });
      }
    }
  });

  _exports.default = _default;
});