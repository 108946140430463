define("client/helpers/index-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.indexOf = indexOf;

  function indexOf(params
  /*, hash*/
  ) {
    return params[0].indexOf(params[1]);
  }

  var _default = Ember.Helper.helper(indexOf);

  _exports.default = _default;
});