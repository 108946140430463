define("client/react/utilities/typed/hooks/use-hotkeys.types", [], function () {
  "use strict"; // import {KeyHandler} from "hotkeys-js"
  // export interface HotkeyMapping {
  // 	enabled: boolean,
  // 	hotkey: string,
  // 	callback: KeyHandler
  // }
  // export type HotkeyMappings = Record<string, HotkeyMapping>
  // export type UseHotkeyOptions = {
  // 	shouldOverride: boolean,
  // 	callback: KeyHandler,
  // 	deps: any[]
  // }
});