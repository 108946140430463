define("client/components/jc-cat-maintenance/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    audit: Ember.inject.service(),
    sortedEntries: Ember.computed.sort('entries', function (a, b) {
      return parseInt(a.get('id')) - parseInt(b.get('id'));
    }),
    actions: {
      editEntry: function editEntry(entry) {
        entry.set('description', entry.get('description') ? entry.get('description').trim() : '');
        this.set('editingEntry', entry);
      },
      cancelEdit: function cancelEdit() {
        this.get('editingEntry').rollbackAttributes();
        this.set('editingEntry', null);
      },
      saveEdit: function saveEdit() {
        var _this = this;

        Ember.$('#isLoading').show();
        var entry = this.get('editingEntry');
        entry.set('description', entry.get('description').toUpperCase());
        entry.save().then(function (ent) {
          _this.set('editingEntry', null);

          Ember.$('#isLoading').hide();
        });
      },
      deleteEntry: function deleteEntry(entry) {
        this.set('deletingEntry', entry);
      },
      cancelDelete: function cancelDelete() {
        this.set('deletingEntry', null);
      },
      saveDelete: function saveDelete() {
        var _this2 = this;

        Ember.$('#isLoading').show();
        var entry = this.get('deletingEntry');
        entry.set('description', '');
        entry.set('laborFactor', 0);
        entry.set('matlFactor', 0);
        entry.save().then(function (newEnt) {
          _this2.set('deletingEntry', null);

          Ember.$('#isLoading').hide();
        });
      }
    }
  });

  _exports.default = _default;
});