define("client/models/pp-transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tranKey: _emberData.default.attr(),
    vendId: _emberData.default.attr(),
    jobId: _emberData.default.attr(),
    date: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr('capitalize'),
    amount: _emberData.default.attr(),
    amtPaid: _emberData.default.attr(),
    discAmt: _emberData.default.attr(),
    dueDate: _emberData.default.attr(),
    newAmount: 0,
    additionalDiscount: 0,
    transactionHold: _emberData.default.attr('yn-tf'),
    vendor: _emberData.default.belongsTo('vendor', {
      async: true
    })
  });

  _exports.default = _default;
});