define("client/templates/ar-keyoff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZaiNY/N6",
    "block": "{\"statements\":[[1,[33,[\"ar-keyoff\"],null,[[\"model\"],[[28,[\"model\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/ar-keyoff.hbs"
    }
  });

  _exports.default = _default;
});