define("client/react/components/typed/salesman-form-autocomplete", ["exports", "react/jsx-runtime", "client/react/components/typed/form-autocomplete", "client/react/components/helpers"], function (_exports, _jsxRuntime, _formAutocomplete, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function SalesmanFormAutocomplete(props) {
    return (0, _jsxRuntime.jsx)(_formAutocomplete.default, Object.assign({
      getOptionLabel: function getOptionLabel(x) {
        return (0, _helpers.getSalesmanLabel)(x);
      },
      fullClearOnBlur: true
    }, props));
  }

  var _default = SalesmanFormAutocomplete;
  _exports.default = _default;
});