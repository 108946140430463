define("client/components/srvc-contract-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Component.extend({
    tagName: '',
    audit: inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var menuItems = Ember.A([]);
      menuItems.pushObjects([Ember.Object.create({
        title: "Search Contracts",
        icon: "search",
        action: 'search'
      }), Ember.Object.create({
        title: "Create Contract",
        icon: "add",
        action: 'create',
        authPath: 'auth.contract.create'
      }), Ember.Object.create({
        title: "Edit Contract",
        icon: "create",
        action: 'edit',
        authPath: 'auth.contract.update'
      }), Ember.Object.create({
        title: "Attachments",
        icon: "attach_file",
        action: 'openAttachments'
      }), Ember.Object.create({
        title: "Covered Equipment",
        icon: "build",
        action: 'openEquipment'
      }), Ember.Object.create({
        title: "Customer Profile",
        icon: "person",
        action: 'openCustomerProfile'
      }), Ember.Object.create({
        title: "View Prior",
        icon: "keyboard_backspace",
        action: 'openPrevious'
      }), Ember.Object.create({
        title: "View Renewal",
        icon: "autorenew",
        action: 'openRenewal'
      }), Ember.Object.create({
        title: "Delete Contract",
        icon: "delete",
        action: 'delete',
        authPath: 'auth.contract.delete'
      })]);
      this.set('menuItems', menuItems);
    },
    cycles: Ember.A([Ember.Object.create({
      label: "Monthly",
      value: "M"
    }), Ember.Object.create({
      label: "Bi-Monthly",
      value: "B"
    }), Ember.Object.create({
      label: "Quarterly",
      value: "Q"
    }), Ember.Object.create({
      label: "Semi-Annual",
      value: "S"
    }), Ember.Object.create({
      label: "Annual",
      value: "A"
    })]),
    auth: inject.service(),
    actions: {
      create: function create() {
        this.sendAction('create');
      },
      search: function search() {
        this.sendAction('onSearch');
      },
      close: function close() {
        this.sendAction('close');
      },
      delete: function _delete() {
        this.sendAction('delete', this.get('contract'));
      },
      edit: function edit() {
        this.sendAction('edit', this.get('contract'));
      },
      openEquipment: function openEquipment() {
        this.set('equipmentOpen', true);
      },
      closeEquipment: function closeEquipment() {
        this.set('equipmentOpen', false);
      },
      openFilters: function openFilters() {
        this.set('filtersOpen', true);
      },
      closeFilters: function closeFilters() {
        this.set('filtersOpen', false);
      },
      openPrevious: function openPrevious() {
        this.sendAction('openPrevious', this.get('contract'));
      },
      openRenewal: function openRenewal() {
        this.sendAction('openRenewal', this.get('contract'));
      },
      openAttachments: function openAttachments() {
        this.set('showAttachments', true);
      },
      openCustomerProfile: function openCustomerProfile() {
        this.set('customerProfile', true);
      }
    }
  });

  _exports.default = _default;
});