define("client/helpers/money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.money = money;

  function money(params) {
    var input = params[0];
    if (input && Math.abs(input) < 0.01) return "0.00";

    if ((input || input === 0) && typeof input === "number") {
      input = input.toFixed(2);
    } else if (typeof input === "string" && !isNaN(parseFloat(input))) {
      input = parseFloat(input).toFixed(2);
    } else {
      input = '0.00';
    }

    var digitsRegex = /\d/g;
    var digits = input.match(digitsRegex);
    var counter = 0;

    for (var i = digits.length - 2; i > 0; i--) {
      counter++;

      if (counter % 3 === 0 && i !== 1) {
        digits.splice(i - 1, 0, ',');
      }
    }

    digits.splice(digits.length - 2, 0, '.');
    if (input[0] === '-') digits.splice(0, 0, '-');
    return digits.join("");
  }

  var _default = Ember.Helper.helper(money);

  _exports.default = _default;
});