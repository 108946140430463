define("client/models/contract-service-due", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    contractId: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    srvcDue1: _emberData.default.attr('capitalize'),
    srvcDue2: _emberData.default.attr('capitalize'),
    srvcDue3: _emberData.default.attr('capitalize'),
    month1: _emberData.default.attr('asterisk-to-tf'),
    month2: _emberData.default.attr('asterisk-to-tf'),
    month3: _emberData.default.attr('asterisk-to-tf'),
    month4: _emberData.default.attr('asterisk-to-tf'),
    month5: _emberData.default.attr('asterisk-to-tf'),
    month6: _emberData.default.attr('asterisk-to-tf'),
    month7: _emberData.default.attr('asterisk-to-tf'),
    month8: _emberData.default.attr('asterisk-to-tf'),
    month9: _emberData.default.attr('asterisk-to-tf'),
    month10: _emberData.default.attr('asterisk-to-tf'),
    month11: _emberData.default.attr('asterisk-to-tf'),
    month12: _emberData.default.attr('asterisk-to-tf'),
    contract: _emberData.default.belongsTo('contract', {
      async: true
    }),
    description: Ember.computed('srvcDue1', 'srvcDue2', 'srvcDue3', {
      get: function get(key) {
        return this.get('srvcDue1') + this.get('srvcDue2') + this.get('srvcDue3');
      },
      set: function set(key, value) {
        value = value ? value.trim() : "";

        if (value.length > 99) {
          this.set('srvcDue1', value.substr(0, 50));
          this.set('srvcDue2', value.substr(50, 50));
          this.set('srvcDue3', value.substr(100, 50));
        } else if (value.length > 49) {
          this.set('srvcDue1', value.substr(0, 50));
          this.set('srvcDue2', value.substr(50, 50));
          this.set('srvcDue3', "");
        } else {
          this.set('srvcDue1', value.substr(0, 50));
          this.set('srvcDue2', "");
          this.set('srvcDue3', "");
        }
      }
    })
  });

  _exports.default = _default;
});