define("client/models/user-assigned", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inflector = Ember.Inflector.inflector; //inflector.singular(/tenantSettings/, 'tenant-settings');

  inflector.irregular('userAssigned', 'userAssigned');

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr(),
    techs: _emberData.default.attr('csv2')
  });

  _exports.default = _default;
});