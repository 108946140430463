define("client/components/cost-centers/component", ["exports", "client/components/react-component/component", "client/react/service/cost-centers"], function (_exports, _component, _costCenters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ["full-height"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.reactRender(React.createElement(_costCenters.default, null));
    }
  });

  _exports.default = _default;
});