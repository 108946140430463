define("client/components/user-integrations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X3ZvjEyf",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"layout-row layout-align-center\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"layout-column flex-40\"],[13],[0,\"\\n\"],[0,\"\\n\"],[6,[\"paper-card\"],null,null,{\"statements\":[[6,[\"component\"],[[28,[\"card\",\"title\"]]],null,{\"statements\":[[6,[\"component\"],[[28,[\"title\",\"text\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[6,[\"component\"],[[28,[\"text\",\"headline\"]]],null,{\"statements\":[[0,\"Coming Soon\"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[\"text\"]},null]],\"locals\":[\"title\"]},null],[6,[\"component\"],[[28,[\"card\",\"content\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\tUser facing integrations will show up here\\n\"]],\"locals\":[]},null]],\"locals\":[\"card\"]},null],[0,\"    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/user-integrations/template.hbs"
    }
  });

  _exports.default = _default;
});