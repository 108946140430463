define("client/models/work-order-part", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var WorkOrderPart = _emberData.default.Model.extend({
    srvcOrdId: _emberData.default.attr(),
    lineNumber: _emberData.default.attr(),
    complaintNumber: _emberData.default.attr(),
    prodId: _emberData.default.attr('capitalize'),
    description: _emberData.default.attr('capitalize'),
    qty: _emberData.default.attr(),
    oldQty: _emberData.default.attr(),
    // this just exists to allow us to handle part allocs when using changeset which normally only allow properties that exist on model... :|
    cost: _emberData.default.attr('currency'),
    sell: _emberData.default.attr('currency'),
    billableFlag: _emberData.default.attr('yn-tf'),
    specialGl: _emberData.default.attr(),
    taxableFlag: _emberData.default.attr('yn-tf'),
    truckStock: _emberData.default.attr(),
    workOrder: _emberData.default.belongsTo('work-order', {
      async: true
    })
  });

  var _default = WorkOrderPart;
  _exports.default = _default;
});