define("client/routes/inv-load-inventory", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      return Ember.RSVP.hash({
        categories: this.store.findAll('part-category', {
          reload: true
        }),
        warehouses: this.store.findAll('warehouse', {
          reload: true
        })
      });
    },
    actions: {
      goHome: function goHome() {
        this.transitionTo('landing');
      }
    }
  });

  _exports.default = _default;
});