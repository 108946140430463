define("client/components/tag-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l4Mq4e/K",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"tagDefinition\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[33,[\"md-chip\"],null,[[\"color\",\"textColor\",\"label\"],[[28,[\"tagDefinition\",\"color\"]],[28,[\"textColor\"]],[28,[\"tagDefinition\",\"title\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"unless\"],[[28,[\"onRemove\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[33,[\"md-chip\"],null,[[\"color\",\"textColor\",\"label\",\"onDelete\"],[[28,[\"computedDefinition\",\"color\"]],[28,[\"textColor\"]],[28,[\"computedDefinition\",\"title\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"confirmRemove\"]]],null],true],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]}],[0,\"\\n\"],[6,[\"if\"],[[28,[\"confirmRemove\"]]],null,{\"statements\":[[6,[\"paper-dialog\"],null,null,{\"statements\":[[6,[\"paper-dialog-content\"],null,null,{\"statements\":[[0,\"            Are you sure you want to remove this tag?\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"paper-dialog-actions\"],null,null,{\"statements\":[[0,\"            \"],[1,[33,[\"paper-button\"],null,[[\"label\",\"onClick\"],[\"No\",[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"confirmRemove\"]]],null],false],null]]]],false],[0,\"\\n            \"],[1,[33,[\"paper-button\"],null,[[\"label\",\"onClick\"],[\"Yes\",[33,[\"action\"],[[28,[null]],\"onRemove\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/tag-display/template.hbs"
    }
  });

  _exports.default = _default;
});