define("client/components/date-input/component", ["exports", "ember-paper/components/paper-input", "client/react/utilities/snackbar"], function (_exports, _paperInput, _snackbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      assert = Ember.assert;

  var _default = _paperInput.default.extend({
    type: "date",
    init: function init() {
      this._super.apply(this, arguments); // if this isn't done we will be sad as all inputs will have date validation


      this.set('customValidations', []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('textarea')) {
        $(window).on("resize.".concat(this.elementId), run.bind(this, this.growTextarea));
      }

      if (this.get('autofocus')) {
        var inp = this.$('input')[0];

        if (inp) {
          inp.focus();
        }
      }
    },
    dateVal: {
      message: 'Date must not be > 100 years from today',
      validate: function validate(value) {
        return !value || Math.abs(moment(value).diff(moment(), 'years')) < 100;
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      assert('{{paper-input}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined);
      var customs = this.get('customValidations');

      if (!customs.includes(this.dateVal)) {
        customs.push(this.dateVal);
      }

      this.notifyValidityChange();
    },
    actions: {
      handleInput: function handleInput(e) {
        var _this = this;

        var val = e.target.value === "" ? null : e.target.value;

        if (val !== null) {
          var year = val.substr(0, 5);
          if (year[4] !== "-") val = val.substr(0, 4) + val.substr(5);
        }

        this.sendAction('onChange', val); // setValue below ensures that the input value is the same as this.value

        run.next(function () {
          if (_this.isDestroyed) {
            return;
          }

          _this.setValue(val);

          var inputElement = _this.$('input').get(0);

          _this.set('isNativeInvalid', inputElement && inputElement.validity && inputElement.validity.badInput);

          _this.notifyValidityChange();
        });
        this.growTextarea();
      },
      handleBlur: function handleBlur(e) {
        var _this2 = this;

        var val = this.get('value');

        if (val && val.substr(0, 2) === "00") {
          val = "20" + val.substr(2);
          this.sendAction('onChange', val);
          run.next(function () {
            if (_this2.isDestroyed) {
              return;
            }

            _this2.setValue(_this2.get('value'));
          });
        }

        if (val && Math.abs(moment(val).diff(moment(), 'years')) > 50) {
          (0, _snackbar.showSnackbar)('Questionable date', {
            variant: 'warning'
          });
        }

        this.sendAction('onBlur', e);
        this.set('isTouched', true);
        this.notifyValidityChange();
      }
    }
  });

  _exports.default = _default;
});