define("client/components/num-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qyoyas2q",
    "block": "{\"statements\":[[1,[33,[\"input\"],null,[[\"type\",\"placeholder\",\"value\",\"class\",\"maxlength\"],[\"text\",[28,[\"placeholder\"]],[28,[\"inValue\"]],\"form-control\",2]]],false],[0,\"\\n\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/num-input/template.hbs"
    }
  });

  _exports.default = _default;
});