define("client/helpers/subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.subtract = subtract;

  function subtract(params
  /*, hash*/
  ) {
    return (parseFloat(params.objectAt(0)) - parseFloat(params.objectAt(1))).toFixed(2) === 'NaN' ? '' : (parseFloat(params.objectAt(0)) - parseFloat(params.objectAt(1))).toFixed(2);
  }

  var _default = Ember.Helper.helper(subtract);

  _exports.default = _default;
});