define("client/components/cascaded-each/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9GEi/d2d",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"items\"]]],null,{\"statements\":[[0,\"\\t\"],[18,\"default\",[[28,[\"item\"]],[28,[\"index\"]]]],[0,\"\\n\"]],\"locals\":[\"item\",\"index\"]},null]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/cascaded-each/template.hbs"
    }
  });

  _exports.default = _default;
});