define("client/models/contract-option", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    updated: _emberData.default.attr(),
    description: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    isDiscount: _emberData.default.attr(),
    isPercent: _emberData.default.attr(),
    expiresOnRenewal: _emberData.default.attr(),
    label: Ember.computed('description', 'amount', function () {
      return "".concat(this.get('description'), " - ").concat(!this.get('isPercent') ? "$" : "").concat(typeof this.get('amount') === "string" ? parseFloat(this.get('amount')).toFixed() : this.get('amount').toFixed(2)).concat(this.get('isPercent') ? "%" : "");
    })
  });

  _exports.default = _default;
});