define("client/templates/inv-product-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wxYE3Rd8",
    "block": "{\"statements\":[[1,[33,[\"inv-product-create\"],null,[[\"selectItem\",\"categories\"],[[33,[\"route-action\"],[\"openView\"],null],[28,[\"model\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/inv-product-create.hbs"
    }
  });

  _exports.default = _default;
});