define("client/components/mapping-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      addRow: function addRow() {
        var obj = Ember.Object.create({});
        obj[this.get('leftPath')] = 0;
        obj[this.get('rightPath')] = Ember.A([]);
        this.get('rows').pushObject(obj);
        if (this.get('updateAction')) Ember.run.debounce(this, this.get('updateAction'), 700);
      },
      remove: function remove(row) {
        this.get('rows').removeObject(row);
        if (this.get('updateAction')) Ember.run.debounce(this, this.get('updateAction'), 700);
      },
      update: function update() {
        if (this.get('updateAction')) Ember.run.debounce(this, this.get('updateAction'), 700);
      }
    }
  });

  _exports.default = _default;
});