define("client/templates/pr-w2-processing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1k9bseuJ",
    "block": "{\"statements\":[[1,[33,[\"pr-w2-processing\"],null,[[\"employees\",\"hasBusinessSettings\"],[[28,[\"model\",\"employees\"]],[28,[\"model\",\"hasBusinessSettings\"]]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/pr-w2-processing.hbs"
    }
  });

  _exports.default = _default;
});