define("client/helpers/time-input-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.timeInputFormatter = timeInputFormatter;

  function timeInputFormatter(params
  /*, hash*/
  ) {
    return moment(params[0], 'hh:mm aa').format('HH:mm');
  }

  var _default = Ember.Helper.helper(timeInputFormatter);

  _exports.default = _default;
});