define("client/react/components/typed/notes-display", ["exports", "react/jsx-runtime", "@mui/material"], function (_exports, _jsxRuntime, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function NotesDisplay(_ref) {
    var className = _ref.className,
        notes = _ref.notes,
        textVariant = _ref.textVariant;
    return (0, _jsxRuntime.jsx)(_material.Typography, Object.assign({
      className: className,
      variant: textVariant || 'body1',
      sx: {
        wordWrap: 'break-word',
        overflow: 'auto',
        whiteSpace: 'break-spaces'
      }
    }, {
      children: notes
    }));
  }

  var _default = NotesDisplay;
  _exports.default = _default;
});