define("client/utils/rows-to-time-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = rowsToTimeString;

  function rowsToTimeString(rows, numPeriods)
  /*
     Transforms a rows value into a duration string
     just take rows/numPeriods to be hours figure
     take (rows % numPeriods) * dispatch increment to be minutes
  */
  {
    numPeriods = Number(numPeriods);
    rows = Number(rows);
    var ret = "";
    var dispatchIncrement = 60 / numPeriods;
    var hours = Math.floor(rows / numPeriods);
    var minutes = rows % numPeriods * dispatchIncrement;

    if (hours > 0) {
      ret += hours + ' hours ';
    }

    if (minutes > 0) {
      ret += minutes + ' minutes';
    }

    return ret;
  }
});