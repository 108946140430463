define("client/components/nsb-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IfS5LNjI",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"role\",\"menuitem\"],[15,\"class\",\"layout-row layout-align-start-center\"],[13],[0,\"\\n    \"],[1,[26,[\"itemName\"]],false],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/nsb-item/template.hbs"
    }
  });

  _exports.default = _default;
});