define("client/models/tenant-settings", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inflector = Ember.Inflector.inflector;
  inflector.singular(/tenantSettings/, 'tenant-settings');
  inflector.irregular('tenant-settings', 'tenant-settings');
  var computed = Ember.computed,
      inject = Ember.inject;

  var TenantSettings = _emberData.default.Model.extend({
    documentType: _emberData.default.attr(),
    dispatchStartTime: _emberData.default.attr(),
    dispatchPeriods: _emberData.default.attr(),
    dispatchEndTime: _emberData.default.attr(),
    equipmentConditions: _emberData.default.attr('csv'),
    equipmentCategories: _emberData.default.attr('csv'),
    contractTypes: _emberData.default.attr('csv'),
    dispatchStatuses: _emberData.default.attr('one-to-one'),
    dispatchCodes: _emberData.default.attr('csv'),
    dispatchPriorities: _emberData.default.attr('csv'),
    emailBody: _emberData.default.attr(),
    codes: _emberData.default.attr(),
    subjectLine: _emberData.default.attr(),
    dispatchWorkOrderTimes: _emberData.default.attr('range'),
    callBackCodes: _emberData.default.attr('json'),
    colorO: _emberData.default.attr(),
    colorR: _emberData.default.attr(),
    colorT: _emberData.default.attr(),
    colorF: _emberData.default.attr(),
    colorS: _emberData.default.attr(),
    colorD: _emberData.default.attr(),
    colorC: _emberData.default.attr(),
    color1: _emberData.default.attr(),
    color2: _emberData.default.attr(),
    color3: _emberData.default.attr(),
    color4: _emberData.default.attr(),
    color5: _emberData.default.attr(),
    color6: _emberData.default.attr(),
    colorX: _emberData.default.attr(),
    colorH: _emberData.default.attr(),
    colorL: _emberData.default.attr(),
    colorW: _emberData.default.attr(),
    colorP: _emberData.default.attr(),
    colorA: _emberData.default.attr(),
    colorE: _emberData.default.attr(),
    colorB: _emberData.default.attr(),
    colorG: _emberData.default.attr(),
    color8: _emberData.default.attr(),
    color7: _emberData.default.attr(),
    color9: _emberData.default.attr(),
    colorV: _emberData.default.attr(),
    firstPrice: _emberData.default.attr(),
    secondPrice: _emberData.default.attr(),
    priceInterval: _emberData.default.attr(),
    showAll: _emberData.default.attr(),
    preferredTypes: _emberData.default.attr('csv'),
    flatRateCategories: _emberData.default.attr('csv'),
    partCategories: _emberData.default.attr('csv'),
    estimateAddresses: _emberData.default.attr('csv'),
    disperseDiagnostic: _emberData.default.attr(),
    discountTypes: _emberData.default.attr('one-to-many'),
    laborDiscountTypes: _emberData.default.attr('one-to-many'),
    specialLaborRates: _emberData.default.attr('one-to-one'),
    flatRateLaborPrice: _emberData.default.attr(),
    hasTapi: _emberData.default.attr(),
    hasMobile: _emberData.default.attr(),
    visibleStatuses: _emberData.default.attr('csv'),
    printToOffice: _emberData.default.attr(),
    emailTechWhenTravel: _emberData.default.attr(),
    allowPrimaryOverride: _emberData.default.attr(),
    hideDiagnostic: _emberData.default.attr(),
    hideDiagInPrimary: _emberData.default.attr(),
    warrantyPartHasCost: _emberData.default.attr(),
    multiFlatRateType: _emberData.default.attr(),
    fillWoColor: _emberData.default.attr(),
    diagnosticCodes: _emberData.default.attr('csv'),
    primaryDiagnostic: _emberData.default.attr(),
    featureFlags: _emberData.default.attr('json'),
    employerName: _emberData.default.attr(),
    employerName2: _emberData.default.attr(),
    employerAddress: _emberData.default.attr(),
    employerCityStateZip: _emberData.default.attr(),
    employerTel: _emberData.default.attr(),
    employerFax: _emberData.default.attr(),
    pageTitle: _emberData.default.attr(),
    confirmationCallerId: _emberData.default.attr('telephone'),
    appointmentConfirmationMessage: _emberData.default.attr(),
    emailAppointmentConfirmationMessage: _emberData.default.attr(),
    emailAppointmentConfirmationSubject: _emberData.default.attr(),
    podiumId: _emberData.default.attr(),
    podiumOn: _emberData.default.attr(),
    podiumToken: _emberData.default.attr(),
    techWoStatuses: _emberData.default.attr('csv'),
    defaultPostInvRoutinesCategory: _emberData.default.attr('capitalize'),
    superTenant: _emberData.default.attr(),
    delinquencyDays: _emberData.default.attr(),
    prPrinter: _emberData.default.attr(),
    apPrinter: _emberData.default.attr(),
    apCopyPrinter: _emberData.default.attr(),
    invoicePrinter: _emberData.default.attr(),
    primarySolicitationMeans: _emberData.default.attr(),
    secondarySolicitationMeans: _emberData.default.attr(),
    tertiarySolicitationMeans: _emberData.default.attr(),
    solicitationMessage: _emberData.default.attr(),
    solicitationFromAddress: _emberData.default.attr(),
    solicitationFromEmail: _emberData.default.attr(),
    solicitationPhoneMessage: _emberData.default.attr(),
    newSolicitationMessage: _emberData.default.attr(),
    newSolicitationPhoneMessage: _emberData.default.attr(),
    contractPartIds: _emberData.default.attr('csv'),
    stripeAccountId: _emberData.default.attr(),
    stripeCashGl: _emberData.default.attr(),
    stripeAutoCashReceipt: _emberData.default.attr(),
    secureLaborEntries: _emberData.default.attr(),
    hideTechPerformance: _emberData.default.attr(),
    hideMobileCreateWo: _emberData.default.attr(),
    hideMobileCreateCustAndSite: _emberData.default.attr(),
    defaultTaxState: _emberData.default.attr(),
    crmStages: _emberData.default.attr('csv'),
    crmSources: _emberData.default.attr('csv'),
    crmDepartments: _emberData.default.attr('csv'),
    crmUnqualifiedReasons: _emberData.default.attr('csv'),
    communicationOutcomes: _emberData.default.attr('csv'),
    linkedToRingCentral: _emberData.default.attr(),
    lockW2Settings: _emberData.default.attr(),
    hideMobileScheduleContractCheckup: _emberData.default.attr(),
    mobileSearchScheduleStartTime: _emberData.default.attr(),
    mobileSearchScheduleEndTime: _emberData.default.attr(),
    comparisonFullBackgroundColor: _emberData.default.attr(),
    comparisonDescriptionIsPrimary: _emberData.default.attr(),
    apDocumentType: _emberData.default.attr(),
    mobileShowFlatRateLaborAllotted: _emberData.default.attr(),
    mobileHideNumberOfCalls: _emberData.default.attr(),
    catalogPartDiscount: _emberData.default.attr(),
    flratePartsOnInvoice: _emberData.default.attr(),
    mobileDefaultTaxable: _emberData.default.attr(),
    mobileOfficeNotificationEmail: _emberData.default.attr(),
    mobileSalesNotificationEmail: _emberData.default.attr(),
    mobilePartsNotificationEmail: _emberData.default.attr()
  });

  TenantSettings.reopenClass({
    FIXTURES: [{
      id: 1,
      dispatchStartTime: "8:00 am",
      dispatchEndTime: "8:00 pm",
      dispatchPeriods: 4,
      equipmentConditions: ["New", "Aged", "Poor", "Needs Replaced"],
      equipmentCategories: ["Thermostat", "Condenser", "Air Handler", "Tankless Water Heater", "Tank Water Heater", "Pool Heater", "Chiller"],
      contractTypes: ["5 Year Parts", "10 Year P&L", "1 Year ESA", "1 Year Generator ESA", "5 Year P&L"],
      dispatchStatuses: ["Complete", "En Route", "In Progress", "Waiting for Part"],
      dispatchCodes: [10, 23, 42, 32, 31, 43],
      dispatchPriorities: [1, 2, 3, 4, 5],
      dispatchWorkOrderTimes: {
        min: 2,
        increment: 2,
        max: 40
      }
    }]
  });
  var _default = TenantSettings;
  _exports.default = _default;
});