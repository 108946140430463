define("client/helpers/sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sum = sum;

  function sum(params
  /*, hash*/
  ) {
    return params[0] ? params[0].reduce(function (p, c) {
      return p + parseFloat(c);
    }, 0.0) : 0.0;
  }

  var _default = Ember.Helper.helper(sum);

  _exports.default = _default;
});