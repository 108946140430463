define("client/templates/inv-warehouse-maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ydo3ma2R",
    "block": "{\"statements\":[[1,[33,[\"inv-warehouse-maintenance\"],null,[[\"warehouses\"],[[28,[\"model\"]]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/inv-warehouse-maintenance.hbs"
    }
  });

  _exports.default = _default;
});