define("client/components/equipment-delete-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r4L3kOGu",
    "block": "{\"statements\":[[6,[\"paper-dialog\"],null,null,{\"statements\":[[6,[\"paper-toolbar\"],null,null,{\"statements\":[[6,[\"paper-toolbar-tools\"],null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[11,\"h2\",[]],[13],[0,\"Delete Equipment\"],[14],[0,\"\\n\\t\\t\\t\"],[11,\"span\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n\\t\\t\\t\"],[6,[\"paper-button\"],null,[[\"iconButton\",\"onClick\",\"disabled\"],[true,[28,[\"close\"]],[28,[\"delete\",\"isRunning\"]]]],{\"statements\":[[1,[33,[\"paper-icon\"],[\"close\"],null],false]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"paper-dialog-content\"],null,null,{\"statements\":[[6,[\"if\"],[[28,[\"delete\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[26,[\"paper-progress-linear\"]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"\\t\\t\\tAre you sure you want to delete \"],[1,[28,[\"equipment\",\"label\"]],false],[0,\"?\\n\"]],\"locals\":[]}]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"paper-dialog-actions\"],null,null,{\"statements\":[[0,\"\\t\\t\"],[1,[33,[\"paper-button\"],null,[[\"label\",\"onClick\",\"disabled\"],[\"Cancel\",[28,[\"close\"]],[28,[\"delete\",\"isRunning\"]]]]],false],[0,\"\\n\\t\\t\"],[1,[33,[\"paper-button\"],null,[[\"label\",\"warn\",\"onClick\",\"disabled\"],[\"Delete\",true,[33,[\"perform\"],[[28,[\"delete\"]]],null],[28,[\"delete\",\"isRunning\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/equipment-delete-modal/template.hbs"
    }
  });

  _exports.default = _default;
});