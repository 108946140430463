define("client/components/screen-share/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MZcpdLWK",
    "block": "{\"statements\":[[6,[\"paper-dialog\"],null,null,{\"statements\":[[6,[\"paper-toolbar\"],null,null,{\"statements\":[[6,[\"paper-toolbar-tools\"],null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[11,\"h4\",[]],[13],[0,\"Connect\"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[6,[\"paper-dialog-content\"],null,[[\"class\"],[\"layout-column\"]],{\"statements\":[[0,\"\\t\\t\"],[1,[33,[\"paper-input\"],null,[[\"value\",\"onChange\",\"label\"],[[28,[\"tenant\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"tenant\"]]],null]],null],\"Tenant\"]]],false],[0,\"\\n\\t\\t\"],[1,[33,[\"paper-input\"],null,[[\"value\",\"onChange\",\"label\"],[[28,[\"user\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"user\"]]],null]],null],\"User\"]]],false],[0,\"\\n\\t\\t\"],[1,[33,[\"paper-input\"],null,[[\"value\",\"onChange\",\"label\"],[[28,[\"displayName\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"displayName\"]]],null]],null],\"Display Name\"]]],false],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"paper-dialog-actions\"],null,[[\"class\"],[\"layout-row\"]],{\"statements\":[[0,\"\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n\\t\\t\"],[6,[\"paper-button\"],null,[[\"onClick\"],[[28,[\"onClose\"]]]],{\"statements\":[[0,\"CLOSE\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"if\"],[[28,[\"allowRequest\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[6,[\"paper-button\"],null,[[\"onClick\",\"primary\"],[[33,[\"perform\"],[[28,[\"request\"]]],null],true]],{\"statements\":[[0,\"REQUEST\"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\t\\t\"],[6,[\"paper-button\"],null,[[\"onClick\",\"primary\"],[[33,[\"perform\"],[[28,[\"connect\"]]],null],true]],{\"statements\":[[0,\"OFFER\"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/screen-share/template.hbs"
    }
  });

  _exports.default = _default;
});