define("client/components/banks-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    auth: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    actions: {
      close: function close() {
        this.get('close')();
      },
      submit: function submit() {
        if (this.get('featureFlags.featureFlags.payrollRework')) {
          this.sendAction('printChecks', this.get('checkNumber'), this.get('checkDate'), this.get('activeBank.ledger'), this.get('activeBank.id'));
        } else {
          this.sendAction('printChecks', this.get('checkNumber'), this.get('checkDate'), this.get('activeBank.dglCode' + this.get('activeBank.row')));
        }
      },
      select: function select(bank) {
        this.get('banks').forEach(function (b) {
          return b.set('active', false);
        });
        bank.set('active', true);

        if (this.get('featureFlags.featureFlags.payrollRework')) {
          this.set('checkNumber', bank.get('checkNumber'));
        } else {
          this.set('checkNumber', bank.get('checkNum' + bank.get('row')));
        }

        this.set('activeBank', bank);
      }
    }
  });

  _exports.default = _default;
});