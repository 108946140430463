define("client/models/dis-journal-seq-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr(),
    row: _emberData.default.attr(),
    checkNumber: _emberData.default.attr(),
    date: _emberData.default.attr(),
    vendId: _emberData.default.attr(),
    payee: _emberData.default.attr(),
    comment: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr(),
    invoiceDate: _emberData.default.attr(),
    netCash: _emberData.default.attr(),
    payApItems: _emberData.default.attr('yn-tf'),
    cashGlAccount: _emberData.default.attr(),
    glAccount1: _emberData.default.attr(),
    glAccount2: _emberData.default.attr(),
    glAccount3: _emberData.default.attr(),
    glAccount4: _emberData.default.attr(),
    glAccount5: _emberData.default.attr(),
    glAccount6: _emberData.default.attr(),
    glAccount7: _emberData.default.attr(),
    glAccount8: _emberData.default.attr(),
    glAccount9: _emberData.default.attr(),
    glAccount10: _emberData.default.attr(),
    amt1: _emberData.default.attr(),
    amt2: _emberData.default.attr(),
    amt3: _emberData.default.attr(),
    amt4: _emberData.default.attr(),
    amt5: _emberData.default.attr(),
    amt6: _emberData.default.attr(),
    amt7: _emberData.default.attr(),
    amt8: _emberData.default.attr(),
    amt9: _emberData.default.attr(),
    amt10: _emberData.default.attr(),
    jobId1: _emberData.default.attr(),
    jobId2: _emberData.default.attr(),
    jobId3: _emberData.default.attr(),
    jobId4: _emberData.default.attr(),
    jobId5: _emberData.default.attr(),
    jobId6: _emberData.default.attr(),
    jobId7: _emberData.default.attr(),
    jobId8: _emberData.default.attr(),
    jobId9: _emberData.default.attr(),
    jobId10: _emberData.default.attr(),
    catId1: _emberData.default.attr(),
    catId2: _emberData.default.attr(),
    catId3: _emberData.default.attr(),
    catId4: _emberData.default.attr(),
    catId5: _emberData.default.attr(),
    catId6: _emberData.default.attr(),
    catId7: _emberData.default.attr(),
    catId8: _emberData.default.attr(),
    catId9: _emberData.default.attr(),
    catId10: _emberData.default.attr(),
    overhead1: _emberData.default.attr(),
    overhead2: _emberData.default.attr(),
    overhead3: _emberData.default.attr(),
    overhead4: _emberData.default.attr(),
    overhead5: _emberData.default.attr(),
    overhead6: _emberData.default.attr(),
    overhead7: _emberData.default.attr(),
    overhead8: _emberData.default.attr(),
    overhead9: _emberData.default.attr(),
    overhead10: _emberData.default.attr(),
    qty1: _emberData.default.attr(),
    qty2: _emberData.default.attr(),
    qty3: _emberData.default.attr(),
    qty4: _emberData.default.attr(),
    qty5: _emberData.default.attr(),
    qty6: _emberData.default.attr(),
    qty7: _emberData.default.attr(),
    qty8: _emberData.default.attr(),
    qty9: _emberData.default.attr(),
    qty10: _emberData.default.attr(),
    code1: _emberData.default.attr(),
    code2: _emberData.default.attr(),
    code3: _emberData.default.attr(),
    code4: _emberData.default.attr(),
    code5: _emberData.default.attr(),
    code6: _emberData.default.attr(),
    code7: _emberData.default.attr(),
    code8: _emberData.default.attr(),
    code9: _emberData.default.attr(),
    code10: _emberData.default.attr(),
    pjTran1: _emberData.default.belongsTo('purchase-journal-transaction', {
      async: true
    }),
    pjTran2: _emberData.default.belongsTo('purchase-journal-transaction', {
      async: true
    }),
    pjTran3: _emberData.default.belongsTo('purchase-journal-transaction', {
      async: true
    }),
    pjTran4: _emberData.default.belongsTo('purchase-journal-transaction', {
      async: true
    }),
    pjTran5: _emberData.default.belongsTo('purchase-journal-transaction', {
      async: true
    }),
    pjTran6: _emberData.default.belongsTo('purchase-journal-transaction', {
      async: true
    }),
    pjTran7: _emberData.default.belongsTo('purchase-journal-transaction', {
      async: true
    }),
    pjTran8: _emberData.default.belongsTo('purchase-journal-transaction', {
      async: true
    }),
    pjTran9: _emberData.default.belongsTo('purchase-journal-transaction', {
      async: true
    }),
    pjTran10: _emberData.default.belongsTo('purchase-journal-transaction', {
      async: true
    }),
    paidInvoices: _emberData.default.hasMany('paid-pj-invoice', {
      async: true
    }),
    unpaidInvoices: _emberData.default.hasMany('ap-transaction', {
      async: true
    }),
    apItemEntries: _emberData.default.hasMany('dis-journal-ap-entry', {
      async: true
    })
  });

  _exports.default = _default;
});