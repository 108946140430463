define("client/initializers/update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(app) {
    // application.inject('route', 'foo', 'service:foo');
    app.inject('route', 'updateService', 'service:update'); //  app.inject('service', 'store', 'store:main');

    app.inject('adapter', 'updateService', 'service:update');
  }

  var _default = {
    name: 'update',
    initialize: initialize
  };
  _exports.default = _default;
});