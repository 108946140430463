define("client/components/dispatch/component", ["exports", "client/config/environment", "client/react/utilities/snackbar"], function (_exports, _environment, _snackbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    utilities: Ember.inject.service(),
    message: "",
    actions: {
      closeMessageDialog: function closeMessageDialog() {
        this.sendAction();
      },
      sendMessage: function sendMessage() {
        Ember.$.ajax({
          url: _environment.default.APP.endpoint + "/fsm/messageTech",
          type: 'post',
          data: {
            user: this.get('auth.currentUser'),
            message: this.get('message'),
            emailTech: this.get('technician.email')
          },
          success: function (success) {
            (0, _snackbar.showSnackbar)("Message successfully sent", {
              variant: 'success'
            });
          }.bind(this),
          error: function (reason) {
            if (reason.status === 200) {
              (0, _snackbar.showSnackbar)("Message successfully sent", {
                variant: 'success'
              });
            } else {
              (0, _snackbar.showSnackbar)("Failed to send", {
                variant: 'error'
              });
            }
          }.bind(this),
          headers: {
            "X-Auth-Tenant": this.get('auth.tenantId'),
            "X-Auth-Token": this.get('auth.access_token')
          },
          dataType: 'json'
        });
        this.sendAction();
      }
    }
  });

  _exports.default = _default;
});