define("client/models/customer-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    note: _emberData.default.attr('capitalize'),
    seq: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    custId: _emberData.default.attr(),
    seqNum: Ember.computed('seq', function () {
      return isNaN(parseInt(this.get('seq'))) ? '' : parseInt(this.get('seq'));
    })
  });

  _exports.default = _default;
});