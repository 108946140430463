define("client/models/job-estimate", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    jobId: _emberData.default.attr('capitalize'),
    category: _emberData.default.attr('trim'),
    hours: _emberData.default.attr(),
    labor: _emberData.default.attr(),
    material: _emberData.default.attr(),
    subs: _emberData.default.attr(),
    jobExpense: _emberData.default.attr(),
    postPercent: _emberData.default.attr(),
    realJobId: _emberData.default.attr(),
    job: _emberData.default.belongsTo('job', {
      async: true
    })
  });

  _exports.default = _default;
});