define("client/components/lead-stage-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1AgBDsJB",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"flex layout-row lead-stage-status\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"lead-progress-track\"],[16,\"style\",[26,[\"colorGradient\"]],null],[13],[14],[0,\"\\n\"],[6,[\"each\"],[[28,[\"stages\"]]],null,{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"layout-column flex layout-align-start-center lead-stage\"],[16,\"style\",[33,[\"if\"],[[28,[\"canChange\"]],\"cursor: pointer;\",\"cursor: not-allowed;\"],null],null],[5,[\"action\"],[[28,[null]],\"changeStage\",[28,[\"stage\"]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[33,[\"contains\"],[[28,[\"stage\"]],[28,[\"completedStages\"]]],null]],null,{\"statements\":[[0,\"                \"],[1,[33,[\"paper-icon\"],[\"check_circle\"],[[\"size\"],[36]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[33,[\"contains\"],[[28,[\"stage\"]],[28,[\"incompleteStages\"]]],null]],null,{\"statements\":[[0,\"                \"],[1,[33,[\"paper-icon\"],[\"cancel\"],[[\"size\"],[36]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                \"],[1,[33,[\"paper-icon\"],[\"adjust\"],[[\"size\"],[36]]],false],[0,\"\\n            \"]],\"locals\":[]}]],\"locals\":[]}],[0,\"            \"],[11,\"span\",[]],[15,\"class\",\"under-title\"],[15,\"style\",\"white-space: nowrap; text-overflow: ellipsis; overflow: hidden;\"],[13],[0,\"\\n\"],[6,[\"if\"],[[33,[\"eq\"],[[28,[\"stage\"]],\"Closed\"],null]],null,{\"statements\":[[0,\"                    \"],[1,[28,[\"stage\"]],false],[0,\" - \"],[1,[33,[\"if\"],[[28,[\"lead\",\"converted\"]],\"Won\",\"Lost\"],null],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                    \"],[1,[28,[\"stage\"]],false],[0,\"\\n\"]],\"locals\":[]}],[0,\"            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[\"stage\"]},null],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/lead-stage-status/template.hbs"
    }
  });

  _exports.default = _default;
});