define("client/mixins/printable-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    print: function print() {
      this.$().css("font-size", "12px");
      var width = this.$('.modal-dialog-new').css("width");
      this.$('.modal-dialog-new').css("width", "100%");
      this.$().print();
      Ember.run.later(function () {
        this.$().css("font-size", "14px");
        this.$('.modal-dialog-new').css("width", width);
      }.bind(this), 250);
    }
  });

  _exports.default = _default;
});