define("client/components/paper-input/component", ["exports", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/mixins/child-mixin", "ember-paper/mixins/validation-mixin"], function (_exports, _focusableMixin, _colorMixin, _childMixin, _validationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      $ = Ember.$,
      computed = Ember.computed,
      isEmpty = Ember.isEmpty,
      run = Ember.run,
      assert = Ember.assert;
  /**
   * @class PaperInput
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ChildMixin
   * @uses ColorMixin
   * @uses ValidationMixin
   */

  var _default = Component.extend(_focusableMixin.default, _colorMixin.default, _childMixin.default, _validationMixin.default, {
    tagName: 'md-input-container',
    classNames: ['md-default-theme'],
    classNameBindings: ['hasValue:md-input-has-value', 'isInvalidAndTouched:md-input-invalid', 'hasLeftIcon:md-icon-left', 'hasRightIcon:md-icon-right', 'focused:md-input-focused', 'block:md-block'],
    type: 'text',
    autofocus: false,
    tabindex: null,
    hideAllMessages: false,
    isTouched: false,
    iconComponent: 'paper-icon',
    // override validation mixin `isInvalid` to account for the native input validity
    isInvalid: computed.or('hasErrorMessages', 'isNativeInvalid'),
    hasValue: computed('value', 'isNativeInvalid', function () {
      var value = this.get('value');
      var isNativeInvalid = this.get('isNativeInvalid');
      return !isEmpty(value) || isNativeInvalid;
    }),
    inputElementId: computed('elementId', function () {
      return "input-".concat(this.get('elementId'));
    }),
    renderCharCount: computed('value', function () {
      var currentLength = this.get('value') ? this.get('value').length : 0;
      return "".concat(currentLength, "/").concat(this.get('maxlength'));
    }),
    hasLeftIcon: computed.bool('icon'),
    hasRightIcon: computed.bool('iconRight'),
    isInvalidAndTouched: computed.and('isInvalid', 'isTouched'),
    validationProperty: 'value',
    // property that validations should be run on
    // Lifecycle hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      assert('{{paper-input}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined);
      this.notifyValidityChange();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('textarea')) {
        $(window).on("resize.".concat(this.elementId), run.bind(this, this.growTextarea));
      }

      if (this.get('autofocus')) {
        var inp = this.$('input')[0];

        if (inp) {
          inp.focus();
        }
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments); // setValue below ensures that the input value is the same as this.value


      this.setValue(this.get('value'));
      this.growTextarea();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('textarea')) {
        $(window).off("resize.".concat(this.elementId));
      }
    },
    growTextarea: function growTextarea() {
      if (this.get('textarea')) {
        var inputElement = this.$('input, textarea');
        inputElement.addClass('md-no-flex').attr('rows', 1);
        var minRows = this.get('passThru.rows');
        var height = this.getHeight(inputElement);

        if (minRows) {
          if (!this.lineHeight) {
            inputElement.get(0).style.minHeight = 0;
            this.lineHeight = inputElement.get(0).clientHeight;
            inputElement.get(0).style.minHeight = null;
          }

          if (this.lineHeight) {
            height = Math.max(height, this.lineHeight * minRows);
          }

          var proposedHeight = Math.round(height / this.lineHeight);
          var maxRows = this.get('passThru.maxRows') || Number.MAX_VALUE;
          var rowsToSet = Math.min(proposedHeight, maxRows);
          inputElement.css('height', "".concat(this.lineHeight * rowsToSet, "px")).attr('rows', rowsToSet).toggleClass('md-textarea-scrollable', proposedHeight >= maxRows);
        } else {
          inputElement.css('height', 'auto');
          inputElement.get(0).scrollTop = 0;

          var _height = this.getHeight(inputElement);

          if (_height) {
            inputElement.css('height', "".concat(_height, "px"));
          }
        }

        inputElement.removeClass('md-no-flex');
      }
    },
    getHeight: function getHeight(inputElement) {
      var _inputElement$get = inputElement.get(0),
          offsetHeight = _inputElement$get.offsetHeight;

      var line = inputElement.get(0).scrollHeight - offsetHeight;
      return offsetHeight + (line > 0 ? line : 0);
    },
    setValue: function setValue(value) {
      if (this.$('input, textarea').val() !== value) {
        this.$('input, textarea').val(value);
      }
    },
    actions: {
      handleInput: function handleInput(e) {
        var _this = this;

        this.sendAction('onChange', e.target.value); // setValue below ensures that the input value is the same as this.value

        run.next(function () {
          if (_this.isDestroyed) {
            return;
          }

          _this.setValue(_this.get('value'));
        });
        this.growTextarea();
        var inputElement = this.$('input').get(0);
        this.set('isNativeInvalid', inputElement && inputElement.validity && inputElement.validity.badInput);
        this.notifyValidityChange();
      },
      handleBlur: function handleBlur(e) {
        this.sendAction('onBlur', e);
        this.set('isTouched', true);
        this.notifyValidityChange();
      }
    }
  });

  _exports.default = _default;
});