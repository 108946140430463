define("client/react/administration/product-comparisons", ["exports", "@mui/material", "@mui/styles", "client/react/utilities/styles", "clsx"], function (_exports, _material, _styles, _styles2, _clsx) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _styles.makeStyles)(_objectSpread(_objectSpread({}, _styles2.styles), {}, {
    parentMargin: {
      margin: 16
    }
  }));

  function ProductComparisons() {
    var classes = useStyles();
    return React.createElement("div", {
      className: (0, _clsx.default)("flex", classes.row, classes.alignCenter, classes.parentMargin)
    }, React.createElement(_material.Card, {
      className: "flex50"
    }, React.createElement(_material.CardContent, null, React.createElement("div", {
      className: (0, _clsx.default)(classes.row, classes.alignCenter)
    }, "Coming soon..."))));
  }

  var _default = ProductComparisons;
  _exports.default = _default;
});