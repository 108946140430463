define("client/components/counted-text-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lBFywJMd",
    "block": "{\"statements\":[[1,[33,[\"textarea\"],null,[[\"name\",\"placeholder\",\"value\",\"class\",\"maxlength\",\"disabled\",\"tabindex\"],[\"no-resize\",[28,[\"placeholder\"]],[28,[\"inValue\"]],\"full-height form-control input-sm no-textarea-resize\",[28,[\"maxlength\"]],[28,[\"disabled\"]],[28,[\"tabindex\"]]]]],false],[0,\"\\n\"],[11,\"span\",[]],[13],[0,\"Characters Left: \"],[1,[26,[\"charsLeft\"]],false],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/counted-text-area/template.hbs"
    }
  });

  _exports.default = _default;
});