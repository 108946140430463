define("client/serializers/fixture", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      var key = relationship.key;

      var relationshipType = _emberData.default.RelationshipChange.determineRelationshipType(record.constructor, relationship);

      if (relationshipType === 'manyToNone' || relationshipType === 'manyToMany' || relationshipType === 'manyToOne') {
        json[key] = get(record, key).mapBy('id');
      }
    }
  });

  _exports.default = _default;
});