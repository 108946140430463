define("client/routes/equipment-search", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    actions: {
      view: function view(equipment) {
        this.transitionTo('equipment-view', equipment);
      },
      back: function back() {
        window.history.back();
      },
      edit: function edit(equipment) {
        this.transitionTo('equipment-edit', equipment);
      },
      create: function create(cust, site) {
        var newItem = Ember.Object.create({
          id: null,
          model: "",
          custId: cust && cust.get('id') ? cust.get('id') : "",
          shipToId: site && site.get('id') ? site.get('shipId') : "",
          serial: "",
          description: "",
          notes: "",
          category: "E",
          partsWarrantyExpires: null,
          laborWarrantyExpires: null,
          equipmentCondition: "",
          manufacturer: "",
          installDate: null,
          purchasedFromUs: false,
          dateInService: null,
          lastServiceDate: null,
          contractCoversLabor: false,
          contractCoversParts: false,
          contractNumber: "",
          seerRating: "",
          capacity: "",
          inAttic: false,
          siteAddress: site && site.get('id') ? site : null,
          customer: cust && cust.get('id') ? cust : null
        });
        this.transitionTo('equipment-create', newItem);
      }
    }
  });

  _exports.default = _default;
});