define("client/controllers/dashboard", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer,
      run = Ember.run;

  var _default = Ember.Controller.extend({
    auth: inject.service(),
    dashboard: inject.service(),
    costCode: '',
    techPage: 1,
    limitOptions: Ember.A([5, 10, 15, 20]),
    techLimit: 10,
    toggle: false,
    application: inject.controller(),
    settings: computed.alias('application.tenantSettings'),
    dispatchers: computed('startDate', 'endDate', function () {
      return this.get('dashboard').dispatchers(this.get('startDate'), this.get('endDate'));
    }),
    campaigns: computed('startDate', 'endDate', function () {
      return this.get('dashboard').campaigns(this.get('startDate'), this.get('endDate'));
    }),
    technicians: computed('startDate', 'endDate', 'toggle', function () {
      return this.get('dashboard').technicians(this.get('startDate'), this.get('endDate'), this.get('settings'), this.get('costCode'));
    }),
    estimates: computed('startDate', 'endDate', function () {
      return this.get('dashboard').estimates(this.get('startDate'), this.get('endDate'));
    }),
    trend: computed('startDate', 'endDate', function () {
      return this.get('dashboard').trend(this.get('startDate'), this.get('endDate'));
    }),
    costCodes: computed('startDate', 'endDate', function () {
      return this.get('dashboard').costCodes(this.get('startDate'), this.get('endDate'));
    }),
    toggler: function toggler() {
      this.toggleProperty('toggle');
    },
    searchObserver: observer('costCode', function () {
      run.debounce(this, this.toggler, 500);
    }),
    startDate: null,
    endDate: null,
    campaignConfig: Ember.A(['Name:name', 'Number:phoneNumber:phone', 'Revenue:revenue:money', '# of Calls:count:number']),
    technicianConfig: Ember.A(['Tech ID:employeeNumber', 'Name:name:capitalize', 'Revenue:revenue:money', '# of Calls:count:number', 'Average Sell:averageSell:money', 'Average Travel:averageTravel:toFixed:2', 'GP (%):gp:percent']),
    dispatcherConfig: Ember.A(['Dispatcher:name', '# of Calls:count:number', 'Conversions:conversions:number', 'Sold ($):revenue:money']),
    costCodeConfig: Ember.A(['Cost Center:costCenter', '# of Calls:count:number', 'Total:total:money', 'GP (%):gp:percent']),
    sortedDispatchers: Ember.computed.sort('dispatchers', function (a, b) {
      return b.revenue - a.revenue;
    }),
    sortedTechnicians: Ember.computed.sort('technicians', function (a, b) {
      return b.revenue - a.revenue;
    }),
    sortedCampaigns: Ember.computed.sort('campaigns', function (a, b) {
      return b.revenue - a.revenue;
    }),
    data: null,
    data2: null,
    data3: null,
    actions: {
      add: function add(x, y) {
        return x + y;
      },
      incrementTechPage: function incrementTechPage() {
        var page = this.get('techPage');
        var max = Math.ceil(this.get('technicians._result.length') / this.get('techLimit'));

        if (page < max) {
          this.set('techPage', page + 1);
        }
      },
      decrementTechPage: function decrementTechPage() {
        var page = this.get('techPage');

        if (page > 1) {
          this.set('techPage', page - 1);
        }
      }
    }
  });

  _exports.default = _default;
});