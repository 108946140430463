define("client/components/pie-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'canvas',
    attributeBindings: ['width', 'height', 'style'],
    chart: null,
    didInsertElement: function didInsertElement() {
      var ctx = this.get('element').getContext('2d');
      var data = this.get('data');
      var myNewChart = new Chart(ctx).Pie(data);
      this.set('chart', myNewChart); //document.getElementById(this.get('legendName')).innerHTML = myNewChart.generateLegend();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.get('chart').destroy();
      var ctx = this.get('element').getContext('2d');
      var data = this.get('data');
      var myNewChart = new Chart(ctx).Pie(data);
      this.set('chart', myNewChart);
    }
  });

  _exports.default = _default;
});