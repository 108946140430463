define("client/components/vendor-edit-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isContact: true,
    isLedger: false,
    isTerms: false,
    editName: "",
    editName2: "",
    editContact: "",
    editStreetAddress: "",
    editCity: "",
    editState: "",
    editZip: "",
    editPhone: "",
    editPurchaseName: "",
    editPurchaseName2: "",
    editPurchaseContact: "",
    editPurchaseAddress: "",
    editPurchaseCity: "",
    editPurchaseState: "",
    editPurchaseZip: "",
    editPurchasePhone: "",
    editPrimaryGLCode: "",
    editGlCode1: "",
    editGlCode2: "",
    editGlCode3: "",
    editGlCode4: "",
    editGlCode5: "",
    editGlCode6: "",
    editGlPercent1: 0,
    editGlPercent2: 0,
    editGlPercent3: 0,
    editGlPercent4: 0,
    editGlPercent5: 0,
    editGlPercent6: 0,
    editCashDiscount: 0,
    editDiscountDays: 0,
    editNetDays: 0,
    editAutoPayFlag: false,
    editAutoPayDays: 0,
    editPayAmount: 0,
    editRequires1099: false,
    editId1099: "",
    actions: {
      cancel: function cancel() {
        this.get('onCancel')();
      },
      changeTab: function changeTab(tab) {
        this.set('isContact', false);
        this.set('isLedger', false);

        if (tab === "ledger") {
          this.set('isLedger', true);
        }

        if (tab === "contact") {
          this.set('isContact', true);
        }

        if (tab === "terms") {
          this.set('isLedger', true);
        }
      },
      save: function save() {
        var vendor = this.get('vendor');
        vendor.set('name', this.get('editName'));
        vendor.set('name2', this.get('editName2'));
        vendor.set('contact', this.get('editContact'));
        vendor.set('street', this.get('editStreetAddress'));
        vendor.set('city', this.get('editCity'));
        vendor.set('state', this.get('editState'));
        vendor.set('zip', this.get('editZip'));
        vendor.set('telephone', this.get('editPhone'));
        vendor.set('purchaseName', this.get('editPurchaseName'));
        vendor.set('purchaseName2', this.get('editPurchaseName2'));
        vendor.set('purchaseContact', this.get('editPurchaseContact'));
        vendor.set('purchaseCity', this.get('editPurchaseCity'));
        vendor.set('purchaseAddress', this.get('editPurchaseAddress'));
        vendor.set('purchaseState', this.get('editPurchaseState'));
        vendor.set('purchaseZip', this.get('editPurchaseZip'));
        vendor.set('purchasePhone', this.get('editPurchasePhone'));
        vendor.set('primaryGLCode', this.get('editPrimaryGLCode'));
        vendor.set('glDist1', this.get('editGlCode1'));
        vendor.set('glDist2', this.get('editGlCode2'));
        vendor.set('glDist3', this.get('editGlCode3'));
        vendor.set('glDist4', this.get('editGlCode4'));
        vendor.set('glDist5', this.get('editGlCode5'));
        vendor.set('glDist6', this.get('editGlCode6'));
        vendor.set('glPercent1', this.get('editGlPercent1'));
        vendor.set('glPercent2', this.get('editGlPercent2'));
        vendor.set('glPercent3', this.get('editGlPercent3'));
        vendor.set('glPercent4', this.get('editGlPercent4'));
        vendor.set('glPercent5', this.get('editGlPercent5'));
        vendor.set('glPercent6', this.get('editGlPercent6'));
        vendor.set('cashDiscount', this.get('editCashDiscount'));
        vendor.set('discDays', this.get('editDiscountDays'));
        vendor.set('netDays', this.get('editNetDays'));
        vendor.set('autoPayFlag', this.get('editAutoPayFlag'));
        vendor.set('autoPayAmt', this.get('editPayAmount'));
        vendor.set('autoPayDays', this.get('editAutoPayDays'));
        vendor.set('requires1099', this.get('editRequires1099'));
        vendor.set('id1099', this.get('editId1099'));
        vendor.save();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      var vendor = this.get('vendor');
      this.set('editName', vendor.get('name'));
      this.set('editName2', vendor.get('name2'));
      this.set('editContact', vendor.get('contact'));
      this.set('editStreetAddress', vendor.get('street'));
      this.set('editCity', vendor.get('city'));
      this.set('editState', vendor.get('state'));
      this.set('editZip', vendor.get('zip'));
      this.set('editPhone', vendor.get('telephone'));
      this.set('editPurchaseName', vendor.get('purchaseName'));
      this.set('editPurchaseName2', vendor.get('purchaseName2'));
      this.set('editPurchaseContact', vendor.get('purchaseContact'));
      this.set('editPurchaseCity', vendor.get('purchaseCity'));
      this.set('editPurchaseAddress', vendor.get('purchaseAddress'));
      this.set('editPurchaseState', vendor.get('purchaseState'));
      this.set('editPurchaseZip', vendor.get('purchaseZip'));
      this.set('editPurchasePhone', vendor.get('purchaseTelephone'));
      this.set('editPrimaryGLCode', vendor.get('primaryGLCode'));
      this.set('editGlCode1', vendor.get('glDist1'));
      this.set('editGlCode2', vendor.get('glDist2'));
      this.set('editGlCode3', vendor.get('glDist3'));
      this.set('editGlCode4', vendor.get('glDist4'));
      this.set('editGlCode5', vendor.get('glDist5'));
      this.set('editGlCode6', vendor.get('glDist6'));
      this.set('editGlPercent1', vendor.get('glPercent1'));
      this.set('editGlPercent2', vendor.get('glPercent2'));
      this.set('editGlPercent3', vendor.get('glPercent3'));
      this.set('editGlPercent4', vendor.get('glPercent4'));
      this.set('editGlPercent5', vendor.get('glPercent5'));
      this.set('editGlPercent6', vendor.get('glPercent6'));
      this.set('editCashDiscount', vendor.get('cashDiscount'));
      this.set('editDiscountDays', vendor.get('discDays'));
      this.set('editNetDays', vendor.get('netDays'));
      this.set('editAutoPayDays', vendor.get('autoPayDays'));
      this.set('editAutoPayFlag', vendor.get('autoPayFlag'));
      this.set('editPayAmount', vendor.get('autoPayAmt'));
    }
  });

  _exports.default = _default;
});