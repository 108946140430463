define("client/utils/compute-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computeField;

  function computeField(formula, ft, mt, lt, st) {
    return eval(formula.toLowerCase());
  }
});