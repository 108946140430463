define("client/components/ss-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rvsCiWF/",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"style\",\"align-self: center;\"],[5,[\"action\"],[[28,[null]],\"perform\"]],[13],[0,\"\\n    \"],[11,\"span\",[]],[16,\"class\",[33,[\"if\"],[[28,[\"notification\",\"read\"]],\"\",\"unread\"],null],null],[13],[1,[28,[\"notification\",\"label\"]],false],[14],[0,\"\\n    \"],[11,\"br\",[]],[13],[14],[0,\"\\n    \"],[1,[28,[\"notification\",\"message\"]],false],[0,\"\\n\"],[14],[0,\"\\n\"],[6,[\"paper-button\"],null,[[\"iconButton\",\"onClick\"],[true,[33,[\"perform\"],[[28,[\"notifications\",\"archiveNotification\"]],[28,[\"notification\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[33,[\"paper-icon\"],[\"archive\"],null],false],[0,\"\\n\"]],\"locals\":[]},null],[1,[33,[\"render-notifier\"],null,[[\"onDestroy\"],[[33,[\"perform\"],[[28,[\"notifications\",\"seeNotification\"]],[28,[\"notification\"]]],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/ss-notification/template.hbs"
    }
  });

  _exports.default = _default;
});