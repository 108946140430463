define("client/components/ap-bank-reconciliation/component", ["exports", "client/components/react-component/component", "client/react/ap/bank-reconciliation"], function (_exports, _component, _bankReconciliation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['layout-column', 'flex', 'height-100'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var banks = this.get('banks').map(function (bank) {
        return JSON.parse(JSON.stringify(bank));
      });
      this.reactRender(React.createElement(_bankReconciliation.default, {
        banks: banks
      }));
    }
  });

  _exports.default = _default;
});