define("client/components/viewer-js/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gu04N555",
    "block": "{\"statements\":[[11,\"img\",[]],[16,\"id\",[33,[\"concat\"],[\"viewerjs-\",[28,[\"elementId\"]]],null],null],[16,\"src\",[26,[\"computedUrl\"]],null],[15,\"style\",\"height: 100%; width: auto;\"],[15,\"alt\",\"Picture\"],[13],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/viewer-js/template.hbs"
    }
  });

  _exports.default = _default;
});