define("client/components/polymer-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OxJnHkCL",
    "block": "{\"statements\":[[9,\"paper-tabs\",{\"attrs\":[[16,\"class\",[26,[\"class\"]],null],[16,\"id\",[26,[\"elementId\"]],null],[16,\"selected\",[34,[[26,[\"selected\"]]]]]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n\"],[6,[\"each\"],[[28,[\"children\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[9,\"paper-tab\",{\"attrs\":[],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,[\"tab\",\"label\"]],false],[0,\"\\n\\t\\t\"]]}],[0,\"\\n\"]],\"locals\":[\"tab\"]},null]]}],[0,\"\\n\"],[6,[\"if\"],[[28,[\"wormholeId\"]]],null,{\"statements\":[[6,[\"ember-wormhole\"],null,[[\"to\"],[[28,[\"wormholeId\"]]]],{\"statements\":[[0,\"\\t\\t\"],[11,\"div\",[]],[16,\"id\",[34,[\"1\",[26,[\"elementId\"]]]]],[16,\"selected\",[34,[[26,[\"selected\"]]]]],[16,\"entry-animation\",[34,[[26,[\"entryAnimation\"]]]]],[16,\"exit-animation\",[34,[[26,[\"exitAnimation\"]]]]],[13],[0,\"\\n\\t\\t\\t\"],[18,\"default\",[[33,[\"hash\"],null,[[\"tab\"],[[33,[\"component\"],[\"polymer-tab\"],[[\"parent\"],[[28,[null]]]]]]]]]],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},{\"statements\":[[0,\"\\t\"],[11,\"div\",[]],[16,\"id\",[34,[\"1\",[26,[\"elementId\"]]]]],[16,\"selected\",[34,[[26,[\"selected\"]]]]],[16,\"entry-animation\",[34,[[26,[\"entryAnimation\"]]]]],[16,\"exit-animation\",[34,[[26,[\"exitAnimation\"]]]]],[13],[0,\"\\n\\t\\t\"],[18,\"default\",[[33,[\"hash\"],null,[[\"tab\"],[[33,[\"component\"],[\"polymer-tab\"],[[\"parent\"],[[28,[null]]]]]]]]]],[0,\"\\n\\t\"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/polymer-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});