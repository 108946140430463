define("client/components/required-date-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bvkz0+ad",
    "block": "{\"statements\":[[1,[33,[\"input\"],null,[[\"type\",\"value\",\"class\",\"disabled\"],[\"date\",[28,[\"inValue\"]],\"form-control\",[28,[\"disabled\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/required-date-input/template.hbs"
    }
  });

  _exports.default = _default;
});