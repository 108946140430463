define("client/routes/purchase-journal", ["exports", "client/config/environment", "client/mixins/authenticated-route"], function (_exports, _environment, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model(params) {
      return Ember.RSVP.hash({
        vendor: this.store.findRecord('vendor', params.id, {
          reload: true
        }),
        jobCats: this.store.findAll('job-category', {
          reload: true
        }),
        payTerm: this.store.findAll('pay-term', {
          reload: true
        }),
        ledgerAccounts: this.store.findAll('ledger-account', {
          reload: true
        })
      });
    },
    actions: {
      toVendor: function toVendor() {
        this.transitionTo('vendor-view', this.get('controller.model.vendor'));
      }
    }
  });

  _exports.default = _default;
});