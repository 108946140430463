define("client/routes/examine-invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('invoice', params.invoice_id, {
        reload: true
      });
    },
    actions: {
      close: function close() {
        this.transitionTo('invoicing-search');
      },
      toCreate: function toCreate() {
        this.transitionTo('invoicing');
      },
      error: function error(_error, transition) {
        this.replaceWith('not-found');
      }
    }
  });

  _exports.default = _default;
});