define("client/models/payroll-run", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    date: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    directDepositTotal: _emberData.default.attr(),
    checkTotal: _emberData.default.attr(),
    dryRun: _emberData.default.attr()
  });

  _exports.default = _default;
});