define("client/components/list-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['panel', 'panel-default'],
    attributeBindings: ['style'],
    style: "height: 65%",
    actions: {
      add: function add() {
        this.get('arr').addObject(this.get('value'));
      },
      remove: function remove(item) {
        this.get('arr').removeObject(item);
      },
      saveSettings: function saveSettings() {
        this.sendAction();
      }
    }
  });

  _exports.default = _default;
});