define("client/extensions/servisuite-native/content-script", [], function () {
  "use strict";

  document.documentElement.setAttribute('extension-installed', true);
  document.addEventListener("servisuite-get-stream", function (data) {
    var extensionId = chrome.runtime.id; //localStorage.extensionId;

    var request = JSON.parse(localStorage.request);
    chrome.runtime.sendMessage(request, function (response) {
      switch (response && response.type) {
        case 'success':
          // send back streamId
          localStorage.streamId = response.streamId;
          var event = document.createEvent('Event');
          event.initEvent('servisuite-return-stream');
          document.dispatchEvent(event);
          break;

        case 'error':
          var event = document.createEvent('Event');
          event.initEvent('servisuite-error');
          document.dispatchEvent(event);
          break;

        default:
          break;
      }
    });
  });
  document.addEventListener("servisuite-native-message", function (data) {
    var extensionId = chrome.runtime.id; //localStorage.extensionId;

    var request = JSON.parse(localStorage.request);
    chrome.runtime.sendMessage(request, function (response) {
      switch (response && response.type) {
        case 'success':
          break;

        case 'error':
          var event = document.createEvent('Event');
          event.initEvent('servisuite-error');
          document.dispatchEvent(event);
          break;

        default:
          break;
      }
    });
  });
});