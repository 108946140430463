define("client/models/purchase-order-history-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    prodId: _emberData.default.attr(),
    poNumber: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    lineNumber: _emberData.default.attr(),
    poDate: _emberData.default.attr(),
    dueDate: _emberData.default.attr(),
    dateReceived: _emberData.default.attr(),
    qty: _emberData.default.attr(),
    description: _emberData.default.attr(),
    acknowledged: _emberData.default.attr(),
    unitCost: _emberData.default.attr(),
    uom: _emberData.default.attr(),
    qtyReceived: _emberData.default.attr(),
    qtyBackOrdered: _emberData.default.attr(),
    freight: _emberData.default.attr(),
    po: _emberData.default.belongsTo('purchase-order-history', {
      async: true
    })
  });

  _exports.default = _default;
});