define("client/routes/ar-cust-maintenance", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      return Ember.RSVP.hash({
        settings: this.store.findRecord('ar-setting', 1, {
          reload: true
        }),
        ledgerAccounts: this.store.findAll('ledger-account', {
          reload: true
        }),
        banks: this.store.query('bank', {
          key: true
        })
      });
    }
  });

  _exports.default = _default;
});