define("client/react/components/labeled-text", ["exports", "@mui/material"], function (_exports, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function LabeledText(_ref) {
    var label = _ref.label,
        text = _ref.text,
        className = _ref.className,
        onClick = _ref.onClick;
    return React.createElement("div", {
      onClick: onClick,
      style: onClick ? {
        cursor: 'pointer'
      } : {},
      className: className
    }, React.createElement(_material.Typography, {
      variant: "overline"
    }, label), React.createElement(_material.Typography, {
      paragraph: true,
      variant: "h6"
    }, text));
  }

  var _default = LabeledText;
  _exports.default = _default;
});