define("client/models/payroll-check-register", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr(),
    employeeId: _emberData.default.attr(),
    name: _emberData.default.attr(),
    glCode: _emberData.default.attr(),
    checkDate: _emberData.default.attr(),
    checkNumber: _emberData.default.attr(),
    earningAmt01: _emberData.default.attr(),
    earningAmt02: _emberData.default.attr(),
    earningAmt03: _emberData.default.attr(),
    earningAmt04: _emberData.default.attr(),
    earningAmt05: _emberData.default.attr(),
    earningAmt06: _emberData.default.attr(),
    earningAmt07: _emberData.default.attr(),
    earningAmt08: _emberData.default.attr(),
    earningAmt09: _emberData.default.attr(),
    earningAmt10: _emberData.default.attr(),
    deductionAmt01: _emberData.default.attr(),
    deductionAmt02: _emberData.default.attr(),
    deductionAmt03: _emberData.default.attr(),
    deductionAmt04: _emberData.default.attr(),
    deductionAmt05: _emberData.default.attr(),
    deductionAmt06: _emberData.default.attr(),
    deductionAmt07: _emberData.default.attr(),
    deductionAmt08: _emberData.default.attr(),
    deductionAmt09: _emberData.default.attr(),
    deductionAmt10: _emberData.default.attr(),
    deductionAmt11: _emberData.default.attr(),
    deductionAmt12: _emberData.default.attr(),
    deductionAmt13: _emberData.default.attr(),
    deductionAmt14: _emberData.default.attr(),
    deductionAmt15: _emberData.default.attr(),
    netAmt: _emberData.default.attr(),
    glCode1: _emberData.default.attr(),
    glAmount1: _emberData.default.attr(),
    glPercent1: _emberData.default.attr(),
    glCode2: _emberData.default.attr(),
    glAmount2: _emberData.default.attr(),
    glPercent2: _emberData.default.attr(),
    glCode3: _emberData.default.attr(),
    glAmount3: _emberData.default.attr(),
    glPercent3: _emberData.default.attr(),
    glCode4: _emberData.default.attr(),
    glAmount4: _emberData.default.attr(),
    glPercent4: _emberData.default.attr(),
    glCode5: _emberData.default.attr(),
    glAmount5: _emberData.default.attr(),
    glPercent5: _emberData.default.attr(),
    glCode6: _emberData.default.attr(),
    glAmount6: _emberData.default.attr(),
    glPercent6: _emberData.default.attr(),
    division: _emberData.default.attr(),
    directDepositFlag: _emberData.default.attr(),
    eic: _emberData.default.attr(),
    eicFlag: _emberData.default.attr(),
    cashGl: _emberData.default.attr(),
    historyEntry: _emberData.default.belongsTo('payroll-history', {
      async: true
    }),
    label: Ember.computed('checkDate', 'checkNumber', function () {
      return this.get('checkNumber').trim() + ' - ' + this.get('checkDate') + ' - $' + this.get('netAmt');
    })
  });

  _exports.default = _default;
});