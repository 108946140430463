define("client/helpers/space-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.spaceHelper = spaceHelper;

  function spaceHelper(params
  /*, hash*/
  ) {
    var check = params[0];
    return check ? check : new Ember.String.htmlSafe("&nbsp;");
  }

  var _default = Ember.Helper.helper(spaceHelper);

  _exports.default = _default;
});