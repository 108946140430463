define("client/helpers/job-config-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.jobConfigFlag = jobConfigFlag;

  function jobConfigFlag(params
  /*, hash*/
  ) {
    var cf = params[0].findBy('seq', params[1]);
    var flag = cf ? cf.get('flag') : false;
    return flag;
  }

  var _default = Ember.Helper.helper(jobConfigFlag);

  _exports.default = _default;
});