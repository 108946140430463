define("client/templates/order-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t0SjioAo",
    "block": "{\"statements\":[[1,[26,[\"order-entry\"]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/order-entry.hbs"
    }
  });

  _exports.default = _default;
});