define("client/models/tag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    tenant: _emberData.default.attr(),
    tagDefinition: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    site: _emberData.default.belongsTo('site-address', {
      async: true
    }),
    job: _emberData.default.belongsTo('job', {
      async: true
    }),
    workOrder: _emberData.default.belongsTo('work-order', {
      async: true
    }),
    lead: _emberData.default.belongsTo('lead', {
      async: true
    }),
    communication: _emberData.default.belongsTo('communication', {
      async: true
    }),
    equipment: _emberData.default.belongsTo('equipment-item', {
      async: true
    }),
    contract: _emberData.default.belongsTo('contract', {
      async: true
    }),
    employee: _emberData.default.belongsTo('employee', {
      async: true
    }),
    invoice: _emberData.default.belongsTo('invoice', {
      async: true
    }),
    vendor: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    purchaseOrder: _emberData.default.belongsTo('purchase-order', {
      async: true
    }),
    inventoryItem: _emberData.default.belongsTo('part-item', {
      async: true
    }),
    contractSolicitation: _emberData.default.belongsTo('contract-solicitation', {
      async: true
    }),
    // task: DS.belongsTo('task', { async: true }), // TODO: Another stage
    serviceEstimate: _emberData.default.belongsTo('service-estimate', {
      async: true
    }),
    attachment: _emberData.default.belongsTo('attachment', {
      async: true
    })
  });

  _exports.default = _default;
});