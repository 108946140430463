define("client/react/components/typed/spacer", ["exports", "react/jsx-runtime"], function (_exports, _jsxRuntime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function Spacer(_ref) {
    var width = _ref.width,
        height = _ref.height,
        vertical = _ref.vertical,
        doubleDefault = _ref.doubleDefault;
    return (0, _jsxRuntime.jsx)("span", {
      style: {
        display: height || vertical ? 'block' : 'inline-block',
        width: width ? width : 12 * (doubleDefault ? 2 : 1),
        height: height ? height : 12 * (doubleDefault ? 2 : 1)
      }
    });
  }

  var _default = Spacer;
  _exports.default = _default;
});