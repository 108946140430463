define("client/react/components/typed/clickable-text", ["exports", "react/jsx-runtime", "@mui/material", "tss-react/mui", "client/react/components/styles/styles", "client/react/components/general", "react-router-dom"], function (_exports, _jsxRuntime, _material, _mui, _styles, _general, _reactRouterDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var useStyles = (0, _mui.makeStyles)()(function (theme) {
    return Object.assign(Object.assign({}, _styles.styles), {
      linkEnabled: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
      }
    });
  });

  function ClickableText(_ref) {
    var className = _ref.className,
        textProps = _ref.textProps,
        _onClick = _ref.onClick,
        toRouteOnClick = _ref.toRouteOnClick,
        children = _ref.children;
    (0, _general.assert)(!!_onClick || !!toRouteOnClick, "You must include either onClick or toRouteOnClick in the props of ClickableText");

    var _useStyles = useStyles(),
        cx = _useStyles.cx,
        classes = _useStyles.classes;

    var navigate = (0, _reactRouterDom.useNavigate)();
    return (0, _jsxRuntime.jsx)(_material.Typography, Object.assign({}, textProps, {
      className: cx(classes.linkEnabled, className),
      onClick: function onClick(e) {
        if (_onClick) {
          _onClick(e);
        }

        if (toRouteOnClick) {
          if (toRouteOnClick.newTab) {
            (0, _general.openInNewTab)(toRouteOnClick.to);
          } else {
            navigate(toRouteOnClick.to, toRouteOnClick.options);
          }
        }
      }
    }, {
      children: children
    }));
  }

  var _default = ClickableText;
  _exports.default = _default;
});