define("client/react/utilities/animations", ["exports", "react-transition-group", "@mui/material"], function (_exports, _reactTransitionGroup, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RotateTransitionIcons = RotateTransitionIcons;

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DURATION = 100;
  var defaultStyle1 = {
    transition: "opacity ".concat(DURATION, "ms linear, transform ").concat(DURATION, "ms linear")
  };
  var transitionStyles1 = {
    entering: {
      opacity: 0,
      transform: "rotate(-90deg)"
    },
    entered: {
      opacity: 0,
      transform: "rotate(-90deg)"
    },
    exiting: {
      opacity: 1
    },
    exited: {
      opacity: 1
    }
  };
  var defaultStyle2 = {
    transition: "opacity ".concat(DURATION, "ms linear, transform ").concat(DURATION, "ms linear"),
    marginLeft: -24
  };
  var transitionStyles2 = {
    entering: {
      opacity: 1
    },
    entered: {
      opacity: 1
    },
    exiting: {
      opacity: 0,
      transform: "rotate(90deg)"
    },
    exited: {
      opacity: 0,
      transform: "rotate(90deg)"
    }
  };

  function RotateTransitionIcons(props) {
    var inProp = props.inProp,
        Icon1 = props.Icon1,
        Icon1Props = props.Icon1Props,
        Icon2 = props.Icon2,
        Icon2Props = props.Icon2Props;
    return React.createElement(_reactTransitionGroup.Transition, {
      "in": inProp,
      timeout: DURATION
    }, function (state) {
      return [React.createElement(_material.SvgIcon, _extends({
        key: "icon1",
        style: _objectSpread(_objectSpread({}, defaultStyle1), transitionStyles1[state])
      }, Icon1Props, {
        component: Icon1
      })), React.createElement(_material.SvgIcon, _extends({
        key: "icon2",
        style: _objectSpread(_objectSpread({}, defaultStyle2), transitionStyles2[state])
      }, Icon2Props, {
        component: Icon2
      }))];
    });
  }
});