define("client/transforms/priority", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) return serialized;
      var t = serialized.charCodeAt(0) - 48;

      switch (t) {
        case 662:
          return 88;

        case 8172:
          return 99;

        default:
          return t.toString();
      }
    },
    serialize: function serialize(deserialized) {
      return String.fromCharCode(Number(deserialized) + 48);
    }
  });

  _exports.default = _default;
});