define("client/components/ap-tran-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    errorMessage: '',
    tagName: '',
    currentPeriod: true,
    previousPeriod: false,
    actions: {
      useless: function useless() {
        this.set('data.jobId', this.get('jobId.jobId') ? this.get('jobId.jobId') : '');
      }
    }
  });

  _exports.default = _default;
});