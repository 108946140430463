define("client/components/line-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'canvas',
    attributeBindings: ['width', 'height', 'style'],
    didInsertElement: function didInsertElement() {
      var ctx = this.get('element').getContext('2d');
      var data = this.get('data');

      window.moneyHelper = function (params
      /*, hash*/
      ) {
        var input = parseFloat(params);
        if (input !== 0 && !input) return "$0.00";

        function CommaFormatted(amount) {
          var delimiter = ","; // replace comma if desired

          var a = amount.split('.', 2);
          var d = a[1];
          var i = parseInt(a[0]);

          if (isNaN(i)) {
            return '';
          }

          var minus = '';

          if (i < 0) {
            minus = '-';
          }

          i = Math.abs(i);
          var n = new String(i);
          var a = [];

          while (n.length > 3) {
            var nn = n.substr(n.length - 3);
            a.unshift(nn);
            n = n.substr(0, n.length - 3);
          }

          if (n.length > 0) {
            a.unshift(n);
          }

          n = a.join(delimiter);

          if (d.length < 1) {
            amount = n;
          } else {
            amount = n + '.' + d;
          }

          amount = minus + amount;
          return amount;
        }

        return "$" + CommaFormatted(input.toFixed(2));
      };

      var myNewChart = new Chart(ctx).Line(data, {
        maintainAspectRatio: false,
        responsive: true,
        scaleLabel: "<%=window.moneyHelper(value)%>",
        tooltipTemplate: "<%if (label){%><%=label%>: <%}%><%= window.moneyHelper(value) %>"
      }); //document.getElementById(this.get('legendName')).innerHTML = myNewChart.generateLegend();
    }
  });

  _exports.default = _default;
});