define("client/components/dropdown-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showDropdown: false,
    willDestroy: function willDestroy() {
      Ember.$(document).unbind('click');
    },
    didInsertElement: function didInsertElement() {
      Ember.$(document).click(function () {
        this.set('showDropdown', false);
      }.bind(this));
    },
    actions: {
      show: function show() {
        this.set('showDropdown', true);
      }
    }
  });

  _exports.default = _default;
});