define("client/models/product-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ProdCat = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    coverImage: _emberData.default.attr(),
    parentCategory: _emberData.default.attr(),
    items: _emberData.default.hasMany('product-item', {
      async: true
    })
  });

  var _default = ProdCat;
  _exports.default = _default;
});