define("client/components/autocomplete-clear/component", ["exports", "ember-paper/components/paper-autocomplete"], function (_exports, _paperAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperAutocomplete.default.extend({
    triggerComponent: "autocomplete-clear-trigger",
    actions: {
      onBlur: function onBlur() {
        this.send('deactivate');
        var action = this.get('onblur');

        if (action) {
          action(this.get('publicAPI'), event);
        }

        this.notifyValidityChange();
        Ember.run.later(this, function () {
          this.get('publicAPI.options').clear();
          this.get('publicAPI.results').clear();
        }, 100);
      }
    }
  });

  _exports.default = _default;
});