define("client/react/components/alt/form-radio-group", ["exports", "@mui/material", "client/react/utilities/use-form"], function (_exports, _material, _useForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function FormRadioGroup(_ref) {
    var label = _ref.label,
        formProps = _ref.formProps,
        _onChange = _ref.onChange,
        className = _ref.className,
        classes = _ref.classes,
        isRow = _ref.isRow,
        children = _ref.children;
    var name = formProps.name,
        control = formProps.control;

    var _useController = (0, _useForm.useController)({
      name: name,
      control: control
    }),
        field = _useController.field;

    return React.createElement(_material.FormControl, {
      className: className,
      component: "fieldset"
    }, React.createElement(_material.FormLabel, {
      className: classes === null || classes === void 0 ? void 0 : classes.label,
      component: "legend"
    }, label), React.createElement(_material.RadioGroup, {
      row: isRow,
      name: name,
      value: field.value,
      onChange: function onChange(e) {
        field.onChange(e.target.value);

        if (_onChange) {
          _onChange(e);
        }
      }
    }, children));
  }

  var _default = FormRadioGroup;
  _exports.default = _default;
});