define("client/models/sales-tax", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    jurisdiction: _emberData.default.attr('capitalize'),
    rate: _emberData.default.attr('currency'),
    label: Ember.computed('jurisdiction', 'rate', function () {
      return this.get('id') + ' - ' + this.get('jurisdiction') + ' - ' + this.get('rate') + '%';
    })
  });

  _exports.default = _default;
});