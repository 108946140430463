define("client/components/landing-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      selectElement: function selectElement(title) {
        if (title) {
          this.set('elementSelected', title);
        } else {
          this.set('elementSelected', null);
        }
      }
    }
  });

  _exports.default = _default;
});