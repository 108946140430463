define("client/components/technician-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+vzNTW7M",
    "block": "{\"statements\":[[11,\"p\",[]],[15,\"style\",\"max-width: 100%; text-overflow: ellipsis; overflow: hidden;\"],[13],[0,\"\\n\\tTechnician \"],[1,[28,[\"technician\",\"employeeNumber\"]],false],[11,\"br\",[]],[13],[14],[0,\"\\n\\t\"],[1,[33,[\"capitalize\"],[[33,[\"name-flip\"],[[28,[\"technician\",\"name\"]]],null]],null],false],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"label\",[]],[15,\"class\",\"md-badge\"],[15,\"style\",\"margin-right: 4px;\"],[13],[0,\"\\n\\t\"],[6,[\"paper-tooltip\"],null,null,{\"statements\":[[0,\"Number of Work Orders on Technician\"]],\"locals\":[]},null],[0,\"\\n\\t\"],[1,[26,[\"count\"]],false],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/technician-block/template.hbs"
    }
  });

  _exports.default = _default;
});