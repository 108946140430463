define("client/models/customer-history-item", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    custId: _emberData.default.attr(),
    date: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr('capitalize'),
    amount: _emberData.default.attr(),
    jobNumber: _emberData.default.attr(),
    notes: _emberData.default.attr('capitalize'),
    source: _emberData.default.attr(),
    retainage: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    })
  });

  _exports.default = _default;
});