define("client/models/work-order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var WorkOrder = _emberData.default.Model.extend({
    assigned: _emberData.default.attr(),
    assignedDate: _emberData.default.attr('date2'),
    tech: _emberData.default.belongsTo('technician', {
      async: true
    }),
    time: _emberData.default.attr('better-time'),
    //
    rows: _emberData.default.attr(),
    assignedSite: _emberData.default.belongsTo('site-address', {
      async: true,
      inverse: null
    }),
    customer: _emberData.default.belongsTo('customer', {
      async: true,
      inverse: null
    }),
    workOrderInfo: _emberData.default.hasMany('work-order-info', {
      async: true
    }),
    workOrderParts: _emberData.default.hasMany('work-order-part', {
      async: true
    }),
    workOrderLabor: _emberData.default.hasMany('work-order-labor', {
      async: true
    }),
    workOrderFlatRates: _emberData.default.hasMany('work-order-flrate', {
      async: true
    }),
    flrateParts: _emberData.default.hasMany('flat-rate-part-usage', {
      async: true
    }),
    estimates: _emberData.default.hasMany('service-estimate', {
      async: true
    }),
    status: _emberData.default.belongsTo('work-order-status', {
      async: true
    }),
    //
    joinedStatus: _emberData.default.attr(),
    code: _emberData.default.attr(),
    //
    priority: _emberData.default.attr('priority'),
    //
    isATS: _emberData.default.attr(),
    shipToId: _emberData.default.attr(),
    siteName: _emberData.default.attr('capitalize'),
    siteName2: _emberData.default.attr('capitalize'),
    siteAddress: _emberData.default.attr('capitalize'),
    siteStreetAddress: Ember.computed.alias('siteAddress'),
    siteCity: _emberData.default.attr('capitalize'),
    siteState: _emberData.default.attr('capitalize'),
    siteZip: _emberData.default.attr('capitalize'),
    siteContact: _emberData.default.attr('capitalize'),
    sitePhone: _emberData.default.attr('telephone'),
    sitePhoneExt: _emberData.default.attr(),
    srvcOrdId: _emberData.default.attr(),
    //
    custId: _emberData.default.attr(),
    custName: _emberData.default.attr('capitalize'),
    custContact: _emberData.default.attr('capitalize'),
    custPhone: _emberData.default.attr('telephone'),
    custPhoneExt: _emberData.default.attr(),
    date: _emberData.default.attr(),
    //
    dateRequested: _emberData.default.attr(),
    //
    dateCompleted: _emberData.default.attr(),
    //
    timeRequested: _emberData.default.attr('better-time'),
    //
    timeAssigned: _emberData.default.attr('better-time'),
    //
    timeCompleted: _emberData.default.attr('better-time'),
    //
    redo: _emberData.default.attr('capitalize'),
    location: _emberData.default.attr('capitalize'),
    operator: _emberData.default.attr('capitalize'),
    //
    technician: _emberData.default.attr(),
    //
    notes: _emberData.default.attr(),
    salesTaxJuris: _emberData.default.attr('capitalize'),
    salesTaxRate: _emberData.default.attr('currency'),
    //
    totBillable: _emberData.default.attr('currency'),
    //
    totNonBillable: _emberData.default.attr('currency'),
    //
    totFreight: _emberData.default.attr('currency'),
    //
    totNonBillableFreight: _emberData.default.attr('currency'),
    //
    salesTax: _emberData.default.attr('currency'),
    //
    salesTaxFlag: _emberData.default.attr(),
    //
    dateForFollowup: _emberData.default.attr('capitalize'),
    tech2: _emberData.default.belongsTo('technician', {
      async: true
    }),
    //
    srvcType: _emberData.default.attr('capitalize'),
    //
    contractId: _emberData.default.attr('capitalize'),
    //
    poNumber: _emberData.default.attr('capitalize'),
    //
    slm: _emberData.default.attr('capitalize'),
    //
    srvcDue: _emberData.default.attr(),
    discPerCent: _emberData.default.attr('currency'),
    //
    discount: _emberData.default.attr('currency'),
    //
    discountFlag: _emberData.default.attr(),
    warehouse: _emberData.default.attr(),
    //
    custAddress: _emberData.default.attr(),
    confirmed: _emberData.default.attr(),
    calledNotConfirmed: _emberData.default.attr(),
    called: _emberData.default.attr(),
    createdFromMobile: _emberData.default.attr(),
    hasAttachments: _emberData.default.attr(),
    thirdPartyId: _emberData.default.attr(),
    jobId: _emberData.default.attr(),
    archived: _emberData.default.attr(),
    images: _emberData.default.hasMany('image'),
    custHomePhone: _emberData.default.attr('telephone'),
    custCellPhone: _emberData.default.attr('telephone'),
    custAltPhone: _emberData.default.attr('telephone'),
    siteHomePhone: _emberData.default.attr('telephone'),
    siteCellPhone: _emberData.default.attr('telephone'),
    siteAltPhone: _emberData.default.attr('telephone'),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    lead: _emberData.default.belongsTo('lead', {
      async: true
    }),
    communications: _emberData.default.hasMany('communication', {
      async: true
    }),
    checkupMaterial: _emberData.default.hasMany('checkupMaterial', {
      async: true
    }),
    label: Ember.computed('srvcOrdId', 'custName', 'assignedDate', function () {
      return "".concat(this.get('srvcOrdId'), " - ").concat(this.get('custName'), " - ").concat(moment(this.get('assignedDate')).format('MM/DD/YYYY'));
    }),
    plainSitePhone: Ember.computed('sitePhone', function () {
      return this.get('sitePhone') ? this.get('sitePhone').replace(/[-()]/g, "") : '';
    }),
    plainCustPhone: Ember.computed('custPhone', function () {
      return this.get('custPhone') ? this.get('custPhone').replace(/[-()]/g, "") : '';
    })
  });

  var _default = WorkOrder;
  _exports.default = _default;
});