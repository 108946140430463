define("client/models/purchase-journal-transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr(),
    date: _emberData.default.attr(),
    glCode: _emberData.default.attr(),
    glDescription: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    jobId: _emberData.default.attr(),
    catId: _emberData.default.attr(),
    code: _emberData.default.attr(),
    overhead: _emberData.default.attr(),
    qty: _emberData.default.attr(),
    paidInvoice: _emberData.default.belongsTo('paid-pj-invoice', {
      async: true
    }),
    apTran: _emberData.default.belongsTo('ap-transaction', {
      async: true
    })
  });

  _exports.default = _default;
});