define("client/models/tag-definition", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    tenant: _emberData.default.attr(),
    title: _emberData.default.attr(),
    color: _emberData.default.attr(),
    allowCustomer: _emberData.default.attr(),
    allowSite: _emberData.default.attr(),
    allowJob: _emberData.default.attr(),
    allowWorkOrder: _emberData.default.attr(),
    allowLead: _emberData.default.attr(),
    allowCommunication: _emberData.default.attr(),
    allowEquipment: _emberData.default.attr(),
    allowContract: _emberData.default.attr(),
    allowEmployee: _emberData.default.attr(),
    allowInvoice: _emberData.default.attr(),
    allowVendor: _emberData.default.attr(),
    allowPurchaseOrder: _emberData.default.attr(),
    allowInventoryItem: _emberData.default.attr(),
    allowContractSolicitation: _emberData.default.attr(),
    allowTask: _emberData.default.attr(),
    allowServiceEstimate: _emberData.default.attr(),
    allowAttachment: _emberData.default.attr()
  });

  _exports.default = _default;
});