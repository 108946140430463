define("client/models/ledger-setting", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    month: _emberData.default.attr(),
    year: _emberData.default.attr(),
    ytdProfitAcc: _emberData.default.attr(),
    retainageAcc: _emberData.default.attr(),
    fiscalYearEnd: _emberData.default.attr(),
    documentNumber: _emberData.default.attr(),
    grossProfitCategory: _emberData.default.attr(),
    printDocumentsFlag: _emberData.default.attr(),
    key: _emberData.default.attr()
  });

  _exports.default = _default;
});