define("client/react/utilities/bank-select-dialog", ["exports", "@mui/material", "@mui/styles", "react", "react-redux", "client/react/utilities/styles", "@mui/material/useAutocomplete", "client/react/data/reducer"], function (_exports, _material, _styles, _react, _reactRedux, _styles2, _useAutocomplete, _reducer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _styles.makeStyles)(function (theme) {
    return _objectSpread({}, _styles2.styles);
  });

  function BankSelectDialog(_ref) {
    var onSelect = _ref.onSelect,
        onClose = _ref.onClose;
    var classes = useStyles();
    var dispatch = (0, _reactRedux.useDispatch)();
    var banks = (0, _reactRedux.useSelector)(function (state) {
      return Object.values(state.data.bank.byId);
    });

    var _useState = (0, _react.useState)(null),
        _useState2 = _slicedToArray(_useState, 2),
        selectedBank = _useState2[0],
        setSelectedBank = _useState2[1];

    (0, _react.useEffect)(function () {
      dispatch((0, _reducer.fetchModels)('bank', {}));
    }, []);
    return React.createElement(_material.Dialog, {
      open: true
    }, React.createElement(_material.DialogTitle, null, "Select a Bank"), React.createElement(_material.DialogContent, null, React.createElement(_material.Autocomplete, {
      options: banks,
      value: selectedBank,
      style: {
        width: 350
      },
      getOptionLabel: function getOptionLabel(option) {
        return option.name;
      },
      renderInput: function renderInput(params) {
        return React.createElement(_material.TextField, _extends({}, params, {
          label: "Bank"
        }));
      },
      onChange: function onChange(_, value) {
        return setSelectedBank(value);
      }
    })), React.createElement(_material.DialogActions, {
      className: classes.layoutAlignEnd
    }, React.createElement(_material.Button, {
      color: "inherit",
      onClick: function onClick() {
        return onClose();
      }
    }, "Close"), React.createElement(_material.Button, {
      color: "primary",
      onClick: function onClick() {
        return onSelect(selectedBank);
      }
    }, "Select")));
  }

  var _default = BankSelectDialog;
  _exports.default = _default;
});