define("client/react/utilities/typed/general", ["exports", "react", "react-router-dom", "lodash"], function (_exports, _react, _reactRouterDom, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assert = assert;
  _exports.getToolbarElement = getToolbarElement;
  _exports.openInNewTab = openInNewTab;
  _exports.sortBy = sortBy;
  _exports.sortInPlace = sortInPlace;
  _exports.timeout = timeout;
  _exports.useQuery = useQuery;
  _exports.uuidv4 = uuidv4;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  function type(type) {
    return function (arg) {
      return _typeof(arg) === type;
    };
  }
  /**
   * Return a comparator function
   * @param  {String} property The key to sort by
   * @param  {Function} map Function to apply to each property
   * @return {Function}        Returns the comparator function
   */


  function sort(property, map) {
    var sortOrder = 1;

    var apply = map || function (_, value) {
      return value;
    };

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substring(1);
    }

    return function fn(a, b) {
      var result = 0;
      var am = apply(property, _lodash.default.get(a, property));
      var bm = apply(property, _lodash.default.get(b, property));
      if (am < bm) result = -1;
      if (am > bm) result = 1;
      return result * sortOrder;
    };
  }
  /**
   * Return a comparator function that sorts by multiple keys
   * @return {Function} Returns the comparator function
   */


  function sortBy() {
    var args = Array.prototype.slice.call(arguments);
    var properties = args.filter(type('string'));
    var map = args.filter(type('function'))[0];
    return function fn(obj1, obj2) {
      var numberOfProperties = properties.length,
          result = 0,
          i = 0;
      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */

      while (result === 0 && i < numberOfProperties) {
        result = sort(properties[i], map)(obj1, obj2);
        i++;
      }

      return result;
    };
  }

  function sortInPlace(property, arr) {
    return arr.sort(sort(property));
  }

  function getToolbarElement() {
    return document.getElementById("app-bar-title");
  }

  function timeout(ms) {
    return new Promise(function (resolve) {
      return setTimeout(resolve, ms);
    });
  }

  function uuidv4() {
    return "".concat(1e7, -1e3).concat(-4e3).concat(-8e3).concat(-1e11).replace(/[018]/g, function (c) {
      return (parseInt(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> parseInt(c) / 4).toString(16);
    });
  }

  function assert(condition, message) {
    if (!condition) {
      throw new Error(message || "Assertion failed");
    }
  }

  function useQuery() {
    var _useLocation = (0, _reactRouterDom.useLocation)(),
        search = _useLocation.search;

    return (0, _react.useMemo)(function () {
      return new URLSearchParams(search);
    }, [search]);
  }

  function openInNewTab(route) {
    var fullUrl = "".concat(window.location.origin).concat(route);
    window.open(fullUrl, '_blank');
  }
});