define("client/transforms/time", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized != null) {
        var time;
        var timeOfDay;
        var adjustment;
        var secondHalf;

        if (parseInt(serialized.substring(0, 2), 10) > 11) {
          timeOfDay = "PM";

          if (parseInt(serialized.substring(0, 2), 10) > 12) {
            adjustment = parseInt(serialized.substring(0, 2), 10) - 12;

            if (adjustment < 10) {
              adjustment = "0" + adjustment.toString();
            }
          } else {
            adjustment = serialized.substring(0, 2);
          }
        } else {
          timeOfDay = "AM";
          adjustment = serialized.substring(0, 2);
        }

        secondHalf = serialized.substring(2, 4);

        if (adjustment === null || adjustment === "NaN" || adjustment === undefined || secondHalf === null || secondHalf === undefined || serialized === null || serialized === "") {
          time = null;
        } else {
          time = adjustment + ":" + secondHalf + " " + timeOfDay;
        }

        return time;
      }
    },
    serialize: function serialize(deserialized) {
      if (deserialized != "Invalid date" && deserialized != null && deserialized != "") {
        return moment(deserialized, "hh:mm A").format("HHmm");
      } else {
        return "";
      }
    }
  });

  _exports.default = _default;
});