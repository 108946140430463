define("client/components/better-file-upload/component", ["exports", "ember-file-upload/components/file-upload/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
      computed = Ember.computed,
      setProperties = Ember.setProperties,
      getProperties = Ember.getProperties;

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      var id = get(this, 'for');
      Ember.assert("Changing the tagName of {{file-upload}} to \"".concat(get(this, 'tagName'), "\" will break interactions."), get(this, 'tagName') === 'label');
    },
    actions: {
      openFileInput: function openFileInput() {
        this.$('#' + get(this, 'for')).click();
      }
    }
  });

  _exports.default = _default;
});