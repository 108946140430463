define("client/components/lpad-number-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    padChar: '0',
    value: '',
    actions: {
      setValue: function setValue(value) {
        this.set('value', value);
        this.sendAction('onChange', value);
      },
      onBlur: function onBlur() {
        var length = this.get('maxlength');
        var value = this.get('value');
        var distance = length - value.length;
        console.log(distance);
        console.log(length);
        console.log(value.length);
        var s = "";

        if (distance > 0) {
          s += this.get('padChar');
        }

        s += value;
        this.set('value', s);
        this.sendAction('onChange', s);
      }
    }
  });

  _exports.default = _default;
});