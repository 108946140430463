define("client/templates/srvc-contract-solicitation-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6HwdNIYB",
    "block": "{\"statements\":[[1,[33,[\"srvc-contract-solicitation-view\"],null,[[\"isViewing\",\"types\",\"arSettings\",\"toConvert\",\"toSearch\",\"toCreate\"],[[28,[\"model\",\"item\"]],[28,[\"model\",\"types\"]],[28,[\"model\",\"arSettings\"]],[33,[\"route-action\"],[\"toConvert\"],null],[33,[\"route-action\"],[\"toSearch\"],null],[33,[\"route-action\"],[\"toCreate\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/srvc-contract-solicitation-view.hbs"
    }
  });

  _exports.default = _default;
});