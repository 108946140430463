define("client/models/job-config-flag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    description: _emberData.default.attr(),
    flag: _emberData.default.attr('yn-tf')
  });

  _exports.default = _default;
});