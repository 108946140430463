define("client/components/bootstrap-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IQMcsc8j",
    "block": "{\"statements\":[[11,\"label\",[]],[13],[0,\"\\n\\t\"],[1,[26,[\"label\"]],false],[0,\"\\n\\t\"],[11,\"input\",[]],[15,\"type\",\"checkbox\"],[16,\"data-size\",[26,[\"size\"]],null],[16,\"data-style\",[26,[\"toggleStyle\"]],null],[15,\"data-toggle\",\"toggle\"],[13],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/bootstrap-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});