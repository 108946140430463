define("client/components/multiplier-input/component", ["exports", "ember-paper/components/paper-input"], function (_exports, _paperInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperInput.default.extend({
    tempValue: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.send('onBlur', 'init');
    },
    actions: {
      onBlur: function onBlur(init) {
        if (this.get('value') === '') {
          this.set('value', this.get('tempValue'));
          this.set('tempValue', null);
          return;
        }

        this.set('tempValue', null);
        var value = this.get('value'); // if (typeof value === "number") value = value.toFixed(2);

        var wholeNumber = /^(-?\d+)$/;
        var justDecimal = /^(-?\d+\.)$/;
        var onePlace = /^(-?\d+\.\d)$/;
        var twoPlaces = /^(-?\d+\.\d{2})$/;
        var threePlaces = /^(-?\d+\.\d{3})$/;
        var moreThanThree = /^(-?\d+\.\d{3,})$/;
        var oneCent = /^(\.\d{1})$/;

        if (wholeNumber.test(value)) {
          value = value + ".0000";
        } else if (justDecimal.test(value)) {
          value = value + "0000";
        } else if (onePlace.test(value) || oneCent.test(value)) {
          value = value + "000";
        } else if (twoPlaces.test(value)) {
          value = value + '00';
        } else if (threePlaces.test(value)) {
          value = value + '0';
        } else if (moreThanThree.test(value)) {
          value = parseFloat(value).toFixed(4);
        } else {
          value = "0.000";
        }

        if (this.get('onChange')) {
          if (init === 'init') {
            Ember.run.scheduleOnce('afterRender', this, function () {
              this.get('onChange')(value, "init"); // ADDED 10/18/17 so order entry didn't override tax/discount flags
            });
          } else {
            this.get('onChange')(value);
          }
        }

        if (this.get('extraBlur') && init !== 'init') {
          this.get('extraBlur')(value);
        }
      },
      onFocus: function onFocus() {
        this.set('tempValue', this.get('value'));
        this.set('value', '');
        this.get('onChange')(this.get('value'));
      }
    }
  });

  _exports.default = _default;
});