define("client/templates/ar-set-gls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9nwDZE43",
    "block": "{\"statements\":[[1,[33,[\"ar-set-gls\"],null,[[\"model\",\"accounts\"],[[28,[\"model\",\"settings\"]],[28,[\"model\",\"accounts\"]]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/ar-set-gls.hbs"
    }
  });

  _exports.default = _default;
});