define("client/components/item-array-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    style: 'height: 100%;',
    itemList: Ember.A([]),
    keyPress: function keyPress(e) {
      if (e.keyCode === 13 && this.get('item') !== "" && this.get('item') !== null && this.get('item') !== undefined) {
        this.sendAction('addItem');
      }
    },
    actions: {
      addItem: function addItem() {
        if (this.get('item') !== "" && this.get('item') !== null && this.get('item') !== undefined) {
          this.sendAction('addItem');
        }
      },
      deleteItem: function deleteItem(item) {
        this.sendAction('deleteItem', item);
      }
    }
  });

  _exports.default = _default;
});