define("client/templates/dispatch/user-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6lbHk6Bz",
    "block": "{\"statements\":[[6,[\"modal-component\"],null,[[\"action\",\"isExpanded\"],[\"cancel\",true]],{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"modal-header\"],[13],[0,\"\\n        \"],[11,\"span\",[]],[15,\"style\",\"color: black; font-weight: bold; padding: 0;\"],[13],[0,\"Are you sure you want to delete the following user?\"],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"modal-body\"],[15,\"style\",\"max-height: 51vh; overflow-y: scroll; text-align: center;\"],[13],[0,\"\\n            \"],[11,\"div\",[]],[13],[0,\"\\n                \"],[11,\"span\",[]],[15,\"class\",\"th-table noIbar\"],[15,\"style\",\"color: black;\"],[13],[0,\"User:\"],[14],[0,\"\\n                \"],[11,\"span\",[]],[15,\"class\",\"td-table noIbar\"],[15,\"style\",\"color: black;\"],[13],[1,[28,[\"currentUser\",\"user\",\"username\"]],false],[14],[11,\"br\",[]],[13],[14],[0,\"\\n            \"],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"modal-footer\"],[13],[0,\"\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn\"],[15,\"style\",\"color: black;\"],[5,[\"action\"],[[28,[null]],\"cancel\"]],[13],[0,\"Cancel\"],[14],[0,\"\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn\"],[15,\"style\",\"color: black;\"],[5,[\"action\"],[[28,[null]],\"confirmDelete\"]],[13],[0,\"Delete\"],[14],[0,\"\\n\"],[0,\"    \"],[14],[0,\"\\n\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/dispatch/user-delete.hbs"
    }
  });

  _exports.default = _default;
});