define("client/models/ar-setting", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    salesAccount1: _emberData.default.attr(),
    salesAccount2: _emberData.default.attr(),
    salesAccount3: _emberData.default.attr(),
    salesAccount4: _emberData.default.attr(),
    salesAccount5: _emberData.default.attr(),
    salesAccount6: _emberData.default.attr(),
    cashAccount: _emberData.default.attr(),
    discAccount: _emberData.default.attr(),
    accountsReceivableGl: _emberData.default.attr(),
    monthEnd: _emberData.default.attr(),
    saleAccount: _emberData.default.attr(),
    freightAccount: _emberData.default.attr(),
    discountAccount: _emberData.default.attr(),
    salesTaxAccount: _emberData.default.attr(),
    inventoryAccount: _emberData.default.attr(),
    inventoryOffset: _emberData.default.attr(),
    costFlag: _emberData.default.attr(),
    financeGl: _emberData.default.attr(),
    financeRate: _emberData.default.attr()
  });

  _exports.default = _default;
});