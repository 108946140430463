define("client/components/support-ticket-modal/component", ["exports", "client/utils/serialize-container"], function (_exports, _serializeContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    ticketDescription: "",
    ticketTitle: "",
    ticketPriority: "10",
    priorities: Ember.A(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]),
    store: Ember.inject.service(),
    actions: {
      submitTicket: function submitTicket() {
        Ember.$('#isLoading').show();
        Ember.run.later(function () {
          var _this = this;

          /*				let state = serializeContainer(Ember.Namespace.NAMESPACES[2].__container__);
          				let stateString = JSON.stringify(state);
          				var stateArray = [];
          				for (var i = 0; i < stateString.length; i++) {
          					stateArray.push(stateString.charCodeAt(i));
          				}
          				let deflater = new Zlib.Deflate(stateArray);
          				let compressedArray = deflater.compress();
          				var stringArray = [];
          				for (var i = 0; i < compressedArray.length; i++) {
          					stringArray.push(String.fromCharCode(compressedArray[i]));
          				}
          				let compressedString = stringArray.join("");
          				let b64compressedString = btoa(compressedString);*/
          var title = this.get('ticketTitle');
          var priority = this.get('ticketPriority');
          var description = this.get('ticketDescription');
          var record = this.get('store').createRecord('support-ticket', {
            tenant: this.get('auth.tenantId'),
            title: title,
            priority: priority,
            description: description,
            state: ""
          });
          record.save().then(function () {
            Ember.$('#isLoading').hide();

            _this.get('close')();
          });
        }.bind(this), 100);
      },
      setPriority: function setPriority(priority) {
        this.set('ticketPriority', priority ? priority : '');
      },
      blurPriority: function blurPriority(searchText) {
        var priority = this.get('ticketPriority');

        if (this.get('priorities').contains(searchText)) {
          this.set('ticketPriority', searchText);
        } else {
          this.set('ticketPriority', '10');
        }
      }
    }
  });

  _exports.default = _default;
});