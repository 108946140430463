define("client/components/search-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bCtzJd5E",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"ember-select-container dropdown-toggle\"],[13],[0,\"\\n\\t\"],[1,[33,[\"input\"],null,[[\"placeholder\",\"type\",\"style\"],[\"Search\",\"text\",\"border-radius: 2px;\"]]],false],[0,\"\\n\\t\\t\"],[11,\"i\",[]],[15,\"class\",\"glyphicon glyphicon-search\"],[15,\"style\",\"margin-left: -24px;\"],[13],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"dropdown-menu-new\"],[15,\"style\",\"padding-top: 0; left: 15px;\"],[13],[0,\"\\n\\t\"],[11,\"ul\",[]],[15,\"class\",\"ember-select-results\"],[15,\"style\",\"height: 100px;\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"results\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[11,\"li\",[]],[15,\"class\",\"ember-select-result-item\"],[13],[0,\"\\n\\t\\t\\t\\t\"],[1,[33,[\"get\"],[[28,[\"result\"]],[28,[\"optionPath\"]]],null],false],[0,\"\\n\\t\\t\\t\"],[14],[0,\"\\n\"]],\"locals\":[\"result\"]},null],[0,\"\\t\"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/search-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});