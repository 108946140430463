define("client/transforms/range", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      serialized = serialized.split(',');
      return {
        min: Number(serialized[0]),
        increment: Number(serialized[1]),
        max: Number(serialized[2])
      };
    },
    serialize: function serialize(deserialized) {
      return deserialized.min.toString() + "," + deserialized.increment.toString() + "," + deserialized.max.toString();
    }
  });

  _exports.default = _default;
});