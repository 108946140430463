define("client/components/customer-notification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    click: function click(event) {
      if (this.get('nonExistingCustomer')) {
        return;
      }

      this.get('clickAction')(this.get('customer'), this.get('site'));
    },
    actions: {
      create: function create() {
        this.get('clickAction')(true);
      },
      search: function search() {
        this.get('clickAction')(false);
      },
      close: function close() {
        this.get('close')();
      }
    }
  });

  _exports.default = _default;
});