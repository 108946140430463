define("client/components/virtual-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RwGsyW/P",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"infinite-list-content\"],[16,\"style\",[26,[\"contentStyle\"]],null],[13],[14],[0,\"\\n\"],[18,\"default\",[[33,[\"map-by\"],[\"raw\",[28,[\"visibleItems\"]]],null],[28,[\"_marginTop\"]]]],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/virtual-body/template.hbs"
    }
  });

  _exports.default = _default;
});