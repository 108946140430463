define("client/components/ap-terms-codes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortParams: ['ledgerId'],
    sortedGlAccounts: Ember.computed.sort('accounts', 'sortParams'),
    glSearchMatcher: function glSearchMatcher(account, term) {
      return (account.get('ledgerId') + ' ' + account.get('description').toLowerCase()).indexOf(term.toLowerCase());
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedGl1', this.get('sortedGlAccounts').findBy('ledgerId', this.get('terms.glCode1')));
      this.set('selectedGl2', this.get('sortedGlAccounts').findBy('ledgerId', this.get('terms.glCode2')));
      this.set('selectedGl3', this.get('sortedGlAccounts').findBy('ledgerId', this.get('terms.glCode3')));
      this.set('selectedGl4', this.get('sortedGlAccounts').findBy('ledgerId', this.get('terms.glCode4')));
    },
    actions: {
      save: function save() {
        Ember.$('#isLoading').show();
        this.get('terms').save().then(function (terms) {
          Ember.$('#isLoading').hide();
        });
      },
      changeGlInfo: function changeGlInfo(num, val) {
        switch (num) {
          case '1':
            this.set('selectedGl1', val);
            this.set('terms.glCode1', val ? val.get('ledgerId') : '');
            this.set('terms.glDesc1', val ? val.get('description') : '');
            break;

          case '2':
            this.set('selectedGl2', val);
            this.set('terms.glCode2', val ? val.get('ledgerId') : '');
            this.set('terms.glDesc2', val ? val.get('description') : '');
            break;

          case '3':
            this.set('selectedGl3', val);
            this.set('terms.glCode3', val ? val.get('ledgerId') : '');
            this.set('terms.glDesc3', val ? val.get('description') : '');
            break;

          case '4':
            this.set('selectedGl4', val);
            this.set('terms.glCode4', val ? val.get('ledgerId') : '');
            this.set('terms.glDesc4', val ? val.get('description') : '');
            break;
        }
      }
    }
  });

  _exports.default = _default;
});