define("client/helpers/citron-date-to-normal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.citronDateToNormal = citronDateToNormal;
  _exports.default = void 0;

  function citronDateToNormal(params
  /*, hash*/
  ) {
    var date = params[0];
    if (!date) return "";
    var mmdd = date.substr(0, 4);
    var decade = date.substr(4, 1);
    var cpoin = decade.charCodeAt(0);
    cpoin = cpoin - 0x41;
    var year = 1900 + (cpoin >= 0 ? 100 : 0) + (cpoin >= 0 ? cpoin * 10 + parseInt(date.substr(5, 1)) : parseInt(date.substr(4, 2)));
    return moment(year + mmdd, 'YYYYMMDD').format('YYYY-MM-DD');
  }

  var _default = Ember.Helper.helper(citronDateToNormal);

  _exports.default = _default;
});