define("client/templates/jc-job-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/G+wEFvi",
    "block": "{\"statements\":[[1,[33,[\"jc-edit-job\"],null,[[\"model\",\"cancelEdit\",\"setJob\",\"searchJobs\",\"createJob\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"back\"],null],[33,[\"route-action\"],[\"viewJob\"],null],[33,[\"route-action\"],[\"searchJobs\"],null],[33,[\"route-action\"],[\"createJob\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/jc-job-edit.hbs"
    }
  });

  _exports.default = _default;
});