define("client/models/ledger-reverse-entry", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    period: _emberData.default.attr(),
    documentNumber: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    ledgerId: _emberData.default.attr(),
    reference: _emberData.default.attr(),
    reference2: _emberData.default.attr(),
    amount: _emberData.default.attr()
  });

  _exports.default = _default;
});