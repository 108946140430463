define("client/components/months-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MWymk3Lm",
    "block": "{\"statements\":[[1,[33,[\"input\"],null,[[\"type\",\"name\",\"checked\",\"disabled\",\"class\"],[\"checkbox\",[28,[\"name\"]],[28,[\"checked\"]],[28,[\"disabled\"]],\"form-control\"]]],false],[0,\"\\n\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/months-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});