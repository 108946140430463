define("client/models/invoice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Invoice = _emberData.default.Model.extend({
    custName: _emberData.default.attr('capitalize'),
    invoiceNumber: _emberData.default.attr(),
    orderNumber1: _emberData.default.attr(),
    orderNumber2: _emberData.default.attr(),
    invoiceDate: _emberData.default.attr(),
    shipDate: _emberData.default.attr(),
    custId: _emberData.default.attr(),
    shipName: _emberData.default.attr('capitalize'),
    shipName2: _emberData.default.attr('capitalize'),
    shipAddress: _emberData.default.attr('capitalize'),
    shipCity: _emberData.default.attr('capitalize'),
    shipState: _emberData.default.attr('capitalize'),
    shipZip: _emberData.default.attr('capitalize'),
    slm: _emberData.default.attr('capitalize'),
    custPoNumber: _emberData.default.attr('capitalize'),
    shipVia: _emberData.default.attr('capitalize'),
    freightTerms: _emberData.default.attr('capitalize'),
    orderTerms: _emberData.default.attr('capitalize'),
    orderDate: _emberData.default.attr(),
    subTotal: _emberData.default.attr(),
    taxPct: _emberData.default.attr(),
    taxAmount: _emberData.default.attr(),
    orderAmount: _emberData.default.attr(),
    taxJurisdiction: _emberData.default.attr(),
    upsZone: _emberData.default.attr(),
    phoneNumber: _emberData.default.attr('telephone'),
    discountPct: _emberData.default.attr(),
    discountAmount: _emberData.default.attr(),
    handling: _emberData.default.attr(),
    insurance: _emberData.default.attr(),
    promisedDate: _emberData.default.attr(),
    orderHold: _emberData.default.attr(),
    orderNotes: _emberData.default.attr('capitalize'),
    lastInvoiceNumber1: _emberData.default.attr(),
    lastInvoiceNumber2: _emberData.default.attr(),
    lastInvoiceNumber3: _emberData.default.attr(),
    lastInvoiceNumber4: _emberData.default.attr(),
    lastInvoiceDate1: _emberData.default.attr(),
    lastInvoiceDate2: _emberData.default.attr(),
    lastInvoiceDate3: _emberData.default.attr(),
    lastInvoiceDate4: _emberData.default.attr(),
    lastInvoiceAmt1: _emberData.default.attr(),
    lastInvoiceAmt2: _emberData.default.attr(),
    lastInvoiceAmt3: _emberData.default.attr(),
    lastInvoiceAmt4: _emberData.default.attr(),
    taxFlag: _emberData.default.attr(),
    discountFlag: _emberData.default.attr(),
    commisionRate: _emberData.default.attr(),
    commissionRate: Ember.computed.alias('commisionRate'),
    shipToId: _emberData.default.attr(),
    orderwarehouse: _emberData.default.attr(),
    slm2: _emberData.default.attr(),
    commissionRate2: _emberData.default.attr(),
    shipCountry: _emberData.default.attr(),
    discountCode: _emberData.default.attr(),
    freightAmount: _emberData.default.attr(),
    packageCount: _emberData.default.attr(),
    weight: _emberData.default.attr(),
    applyAgainst: _emberData.default.attr(),
    filler: _emberData.default.attr(),
    jobNumber: _emberData.default.attr(),
    jobFiller: _emberData.default.attr(),
    jobCostFreightFlag: _emberData.default.attr(),
    jobFiller2: _emberData.default.attr(),
    void: _emberData.default.attr(),
    items: _emberData.default.hasMany('invoice-item', {
      async: true
    }),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    order: _emberData.default.belongsTo('order', {
      async: true
    }),
    job: _emberData.default.belongsTo('job', {
      async: true
    }),
    site: _emberData.default.belongsTo('site-address', {
      async: true
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    label: Ember.computed('custName', 'invoiceNumber', 'invoiceDate', function () {
      return "".concat(this.get('invoiceNumber'), " - ").concat(this.get('custName'), " - ").concat(this.get('invoiceDate'));
    })
  });

  var _default = Invoice;
  _exports.default = _default;
});