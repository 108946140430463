define("client/routes/ap-terms-codes", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      return Ember.RSVP.hash({
        terms: this.store.findRecord('pay-term', 1, {
          reload: true
        }),
        accounts: this.store.findAll('ledger-account', {
          reload: true
        })
      });
    },
    actions: {
      didTransition: function didTransition() {
        Ember.$('#isLoading').hide();
      },
      willTransition: function willTransition() {
        this.controller.get('model.terms').rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});