define("client/templates/check-reconciliation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hwv9+lUG",
    "block": "{\"statements\":[[1,[33,[\"ap-bank-reconciliation\"],null,[[\"banks\"],[[28,[\"model\",\"banks\"]]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/check-reconciliation.hbs"
    }
  });

  _exports.default = _default;
});