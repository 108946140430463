define("client/components/print-checks-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Y0iP6d5",
    "block": "{\"statements\":[[6,[\"modal-dialog\"],null,[[\"action\"],[\"close\"]],{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"modal-header-new\"],[13],[0,\"\\n        \"],[11,\"h4\",[]],[13],[0,\"Auto Select Checks\"],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"modal-body-new\"],[15,\"style\",\"max-height: 56vh; overflow-y: auto;\"],[13],[0,\"\\n        \"],[1,[33,[\"input\"],null,[[\"value\",\"class\",\"placeholder\"],[[28,[\"printer\"]],\"form-control\",\"Printer\"]]],false],[0,\"\\n  \\t\\t\"],[11,\"label\",[]],[13],[0,\"Double Spaced?\"],[14],[11,\"div\",[]],[15,\"style\",\"display:inline-block;\"],[13],[1,[33,[\"input\"],null,[[\"checked\",\"type\"],[[28,[\"doubleSpaced\"]],\"checkbox\"]]],false],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"modal-footer-new\"],[13],[0,\"\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn btn-success btn-sm\"],[5,[\"action\"],[[28,[null]],\"submit\"]],[13],[0,\"Submit\"],[14],[0,\"\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn btn-default btn-sm\"],[5,[\"action\"],[[28,[null]],\"close\"]],[13],[0,\"Cancel\"],[14],[0,\"\\n    \"],[14],[0,\"\\n\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/print-checks-modal/template.hbs"
    }
  });

  _exports.default = _default;
});