define("client/components/paper-menu2/component", ["exports", "ember-paper/components/paper-menu"], function (_exports, _paperMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MENU_EDGE_MARGIN = 8;

  function clamp(pos, bounds, containerNode) {
    pos.top = Math.max(Math.min(pos.top, bounds.bottom - containerNode.offsetHeight), bounds.top);
    pos.left = Math.max(Math.min(pos.left, bounds.right - containerNode.offsetWidth), bounds.left);
  }

  function firstVisibleChild(node) {
    for (var i = 0; i < node.children.length; ++i) {
      if (window.getComputedStyle(node.children[i]).display !== 'none') {
        return node.children[i];
      }
    }
  }

  var _default = _paperMenu.default.extend({
    calculatePosition: function calculatePosition(trigger, content, destination, _ref) {
      var dropdown = _ref.dropdown;
      var containerNode = content;
      var openMenuNode = content.firstElementChild;
      var openMenuNodeRect = openMenuNode.getBoundingClientRect();
      var boundryNode = document.body;
      var boundryNodeRect = boundryNode.getBoundingClientRect();
      var menuStyle = window.getComputedStyle(openMenuNode);
      var originNode = trigger.querySelector('.md-menu-origin') || trigger.querySelector('md-icon') || trigger;
      var originNodeRect = originNode.getBoundingClientRect();
      var bounds = {
        left: boundryNodeRect.left + MENU_EDGE_MARGIN,
        top: Math.max(boundryNodeRect.top, 0) + MENU_EDGE_MARGIN,
        bottom: Math.max(boundryNodeRect.bottom, Math.max(boundryNodeRect.top, 0) + boundryNodeRect.height) - MENU_EDGE_MARGIN,
        right: boundryNodeRect.right - MENU_EDGE_MARGIN
      };
      var alignTarget;
      var alignTargetRect = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      };
      var existingOffsets = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      };
      var positionMode = dropdown.get('positionMode');

      if (positionMode.top === 'target' || positionMode.left === 'target' || positionMode.left === 'target-right') {
        alignTarget = firstVisibleChild(openMenuNode);

        if (alignTarget) {
          // TODO: Allow centering on an arbitrary node, for now center on first menu-item's child
          alignTarget = alignTarget.firstElementChild || alignTarget;
          alignTarget = alignTarget.querySelector('md-icon') || alignTarget.querySelector('.md-menu-align-target') || alignTarget;
          alignTargetRect = alignTarget.getBoundingClientRect();
          existingOffsets = {
            top: parseFloat(containerNode.style.top || 0),
            left: parseFloat(containerNode.style.left || 0)
          };
        }
      }

      var position = {};
      var transformOrigin = 'top ';

      switch (positionMode.top) {
        case 'target':
          position.top = existingOffsets.top + originNodeRect.top;
          break;

        case 'cascade':
          position.top = originNodeRect.top - parseFloat(menuStyle.paddingTop) - originNode.style.top;
          break;

        case 'bottom':
          position.top = originNodeRect.top + originNodeRect.height;
          break;

        default:
          assert("Invalid target mode '".concat(positionMode.top, "' specified for paper-menu on Y axis."));
      }

      switch (positionMode.left) {
        case 'target':
          {
            position.left = existingOffsets.left + originNodeRect.left - alignTargetRect.left;
            transformOrigin += 'left';
            break;
          }

        case 'target-left':
          {
            position.left = originNodeRect.left;
            transformOrigin += 'left';
            break;
          }

        case 'target-right':
          {
            position.left = originNodeRect.right - openMenuNodeRect.width;
            transformOrigin += 'right';
            break;
          }

        case 'cascade':
          {
            var willFitRight = originNodeRect.right + openMenuNodeRect.width < bounds.right;
            position.left = willFitRight ? originNodeRect.right - originNode.style.left : originNodeRect.left - originNode.style.left - openMenuNodeRect.width;
            transformOrigin += willFitRight ? 'left' : 'right';
            break;
          }

        case 'right':
          {
            position.left = originNodeRect.right - openMenuNodeRect.width;
            transformOrigin += 'right';
            break;
          }

        case 'left':
          {
            position.left = originNodeRect.left;
            transformOrigin += 'left';
            break;
          }

        default:
          {
            assert("Invalid target mode '".concat(positionMode.left, "' specified for paper-menu on X axis."));
          }
      } // sum offsets


      var offsets = dropdown.get('offsets');
      position.top += offsets.top;
      position.left += offsets.left;
      clamp(position, bounds, containerNode);
      var dropdownTop = Math.round(position.top);
      var dropdownLeft = Math.round(position.left);
      var scaleX = Math.round(100 * Math.min(originNodeRect.width / containerNode.offsetWidth, 1.0)) / 100;
      var scaleY = Math.round(100 * Math.min(originNodeRect.height / containerNode.offsetHeight, 1.0)) / 100;
      var style = {
        top: dropdownTop,
        left: dropdownLeft,
        // Animate a scale out if we aren't just repositioning
        //      transform: !dropdown.didAnimateScale ? `scale(${scaleX}, ${scaleY})` : undefined,
        transformOrigin: transformOrigin
      };
      dropdown.setProperties({
        //     transform: !dropdown.didAnimateScale ? `scale(${scaleX}, ${scaleY})` : undefined,
        transformOrigin: transformOrigin
      });
      dropdown.didAnimateScale = true;
      return {
        style: style,
        horizontalPosition: '',
        verticalPosition: ''
      };
    }
  });

  _exports.default = _default;
});