define("client/components/ledger-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UCyp3NUO",
    "block": "{\"statements\":[[6,[\"vr-paper-select\"],null,[[\"class\",\"searchEnabled\",\"options\",\"selected\",\"onchange\",\"matcher\",\"label\",\"placeholder\",\"disabled\"],[[33,[\"if\"],[[33,[\"not\"],[[28,[\"errorSpace\"]]],null],[33,[\"concat\"],[\"no-error \",[28,[\"class\"]]],null],[28,[\"class\"]]],null],true,[28,[\"sortedGlAccounts\"]],[33,[\"if\"],[[28,[\"useId\"]],[33,[\"find-by\"],[\"ledgerId\",[28,[\"selected\"]],[28,[\"glAccounts\"]]],null],[28,[\"selected\"]]],null],[33,[\"if\"],[[28,[\"onchange\"]],[28,[\"onchange\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"selected\"]]],null]],null]],null],[28,[\"glSearchMatcher\"]],[28,[\"label\"]],[28,[\"placeholder\"]],[28,[\"disabled\"]]]],{\"statements\":[[0,\"\\t\"],[1,[28,[\"acc\",\"ledgerId\"]],false],[0,\" - \"],[1,[28,[\"acc\",\"description\"]],false],[0,\"\\n\"]],\"locals\":[\"acc\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/ledger-select/template.hbs"
    }
  });

  _exports.default = _default;
});