define("client/react/data/models-map", ["exports", "client/react/data/transforms"], function (_exports, _transforms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MODELS_MAP = {
    payroll: {
      singular: "payroll",
      plural: "payrolls",
      transforms: [function (obj) {
        // @ts-ignore
        obj['label'] = "".concat(moment(obj.startDate).format("MM/DD/YY"), " - ").concat(moment(obj.endDate).format('MM/DD/YY'), " ").concat(obj.description);
        return obj;
      }]
    },
    dashboard: {
      singular: 'dashboard',
      plural: 'dashboards'
    },
    dashboardWidget: {
      singular: 'dashboardWidget',
      plural: 'dashboardWidgets'
    },
    employee: {
      singular: 'payrollEmployee',
      plural: 'payrollEmployees',
      // @ts-ignore
      transforms: [(0, _transforms.assocName)('firstName', 'lastName')]
    },
    employeePayRate: {
      singular: 'employeePayRate',
      plural: 'employeePayRates'
    },
    employeeEmail: {
      singular: 'employeeEmailRework',
      plural: 'employeeEmails'
    },
    employeeBank: {
      singular: 'employeeBank',
      plural: 'employeeBanks'
    },
    employeeDeduction: {
      singular: 'employeeDeduction',
      plural: 'employeeDeductions'
    },
    employeeEarning: {
      singular: 'employeeEarning',
      plural: 'employeeEarnings'
    },
    employment: {
      singular: 'employment',
      plural: 'employments'
    },
    job: {
      singular: 'job',
      plural: 'jobs',
      transforms: [(0, _transforms.assocLabel)('jobId', 'projectName')]
    },
    jobCategory: {
      singular: 'jobCategory',
      plural: 'jobCategories'
    },
    jobGlConfig: {
      singular: 'jobGlConfig',
      plural: 'jobGlConfigs',
      indexes: [{
        name: 'byKey',
        field: 'key'
      }]
    },
    workOrder: {
      singular: 'workOrder',
      plural: 'workOrders',
      transforms: [(0, _transforms.assocLabel)('srvcOrdId', 'siteName')]
    },
    partCategory: {
      singular: 'partCategory',
      plural: 'partCategories'
    },
    payEntryType: {
      singular: 'payEntryType',
      plural: 'payEntryTypes'
    },
    payrollDivision: {
      singular: 'payrollDivision',
      plural: 'payrollDivisions'
    },
    timesheet: {
      singular: 'timesheet',
      plural: 'timesheets'
    },
    payEntry: {
      singular: 'payEntry',
      plural: 'payEntries'
    },
    payrollStatement: {
      singular: 'payrollStatement',
      plural: 'payrollStatements'
    },
    earningType: {
      singular: 'earningType',
      plural: 'earningTypes'
    },
    deductionType: {
      singular: 'deductionType',
      plural: 'deductionTypes'
    },
    payrollStatementEarning: {
      singular: 'payrollStatementEarning',
      plural: 'payrollStatementEarnings'
    },
    payrollStatementDeduction: {
      singular: 'payrollStatementDeduction',
      plural: 'payrollStatementDeductions'
    },
    ledgerAccount: {
      singular: 'ledgerAccount',
      plural: 'ledgerAccounts',
      indexes: [{
        name: 'byLedger',
        field: 'ledgerId'
      }]
    },
    payrollType: {
      singular: 'payrollType',
      plural: 'payrollTypes'
    },
    bank: {
      singular: 'bank',
      plural: 'banks'
    },
    payrollSettings: {
      singular: 'payrollSettings',
      plural: 'payrollSettings'
    },
    tenantSettings: {
      singular: 'tenantSettings',
      plural: 'tenantSettings'
    },
    subledger: {
      singular: 'subledger',
      plural: 'subledgers'
    },
    tagDefinition: {
      singular: 'tagDefinition',
      plural: 'tagDefinitions'
    },
    tag: {
      singular: 'tag',
      plural: 'tags'
    },
    attachment: {
      singular: 'attachment',
      plural: 'attachments'
    },
    division: {
      singular: 'division',
      plural: 'divisions'
    },
    payrollAccrual: {
      singular: 'payrollAccrual',
      plural: 'payrollAccruals'
    },
    payrollAccrualType: {
      singular: 'payrollAccrualType',
      plural: 'payrollAccrualTypes'
    },
    dynamicInvoice: {
      singular: 'dynamicInvoice',
      plural: 'dynamicInvoices'
    },
    partItem: {
      singular: 'partItem',
      plural: 'partItems'
    },
    flatRateItem: {
      singular: 'flatRateItem',
      plural: 'flatRateItems'
    },
    productCategory: {
      singular: 'productCategory',
      plural: 'productCategories'
    },
    productItem: {
      singular: 'productItem',
      plural: 'productItems'
    },
    vendor: {
      singular: 'vendor',
      plural: 'vendors'
    },
    serviceGlSetting: {
      singular: 'serviceGlSetting',
      plural: 'serviceGlSettings',
      indexes: [{
        name: 'byCode',
        indexFn: function indexFn(costCenter) {
          return costCenter.id.toString().padStart(2, '0');
        }
      }]
    },
    workOrderStatus: {
      singular: 'workOrderStatus',
      plural: 'workOrderStatuses'
    },
    serviceLaborRate: {
      singular: 'serviceLaborRate',
      plural: 'serviceLaborRates'
    },
    technician: {
      singular: 'technician',
      plural: 'technicians',
      indexes: [{
        name: 'byEmployeeNumber',
        field: 'employeeNumber'
      }]
    },
    salesTax: {
      singular: 'salesTax',
      plural: 'salesTaxes'
    },
    flrateSetting: {
      singular: 'flrateSetting',
      plural: 'flrateSettings'
    },
    warehouse: {
      singular: 'warehouse',
      plural: 'warehouses'
    },
    altLaborRate: {
      singular: 'altLaborRate',
      plural: 'altLaborRates'
    },
    customer: {
      singular: 'customer',
      plural: 'customers'
    },
    siteAddress: {
      singular: 'siteAddress',
      plural: 'siteAddresses',
      indexes: [{
        name: 'byCustomerId',
        fields: ['custId', 'id']
      }]
    },
    contract: {
      singular: 'contract',
      plural: 'contracts',
      indexes: [{
        name: 'byCustomerAndShipToId',
        fields: ['custId', 'shipToId', 'id']
      }]
    },
    contractType: {
      singular: 'contractType',
      plural: 'contractTypes',
      indexes: [{
        name: 'byCode',
        field: 'code'
      }]
    },
    workOrderInfo: {
      singular: 'workOrderInfo',
      plural: 'workOrderInfos',
      indexes: [{
        name: 'bySrvcId',
        fields: ['srvcId', 'id']
      }]
    },
    workOrderPart: {
      singular: 'workOrderPart',
      plural: 'workOrderParts',
      indexes: [{
        name: 'bySrvcOrdId',
        fields: ['srvcOrdId', 'id']
      }]
    },
    workOrderLabor: {
      singular: 'workOrderLabor',
      plural: 'workOrderLabors',
      indexes: [{
        name: 'bySrvcOrdId',
        fields: ['srvcOrdId', 'id']
      }]
    },
    workOrderFlrate: {
      singular: 'workOrderFlrate',
      plural: 'workOrderFlrates',
      indexes: [{
        name: 'bySrvcId',
        fields: ['srvcId', 'id']
      }]
    },
    flatRatePartUsage: {
      singular: 'flatRatePartUsage',
      plural: 'flatRatePartUsages',
      indexes: [{
        name: 'bySrvcOrdId',
        fields: ['srvcOrdId', 'id']
      }]
    },
    lead: {
      singular: 'lead',
      plural: 'leads',
      indexes: [{
        name: 'byCustomerAndSite',
        fields: ['customer', 'site', 'id']
      }]
    },
    equipmentItem: {
      singular: 'equipmentItem',
      plural: 'equipmentItems',
      indexes: [{
        name: 'byCustomerAndShipToId',
        fields: ['custId', 'shipToId', 'id']
      }]
    },
    contractOption: {
      singular: 'contractOption',
      plural: 'contractOptions'
    },
    contractSolicitationTemplate: {
      singular: "contractSolicitationTemplate",
      plural: "contractSolicitationTemplates"
    },
    serviceLocation: {
      singular: "serviceLocation",
      plural: "serviceLocations"
    },
    appointmentConfirmationTemp: {
      singular: 'appointmentConfirmationTemp',
      plural: 'appointmentConfirmationTemps',
      indexes: [{
        name: 'bySrvcOrdId',
        field: 'srvcOrdId'
      }]
    },
    appointmentConfirmationSetting: {
      singular: 'appointmentConfirmationSetting',
      plural: 'appointmentConfirmationSettings'
    },
    checklistTemplate: {
      singular: 'checklistTemplate',
      plural: 'checklistTemplates'
    },
    checklistTemplateItem: {
      singular: 'checklistTemplateItem',
      plural: 'checklistTemplateItems'
    },
    checklistItem: {
      singular: 'checklistItem',
      plural: 'checklistItems',
      indexes: [{
        name: 'byWorkOrderId',
        fields: ['workOrderId', 'id']
      }]
    }
  };
  var _default = MODELS_MAP;
  _exports.default = _default;
});