define("client/helpers/ledger-detail-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.ledgerDetailPeriod = ledgerDetailPeriod;

  function ledgerDetailPeriod(params
  /*, hash*/
  ) {
    var param = params[0];
    return param.substr(0, 2) + "/" + param.substr(2);
  }

  var _default = Ember.Helper.helper(ledgerDetailPeriod);

  _exports.default = _default;
});