define("client/components/table-virtual-repeat/component", ["exports", "ember-paper/components/paper-virtual-repeat"], function (_exports, _paperVirtualRepeat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _paperVirtualRepeat.default.extend({
    offset: 0,
    size: computed('initialSize', 'items.[]', 'itemHeight', function () {
      var itemSize = this.get('itemHeight');
      var offset = this.get('offset');
      var fullSize = this.get('items.length') * itemSize + offset;

      if (!this.get('items.length')) {
        return this.get('initialSize');
      }

      if (fullSize <= itemSize) {
        return itemSize;
      }

      return Math.min(fullSize, this.get('initialSize'));
    })
  });

  _exports.default = _default;
});