define("client/templates/equipment-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m4iRp+gb",
    "block": "{\"statements\":[[1,[33,[\"srvc-equipment-view\"],null,[[\"equipment\",\"close\",\"create\",\"edit\",\"delete\",\"search\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"back\"],null],[33,[\"route-action\"],[\"create\"],null],[33,[\"route-action\"],[\"edit\"],null],[33,[\"route-action\"],[\"delete\"],null],[33,[\"route-action\"],[\"search\"],null]]]],false],[0,\"\\n\"],[6,[\"if\"],[[28,[\"isDeleting\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[33,[\"equipment-delete-modal\"],null,[[\"equipment\",\"close\",\"onDeleteFinish\"],[[28,[\"isDeleting\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null],null],null],[33,[\"route-action\"],[\"closeDelete\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/equipment-view.hbs"
    }
  });

  _exports.default = _default;
});