define("client/helpers/time-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.timeFormat = timeFormat;

  function timeFormat(params
  /*, hash*/
  ) {
    return params[0] ? moment(params, 'HH:mm').format(params[1] || 'hh:mm a') : "";
  }

  var _default = Ember.Helper.helper(timeFormat);

  _exports.default = _default;
});