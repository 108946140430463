define("client/helpers/first-descriptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.firstDescriptor = firstDescriptor;

  function firstDescriptor(params
  /*, hash*/
  ) {
    var descriptor = params[0].get('descriptors.firstObject.descriptor');
    return descriptor ? descriptor[0].toUpperCase() === descriptor[0] ? descriptor.toLowerCase() : descriptor : "";
  }

  var _default = Ember.Helper.helper(firstDescriptor);

  _exports.default = _default;
});