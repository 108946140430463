define("client/components/deferred-content/settled-content", ["exports", "ember-deferred-content/components/deferred-content/settled-content"], function (_exports, _settledContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _settledContent.default;
    }
  });
});