define("client/utils/prepare-changeset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareChangeset;

  function prepareChangeset(jsonModel, changeset, store) {
    var obj = jsonModel;
    var changes = changeset.get('changes');
    var model = changeset.get('_content');
    var dsModel = store.modelFor(model._internalModel.modelName);
    changes.forEach(function (item) {
      var key = item.key;
      var value = item.value;
      obj[key] = value;
    });
    dsModel.eachTransformedAttribute(function (name, type) {
      if (!changes.findBy('key', name)) return;
      var transform = Ember.getOwner(store).lookup('transform:' + type);
      obj[name] = transform.serialize(obj[name]);
    });
    return obj;
  }
});