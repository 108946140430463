define("client/routes/admin-panel", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    auth: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        users: this.store.findAll('user', {
          reload: true
        }),
        prodCategories: this.store.findAll('product-category', {
          reload: true
        }),
        woColors: this.store.query('wo-color', {
          tenant: this.get('auth.tenantId')
        })
      });
    },
    actions: {
      didTransition: function didTransition() {
        if (this.get('auth.auth.userCrud')) {
          this.controller.set('screen', "user");
        } else if (this.get('auth.auth.mobileSettings')) {
          this.controller.set('screen', "mobile");
        } else if (this.get('auth.auth.workOrderColors')) {
          this.controller.set('screen', "wo");
        } else if (this.get('auth.auth.productCatalog')) {
          this.controller.set('screen', "prod");
        } else if (this.get('auth.auth.contractSolicitationSettings')) {
          this.controller.set('screen', "solicitations");
        } else if (this.get('auth.auth.stripeSettings')) {
          this.controller.set('screen', "stripeSettings");
        }
      }
    }
  });

  _exports.default = _default;
});