define("client/helpers/yymm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.yymm = yymm;

  function yymm(params
  /*, hash*/
  ) {
    if (!params[0]) return "";
    return moment(params[0], 'YYMM').format('MM/YY');
  }

  var _default = Ember.Helper.helper(yymm);

  _exports.default = _default;
});