define("client/react/components/typed/form-date-picker", ["exports", "react/jsx-runtime", "@mui/material", "@mui/x-date-pickers-pro", "@Harris-Barrick-Software/use-form", "moment"], function (_exports, _jsxRuntime, _material, _xDatePickersPro, _useForm, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function FormDatePicker(_a) {
    var formProps = _a.formProps,
        onChange = _a.onChange,
        required = _a.required,
        inputProps = _a.inputProps,
        restProps = __rest(_a, ["formProps", "onChange", "required", "inputProps"]);

    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });

    if (!controller) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field,
        fieldState = controller.fieldState;

    function _onChange(date, value) {
      field.onChange(date);

      if (onChange) {
        onChange(date, value);
      }
    }

    return (0, _jsxRuntime.jsx)(_xDatePickersPro.DesktopDatePicker, Object.assign({
      inputFormat: "MM/DD/YYYY",
      showToolbar: true,
      openTo: "day",
      OpenPickerButtonProps: {
        tabIndex: -1
      }
    }, restProps, {
      onChange: function onChange(date, value) {
        _onChange(date, value);
      },
      renderInput: function renderInput(params) {
        var _a;

        return (0, _jsxRuntime.jsx)(_material.TextField, Object.assign({}, params, {
          label: restProps.label,
          className: restProps.className,
          error: fieldState.invalid,
          helperText: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message,
          size: restProps.size ? restProps.size : "small",
          variant: "standard",
          margin: restProps.margin,
          required: required
        }, inputProps, {
          onFocus: function onFocus(e) {
            if (restProps.onFocus) {
              restProps.onFocus(e);
            }
          },
          onBlur: function onBlur(e) {
            var standardFormat = "MM/DD/YYYY";
            var inputFormat = restProps.inputFormat || standardFormat;
            var unformattedInput = e.target.value;
            var autoInsertYear = unformattedInput && inputFormat === standardFormat && unformattedInput.length === 8;
            var formattedInput = autoInsertYear ? "".concat(unformattedInput.substring(0, 6), "20").concat(unformattedInput.substring(6)) : unformattedInput;
            var valAsMoment = formattedInput && formattedInput.length === inputFormat.length ? (0, _moment.default)(formattedInput, inputFormat) : null;

            if (autoInsertYear) {
              // @ts-ignore
              _onChange(valAsMoment, formattedInput);
            }

            if (restProps.onBlur) {
              restProps.onBlur(e, valAsMoment);
            }

            field.onBlur();
          }
        }));
      },
      value: field.value
    }));
  }

  var _default = FormDatePicker;
  _exports.default = _default;
});