define("client/models/primary-employee-bank", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    employeeId: _emberData.default.attr(),
    accountType: _emberData.default.attr(),
    routingNumber: _emberData.default.attr(),
    accountNumber: _emberData.default.attr(),
    employee: _emberData.default.belongsTo('employee', {
      async: true
    })
  });

  _exports.default = _default;
});