define("client/controllers/admin-panel", ["exports", "client/react/utilities/snackbar"], function (_exports, _snackbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    auth: Ember.inject.service(),
    featureFlagService: Ember.inject.service('feature-flags'),
    settings: Ember.computed.alias('application.tenantSettings'),
    featureFlags: Ember.computed.alias('featureFlagService.featureFlags'),
    queryParams: ['initialScreen', 'ringCentralLinkSuccess'],
    initialScreen: null,
    ringCentralLinkSuccess: null,
    ringCentralToast: Ember.observer('ringCentralLinkSuccess', function () {
      if (this.get('ringCentralLinkSuccess') === "true") {
        (0, _snackbar.showSnackbar)("Successfully linked to Ring Central", {
          variant: 'success'
        });
      } else if (this.get('ringCentralLinkSuccess') === "false") {
        (0, _snackbar.showSnackbar)('Something went wrong linking to Ring Central', {
          variant: 'error'
        });
      }
    }),
    selectedScreen: Ember.computed('initialScreen', 'screen', function () {
      if (this.get('initialScreen')) {
        return this.get('initialScreen');
      } else {
        return this.get('screen');
      }
    }),
    actions: {
      changeScreen: function changeScreen(screen) {
        this.set('screen', screen);
        this.set('initialScreen', null);
      }
    }
  });

  _exports.default = _default;
});