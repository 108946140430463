define("client/components/fancy-text-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uTcOvO66",
    "block": "{\"statements\":[[11,\"textarea\",[]],[15,\"placeholder\",\"\"],[15,\"autofocus\",\"\"],[13],[1,[26,[\"value\"]],false],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/fancy-text-editor/template.hbs"
    }
  });

  _exports.default = _default;
});