define("client/components/vr-paper-select-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n7NzZTT3",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"dropdown\",\"isOpen\"]]],null,{\"statements\":[[6,[\"ember-wormhole\"],null,[[\"to\",\"renderInPlace\"],[[28,[\"to\"]],[28,[\"renderInPlace\"]]]],{\"statements\":[[0,\"    \"],[1,[33,[\"paper-backdrop\"],null,[[\"class\"],[\"md-select-backdrop md-click-catcher\"]]],false],[0,\"\\n    \"],[11,\"div\",[]],[16,\"id\",[26,[\"dropdownId\"]],null],[16,\"class\",[34,[\"ember-basic-dropdown-content \",[26,[\"class\"]],\" \",[33,[\"if\"],[[28,[\"renderInPlace\"]],\"ember-basic-dropdown-content--in-place\"],null],\" \",[33,[\"if\"],[[28,[\"hPosition\"]],[33,[\"concat\"],[\"ember-basic-dropdown-content--\",[28,[\"hPosition\"]]],null]],null],\" \",[33,[\"if\"],[[28,[\"vPosition\"]],[33,[\"concat\"],[\"ember-basic-dropdown-content--\",[28,[\"vPosition\"]]],null]],null],\"\\n      md-select-menu-container md-clickable \",[33,[\"if\"],[[28,[\"isActive\"]],\"md-active\"],null],\" \",[33,[\"unless\"],[[28,[\"isActive\"]],\"md-leave\"],null]]]],[16,\"style\",[26,[\"style\"]],null],[16,\"dir\",[26,[\"dir\"]],null],[13],[0,\"\\n\"],[6,[\"paper-select-menu-inner\"],null,[[\"width\",\"dense\",\"dropdown\",\"searchEnabled\"],[[28,[\"width\"]],[28,[\"dense\"]],[28,[\"dropdown\"]],[28,[\"searchEnabled\"]]]],{\"statements\":[[6,[\"paper-virtual-repeat\"],null,[[\"itemHeight\",\"containerSelector\",\"items\"],[48,\"md-select-menu\",[28,[\"dropdown\",\"results\"]]]],{\"statements\":[[0,\"\\t        \"],[18,\"default\",[[28,[\"innerContentHash\"]]]],[0,\"\\n\"]],\"locals\":[\"virtualItems\"]},null]],\"locals\":[\"innerContentHash\"]},null],[0,\"    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/vr-paper-select-content/template.hbs"
    }
  });

  _exports.default = _default;
});