define("client/components/dynamic-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    columnHeadings: Ember.A([]),
    values: Ember.A([]),
    keysAndTypes: Ember.A([]),
    maxLength: 1000,
    header: "",
    templateObject: null,
    addFirstLine: function () {
      if (this.get('values.length') === 0) {
        var newObj = Ember.Object.create({});

        for (var i = 0; i < this.get('keysAndTypes.length'); i++) {
          newObj.set(this.get('keysAndTypes').objectAt(i).get('key'), "");
          newObj.set('typeOfValue', this.get('keysAndTypes').objectAt(i).get('type'));
        }

        this.get('values').pushObject(newObj);
        this.set('templateObject', newObj);
      }
    }.observes('values').on('init'),
    actions: {
      addRow: function addRow() {}
    }
  });

  _exports.default = _default;
});