define("client/components/pr-time-entry/component", ["exports", "client/components/react-component/component", "client/react/payroll/time-entry"], function (_exports, _component, _timeEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['layout-column', 'flex', 'height-100'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.reactRender(React.createElement(_timeEntry.default, null));
    }
  });

  _exports.default = _default;
});