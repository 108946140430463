define("client/components/md-popover/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L64Yr+0k",
    "block": "{\"statements\":[[18,\"default\"],[0,\"\\n\"],[6,[\"yield-slot\"],[\"trigger\",[33,[\"block-params\"],[[33,[\"action\"],[[28,[null]],\"open\"],null],[33,[\"action\"],[[28,[null]],\"close\"],null]],null]],null,{\"statements\":[[0,\"\\t\"],[18,\"default\"],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"if\"],[[28,[\"isOpen\"]]],null,{\"statements\":[[6,[\"ember-wormhole\"],null,[[\"to\",\"renderInPlace\"],[\"paper-wormhole\",[28,[\"renderInPlace\"]]]],{\"statements\":[[0,\"\\t\\t\"],[11,\"div\",[]],[16,\"id\",[26,[\"popoverId\"]],null],[16,\"class\",[34,[\"md-popover md-whiteframe-8dp md-card \",[26,[\"animationClass\"]]]]],[16,\"style\",[34,[\"width: \",[33,[\"or\"],[[28,[\"width\"]],300],null],\"px; top: \",[26,[\"top\"]],\"; left: \",[26,[\"left\"]],\";\"]]],[13],[0,\"\\n\"],[6,[\"yield-slot\"],[\"popover\",[33,[\"block-params\"],[[33,[\"action\"],[[28,[null]],\"reposition\"],null],[33,[\"action\"],[[28,[null]],\"close\"],null]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[18,\"default\"],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"unless\"],[[28,[\"disableNotifyRender\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[33,[\"render-notifier\"],null,[[\"onRender\"],[[33,[\"action\"],[[28,[null]],\"reposition\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\t\\t\"],[14],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"md-popover-arrow\"],[13],[14],[0,\"\\t\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/md-popover/template.hbs"
    }
  });

  _exports.default = _default;
});