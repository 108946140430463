define("client/templates/purchase-journal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Aki/vFEt",
    "block": "{\"statements\":[[1,[33,[\"ap-purchase-journal\"],null,[[\"vendor\",\"jobCategories\",\"specialTerms\",\"ledgerAccounts\",\"tranToRoute\",\"toVendor\"],[[28,[\"model\",\"vendor\"]],[28,[\"model\",\"jobCats\"]],[33,[\"object-at\"],[0,[28,[\"model\",\"payTerm\"]]],null],[28,[\"model\",\"ledgerAccounts\"]],[33,[\"route-action\"],[\"tranToRoute\",\"purchase-journal-vendor-search\"],null],[33,[\"route-action\"],[\"toVendor\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/purchase-journal.hbs"
    }
  });

  _exports.default = _default;
});