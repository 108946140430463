define("client/mixins/lazy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    scrollTimeout: 100,
    boundingClientRect: 0,
    windowHeight: 0,
    windowWidth: 0,
    enteredViewport: Ember.computed('boundingClientRect', 'windowHeight', 'windowWidth', function () {
      var rect, windowHeight, windowWidth;
      rect = this.get('boundingClientRect');
      windowHeight = this.get('windowHeight');
      windowWidth = this.get('windowWidth');
      return rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth;
    }),
    exitedViewport: Ember.computed.not('enteredViewport'),
    _updateBoundingClientRect: function _updateBoundingClientRect() {
      var el;
      el = this.$()[0];
      this.set('boundingClientRect', el.getBoundingClientRect());
    },
    _setup: function () {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        this._updateBoundingClientRect();

        this.set('windowHeight', window.innerHeight || document.documentElement.clientHeight);
        this.set('windowWidth', window.innerWidth || document.documentElement.clientWidth);
      });
    }.on('didInsertElement'),
    _scrollHandler: function _scrollHandler() {
      return Ember.run.debounce(this, '_updateBoundingClientRect', this.get('scrollTimeout'));
    },
    _bindScroll: function () {
      var scrollHandler;
      scrollHandler = this._scrollHandler.bind(this);
      Ember.$(document).on('touchmove.scrollable', scrollHandler);
      Ember.$(window).on('scroll.scrollable', scrollHandler);
    }.on('didInsertElement'),
    _unbindScroll: function () {
      Ember.$(window).off('.scrollable');
      Ember.$(document).off('.scrollable');
    }.on('willDestroyElement')
  });

  _exports.default = _default;
});