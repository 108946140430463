define("client/templates/billing-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uQI5PVno",
    "block": "{\"statements\":[[6,[\"modal-component\"],null,[[\"action\",\"isExpanded\"],[\"cancel\",true]],{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"modal-header\"],[13],[0,\"\\n        \"],[11,\"span\",[]],[15,\"style\",\"color: black; font-weight: bold; padding: 0;\"],[13],[0,\"Add User\"],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"modal-body\"],[15,\"style\",\"max-height: 51vh; overflow-y: scroll;\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[13],[0,\"\\n            \"],[1,[33,[\"credit-card-input\"],null,[[\"inValue\",\"placeholder\"],[[28,[\"ccNumber\"]],\"Credit Card Number\"]]],false],[0,\"\\n            \"],[1,[33,[\"cvc-input\"],null,[[\"inValue\",\"placeholder\"],[[28,[\"cvcNumber\"]],\"CVC\"]]],false],[0,\"\\n            \"],[1,[33,[\"expiration-date-input\"],null,[[\"inValue\",\"placeholder\"],[[28,[\"expDate\"]],\"Expires\"]]],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"modal-footer\"],[13],[0,\"\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn\"],[15,\"style\",\"color: black;\"],[5,[\"action\"],[[28,[null]],\"cancel\"]],[13],[0,\"Cancel\"],[14],[0,\"\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn\"],[15,\"style\",\"color: black;\"],[5,[\"action\"],[[28,[null]],\"changeCard\"]],[13],[0,\"Save\"],[14],[0,\"\\n    \"],[14],[0,\"\\n\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/billing-modal.hbs"
    }
  });

  _exports.default = _default;
});