define("client/models/subledger-config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    purchaseSubledger: _emberData.default.belongsTo('subledger'),
    disbursementsSubledger: _emberData.default.belongsTo('subledger'),
    genericSalesSubledger: _emberData.default.belongsTo('subledger'),
    serviceSalesSubledger: _emberData.default.belongsTo('subledger'),
    salesPostingSubledger: _emberData.default.belongsTo('subledger'),
    cashReceiptsSubledger: _emberData.default.belongsTo('subledger'),
    jobCostClosingSubledger: _emberData.default.belongsTo('subledger'),
    jobCostMaterialSubledger: _emberData.default.belongsTo('subledger'),
    jobCostPayrollSubledger: _emberData.default.belongsTo('subledger'),
    payrollSubledger: _emberData.default.belongsTo('subledger')
  });

  _exports.default = _default;
});