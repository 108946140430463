define("client/routes/pr-employee-create", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    actions: {
      transition: function transition(routeName, param) {
        this.transitionTo(routeName, param);
      },
      transitionNoParam: function transitionNoParam(routeName) {
        this.transitionTo(routeName);
      }
    }
  });

  _exports.default = _default;
});