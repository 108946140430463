define("client/models/payroll-deduction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    employeeId: _emberData.default.attr(),
    code: _emberData.default.attr(),
    type: _emberData.default.attr(),
    amount: _emberData.default.attr('currency'),
    limit: _emberData.default.attr(),
    withheldToDate: _emberData.default.attr(),
    glCode: _emberData.default.attr(),
    employee: _emberData.default.belongsTo('employee', {
      async: true
    })
  });

  _exports.default = _default;
});