define("client/routes/ledger-system-defaults", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      var first = this.store.findAll('ledger-setting', {
        reload: true
      }).then(function (settings) {
        var setting = settings.objectAt(0);
        return setting;
      });
      var second = this.store.findAll('ledger-account', {
        reload: true
      }).then(function (accounts) {
        return accounts;
      });
      var promise = {
        settings: first,
        accounts: second
      };
      return Ember.RSVP.hash(promise).then(function (resolved) {
        Ember.$('#isLoading').hide();
        return resolved;
      });
    }
  });

  _exports.default = _default;
});