define("client/components/ss-input/component", ["exports", "ember-paper/components/paper-input"], function (_exports, _paperInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperInput.default.extend({
    tempValue: '',
    oldLength: 0,
    actions: {
      handleInput: function handleInput(e) {
        var input = e.target.value;
        input = input.replace(/[^\d]/g, '');
        input = input.substr(0, 9);
        var length = input.length;

        if (input.length < this.get('oldLength') || input.length === this.get('oldLength')) {
          switch (true) {
            case length === 0:
              input = '';
              break;

            case length > 0 && length < 4:
              input = input;
              break;

            case length > 3 && length < 6:
              input = input.substr(0, 3) + '-' + input.substr(3, 2);
              break;

            case length > 5:
              input = input.substr(0, 3) + '-' + input.substr(3, 2) + '-' + input.substr(5);
              break;
          }
        } else {
          switch (true) {
            case length === 0:
              input = '';
              break;

            case length === 1 || length === 2:
              input = input;
              break;

            case length === 3:
              input = input + '-';
              break;

            case length > 3 && length < 6:
              var first = input.substr(0, 3);
              var second = input.substr(3, 2);
              input = first + '-' + second;
              break;

            case length > 5:
              var first = input.substr(0, 3);
              var second = input.substr(3, 2);
              var third = input.substr(5);
              input = first + '-' + second + '-' + third;
              break;
          }
        }

        this.set('oldLength', length);
        this.sendAction('onChange', input); // setValue below ensures that the input value is the same as this.value

        this.setValue(input);
        this.growTextarea();
        var inputElement = this.$('input').get(0);
        this.set('isNativeInvalid', inputElement && inputElement.validity && inputElement.validity.badInput);
        this.notifyValidityChange();
      }
    }
  });

  _exports.default = _default;
});