define("client/components/ar-cust-maintenance/component", ["exports", "ember-concurrency", "ember-changeset"], function (_exports, _emberConcurrency, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    searching: true,
    actions: {
      cancelEditCustomer: function cancelEditCustomer() {
        this.set('model', this.get('editCustomer._content'));
        this.set('editCustomer', null);
      },
      cancelDeleteCustomer: function cancelDeleteCustomer() {
        this.set('deleteCustomer', null);
      },
      cancelDeleteSite: function cancelDeleteSite() {
        this.set('deleteSite', null);
      },
      searchCustomers: function searchCustomers() {
        this.set('model', null);
        this.set('searching', true);
      },
      cancelCreate: function cancelCreate() {
        this.set('createCustomer', null);
        this.set('model', this.get('lastScreen'));
        this.set('searching', this.get('model') ? false : true);
      }
    }
  });

  _exports.default = _default;
});