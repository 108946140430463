define("client/components/customer-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jCmwm1D9",
    "block": "{\"statements\":[[6,[\"md-autocomplete\"],null,[[\"required\",\"class\",\"matcher\",\"searchText\",\"labelPath\",\"search\",\"label\",\"onSelectionChange\",\"onBlur\",\"onSearchTextChange\"],[[28,[\"required\"]],\"flex\",[28,[\"matcher\"]],[28,[\"custId\"]],\"custId\",[33,[\"perform\"],[[28,[\"search\"]]],null],[28,[\"label\"]],[33,[\"action\"],[[28,[null]],\"setCustomer\"],null],[33,[\"action\"],[[28,[null]],\"blur\"],null],[28,[\"onCustIDChange\"]]]],{\"statements\":[[0,\"\\t\"],[1,[33,[\"paper-autocomplete-highlight\"],null,[[\"label\",\"searchText\",\"flags\"],[[33,[\"concat\"],[[28,[\"customer\",\"custId\"]],\" - \",[28,[\"customer\",\"billingName\"]]],null],[28,[\"select\",\"searchText\"]],\"i\"]]],false],[0,\"\\n\"]],\"locals\":[\"customer\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/customer-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});