define("client/templates/select-checks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9EFkcl/8",
    "block": "{\"statements\":[[1,[33,[\"select-checks\"],null,[[\"transition\"],[[33,[\"route-action\"],[\"transition\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/select-checks.hbs"
    }
  });

  _exports.default = _default;
});