define("client/react/components/typed/main-sidebar", ["exports", "react/jsx-runtime", "@mui/material", "@mui/icons-material", "client/react/components/styles/styles", "client/react/components/screens/app-root", "react", "react-router-dom", "tss-react/mui"], function (_exports, _jsxRuntime, _material, _iconsMaterial, _styles, _appRoot, _react, _reactRouterDom, _mui) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _mui.makeStyles)()(function (theme) {
    return Object.assign(Object.assign({}, _styles.styles), {
      menuClosed: {
        width: _appRoot.DRAWER_COLLAPSED_WIDTH,
        transition: "".concat(theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }), " !important"),
        "& .MuiDrawer-paper": {
          width: _appRoot.DRAWER_COLLAPSED_WIDTH,
          transition: "".concat(theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          }), " !important")
        }
      },
      menuOpen: {
        width: _appRoot.DRAWER_EXPANDED_WIDTH,
        transition: "".concat(theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }), " !important"),
        "& .MuiDrawer-paper": {
          width: _appRoot.DRAWER_EXPANDED_WIDTH,
          transition: "".concat(theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
          }), " !important")
        }
      },
      drawerBase: {
        flexShrink: 0,
        "& .MuiDrawer-paper": Object.assign({
          boxSizing: 'border-box',
          overflowX: 'hidden',
          overflowY: 'hidden'
        }, _styles.styles.column)
      }
    });
  });
  /**
   *
   * @param {Object} props
   * @param {boolean} props.isOpen
   * @param {Function} props.setIsOpen
   * @returns {JSX.Element}
   * @constructor
   */

  function MainSidebar(_ref) {
    var _cx;

    var isOpen = _ref.isOpen,
        setIsOpen = _ref.setIsOpen;
    var navigate = (0, _reactRouterDom.useNavigate)();

    var _useStyles = useStyles(),
        classes = _useStyles.classes,
        cx = _useStyles.cx;

    return (0, _jsxRuntime.jsxs)(_material.Drawer, Object.assign({
      variant: 'persistent',
      className: cx(classes.column, classes.drawerBase, (_cx = {}, _defineProperty(_cx, classes.menuOpen, isOpen), _defineProperty(_cx, classes.menuClosed, !isOpen), _cx)),
      open: true
    }, {
      children: [(0, _jsxRuntime.jsx)(_material.Toolbar, {
        variant: 'dense',
        sx: {
          minHeight: 40
        }
      }), (0, _jsxRuntime.jsxs)(_material.List, Object.assign({
        disablePadding: true,
        sx: {
          '& li': {
            whiteSpace: 'nowrap'
          }
        },
        className: cx('flex', classes.overflowXHidden, classes.overflowYScroll)
      }, {
        children: [(0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true,
          onClick: function onClick() {
            return navigate('/home');
          }
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Home, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Home'
            })]
          })
        })), (0, _jsxRuntime.jsxs)(ExpandableSidebarItem, Object.assign({
          parentText: 'Accounts Payable',
          parentIcon: (0, _jsxRuntime.jsx)(_iconsMaterial.CreditCard, {}),
          openParent: function openParent() {
            return setIsOpen(true);
          },
          parentOpen: isOpen
        }, {
          children: [(0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/manageVendors');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Vendor Management'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/postExpense/new');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Post Expense'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              return navigate('/issueCheck/new');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Issue Check'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/payInvoices');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Pay Invoices'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/viewCheck/unknown');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'View Check'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/viewVendorInvoice/unknown');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'View Vendor Invoice'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              return navigate('/apKeyOff/unknown');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Key Off'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            onClick: function onClick() {
              navigate('/bankReconciliation');
            },
            disablePadding: true,
            dense: true
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Bank Reconciliation'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/process1099s');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: '1099 Processing'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Reports'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              return navigate('/accountsPayableSettings');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Settings'
              })
            })
          }))]
        })), (0, _jsxRuntime.jsxs)(ExpandableSidebarItem, Object.assign({
          parentText: 'Accounts Receivable',
          parentIcon: (0, _jsxRuntime.jsx)(_iconsMaterial.AttachMoney, {}),
          openParent: function openParent() {
            return setIsOpen(true);
          },
          parentOpen: isOpen
        }, {
          children: [(0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              return navigate('/manageCustomers');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Customer Management'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/postCashReceipt/unknown');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Post Cash Receipt'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/postSale/unknown');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Post Sale'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/viewCustomerInvoice/unknown');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'View Customer Invoice'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              return navigate('/arKeyOff/unknown');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Key Off'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Reports'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              return navigate('/accountsReceivableSettings');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Settings'
              })
            })
          }))]
        })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Dashboard, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Dashboards'
            })]
          })
        })), (0, _jsxRuntime.jsxs)(ExpandableSidebarItem, Object.assign({
          parentText: "General Ledger",
          parentIcon: (0, _jsxRuntime.jsx)(_iconsMaterial.ChromeReaderMode, {}),
          openParent: function openParent() {
            return setIsOpen(true);
          },
          parentOpen: isOpen
        }, {
          children: [(0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/manageLedgerAccounts');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Ledger Management'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/documentPosting');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Document Posting'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/unapprovedPostings');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Unapproved Postings'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/manageSubledgers');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Subledger Management'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/manageLedgerCategories');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Category Management'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/manageLedgerDivisions');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Division Management'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/postingConfiguration');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Posting Configuration'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/generalLedgerSettings');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Settings'
              })
            })
          }))]
        })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Receipt, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Invoicing'
            })]
          })
        })), (0, _jsxRuntime.jsxs)(ExpandableSidebarItem, Object.assign({
          parentText: "Inventory",
          parentIcon: (0, _jsxRuntime.jsx)(_iconsMaterial.Inventory, {}),
          openParent: function openParent() {
            return setIsOpen(true);
          },
          parentOpen: isOpen
        }, {
          children: [(0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/manageProducts');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Manage Products'
              })
            })
          })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
            disablePadding: true,
            dense: true,
            onClick: function onClick() {
              navigate('/inventorySettings');
            }
          }, {
            children: (0, _jsxRuntime.jsx)(_material.ListItemButton, {
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: 'Settings'
              })
            })
          }))]
        })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Construction, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Job Costing'
            })]
          })
        })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.BatchPrediction, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Resource Planning'
            })]
          })
        })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Build, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Utilities'
            })]
          })
        })), (0, _jsxRuntime.jsx)(_material.Divider, {}), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.AdminPanelSettings, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Administration'
            })]
          })
        })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.History, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Audit Trail'
            })]
          })
        })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Info, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Status'
            })]
          })
        })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.BugReport, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Support Ticket'
            })]
          })
        })), (0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
          disablePadding: true
        }, {
          children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
            children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.Help, {})
            }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
              primary: 'Wiki Help'
            })]
          })
        }))]
      }))]
    }));
  }
  /**
   *
   * @param {Object} props
   * @param {JSX.Element} props.parentIcon
   * @param {string} props.parentText
   * @param {boolean} props.parentOpen
   * @param {Function} props.openParent
   * @param props.children
   * @returns {JSX.Element}
   * @constructor
   */


  function ExpandableSidebarItem(_ref2) {
    var children = _ref2.children,
        parentIcon = _ref2.parentIcon,
        parentText = _ref2.parentText,
        parentOpen = _ref2.parentOpen,
        openParent = _ref2.openParent;

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        isOpen = _useState2[0],
        setIsOpen = _useState2[1];

    var _useState3 = (0, _react.useState)(parentOpen),
        _useState4 = _slicedToArray(_useState3, 2),
        oldParentOpen = _useState4[0],
        setOldParentOpen = _useState4[1];

    (0, _react.useEffect)(function () {
      setOldParentOpen(parentOpen);

      if (!parentOpen && oldParentOpen) {
        setIsOpen(false);
      } // eslint-disable-next-line

    }, [parentOpen]);
    return (0, _jsxRuntime.jsxs)(_material.Collapse, Object.assign({
      collapsedSize: 48,
      in: isOpen
    }, {
      children: [(0, _jsxRuntime.jsx)(_material.ListItem, Object.assign({
        onClick: function onClick() {
          return setIsOpen(function (pIsOpen) {
            if (!pIsOpen) {
              openParent();
            }

            return !pIsOpen;
          });
        },
        disablePadding: true
      }, {
        children: (0, _jsxRuntime.jsxs)(_material.ListItemButton, {
          children: [(0, _jsxRuntime.jsx)(_material.ListItemIcon, {
            children: parentIcon
          }), (0, _jsxRuntime.jsx)(_material.ListItemText, {
            primary: parentText
          })]
        })
      })), children]
    }));
  }

  var _default = MainSidebar;
  _exports.default = _default;
});