define("client/models/solicitation-checkup-material", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    updated: _emberData.default.attr(),
    checkupComplaint: _emberData.default.belongsTo('solicitation-checkup-complaint', {
      async: true
    }),
    material: _emberData.default.belongsTo('part-item', {
      async: true
    }),
    quantity: _emberData.default.attr()
  });

  _exports.default = _default;
});