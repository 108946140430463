define("client/helpers/contract-styler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contractStyler = contractStyler;
  _exports.default = void 0;

  function contractStyler(params
  /*, hash*/
  ) {
    if (moment(params.objectAt(0)).isAfter(moment(), 'day') || moment(params.objectAt(0)).isSame(moment(), 'day')) return "background-color:#dff0d8;";else return "background-color:#F3D6D6;";
  }

  var _default = Ember.Helper.helper(contractStyler);

  _exports.default = _default;
});