define("client/templates/ar-cust-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5cd46sAw",
    "block": "{\"statements\":[[1,[33,[\"ar-view-cust\"],null,[[\"model\",\"editCustomer\",\"closeCustomer\",\"createCustomer\",\"ledgerAccounts\",\"settings\",\"banks\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"editCustomer\"],null],[33,[\"route-action\"],[\"searchCustomers\"],null],[33,[\"route-action\"],[\"createCustomer\"],null],[28,[\"ledgerAccounts\"]],[28,[\"settings\"]],[28,[\"banks\"]]]]],false],[0,\"\\n\\n\"],[1,[26,[\"outlet\"]],false],[0,\"\\n\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/ar-cust-view.hbs"
    }
  });

  _exports.default = _default;
});