define("client/components/pr-payroll-division-management/component", ["exports", "client/react/payroll/payroll-division-management", "client/components/react-component/component"], function (_exports, _payrollDivisionManagement, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['height-100'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.reactRender(React.createElement(_payrollDivisionManagement.default, null));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.unmountReactElement();
    }
  });

  _exports.default = _default;
});