define("client/routes/pr-settings", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    actions: {
      willTransition: function willTransition(transition) {
        console.log(transition);

        if (transition.intent.url && transition.intent.url.indexOf('/pr-settings') > -1) {
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});