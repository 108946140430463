define("client/components/srvc-archive/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      setWo: function setWo(wo) {
        this.set('isViewing', wo);
      }
    }
  });

  _exports.default = _default;
});