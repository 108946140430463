define("client/components/paper-color-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run;

  var _default = Ember.Component.extend({
    classNames: ['layout-row'],
    changeAction: function changeAction() {
      console.log(this.$('paper-swatch-picker')[0].color);
      this.sendAction('onChange', this.$('paper-swatch-picker')[0].color);
    },
    didInsertElement: function didInsertElement() {
      this.$('paper-swatch-picker').on('color-changed', function (e) {
        console.log(e);
        run.debounce(this, this.changeAction, 10);
      }.bind(this));
    }
  });

  _exports.default = _default;
});