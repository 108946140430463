define("client/routes/jc-mass-commission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      toJob: function toJob(job) {
        this.transitionTo('jc-view-job', job.get('id'));
      }
    }
  });

  _exports.default = _default;
});