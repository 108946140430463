define("client/react/utilities/transforms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deserializeCsv = deserializeCsv;
  _exports.deserializeDate = deserializeDate;
  _exports.deserializeTime = deserializeTime;
  _exports.serializeCsv = serializeCsv;
  _exports.serializeDate = serializeDate;
  _exports.serializeTime = serializeTime;

  function deserializeDate(date) {
    if (date) {
      return "".concat(date.substr(5, 2), "/").concat(date.substr(8, 2), "/").concat(date.substr(0, 4));
    } else {
      return null;
    }
  }

  function serializeDate(date) {
    if (date) {
      if (date instanceof Date) {
        var dateAsString = date.toISOString();
        return "".concat(dateAsString.substr(6, 4), "-").concat(dateAsString.substr(0, 2), "-").concat(dateAsString.substr(3, 2));
      } else {
        return "".concat(date.substr(6, 4), "-").concat(date.substr(0, 2), "-").concat(date.substr(3, 2));
      }
    } else {
      return null;
    }
  }

  function deserializeTime(time) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        isUtc = _ref.isUtc,
        formatter = _ref.formatter;

    if (time) {
      var formatToUse = formatter || "hhmm";
      return isUtc ? moment.utc(time, formatToUse).local().toDate() : moment(time, formatToUse).toDate();
    } else {
      return null;
    }
  }

  function serializeTime(time) {
    if (time) {
      var hour = parseInt(time.substr(0, 2));
      var minute = parseInt(time.substr(3, 2));
      return "".concat(hour).concat(minute);
    } else {
      return '';
    }
  }

  function deserializeCsv(string) {
    return !!string ? string.split(',') : [];
  }

  function serializeCsv(array) {
    return array ? array.join() : '';
  }
});