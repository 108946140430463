define("client/helpers/key-value-concat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.keyValueConcat = keyValueConcat;

  function keyValueConcat(item, value) {
    var t = value.get(item.get('key'));

    if (item.get('maxlength')) {
      if (item.get('type') === "currency" && t) {
        return new Ember.String.htmlSafe('{{input type="text" class="form-control input-right-align" value=' + "$" + parseFloat(t).toFixed(2) + ' maxlength=' + item.get('maxlength') + '}}');
      } else {
        return new Ember.String.htmlSafe('{{input type="text" class="form-control" value=' + t + ' maxlength=' + item.get('maxlength') + '}}');
      }
    } else {
      if (item.get('type') === "currency" && t) {
        return new Ember.String.htmlSafe('{{input type="text" class="form-control input-right-align" value=' + "$" + parseFloat(t).toFixed(2) + '}}');
      } else {
        return new Ember.String.htmlSafe('{{input type="text" class="form-control" value=' + t + '}}');
      }
    }
  }

  var _default = Ember.Handlebars.makeBoundHelper(keyValueConcat);

  _exports.default = _default;
});