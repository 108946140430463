define("client/templates/inv-po-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "18xZZvT0",
    "block": "{\"statements\":[[1,[26,[\"inv-po-history\"]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/inv-po-history.hbs"
    }
  });

  _exports.default = _default;
});