define("client/transforms/currency", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return parseFloat(serialized).toFixed(2);
    },
    serialize: function serialize(deserialized) {
      return isNaN(parseFloat(deserialized)) ? 0 : parseFloat(deserialized);
    }
  });

  _exports.default = _default;
});