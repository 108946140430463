define("client/components/ledger-description/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  // Source refers to the source on the realtime ledger descriptions
  // key is only used by the template of this component to decide which link to use
  var sources = [{
    source: "Contract Solicitation Conversion",
    ids: [{
      key: "solicitation"
    }, {
      key: "contract"
    }]
  }, {
    source: "Contract Solicitation Unconversion",
    ids: [{
      key: "solicitation"
    }, {
      key: "contract"
    }]
  }, {
    source: "Contract Billing",
    ids: [{
      key: "contract"
    }]
  }, {
    source: "Contract Cash Receipts",
    ids: [{
      key: "contract"
    }]
  }];

  var _default = Ember.Component.extend({
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var source = this.get('record.source');
      var s = this.get('description') || "";

      var arr = _toConsumableArray(s.split(/(\(|\))/g));

      var ret = [];
      var nextWordIsId = false;
      var idCount = 0;
      var sourceConfig = sources.filter(function (x) {
        return x.source === source;
      })[0];

      for (var i = 0; i < arr.length; i++) {
        var curr = arr[i];
        var word = {};

        if (sourceConfig && curr === "(") {
          nextWordIsId = true;
        } else if (nextWordIsId) {
          word[sourceConfig.ids[idCount].key] = true;
          idCount++;
          nextWordIsId = false;
        }

        word['word'] = curr;
        ret.push(word);
      }

      this.set('words', Ember.A(ret));
    }
  });

  _exports.default = _default;
});