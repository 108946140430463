define("client/models/ledger-config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    ytdIncomeAccount: _emberData.default.attr(),
    retainedEarningsAccount: _emberData.default.attr(),
    masterPassword: _emberData.default.attr(),
    masterCloseDate: _emberData.default.attr(),
    documentPostingCloseType: _emberData.default.attr(),
    passwordDirty: _emberData.default.attr()
  });

  _exports.default = _default;
});