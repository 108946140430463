define("client/controllers/user-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['initialScreen', 'nylasLinkSuccess', 'nylasLinkFail'],
    initialScreen: null,
    nylasLinkSuccess: false,
    nylasLinkFail: false
  });

  _exports.default = _default;
});