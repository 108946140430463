define("client/models/flrate-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr(),
    laborCostRate: _emberData.default.attr(),
    laborSellRate: _emberData.default.attr(),
    otFactor: _emberData.default.attr(),
    addlFlatRateItemFactor: _emberData.default.attr(),
    srvcAgreementFactor: _emberData.default.attr()
  });

  _exports.default = _default;
});