define("client/components/ats-send-modal/component", ["exports", "client/config/environment", "client/react/utilities/snackbar"], function (_exports, _environment, _snackbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    date: null,
    startTime: null,
    endTime: null,
    srvcOrdId: null,
    newEmail: false,
    store: Ember.inject.service(),
    email: computed('initial', function () {
      return this.get('initial.address');
    }),
    type: null,
    initial: null,
    canSave: function () {
      return !this.get('email');
    }.property('email'),
    auth: Ember.inject.service(),
    utilities: Ember.inject.service(),
    // init() {
    // 	this._super(...arguments);
    // 	//this.set('date',moment(this.get('date'),'MM/DD/YYYY').format('YYYY-MM-DD'));
    // 	this.set('email',this.get('emails.firstObject.address'));
    // 	this.set('initial',this.get('emails.firstObject'));
    // 	this.set('endTime',moment(this.get('startTime'),'HH:mm').add(1,'hours').format('HH:mm'));
    // },
    actions: {
      close: function close() {
        this.get('close')();
      },
      newEmail: function newEmail() {
        this.set('newEmail', true);
      },
      closeNewEmail: function closeNewEmail() {
        this.set('newEmail', false);
      },
      updateEmail: function updateEmail(email) {
        this.set('email', email ? email.get('address') : null);
      },
      addEmail: function addEmail() {
        this.set('email', this.get('newEmailAddress') ? this.get('newEmailAddress') : null);
        var newE = this.get('store').createRecord('customer-email', {
          name: '',
          seq: '',
          businessPhone: '',
          homePhone: '',
          faxNumber: '',
          cellPhone: '',
          title: '',
          ext: '',
          flags2: '',
          type: 'C',
          address: this.get('newEmailAddress'),
          flags: '',
          custId: this.get('customer.id'),
          customer: this.get('customer')
        });
        newE.save();
        this.set('initial', newE);
        this.set('newEmail', false);
      },
      send: function send() {
        Ember.$.ajax({
          url: _environment.default.APP.endpoint + "/fsm/sendATS",
          type: 'post',
          data: this.getProperties('date', 'startTime', 'endTime', 'srvcOrdId', 'email', 'type'),
          success: function (success) {
            (0, _snackbar.showSnackbar)("Email successfully sent", {
              variant: 'success'
            });
            this.get('onsend')();
          }.bind(this),
          error: function (reason) {
            if (reason.status === 200) {
              (0, _snackbar.showSnackbar)("Email successfully sent", {
                variant: 'success'
              });
              this.get('onsend')();
            } else {
              (0, _snackbar.showSnackbar)("Failed to send", {
                variant: 'error'
              });
            }
          }.bind(this),
          headers: {
            "X-Auth-Tenant": this.get('auth.tenantId'),
            "X-Auth-Token": this.get('auth.access_token')
          },
          dataType: 'json'
        });
      }
    }
  });

  _exports.default = _default;
});