define("client/components/counted-text-area/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inValue: "",
    isInserted: false,
    attributeBindings: ['style'],
    style: "height:100%;",
    init: function init() {
      this._super.apply(this, arguments);

      this.set('isInserted', true);
    },
    keyDown: function keyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    },
    charsLeft: function () {
      if (this.get('isInserted')) {
        var count = this.get('maxlength') - this.get('inValue.length');
        return count;
      }

      return this.get('maxlength');
    }.property('inValue', 'maxlength', 'isInserted'),
    actions: {
      enterDown: function enterDown() {
        if (this.get('enter')) {
          this.get('enter')();
        }
      }
    }
  });

  _exports.default = _default;
});