define("client/helpers/format-checkups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCheckups = formatCheckups;

  function formatCheckups(params
  /*, hash*/
  ) {
    var arr = params[0];

    if (arr === null) {
      return "";
    } else {
      return arr.sortBy("originalMonthYear").reduce(function (prev, curr, idx) {
        if (curr.get('forRenewal')) {
          prev.push("<span><b>Renewal Checkup: </b>" + curr.get('monthYear') + "</span>");
          return prev;
        } else {
          prev.push("<span><b>Checkup ".concat(idx + 1, ": </b>") + curr.get('monthYear') + "</span>");
          return prev;
        }
      }, []).join(", ");
    }
  }

  var _default = Ember.Helper.helper(formatCheckups);

  _exports.default = _default;
});