define("client/transitions/web-fly-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hero;
  var RSVP = Ember.RSVP;

  function hero() {
    var _this = this;

    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    // if (!this.newElement) {
    //   return RSVP.resolve();
    // } else if (!this.oldElement) {
    //   this.newElement.css({visibility: ''});
    //   return RSVP.resolve();
    // }
    var translateX, translateY;
    var fromRect = this.oldElement[0].getBoundingClientRect(); // var oldOffset = this.oldElement.offset();
    // var newOffset = this.newElement.offset();

    var toRect = this.newElement[0].getBoundingClientRect();
    var deltaLeft = fromRect.left - toRect.left;
    var deltaTop = fromRect.top - toRect.top;
    var deltaWidth = toRect.width / fromRect.width;
    var deltaHeight = toRect.height / fromRect.height; // translateX = (toRect.left ) - (fromRect.left + (fromRect.width / 2));
    // translateY = (toRect.top + (toRect.height / 2)) - (fromRect.top + (fromRect.height / 2));
    // var translate = 'translate(' + translateX + 'px,' + translateY + 'px)';
    // var size = Math.max(fromRect.width + Math.abs(translateX) * 2, fromRect.height + Math.abs(translateY) * 2);
    // var diameter = Math.sqrt(2 * size * size);
    // var scaleX = diameter / fromRect.width;
    // var scaleY = diameter / fromRect.height;
    // var scale = 'scale(' + scaleX + ',' + scaleY + ')';
    // console.log(this.newElement);

    var finalState = {
      'transform': 'translate(' + deltaLeft + 'px,' + deltaTop + 'px) scale(' + deltaWidth + ',' + deltaHeight + ')'
    };
    var initialState = {
      transform: 'none'
    };
    return new RSVP.Promise(function (resolve) {
      _this.newElement.css({
        visibility: 'hidden'
      });

      var animationRunner = _this.oldElement[0].animate([initialState, finalState], {
        duration: opts.duration || 500
      });

      animationRunner.onfinish = function () {
        _this.oldElement.css({
          visibility: 'hidden'
        });

        _this.newElement.css({
          visibility: ''
        });

        resolve();
      };
    });
  }
});