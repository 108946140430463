define("client/templates/pr-employee-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ekeD+ajE",
    "block": "{\"statements\":[[1,[33,[\"pr-employee-edit\"],null,[[\"selectEmployee\",\"employee\"],[[33,[\"route-action\"],[\"transition\",\"pr-employee-view\"],null],[33,[\"changeset\"],[[28,[\"model\"]]],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/pr-employee-edit.hbs"
    }
  });

  _exports.default = _default;
});