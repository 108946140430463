define("client/models/image", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Invoice = _emberData.default.Model.extend({
    srvcOrdId: _emberData.default.attr(),
    custId: _emberData.default.attr(),
    shipToId: _emberData.default.attr(),
    url: _emberData.default.attr()
  });

  var _default = Invoice;
  _exports.default = _default;
});