define("client/react/payroll/selectors", ["exports", "@reduxjs/toolkit"], function (_exports, _toolkit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activePayRate = activePayRate;
  _exports.activePayrollsByIdSelector = activePayrollsByIdSelector;
  _exports.activePayrollsSelector = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var payrollSelector = function payrollSelector(state) {
    return Object.values(state.data.payroll.byId);
  };

  var activePayrollsSelector = (0, _toolkit.createSelector)(payrollSelector, function (payrolls) {
    return payrolls.filter(function (x) {
      return !x.processed;
    });
  });
  _exports.activePayrollsSelector = activePayrollsSelector;

  function activePayrollsByIdSelector(state) {
    var byId = state.data.payroll.byId;
    var keys = Object.keys(byId);
    var ret = {};

    for (var i = 0; i < keys.length; i++) {
      var temp = byId[keys[i]];

      if (!temp.processed) {
        ret[keys[i]] = temp;
      }
    }

    return ret;
  }

  function activePayRate(state, employeeId) {
    var validPayRates = Object.values(state.data.employeePayRate.byId).filter(function (x) {
      return employeeId && x.employee === employeeId;
    }).filter(function (x) {
      return !moment().isBefore(moment(x.effectiveDatetime));
    });
    var maxPayRate = validPayRates[0];

    for (var i = 1; i < validPayRates.length; i++) {
      var payRate = validPayRates[i];

      if (moment(payRate.effectiveDatetime).isAfter(moment(maxPayRate.effectiveDatetime))) {
        maxPayRate = payRate;
      }
    }

    if (maxPayRate && maxPayRate.hourly) {
      return maxPayRate;
    } else {
      if (!maxPayRate) {
        return;
      }

      return _objectSpread(_objectSpread({}, maxPayRate), {}, {
        pay: 0
      });
    }
  }
});