define("client/templates/dispatch/email-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qJSpuIYk",
    "block": "{\"statements\":[[6,[\"full-modal\"],null,[[\"action\"],[\"closeEmail\"]],{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"modal-header\"],[13],[0,\"\\n\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"modal-body\"],[15,\"style\",\"max-height:82%;\"],[13],[0,\"\\n        To: \"],[1,[33,[\"input\"],null,[[\"value\",\"type\",\"class\"],[[28,[\"emailTo\"]],\"text\",\"form-control\"]]],false],[0,\"\\n        Subject: \"],[1,[33,[\"input\"],null,[[\"value\",\"type\",\"class\"],[[28,[\"emailSubject\"]],\"text\",\"form-control\"]]],false],[0,\"\\n        Body: \"],[1,[33,[\"input\"],null,[[\"value\",\"type\",\"class\"],[[28,[\"emailBody\"]],\"text\",\"form-control\"]]],false],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"modal-footer\"],[15,\"style\",\"max-height: 10%;\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"style\",\"display: inline-block; vertical-align: middle;\"],[13],[0,\"\\n            \"],[11,\"button\",[]],[15,\"class\",\"btn btn-default\"],[5,[\"action\"],[[28,[null]],\"closeEmail\"]],[13],[0,\"Close\"],[14],[0,\"\\n            \"],[11,\"button\",[]],[15,\"class\",\"btn btn-default\"],[5,[\"action\"],[[28,[null]],\"testEmail\"]],[13],[0,\"Send\"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/dispatch/email-modal.hbs"
    }
  });

  _exports.default = _default;
});