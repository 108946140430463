define("client/components/select-cust-site/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      setCustomer: function setCustomer(cust) {
        this.get('setCustomer')(cust);
      },
      setSite: function setSite(site) {
        this.get('setSite')(site);
      },
      setCustomerAndSite: function setCustomerAndSite(cust, site) {
        this.send('setCustomer', cust);
        this.send('setSite', site);
      },
      closeWithoutSite: function closeWithoutSite() {
        this.get('closeWithoutSite')();
      },
      clearCustomer: function clearCustomer() {
        this.get('setCustomer')(null);
      },
      close: function close() {
        this.get('close')();
      }
    }
  });

  _exports.default = _default;
});