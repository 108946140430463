define("client/react/payroll/compute-payroll", ["exports", "react", "client/react/payroll/compute-payroll-list", "react-router", "react-router-dom", "client/react/payroll/compute-payroll-statement", "client/react/payroll/payroll-complete", "react-redux", "client/react/payroll/reducer"], function (_exports, _react, _computePayrollList, _reactRouter, _reactRouterDom, _computePayrollStatement, _payrollComplete, _reactRedux, _reducer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ComputePayroll() {
    var dispatch = (0, _reactRedux.useDispatch)(); // retrieve payrollSettings

    (0, _react.useEffect)(function () {
      dispatch((0, _reducer.fetchSelectedPayroll)());
    }, []);
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactRouterDom.HashRouter, null, _react.default.createElement(_reactRouter.Switch, null, _react.default.createElement(_reactRouter.Route, {
      path: "/pr-compute-payroll/:id/complete"
    }, _react.default.createElement(_payrollComplete.default, null)), _react.default.createElement(_reactRouter.Route, {
      path: "/pr-compute-payroll/:id"
    }, _react.default.createElement(_computePayrollStatement.default, null)), _react.default.createElement(_reactRouter.Route, {
      exact: true,
      path: "/pr-compute-payroll"
    }, _react.default.createElement(_computePayrollList.default, null)))));
  }

  var _default = ComputePayroll;
  _exports.default = _default;
});