define("client/routes/pr-compute-payroll", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    featureFlags: Ember.inject.service(),
    model: function model() {
      if (!this.get('featureFlags.featureFlags.payrollRework')) {
        return Ember.RSVP.hash({
          employees: this.get('store').findAll('employee', {
            reload: true
          }),
          payrollEntries: this.get('store').findAll('temp-payroll', {
            reload: true
          }),
          payrollHours: this.store.findAll('payroll-hours', {
            reload: true
          })
        });
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        console.log(transition);

        if (transition.intent.url && transition.intent.url.indexOf('/pr-compute-payroll') > -1) {
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});