define("client/models/payroll-history", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    employeeId: _emberData.default.attr(),
    date: _emberData.default.attr(),
    sequence: _emberData.default.attr(),
    checkNumber: _emberData.default.attr(),
    regHours: _emberData.default.attr(),
    otHours: _emberData.default.attr(),
    dtHours: _emberData.default.attr(),
    sickHours: _emberData.default.attr(),
    vacationHours: _emberData.default.attr(),
    otherHours: _emberData.default.attr(),
    holidayHours: _emberData.default.attr(),
    regEarnings: _emberData.default.attr(),
    otEarnings: _emberData.default.attr(),
    dtEarnings: _emberData.default.attr(),
    sickEarnings: _emberData.default.attr(),
    vacationEarnings: _emberData.default.attr(),
    otherEarnings: _emberData.default.attr(),
    holidayEarnings: _emberData.default.attr(),
    commission: _emberData.default.attr(),
    salary: _emberData.default.attr(),
    travel: _emberData.default.attr(),
    //10
    reimbursement: _emberData.default.attr(),
    //11
    ficaWh: _emberData.default.attr(),
    fedWh: _emberData.default.attr(),
    stateWh: _emberData.default.attr(),
    cityWh: _emberData.default.attr(),
    dblWh: _emberData.default.attr(),
    misc1: _emberData.default.attr(),
    misc2: _emberData.default.attr(),
    misc3: _emberData.default.attr(),
    misc4: _emberData.default.attr(),
    misc5: _emberData.default.attr(),
    misc6: _emberData.default.attr(),
    misc7: _emberData.default.attr(),
    misc8: _emberData.default.attr(),
    misc9: _emberData.default.attr(),
    other: _emberData.default.attr(),
    eic: _emberData.default.attr(),
    taxState: _emberData.default.attr(),
    taxCity: _emberData.default.attr(),
    division: _emberData.default.attr(),
    sec125Flag: _emberData.default.attr(),
    netAmount: Ember.computed('regEarnings', 'otEarnings', 'dtEarnings', 'sickEarnings', 'vacationEarnings', 'otherEarnings', 'holidayEarnings', 'commission', 'salary', 'travel', 'reimbursement', 'ficaWh', 'fedWh', 'stateWh', 'cityWh', 'dblWh', 'misc1', 'misc2', 'misc3', 'misc4', 'misc5', 'misc6', 'misc7', 'misc8', 'misc9', 'other', function () {
      var reg = isNaN(parseFloat(this.get('regEarnings'))) ? 0 : parseFloat(this.get('regEarnings')),
          ot = isNaN(parseFloat(this.get('otEarnings'))) ? 0 : parseFloat(this.get('otEarnings')),
          dt = isNaN(parseFloat(this.get('dtEarnings'))) ? 0 : parseFloat(this.get('dtEarnings')),
          sick = isNaN(parseFloat(this.get('sickEarnings'))) ? 0 : parseFloat(this.get('sickEarnings')),
          vacation = isNaN(parseFloat(this.get('vacationEarnings'))) ? 0 : parseFloat(this.get('vacationEarnings')),
          other = isNaN(parseFloat(this.get('otherEarnings'))) ? 0 : parseFloat(this.get('otherEarnings')),
          holiday = isNaN(parseFloat(this.get('holidayEarnings'))) ? 0 : parseFloat(this.get('holidayEarnings')),
          commission = isNaN(parseFloat(this.get('commission'))) ? 0 : parseFloat(this.get('commission')),
          salary = isNaN(parseFloat(this.get('salary'))) ? 0 : parseFloat(this.get('salary')),
          travel = isNaN(parseFloat(this.get('travel'))) ? 0 : parseFloat(this.get('travel')),
          reimbursement = isNaN(parseFloat(this.get('reimbursement'))) ? 0 : parseFloat(this.get('reimbursement')),
          fica = isNaN(parseFloat(this.get('ficaWh'))) ? 0 : parseFloat(this.get('ficaWh')),
          fed = isNaN(parseFloat(this.get('fedWh'))) ? 0 : parseFloat(this.get('fedWh')),
          state = isNaN(parseFloat(this.get('stateWh'))) ? 0 : parseFloat(this.get('stateWh')),
          city = isNaN(parseFloat(this.get('cityWh'))) ? 0 : parseFloat(this.get('cityWh')),
          dbl = isNaN(parseFloat(this.get('dblWh'))) ? 0 : parseFloat(this.get('dblWh')),
          misc1 = isNaN(parseFloat(this.get('misc1'))) ? 0 : parseFloat(this.get('misc1')),
          misc2 = isNaN(parseFloat(this.get('misc2'))) ? 0 : parseFloat(this.get('misc2')),
          misc3 = isNaN(parseFloat(this.get('misc3'))) ? 0 : parseFloat(this.get('misc3')),
          misc4 = isNaN(parseFloat(this.get('misc4'))) ? 0 : parseFloat(this.get('misc4')),
          misc5 = isNaN(parseFloat(this.get('misc5'))) ? 0 : parseFloat(this.get('misc5')),
          misc6 = isNaN(parseFloat(this.get('misc6'))) ? 0 : parseFloat(this.get('misc6')),
          misc7 = isNaN(parseFloat(this.get('misc7'))) ? 0 : parseFloat(this.get('misc7')),
          misc8 = isNaN(parseFloat(this.get('misc8'))) ? 0 : parseFloat(this.get('misc8')),
          misc9 = isNaN(parseFloat(this.get('misc9'))) ? 0 : parseFloat(this.get('misc9')),
          otherWh = isNaN(parseFloat(this.get('other'))) ? 0 : parseFloat(this.get('other'));
      return (reg + ot + dt + sick + vacation + other + holiday + commission + salary + travel + reimbursement - fica - fed - state - city - dbl - misc1 - misc2 - misc3 - misc4 - misc5 - misc6 - misc7 - misc8 - misc9 - otherWh).toFixed(2);
    }),
    employee: _emberData.default.belongsTo('employee', {
      async: true
    })
  });

  _exports.default = _default;
});