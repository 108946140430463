define("client/react/components/typed/user-avatar", ["exports", "react/jsx-runtime", "@mui/material", "@mui/icons-material", "client/react/components/hooks/use-menu", "client/react/components/styles/styles", "react-redux", "client/react/user-settings/data/slice"], function (_exports, _jsxRuntime, _material, _iconsMaterial, _useMenu2, _styles, _reactRedux, _slice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function UserAvatar() {
    var dispatch = (0, _reactRedux.useDispatch)();
    var useDarkTheme = (0, _reactRedux.useSelector)(function (state) {
      return state.userSettings.useDarkTheme;
    });

    var _useMenu = (0, _useMenu2.default)({
      childrenProps: [{
        onClick: function onClick() {
          return closeMenu();
        },
        children: "User Settings"
      }, {
        onClick: function onClick() {
          return closeMenu();
        },
        children: "Log Out"
      }, {
        isDivider: true
      }, {
        onClick: function onClick() {
          closeMenu();
          dispatch((0, _slice.toggleDarkTheme)());
        },
        children: !useDarkTheme ? (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: ["Dark Mode\xA0", (0, _jsxRuntime.jsx)(_iconsMaterial.Brightness7, {})]
        }) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: ["Light Mode\xA0", (0, _jsxRuntime.jsx)(_iconsMaterial.Brightness4, {})]
        })
      }]
    }),
        setAnchorEl = _useMenu.setAnchorEl,
        MenuComponent = _useMenu.MenuComponent,
        closeMenu = _useMenu.closeMenu;

    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_material.Avatar, Object.assign({
        sx: Object.assign(Object.assign({}, _styles.styles.cursorPointer), {
          height: 32,
          width: 32
        }),
        onClick: function onClick(e) {
          setAnchorEl(e.currentTarget);
        }
      }, {
        children: "JH"
      })), MenuComponent]
    });
  }

  var _default = UserAvatar;
  _exports.default = _default;
});