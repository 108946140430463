define("client/models/invoice-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    prodId: _emberData.default.attr('capitalize'),
    invOrdKey: _emberData.default.attr(),
    // inv# + orderNumber1 + orderNumber2 + invoiceDate in citron (with letters) format
    seq: _emberData.default.attr(),
    orderQty: _emberData.default.attr('whole-number'),
    shipQty: _emberData.default.attr('whole-number'),
    prodInvGlCode: _emberData.default.attr(),
    filler: _emberData.default.attr(),
    prodDescription: _emberData.default.attr('capitalize'),
    unitPrice: _emberData.default.attr(),
    lineAmount: _emberData.default.attr(),
    prodWeight: _emberData.default.attr(),
    uom: _emberData.default.attr(),
    unitCost: _emberData.default.attr(),
    allocateAssigned: _emberData.default.attr(),
    discountPct: _emberData.default.attr(),
    prodCostGlCode: _emberData.default.attr(),
    changeFlag: _emberData.default.attr(),
    taxableFlag: _emberData.default.attr('yn-tf'),
    prodCategory: _emberData.default.attr(),
    manualAmountFlag: _emberData.default.attr(),
    pageNumber: _emberData.default.attr(),
    pageLineQty: _emberData.default.attr('whole-number'),
    longDescription: Ember.computed('prodId', 'prodDescription', {
      get: function get(key) {
        return this.get('prodId') + this.get('prodDescription');
      },
      set: function set(key, value) {
        if (value.length > 15) {
          this.set('prodId', value.substr(0, 15));
          this.set('prodDescription', value.substr(15, 30));
        } else {
          this.set('prodId', value.substr(0, 15));
          this.set('prodDescription', 0);
        }

        return this.get('prodId') + this.get('prodDescription');
      }
    })
  });

  _exports.default = _default;
});