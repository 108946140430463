define("client/components/landing-element/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fbGJpvx1",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"landing-element layout-column layout-align-start-center\"],[13],[0,\"\\n    \"],[6,[\"paper-button\"],null,[[\"class\",\"iconButton\",\"primary\",\"onClick\"],[\"landing-button\",true,true,[33,[\"action\"],[[28,[null]],[28,[\"onSelect\"]],[28,[\"title\"]]],null]]],{\"statements\":[[1,[33,[\"paper-icon\"],[[28,[\"icon\"]]],[[\"size\"],[\"64\"]]],false]],\"locals\":[]},null],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"landing-element-title\"],[13],[1,[26,[\"title\"]],false],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/landing-element/template.hbs"
    }
  });

  _exports.default = _default;
});