define("client/components/tree-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7kgcoQt7",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"ember-table-tables-container\"],[13],[0,\"\\n\\t\"],[18,\"default\",[[33,[\"hash\"],null,[[\"left\",\"right\",\"headerLeft\",\"headerRight\"],[[33,[\"component\"],[\"tree-left-block\"],null],[33,[\"component\"],[\"tree-right-block\"],null],[33,[\"component\"],[\"tree-table-header\"],null],[33,[\"component\"],[\"tree-table-right-header\"],null]]]]]],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/tree-table/template.hbs"
    }
  });

  _exports.default = _default;
});