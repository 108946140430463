define("client/transforms/csv", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized !== null) {
        var ret = serialized.split(",");

        if (ret.length === 1 && ret[0] === "") {
          ret = [];
        }

        return ret;
      } else {
        return [];
      }
    },
    serialize: function serialize(deserialized) {
      //    var s = deserialized[0];
      //    var i;
      //    for (i = 1; i < deserialized.length; i++) {
      //      s += "," + deserialized[i];
      //    }
      if (!deserialized) {
        return "";
      }

      var t = deserialized.join(",");

      if (t !== null) {
        return t;
      } else {
        return "";
      }
    }
  });

  _exports.default = _default;
});