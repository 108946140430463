define("client/models/payroll-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    employee: _emberData.default.belongsTo('employee'),
    employeeId: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    note: _emberData.default.attr()
  });

  _exports.default = _default;
});