define("client/models/prior-period-transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tranKey: _emberData.default.attr(),
    vendId: _emberData.default.attr(),
    jobId: _emberData.default.attr(),
    date: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    amtPaid: _emberData.default.attr(),
    discAmt: _emberData.default.attr(),
    dueDate: _emberData.default.attr(),
    transactionHold: _emberData.default.attr()
  });

  _exports.default = _default;
});