define("client/templates/srvc-contract-solicitation-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vz7BnT+r",
    "block": "{\"statements\":[[1,[33,[\"srvc-contract-solicitation-search\"],null,[[\"types\",\"settings\",\"toView\",\"toCreate\",\"toConvert\"],[[28,[\"model\",\"types\"]],[28,[\"model\",\"settings\"]],[33,[\"route-action\"],[\"toView\"],null],[33,[\"route-action\"],[\"toCreate\"],null],[33,[\"route-action\"],[\"toConvert\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/srvc-contract-solicitation-search.hbs"
    }
  });

  _exports.default = _default;
});