define("client/components/interior-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jyTyAVr2",
    "block": "{\"statements\":[[11,\"span\",[]],[16,\"class\",[34,[\"interior-header-component \",[33,[\"if\"],[[28,[\"class\"]],[28,[\"class\"]]],null]]]],[16,\"style\",[33,[\"if\"],[[28,[\"style\"]],[28,[\"style\"]],\"\"],null],null],[13],[1,[26,[\"label\"]],false],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/interior-header/template.hbs"
    }
  });

  _exports.default = _default;
});