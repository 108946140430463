define("client/components/required-text-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yQegMX83",
    "block": "{\"statements\":[[1,[33,[\"input\"],null,[[\"type\",\"placeholder\",\"required\",\"value\",\"class\",\"disabled\",\"maxlength\"],[\"text\",[28,[\"placeholder\"]],\"required\",[28,[\"inValue\"]],\"form-control\",[28,[\"disabled\"]],[28,[\"maxlength\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/required-text-input/template.hbs"
    }
  });

  _exports.default = _default;
});