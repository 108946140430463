define("client/models/customer-discount", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    custId: _emberData.default.attr(),
    jobNumber: _emberData.default.attr('capitalize'),
    type: _emberData.default.attr(),
    partId: _emberData.default.attr('capitalize'),
    percentage: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    column: _emberData.default.attr(),
    specialPrice: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    sensiblePriceColumn: Ember.computed('column', function () {
      switch (this.get('column')) {
        case "R":
          return 1;

        case "C":
          return 7;

        case "1":
          return 2;

        case "2":
          return 3;

        case "3":
          return 4;

        case "4":
          return 5;

        case "5":
          return 6;

        default:
          return null;
      }
    })
  });

  _exports.default = _default;
});