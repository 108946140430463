define("client/components/vr-paper-select-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ho4/iF9f",
    "block": "{\"statements\":[[18,\"default\",[[33,[\"hash\"],null,[[\"isOpen\",\"disabled\",\"actions\",\"trigger\",\"content\"],[[28,[\"publicAPI\",\"isOpen\"]],[28,[\"publicAPI\",\"disabled\"]],[28,[\"publicAPI\",\"actions\"]],[33,[\"component\"],[[28,[\"triggerComponent\"]]],[[\"tagName\",\"dropdown\",\"hPosition\",\"onFocus\",\"renderInPlace\",\"vPosition\"],[\"md-select\",[33,[\"readonly\"],[[28,[\"publicAPI\"]]],null],[33,[\"readonly\"],[[28,[\"hPosition\"]]],null],[33,[\"action\"],[[28,[null]],\"handleFocus\"],null],[33,[\"readonly\"],[[28,[\"renderInPlace\"]]],null],[33,[\"readonly\"],[[28,[\"vPosition\"]]],null]]]],[33,[\"component\"],[\"paper-select-content\"],[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"vPosition\",\"top\",\"left\",\"right\",\"width\"],[[33,[\"readonly\"],[[28,[\"publicAPI\"]]],null],[33,[\"readonly\"],[[28,[\"hPosition\"]]],null],[33,[\"readonly\"],[[28,[\"renderInPlace\"]]],null],[33,[\"readonly\"],[[28,[\"vPosition\"]]],null],[33,[\"readonly\"],[[28,[\"top\"]]],null],[33,[\"readonly\"],[[28,[\"left\"]]],null],[33,[\"readonly\"],[[28,[\"right\"]]],null],[33,[\"readonly\"],[[28,[\"width\"]]],null]]]]]]]]]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/vr-paper-select-menu/template.hbs"
    }
  });

  _exports.default = _default;
});