define("client/components/virtual-body/component", ["exports", "virtual-each/components/virtual-each"], function (_exports, _virtualEach) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _virtualEach.default.extend({
    tagName: 'div',
    classNames: ['table-wrapper'],
    attributeBindings: ['style'],
    style: "overflow-y: auto;position: relative; max-height: 300px; height:300px;"
  });

  _exports.default = _default;
});