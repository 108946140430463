define("client/components/paper-virtual-repeat2/component", ["exports", "ember-paper/components/paper-virtual-repeat"], function (_exports, _paperVirtualRepeat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _paperVirtualRepeat.default.extend({
    size: computed('initialSize', 'items.[]', 'itemHeight', function () {
      var itemSize = this.get('itemHeight');
      var fullSize = ((this.get('extraItems') ? this.get('extraItems') : 0) + this.get('items.length')) * itemSize;

      if (fullSize <= itemSize) {
        return itemSize;
      }

      return Math.min(fullSize, this.get('initialSize'));
    })
  });

  _exports.default = _default;
});