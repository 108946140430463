define("client/components/paper-virtual-repeat2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wI8ZWq3l",
    "block": "{\"statements\":[[6,[\"paper-virtual-repeat-scroller\"],null,[[\"onScroll\"],[[33,[\"action\"],[[28,[null]],\"onScroll\"],null]]],{\"statements\":[[0,\"  \"],[11,\"div\",[]],[15,\"class\",\"md-virtual-repeat-sizer\"],[16,\"style\",[26,[\"contentStyle\"]],null],[13],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"ignoreOffsetter\"]]],null,{\"statements\":[[0,\"  \\t\"],[11,\"div\",[]],[15,\"class\",\"md-virtual-repeat-offsetter\"],[13],[14],[0,\"\\n\\n\"]],\"locals\":[]},{\"statements\":[[0,\"  \\t\"],[11,\"div\",[]],[15,\"class\",\"md-virtual-repeat-offsetter\"],[16,\"style\",[26,[\"offsetterStyle\"]],null],[13],[0,\"\\n    \\t\"],[18,\"default\",[[28,[\"rawVisibleItems\"]],[28,[\"visibleItems\"]]]],[0,\"\\n  \\t\"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},null],[6,[\"if\"],[[28,[\"ignoreOffsetter\"]]],null,{\"statements\":[[0,\"\\t\"],[18,\"default\",[[28,[\"rawVisibleItems\"]],[28,[\"visibleItems\"]]]],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/paper-virtual-repeat2/template.hbs"
    }
  });

  _exports.default = _default;
});