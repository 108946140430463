define("client/routes/equipment-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.Object.create({
        id: null,
        model: "",
        custId: "",
        //cust && cust.get('id') ? cust.get('id') : "",
        shipToId: "",
        //site && site.get('id') ? site.get('shipId') : "",
        serial: "",
        description: "",
        notes: "",
        category: "E",
        partsWarrantyExpires: null,
        laborWarrantyExpires: null,
        equipmentCondition: "",
        manufacturer: "",
        installDate: null,
        purchasedFromUs: false,
        dateInService: null,
        lastServiceDate: null,
        contractCoversLabor: false,
        contractCoversParts: false,
        contractNumber: "",
        seerRating: "",
        capacity: "",
        inAttic: false,
        siteAddress: null,
        //site && site.get('id') ? site : null,
        customer: null //cust && cust.get('id') ? cust : null

      });
    },
    actions: {
      back: function back() {
        window.history.back();
      },
      view: function view(equipment) {
        this.transitionTo('equipment-view', equipment);
      }
    }
  });

  _exports.default = _default;
});