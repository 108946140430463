define("client/models/product-count-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    custId: _emberData.default.attr(),
    shipToId: _emberData.default.attr(),
    productId: _emberData.default.attr(),
    units01: _emberData.default.attr(),
    units02: _emberData.default.attr(),
    units03: _emberData.default.attr(),
    units04: _emberData.default.attr(),
    units05: _emberData.default.attr(),
    units06: _emberData.default.attr(),
    units07: _emberData.default.attr(),
    units08: _emberData.default.attr(),
    units09: _emberData.default.attr(),
    units10: _emberData.default.attr(),
    units11: _emberData.default.attr(),
    units12: _emberData.default.attr(),
    saleAmt01: _emberData.default.attr(),
    saleAmt02: _emberData.default.attr(),
    saleAmt03: _emberData.default.attr(),
    saleAmt04: _emberData.default.attr(),
    saleAmt05: _emberData.default.attr(),
    saleAmt06: _emberData.default.attr(),
    saleAmt07: _emberData.default.attr(),
    saleAmt08: _emberData.default.attr(),
    saleAmt09: _emberData.default.attr(),
    saleAmt10: _emberData.default.attr(),
    saleAmt11: _emberData.default.attr(),
    saleAmt12: _emberData.default.attr(),
    lastYrUnits01: _emberData.default.attr(),
    lastYrUnits02: _emberData.default.attr(),
    lastYrUnits03: _emberData.default.attr(),
    lastYrUnits04: _emberData.default.attr(),
    lastYrUnits05: _emberData.default.attr(),
    lastYrUnits06: _emberData.default.attr(),
    lastYrUnits07: _emberData.default.attr(),
    lastYrUnits08: _emberData.default.attr(),
    lastYrUnits09: _emberData.default.attr(),
    lastYrUnits10: _emberData.default.attr(),
    lastYrUnits11: _emberData.default.attr(),
    lastYrUnits12: _emberData.default.attr(),
    lastYrSaleAmt01: _emberData.default.attr(),
    lastYrSaleAmt02: _emberData.default.attr(),
    lastYrSaleAmt03: _emberData.default.attr(),
    lastYrSaleAmt04: _emberData.default.attr(),
    lastYrSaleAmt05: _emberData.default.attr(),
    lastYrSaleAmt06: _emberData.default.attr(),
    lastYrSaleAmt07: _emberData.default.attr(),
    lastYrSaleAmt08: _emberData.default.attr(),
    lastYrSaleAmt09: _emberData.default.attr(),
    lastYrSaleAmt10: _emberData.default.attr(),
    lastYrSaleAmt11: _emberData.default.attr(),
    lastYrSaleAmt12: _emberData.default.attr(),
    lastInvoiceNumber: _emberData.default.attr(),
    lastInvoiceDate: _emberData.default.attr(),
    lastSaleQty: _emberData.default.attr(),
    lastSalePrice: _emberData.default.attr()
  });

  _exports.default = _default;
});