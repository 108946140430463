define("client/transforms/better-time", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.isEmpty(serialized)) {
        return "";
      } else {
        var time = serialized.substr(0, 2) + ":" + serialized.substr(2);

        while (time.length < 5) {
          time += "0";
        }

        return time;
      }
    },
    serialize: function serialize(deserialized) {
      return deserialized ? deserialized.replace(/\D/g, '').substr(0, 4) : '';
    }
  });

  _exports.default = _default;
});