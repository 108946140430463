define("client/services/warehouse-creation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    warehouse: function warehouse(product) {
      var ware = this.get('store').createRecord('warehouse-part', {
        prodId: product.get('prodId'),
        openingBalanceA: product.get('openingBalance'),
        openingBalanceB: 0,
        openingBalanceC: 0,
        openingBalanceD: 0,
        openingBalanceE: 0,
        openingBalanceF: 0,
        openingBalanceG: 0,
        openingBalanceH: 0,
        receivedYtdA: product.get('received'),
        receivedYtdB: 0,
        receivedYtdC: 0,
        receivedYtdD: 0,
        receivedYtdE: 0,
        receivedYtdF: 0,
        receivedYtdG: 0,
        receivedYtdH: 0,
        usedYtdA: product.get('used'),
        usedYtdB: 0,
        usedYtdC: 0,
        usedYtdD: 0,
        usedYtdE: 0,
        usedYtdF: 0,
        usedYtdG: 0,
        usedYtdH: 0,
        allocatedA: product.get('allocated'),
        allocatedB: 0,
        allocatedC: 0,
        allocatedD: 0,
        allocatedE: 0,
        allocatedF: 0,
        allocatedG: 0,
        allocatedH: 0
      });
      return ware.save();
    },
    warehouse2: function warehouse2(product) {
      var ware = this.get('store').createRecord('second-warehouse-part', {
        prodId: product.get('prodId'),
        openingBalanceI: 0,
        openingBalanceJ: 0,
        openingBalanceK: 0,
        openingBalanceL: 0,
        openingBalanceM: 0,
        openingBalanceN: 0,
        openingBalanceO: 0,
        openingBalanceP: 0,
        receivedYtdI: 0,
        receivedYtdJ: 0,
        receivedYtdK: 0,
        receivedYtdL: 0,
        receivedYtdM: 0,
        receivedYtdN: 0,
        receivedYtdO: 0,
        receivedYtdP: 0,
        usedYtdI: 0,
        usedYtdJ: 0,
        usedYtdK: 0,
        usedYtdL: 0,
        usedYtdM: 0,
        usedYtdN: 0,
        usedYtdO: 0,
        usedYtdP: 0,
        allocatedI: 0,
        allocatedJ: 0,
        allocatedK: 0,
        allocatedL: 0,
        allocatedM: 0,
        allocatedN: 0,
        allocatedO: 0,
        allocatedP: 0
      });
      return ware.save();
    },
    warehouse3: function warehouse3(product) {
      var ware = this.get('store').createRecord('third-warehouse-part', {
        prodId: product.get('prodId'),
        openingBalanceQ: 0,
        openingBalanceR: 0,
        openingBalanceS: 0,
        openingBalanceT: 0,
        openingBalanceU: 0,
        openingBalanceV: 0,
        openingBalanceW: 0,
        openingBalanceX: 0,
        receivedYtdQ: 0,
        receivedYtdR: 0,
        receivedYtdS: 0,
        receivedYtdT: 0,
        receivedYtdU: 0,
        receivedYtdV: 0,
        receivedYtdW: 0,
        receivedYtdX: 0,
        usedYtdQ: 0,
        usedYtdR: 0,
        usedYtdS: 0,
        usedYtdT: 0,
        usedYtdU: 0,
        usedYtdV: 0,
        usedYtdW: 0,
        usedYtdX: 0,
        allocatedQ: 0,
        allocatedR: 0,
        allocatedS: 0,
        allocatedT: 0,
        allocatedU: 0,
        allocatedV: 0,
        allocatedW: 0,
        allocatedX: 0
      });
      return ware.save();
    }
  });

  _exports.default = _default;
});