define("client/models/cross-reference", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    prodId: _emberData.default.attr('capitalize'),
    crossReferenceId: _emberData.default.attr('capitalize'),
    product: _emberData.default.belongsTo('part-item', {
      async: true,
      inverse: "crossReferences"
    }),
    crossReferenceProduct: _emberData.default.belongsTo('part-item', {
      async: true
    })
  });

  _exports.default = _default;
});