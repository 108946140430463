define("client/components/autocomplete-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    canClear: Ember.computed('allowClear', function () {
      if (this.get('allowClear') === undefined) return true;else return this.get('allowClear');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var selected = this.get('selected');
      var labelPath = this.get('labelPath');

      if (selected && labelPath) {
        this.set('searchText', selected.get(labelPath));
      } else if (selected) {
        this.set('searchText', selected);
      } else if (!selected) {
        this.set('searchText', '');
      }
    },
    actions: {
      onSelectionChange: function onSelectionChange(val) {
        var labelPath = this.get('labelPath');
        this.set('searchText', val ? labelPath ? val.get(labelPath) : val : '');
        this.sendAction('onSelectionChange', val);
      },
      onSearchTextChange: function onSearchTextChange(val) {
        if (this.get('onSearchTextChange')) {
          this.sendAction('onSearchTextChange', val);
        } else {
          this.set('searchText', val);
        }
      },
      onBlur: function onBlur() {
        var selected = this.get('selected');
        var labelPath = this.get('labelPath');
        this.sendAction('onBlur', this.get('searchText'), selected);
        var searchText = '';
        if (selected && labelPath) searchText = selected.get(labelPath);else if (selected) searchText = selected;else searchText = '';
        this.set('searchText', searchText);
      },
      onClear: function onClear() {
        this.set('searchText', '');
        if (this.get('onClear')) this.sendAction('onClear');else this.send('onSelectionChange', null);
      }
    }
  });

  _exports.default = _default;
});