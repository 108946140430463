define("client/components/dispatch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qVofK0Lo",
    "block": "{\"statements\":[[6,[\"modal-dialog\"],null,[[\"action\"],[\"closeMessageDialog\"]],{\"statements\":[[0,\"\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"modal-header-new\"],[13],[0,\"\\n\\t\\t\\t\"],[11,\"h4\",[]],[13],[0,\"Message to \"],[1,[28,[\"technician\",\"name\"]],false],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"modal-body-new\"],[13],[0,\"\\n\\t\\t\\t\"],[1,[33,[\"textarea\"],null,[[\"value\",\"class\"],[[28,[\"message\"]],\"form-control input-sm\"]]],false],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"modal-footer-new\"],[13],[0,\"\\n\\t\\t\\t\"],[11,\"button\",[]],[15,\"class\",\"btn btn-success\"],[5,[\"action\"],[[28,[null]],\"sendMessage\"]],[13],[0,\"Send\"],[14],[0,\"\\n\\t\\t\\t\"],[11,\"button\",[]],[15,\"class\",\"btn btn-default\"],[5,[\"action\"],[[28,[null]],\"closeMessageDialog\"]],[13],[0,\"Close\"],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/dispatch/template.hbs"
    }
  });

  _exports.default = _default;
});