define("client/components/child-form-state/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var StateComponent = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('state', computed.alias('parent.registration.' + this.get('name')));
    }
  });
  StateComponent.reopenClass({
    positionalParams: ['name']
  });
  var _default = StateComponent;
  _exports.default = _default;
});