define("client/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;

  function capitalize(params
  /*, hash*/
  ) {
    var input = params[0];
    if (!input) return "";
    var safeWords = ['Llc'];
    input = input.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    }).replace(/\b(Llc)\b/g, function (letters) {
      return letters.toUpperCase();
    }).replace(/'[A-Za-z]/g, function (letter) {
      return letter.toLowerCase();
    });
    return input;
  }

  var _default = Ember.Helper.helper(capitalize);

  _exports.default = _default;
});