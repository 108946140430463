define("client/components/num-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inputObserver: function () {
      if (this.get('isInserted')) {
        var val = this.get('inValue');
        val = val.replace(/\D/g, '');
        this.set('inValue', val);
      }
    }.observes('inValue').on('init'),
    didInsertElement: function didInsertElement() {
      this.set('isInserted', true);
    },
    inValue: "",
    isInserted: false
  });

  _exports.default = _default;
});