define("client/transforms/date2", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var date = moment(serialized, 'YYYY-MM-DD HH:mm:ss.S').format('YYYY-MM-DD');

      if (date === "Invalid date") {
        date = null;
      }

      return date;
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });

  _exports.default = _default;
});