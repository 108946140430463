define("client/react/components/typed/customer-form-autocomplete", ["exports", "react/jsx-runtime", "client/react/components/helpers", "client/react/components/typed/model-form-autocomplete"], function (_exports, _jsxRuntime, _helpers, _modelFormAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function CustomerFormAutocomplete(_a) {
    var formProps = _a.formProps,
        searchProps = _a.searchProps,
        watch = _a.watch,
        restProps = __rest(_a, ["formProps", "searchProps", "watch"]);

    return (0, _jsxRuntime.jsx)(_modelFormAutocomplete.default, Object.assign({
      formProps: formProps,
      searchProps: Object.assign({
        searchModel: 'customer',
        // ts-ignore on these two because we are technically lying to the type system. If we want the types to say that we can't have a searchParam when useModelSearch is true,
        // then we can't go around passing in searchParams when the fate of useModelSearch is not yet certain. The only choices are to change the types to reflect reality or just ignore the error
        // because we know better.
        // @ts-ignore
        searchParam: 'humanId',
        // @ts-ignore
        keyField: 'humanId'
      }, searchProps)
    }, restProps, {
      getOptionLabel: function getOptionLabel(v) {
        return (0, _helpers.getCustomerLabel)(v);
      },
      watch: watch,
      renderInputParams: restProps.renderInputParams
    }));
  }

  var _default = CustomerFormAutocomplete;
  _exports.default = _default;
});