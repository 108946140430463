define("client/components/md-contextmenu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      guidFor = Ember.guidFor;
  var ContextMenu = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('firstRender', true);
      this.get('allMenus').addObject(this);
      this.set('popoverId', 'popover' + guidFor(this));
    },
    cElement: null,
    allMenus: Ember.A([]),
    tagName: "",
    isOpen: false,
    animationClass: 'scale-in-top-left',
    handler: function handler(e) {
      var _this = this;

      e.preventDefault();
      this.set('left', e.clientX + 'px');
      this.set('top', e.clientY + 'px');
      this.get('allMenus').forEach(function (x) {
        return x.set('isOpen', false);
      });
      this.set('isOpen', true);
      Ember.run.later(function () {
        $(window).one('click', function (e) {
          _this.set('isOpen', false);
        });
      }, 100);
    },
    didRender: function didRender() {
      if (this.get('firstRender')) {
        var element = Ember.ViewUtils.getViewBounds(this).firstNode;

        while (element.nodeName == "#text") {
          element = element.nextElementSibling;
        }

        if (element) {
          this.set('cElement', element);
          element.addEventListener('contextmenu', this.handler.bind(this));
        }
      }
    },
    willDestroy: function willDestroy() {
      this.get('allMenus').removeObject(this);
      var element = this.get('cElement');

      if (element) {
        element.removeEventListener('contextmenu', this.handler.bind(this));
      }
    },
    actions: {
      reposition: function reposition() {
        var popover = $('#' + this.get('popoverId'))[0];
        var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var isTooWide = popover.offsetLeft + popover.offsetWidth > viewportWidth;
        var isTooTall = popover.offsetTop + popover.offsetHeight > viewportHeight;
        var newLeft = isTooWide ? popover.offsetLeft - popover.offsetWidth : popover.offsetLeft;
        var newTop = isTooTall ? popover.offsetTop - popover.offsetHeight : popover.offsetTop;
        var animationClass = '';

        if (isTooWide) {
          if (isTooTall) {
            animationClass = 'scale-in-bottom-right';
          } else {
            animationClass = 'scale-in-top-right';
          }
        } else {
          if (isTooTall) {
            animationClass = 'scale-in-bottom-left';
          } else {
            animationClass = 'scale-in-top-left';
          }
        }

        this.set('animationClass', animationClass);
        this.set('top', newTop + 'px');
        this.set('left', newLeft + 'px');
      }
    }
  });
  var _default = ContextMenu;
  _exports.default = _default;
});