define("client/models/order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    custName: _emberData.default.attr('capitalize'),
    number: _emberData.default.attr(),
    custId: _emberData.default.attr(),
    shipName: _emberData.default.attr('capitalize'),
    shipName2: _emberData.default.attr('capitalize'),
    shipAddress: _emberData.default.attr('capitalize'),
    shipCity: _emberData.default.attr('capitalize'),
    shipState: _emberData.default.attr('capitalize'),
    shipZip: _emberData.default.attr('capitalize'),
    slm: _emberData.default.attr('capitalize'),
    customerPo: _emberData.default.attr(),
    shipVia: _emberData.default.attr('capitalize'),
    frtTerms: _emberData.default.attr('capitalize'),
    terms: _emberData.default.attr('capitalize'),
    date: _emberData.default.attr(),
    subTotal: _emberData.default.attr('number'),
    salesTaxPercent: _emberData.default.attr('number'),
    salesTax: _emberData.default.attr('number'),
    total: _emberData.default.attr(),
    salesTaxJurisdiction: _emberData.default.attr(),
    upsZone: _emberData.default.attr(),
    telephone: _emberData.default.attr('telephone'),
    discountPercent: _emberData.default.attr('number'),
    discount: _emberData.default.attr('number'),
    handlingChgFlag: _emberData.default.attr('yn-tf'),
    insuranceFlag: _emberData.default.attr('yn-tf'),
    promiseDate: _emberData.default.attr(),
    creditHold: _emberData.default.attr('yn-tf'),
    notes: _emberData.default.attr('capitalize'),
    pickticketCount: _emberData.default.attr(),
    lastInvoice1: _emberData.default.attr(),
    lastInvoice2: _emberData.default.attr(),
    lastInvoice3: _emberData.default.attr(),
    lastInvoice4: _emberData.default.attr(),
    lastDate1: _emberData.default.attr(),
    lastDate2: _emberData.default.attr(),
    lastDate3: _emberData.default.attr(),
    lastDate4: _emberData.default.attr(),
    lastAmount1: _emberData.default.attr(),
    lastAmount2: _emberData.default.attr(),
    lastAmount3: _emberData.default.attr(),
    lastAmount4: _emberData.default.attr(),
    manualTaxFlag: _emberData.default.attr('number'),
    manualDiscountFlag: _emberData.default.attr('number'),
    commissionRate: _emberData.default.attr(),
    shipToId: _emberData.default.attr(),
    warehouse: _emberData.default.attr('capitalize'),
    labels: _emberData.default.attr(),
    slm2: _emberData.default.attr('capitalize'),
    commRate2: _emberData.default.attr(),
    country: _emberData.default.attr('capitalize'),
    discCode: _emberData.default.attr(),
    freight: _emberData.default.attr(),
    packages: _emberData.default.attr(),
    weight: _emberData.default.attr(),
    jobId: _emberData.default.attr(),
    freightFlag: _emberData.default.attr(),
    jobCostStatus: _emberData.default.attr(),
    jobOrderType: _emberData.default.attr(),
    items: _emberData.default.hasMany('order-item', {
      async: true
    }),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    job: _emberData.default.belongsTo('job', {
      async: true
    }),
    site: _emberData.default.belongsTo('site-address', {
      async: true
    }),
    label: Ember.computed('number', 'custName', function () {
      return this.get('number') + ' - ' + this.get('custName');
    })
  });

  _exports.default = _default;
});