define("client/react/store", ["exports", "@reduxjs/toolkit", "client/react/auth/reducer", "client/react/payroll/reducer", "client/react/data/reducer", "client/react/custom-dashboards/reducer", "@manaflair/redux-batch", "react-redux"], function (_exports, _toolkit, _reducer, _reducer2, _reducer3, _reducer4, _reduxBatch, _reactRedux) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useAppDispatch = _exports.store = void 0;
  var initialState = Object.assign({}, _reducer.authInitialState, _reducer2.payrollInitialState, _reducer4.initialState); // const mergeReducers = (...reducers) => (
  //     (state, action) => {
  //         let old = Object.assign({}, state || initialState);
  //         return reducers.reduce((accumulatedState, reducer) => {
  //             let output = reducer(accumulatedState, action);
  //             return Object.assign({}, accumulatedState, output);
  //         }, old);
  //     }
  // );

  var store = (0, _toolkit.configureStore)({
    reducer: {
      auth: _reducer.authReducer,
      data: _reducer3.default,
      dashboards: _reducer4.default,
      payroll: _reducer2.payrollReducer
    },
    enhancers: [_reduxBatch.reduxBatch]
  });
  _exports.store = store;
  var useAppDispatch = _reactRedux.useDispatch;
  _exports.useAppDispatch = useAppDispatch;
});