define("client/templates/pr-employee-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JoHzGrcv",
    "block": "{\"statements\":[[1,[33,[\"pr-employee-search\"],null,[[\"selectEmployee\",\"create\",\"edit\",\"delete\"],[[33,[\"route-action\"],[\"transition\",\"pr-employee-view\"],null],[33,[\"route-action\"],[\"transitionNoParam\",\"pr-employee-create\"],null],[33,[\"route-action\"],[\"transition\",\"pr-employee-edit\"],null],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null]],null]]]],false],[0,\"\\n\"],[6,[\"if\"],[[28,[\"isDeleting\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"pr-employee-delete\"],null,[[\"employee\",\"onClose\"],[[28,[\"isDeleting\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null],null],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/pr-employee-search.hbs"
    }
  });

  _exports.default = _default;
});