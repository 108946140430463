define("client/routes/jc-job-create", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    actions: {
      back: function back() {
        window.history.back();
      },
      createJob: function createJob() {
        this.transitionTo('jc-job-create');
      },
      viewJob: function viewJob(job) {
        this.transitionTo('jc-job-view', job.get('id'));
      },
      searchJobs: function searchJobs() {
        this.transitionTo('jc-job-search');
      }
    }
  });

  _exports.default = _default;
});