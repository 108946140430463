define("client/templates/dispatch/customer-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uw3UYhT1",
    "block": "{\"statements\":[[1,[33,[\"customer-profile\"],null,[[\"customer\",\"site\",\"closeCustomerProfile\",\"activeTab\",\"createAvailable\",\"createAction\",\"workOrder\"],[[28,[\"dispatchCustomer\"]],[28,[\"dispatchSite\"]],[33,[\"action\"],[[28,[null]],\"updateWo\"],null],\"profile\",[28,[\"workOrderCreate\"]],[33,[\"action\"],[[28,[null]],\"createWorkOrder\"],null],[28,[\"currentWoSummary2\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/dispatch/customer-profile.hbs"
    }
  });

  _exports.default = _default;
});