define("client/components/image-upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    attributeBindings: ['method', 'enctype'],
    linksArray: null,
    method: 'post',
    singleLink: false,
    //style: 'display: none;',
    enctype: 'multipart/form-data',
    containerObserver: function () {
      var clickEvent = document.createEvent('MouseEvents');
      clickEvent.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      this.$("input")[0].dispatchEvent(clickEvent);
    }.observes('containerClicked'),
    //  images: function() {
    //     if (this.get('data')) {
    //         if (this.get('showCategories')) {
    //             this.get('imageLinks').clear();
    //         }
    //         var files = this.get('data').originalFiles;
    //         for (var i = 0; i < files.length; i++) {
    //             var reader = new FileReader();
    //             reader.onload = function(e) {
    //                 if (e.target.result) {
    //                   this.get('imageLinks').pushObject(e.target.result);
    //                 }
    //             }.bind(this);
    //             reader.readAsDataURL(files[i]);
    //         }
    //  }
    // }.observes('data').on('init'),
    // primaryObserve: function() {
    //     if (this.get('data2')) {
    //         var reader = new FileReader();
    //         reader.onload = function(e) {
    //             this.set('primaryLink',e.target.result);
    //         }.bind(this);
    //         reader.readAsDataURL(this.get('data2').files[0]);
    //     }
    // }.observes('data2').on('init'),
    didInsertElement: function didInsertElement() {
      var self = this;
      this.$('#hashtag').fileupload({
        dataType: 'json',
        url: this.get('uploadUrl'),
        formData: function formData() {
          return self.get('formData');
        },
        done: function done(e, data) {
          self.sendAction('uploaded', data.result);
          self.markCompleted(data.result.filenames);
        },
        fail: function fail(e, data) {
          self.sendAction('failed', data.result);
        },
        add: function add(e, data) {
          data.process().done(function () {
            console.log(data); //data.submit();

            self.set('data', data);
            var files = data.originalFiles;
            var reader = new FileReader();

            for (var i = 0; i < files.length; i++) {
              var reader = new FileReader();

              reader.onload = function (e) {
                if (e.target.result) {
                  self.sendAction('onAddLink', e.target.result);
                }
              }.bind(this);

              reader.readAsDataURL(files[i]);
            } //self.$().val(data.files[0].name);

          });
        }
      });
    },
    hideFileInput: function () {
      if (this.get('singleLink')) return false;
      if (this.get('multiple')) return false;
      return this.get('linksArray.length') !== 0;
    }.property('linksArray.[]')
  });

  _exports.default = _default;
});