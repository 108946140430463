define("client/models/part-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    prodId: _emberData.default.attr(),
    lineNumber: _emberData.default.attr(),
    note1: _emberData.default.attr('capitalize'),
    note2: _emberData.default.attr('capitalize'),
    code1: _emberData.default.attr(),
    code2: _emberData.default.attr(),
    part: _emberData.default.belongsTo('part-item', {
      async: true
    })
  });

  _exports.default = _default;
});