define("client/components/parent-form/component", ["exports", "ember-paper/components/paper-form"], function (_exports, _paperForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperForm.default.extend({
    actions: {
      onSubmit: function onSubmit() {
        var childrens = this.get('childComponents').any(function (item) {
          return item.get('childComponents');
        });

        if (childrens) {
          if (this.get('isInvalid')) {
            this.get('childComponents').forEach(function (item) {
              item.get('childComponents').setEach('isTouched', true);
            });
          } else {
            this.sendAction('onSubmit');
            this.get('childComponents').forEach(function (item) {
              item.get('childComponents').setEach('isTouched', false);
            });
          }
        } else {
          if (this.get('isInvalid')) {
            this.get('childComponents').setEach('isTouched', true);
          } else {
            this.sendAction('onSubmit');
            this.get('childComponents').setEach('isTouched', false);
          }
        }
      }
    }
  });

  _exports.default = _default;
});