define("client/templates/gl-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4idroZ94",
    "block": "{\"statements\":[[1,[33,[\"gl-settings\"],null,[[\"settings\"],[[33,[\"changeset\"],[[28,[\"model\"]]],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/gl-settings.hbs"
    }
  });

  _exports.default = _default;
});