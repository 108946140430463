define("client/utils/time-to-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = timeToRow;

  function timeToRow(time, startTime, numPeriods) {
    //    var start = "8:00 am";
    console.log('timeToRow executing!');
    console.log("time: ".concat(time, ", startTime: ").concat(startTime));

    var calc = function calc(time) {
      var t2 = time.split(':');
      var a = t2[0];
      var b = t2[1];
      a = Number(a);
      b = Number(b); //should be numPeriods * the number of hours + # minutes / number in period

      var ret = numPeriods * a + Math.round(b / (60 / numPeriods));
      return ret;
    };

    var ret = calc(time) - calc(startTime) + 1;
    console.log(ret);
    return ret;
  }
});