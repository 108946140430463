define("client/models/solicitation-checkup", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    updated: _emberData.default.attr(),
    solicitation: _emberData.default.belongsTo('contract-solicitation', {
      async: true
    }),
    monthYear: _emberData.default.attr('month-year'),
    forRenewal: _emberData.default.attr(),
    complaints: _emberData.default.hasMany('solicitation-checkup-complaint', {
      async: true
    })
  });

  _exports.default = _default;
});