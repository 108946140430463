define("client/components/tree-table-right-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ember-table-block', 'ember-table-header-block', 'ember-table-right-table-block'],
    attributeBindings: ['style'],
    style: "display: flex; flex-direction: column;"
  });

  _exports.default = _default;
});