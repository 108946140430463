define("client/react/payroll/employee-management", ["exports", "react", "react-router", "react-router-dom", "react-redux", "client/react/payroll/reducer", "client/react/payroll/employee-list", "client/react/payroll/employee-view", "client/react/payroll/employee-edit", "client/react/payroll/employee-create"], function (_exports, _react, _reactRouter, _reactRouterDom, _reactRedux, _reducer, _employeeList, _employeeView, _employeeEdit, _employeeCreate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function EmployeeManagement() {
    var dispatch = (0, _reactRedux.useDispatch)(); // retrieve payrollSettings

    (0, _react.useEffect)(function () {
      dispatch((0, _reducer.fetchSelectedPayroll)());
    }, []);
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactRouterDom.HashRouter, null, _react.default.createElement(_reactRouter.Switch, null, _react.default.createElement(_reactRouter.Route, {
      path: "/pr-employee-management/:id/edit"
    }, _react.default.createElement(_employeeEdit.default, null)), _react.default.createElement(_reactRouter.Route, {
      path: "/pr-employee-management/:id/view"
    }, _react.default.createElement(_employeeView.default, null)), _react.default.createElement(_reactRouter.Route, {
      path: "/pr-employee-management/create"
    }, _react.default.createElement(_employeeCreate.default, null)), _react.default.createElement(_reactRouter.Route, {
      exact: true,
      path: "/pr-employee-management"
    }, _react.default.createElement(_employeeList.default, null)))));
  }

  var _default = EmployeeManagement;
  _exports.default = _default;
});