define("client/routes/dashboard", ["exports", "client/config/environment", "client/mixins/authenticated-route"], function (_exports, _environment, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    auth: Ember.inject.service(),
    activate: function activate() {},
    beforeModel: function beforeModel() {
      Ember.$('#isLoading').show();
    },
    afterModel: function afterModel() {
      Ember.$('#isLoading').hide();
    } // model: function() {
    // 	return new Ember.RSVP.Promise(function(resolve,reject){
    // 		this.controllerFor('dashboard').set('startDate',moment().subtract(1,'years').format('YYYY-MM-DD'));
    // 		this.controllerFor('dashboard').set('endDate',moment().format('YYYY-MM-DD'));
    // 		Ember.$.ajax({
    // 			url: ENV.APP.endpoint + "/fsm/dashboardReport?startDate=" + moment().subtract(1,'years').format('YYYY-MM-DD') + "&endDate=" + moment().format('YYYY-MM-DD'),
    // 			type: 'get',
    // 			success: function(response) {
    // 				console.log(response);
    // 				var trendRevenue = response.trend.map( x => x.revenue);
    // 				var trendLabels = response.trend.map( x => x.month + '/' + x.year);
    // 				this.controllerFor('dashboard').set('data3',{
    // 			    	labels: trendLabels,
    // 			    	datasets: [
    // 				        {
    // 			            label: "My Second dataset",
    // 			            fillColor: "rgba(151,187,205,0.2)",
    // 			            strokeColor: "rgba(151,187,205,1)",
    // 			            pointColor: "rgba(151,187,205,1)",
    // 			            pointStrokeColor: "#fff",
    // 			            pointHighlightFill: "#fff",
    // 			            pointHighlightStroke: "rgba(151,187,205,1)",
    // 			            data: trendRevenue
    // 			        }
    // 				    ]
    // 				});
    // 				var selectColor = function(colorNum, colors){
    //    					if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
    //    					return "hsl(" + (colorNum * (360 / colors) % 360) + ",100%,50%)";
    // 				}
    // 				var getColor = function(i) {
    // 					return selectColor(i+1,response.campaigns.length);
    // 				};
    // 				var estimates = response.estimates.count
    // 				var estimateConversions = response.estimates.conversions;
    // 				var campaignChart = response.campaigns.filter(function(item) { return item.name;}).map( function(x,i) { return { value: x.count, color: getColor(i), label: x.name, highlight: "#FF5A5E", labelColor: 'white', labelFontSize : '16'}});
    // 				var dispatchCounts = response.dispatchers.map(x => x.conversions).reduce( (prev,curr) => prev + curr);
    // 				var dCounts2 = response.dispatchers.map(x => x.count).reduce( (prev,curr) => prev + curr);
    // 				this.controllerFor('dashboard').set('data2',[
    // 					{value: dispatchCounts, color: "#5c99fc"},
    // 					{value: dCounts2, color: "#cfcfcf"}
    // 				]);
    // 				this.controllerFor('dashboard').set('data4',[
    // 					{value: estimateConversions, color: "#5c99fc"},
    // 					{value: estimates, color: "#cfcfcf"}]);
    // 				this.controllerFor('dashboard').set('data',campaignChart);
    // 				this.controllerFor('dashboard').set('technicians',Ember.A(response.technicians.map( x => Ember.Object.create(x))));
    // 				this.controllerFor('dashboard').set('campaigns',response.campaigns);
    // 				this.controllerFor('dashboard').set('dispatchers',response.dispatchers);
    // 				resolve(response);
    // 			}.bind(this),
    // 			error: function(response) {
    // 				console.log(response);
    // 				reject(response);
    // 			},
    // 			dataType: 'json',
    // 			headers: {
    // 				'X-Auth-Tenant': this.get('auth.tenantId')
    // 			}
    // 		});
    // 	}.bind(this));
    // }

  });

  _exports.default = _default;
});