define("client/router", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("dispatch");
    this.route("inventory", {
      path: "/inventory"
    });
    this.route('login');
    this.route('index', {
      path: "/"
    });
    this.route('tenant-configuration');
    this.route("admin-panel");
    this.route("landing");
    this.route("rec-login");
    this.route("records");
    this.route("vendor-search");
    this.route("vendor", {
      path: "/vendor/:id"
    });
    this.route("purchase-journal", {
      path: "/purchase-journal/:id"
    });
    this.route('ap-reports');
    this.route('dashboard');
    this.route('select-checks');
    this.route('vendor-checks', {
      path: "/vendor-checks/:id"
    });
    this.route('disbursements-journal');
    this.route('service-reports');
    this.route('service-estimates');
    this.route('check-reconciliation');
    this.route('gl-file-maintenance');
    this.route('ledger-std-entries');
    this.route('document-posting');
    this.route('ledger-categories');
    this.route('ledger-divisions');
    this.route('ledger-system-defaults');
    this.route('ledger-eop-processing');
    this.route('ap-terms-codes');
    this.route('jc-job-search');
    this.route('jc-cat-maintenance');
    this.route('gl-reports');
    this.route('jc-types');
    this.route('ar-cust-maintenance');
    this.route('ar-posting');
    this.route('ar-keyoff');
    this.route('ar-set-gls');
    this.route('ar-retention');
    this.route('pr-gl-accounts');
    this.route('pr-post-labor');
    this.route('inv-po-maintenance');
    this.route('inv-cat-maintenance');
    this.route('jc-reports');
    this.route('inv-routines');
    this.route('pr-reports');
    this.route('order-entry');
    this.route('ar-reports');
    this.route('ic-reports');
    this.route('srvc-work-order-maintenance');
    this.route('ar-cust-search');
    this.route('ar-cust-view', {
      path: 'view-customer/:customer_id'
    }, function () {
      this.route('ar-site-edit', {
        path: 'edit-site/:siteAddress_id'
      });
    });
    this.route('ar-cust-create');
    this.route('ar-cust-edit', {
      path: 'edit-customer/:customer_id'
    });
    this.route('srvc-work-order-search', {
      path: 'search-work-order'
    });
    this.route('srvc-work-order-view', {
      path: 'view-work-order/:workOrder_id'
    });
    this.route('srvc-work-order-create', {
      path: 'create-work-order'
    });
    this.route('srvc-work-order-edit', {
      path: 'edit-work-order/:workOrder_id'
    });
    this.route('srvc-tech-maintenance');
    this.route('srvc-gl-accounts');
    this.route('srvc-labor-rate');
    this.route('srvc-flrates');
    this.route('inv-warehouse-maintenance');
    this.route('srvc-wo-templates');
    this.route('or-reports');
    this.route('sales-tax');
    this.route('srvc-contract-cash-receipts');
    this.route('audit-trail');
    this.route('failure');
    this.route('user-settings');
    this.route('purchase-journal-vendor-search');
    this.route('invoicing');
    this.route('examine-invoice', {
      path: 'examine-invoice/:invoice_id'
    });
    this.route('invoicing-search');
    this.route('sales-posting');
    this.route('cash-receipts');
    this.route('srvc-contract-options');
    this.route('srvc-contract-types');
    this.route('inv-load-inventory');
    this.route('appointment-confirmations');
    this.route('service-dashboard');
    this.route('vendor-view', {
      path: "/vendor-view/:id"
    });
    this.route('vendor-create');
    this.route('vendor-edit', {
      path: "/vendor-edit/:id"
    });
    this.route('invoicing-reports');
    this.route('inv-po-history');
    this.route('part-matrix');
    this.route('financial-calculator');
    this.route('view-screen-share');
    this.route('srvc-flrate-settings');
    this.route('srvc-contract-solicitation-search');
    this.route('srvc-contract-solicitation-view', {
      path: "view-solicitation/:id"
    });
    this.route('srvc-contract-solicitation-create');
    this.route('srvc-contract-solicitation-convert', {
      path: "convert-solicitation/:id"
    });
    this.route('srvc-contract-solicitation-templates');
    this.route('equipment-edit', {
      path: "equipment-edit/:id"
    });
    this.route('equipment-view', {
      path: "equipment-view/:id"
    });
    this.route('equipment-search');
    this.route('equipment-create', {
      path: "equipment-create/:id"
    });
    this.route('srvc-archive-search');
    this.route('srvc-archive-view', {
      path: "srvc-archive-view/:id"
    });
    this.route('journals');
    this.route('jc-job-view', {
      path: 'view-job/:id'
    });
    this.route('jc-job-edit', {
      path: 'edit-job/:id'
    });
    this.route('jc-job-create', {
      path: 'create-job'
    });
    this.route('ap-keyoff');
    this.route('jc-mass-commission');
    this.route('jc-close-job', {
      path: 'close-job/:id'
    });
    this.route('manage-templates');
    this.route('crm-reports');
    this.route('lead-search');
    this.route('lead-view', {
      path: 'lead-view/:id'
    });
    this.route('lead-create');
    this.route('lead-edit', {
      path: 'lead-edit/:id'
    });
    this.route('crm-dashboard');
    this.route('audit-log-master');
    this.route('pr-w2-processing');
    this.route('ap-tnn-processing');
    this.route('inv-product-view', {
      path: 'inv-product-view/:id'
    });
    this.route('inv-product-search');
    this.route('inv-product-create');
    this.route('inv-product-edit', {
      path: 'inv-product-edit/:id'
    });
    this.route('pr-employee-view', {
      path: "pr-employee-view/:id"
    });
    this.route('pr-employee-edit', {
      path: 'pr-employee-edit/:id'
    });
    this.route('pr-void-check', {
      path: 'pr-void-check/:id'
    });
    this.route('pr-employee-search');
    this.route('pr-employee-create');
    this.route('pr-post-history');
    this.route('srvc-contract-view', {
      path: 'srvc-contract-view/:id'
    });
    this.route('srvc-contract-edit', {
      path: 'srvc-contract-edit/:id'
    });
    this.route('srvc-contract-create');
    this.route('srvc-contract-search');
    this.route('not-found');
    this.route('srvc-flrate-view', {
      path: 'srvc-flrates/:id'
    });
    this.route('gl-subledgers');
    this.route('gl-subledger-config');
    this.route('unapproved-postings');
    this.route('gl-ledgers', function () {
      this.route('view', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/edit/:id'
      });
    });
    this.route('srvc-contract-renewals');
    this.route('srvc-locations');
    this.route('gl-settings');
    this.route('gl-document-templates');
    this.route('gl-recurring-entries');
    this.route('gl-ledger-divisions');
    this.route('srvc-billing-search'); // Swap these two on service billing rework
    // this.route('srvc-billing', { path: '/srvc-billing/*:' });

    this.route('srvc-billing', {
      path: '/srvc-billing/:id'
    });
    this.route('srvc-billing-credit', {
      path: 'srvc-billing-credit/:id'
    }); // We trick ember into using the same route for the other paths so that react-router can take over
    // Using both routers for now will make it much easier for us to transition to react altogether in the future.
    // Have to use different route though because ember is stupid
    // this.route('pr-time-sheet', { path: '/pr-time-entry/:id'});

    this.route('pr-time-entry', {
      path: '/pr-time-entry/*:'
    });
    this.route('pr-time-entry', {
      path: '/pr-time-entry'
    });
    this.route('pr-compute-payroll', {
      path: 'pr-compute-payroll/*:'
    });
    this.route('pr-compute-payroll', {
      path: '/pr-compute-payroll'
    }); // this.route('pr-compute-payroll', { path: '/pr-compute-payroll/:id/complete'});

    this.route('pr-type-management');
    this.route('pr-settings', {
      path: '/pr-settings/*:'
    });
    this.route('pr-settings', {
      path: '/pr-settings'
    });
    this.route('pr-employee-management', {
      path: '/pr-employee-management/*:'
    });
    this.route('pr-employee-management', {
      path: '/pr-employee-management'
    });
    this.route('gl-bottom-line', {
      path: 'business-overview'
    });
    this.route('srvc-product-catalog', {
      path: 'product-catalog/*:'
    });
    this.route('srvc-product-catalog', {
      path: 'product-catalog'
    });
    this.route('bank-management');
    this.route('inv-allocate-inventory');
    this.route('pr-payroll-division-management');
    this.route('jc-post-job-expense');
    this.route('custom-dashboards', {
      path: 'dashboards/*:'
    });
    this.route('custom-dashboards', {
      path: 'dashboards'
    });
    this.route('ar-adjustment');
  });
  var _default = Router;
  _exports.default = _default;
});