define("client/models/customer-transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    custId: _emberData.default.attr(),
    date: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    superTenant: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr('capitalize'),
    amount: _emberData.default.attr(),
    custId2: _emberData.default.attr(),
    jobNumber: _emberData.default.attr('capitalize'),
    invoiceNumber2: _emberData.default.attr(),
    notes: _emberData.default.attr('capitalize'),
    source: _emberData.default.attr(),
    contractAmount: _emberData.default.attr(),
    changeOrderAmount: _emberData.default.attr(),
    retentionAmount: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    label: Ember.computed('invoiceNumber', 'invoiceNumber2', 'amount', 'contractAmount', function () {
      var invoiceNumber = this.get('invoiceNumber') ? this.get('invoiceNumber') : this.get('invoiceNumber2');
      var amount = typeof this.get('amount') === 'string' ? parseFloat(this.get('amount')).toFixed(2) : this.get('amount').toFixed(2);
      return invoiceNumber + ': $' + amount;
    })
  });

  _exports.default = _default;
});