define("client/components/gl-document-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    featureFlags: Ember.inject.service(),
    tagName: "",
    documentColumns: [{
      name: "Ledger",
      valuePath: "ledger",
      width: 235
    }, {
      name: "Reference",
      valuePath: "reference",
      width: 85
    }, {
      name: "Job Name",
      valuePath: "jobName",
      width: 268
    }, {
      name: "Description",
      valuePath: "description",
      width: 316
    }, {
      name: "Amount",
      valuePath: "amount",
      textAlign: "right",
      width: 85
    }]
  });

  _exports.default = _default;
});