define("client/models/appointment-confirmation-temp", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    srvcOrdId: _emberData.default.attr(),
    name: _emberData.default.attr(),
    name2: _emberData.default.attr(),
    address: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    zip: _emberData.default.attr(),
    appointmentDateTime: _emberData.default.attr(),
    phoneNumber: _emberData.default.attr()
  });

  _exports.default = _default;
});