define("client/components/nsb-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    attributeBindings: ['style', 'role'],
    role: "menuitem",
    style: "height:30px;display:flex;",
    click: function click() {
      this.sendAction('onClick'); //        return false;
    }
  });

  _exports.default = _default;
});