define("client/utils/is-valid-uom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isValidUom;

  function isValidUom(uom) {
    return ['E', 'P', 'C', 'M', 'T', 'D', 'G'].includes(uom);
  }
});