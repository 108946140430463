define("client/models/ar-cash-one", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    key: _emberData.default.attr(),
    type: _emberData.default.attr(),
    custId: _emberData.default.attr(),
    date: _emberData.default.attr(),
    checkNumber: _emberData.default.attr('capitalize'),
    cash: _emberData.default.attr('number'),
    discount: _emberData.default.attr('number'),
    glTotal: _emberData.default.attr('number'),
    arTotal: _emberData.default.attr('number'),
    custName: _emberData.default.attr('capitalize'),
    cashGl: _emberData.default.attr(),
    depositCode: _emberData.default.attr()
  });

  _exports.default = _default;
});