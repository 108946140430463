define("client/models/ledger-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    ledgerId: _emberData.default.attr('trim'),
    description: _emberData.default.attr('capitalize'),
    category: _emberData.default.attr(),
    type: _emberData.default.attr(),
    summaryId: _emberData.default.attr('capitalize'),
    amount1: _emberData.default.attr(),
    amount2: _emberData.default.attr(),
    amount3: _emberData.default.attr(),
    amount4: _emberData.default.attr(),
    amount5: _emberData.default.attr(),
    amount6: _emberData.default.attr(),
    amount7: _emberData.default.attr(),
    amount8: _emberData.default.attr(),
    amount9: _emberData.default.attr(),
    amount10: _emberData.default.attr(),
    amount11: _emberData.default.attr(),
    amount12: _emberData.default.attr(),
    lastYrAmt1: _emberData.default.attr(),
    lastYrAmt2: _emberData.default.attr(),
    lastYrAmt3: _emberData.default.attr(),
    lastYrAmt4: _emberData.default.attr(),
    lastYrAmt5: _emberData.default.attr(),
    lastYrAmt6: _emberData.default.attr(),
    lastYrAmt7: _emberData.default.attr(),
    lastYrAmt8: _emberData.default.attr(),
    lastYrAmt9: _emberData.default.attr(),
    lastYrAmt10: _emberData.default.attr(),
    lastYrAmt11: _emberData.default.attr(),
    lastYrAmt12: _emberData.default.attr(),
    budget1: _emberData.default.attr(),
    budget2: _emberData.default.attr(),
    budget3: _emberData.default.attr(),
    budget4: _emberData.default.attr(),
    budget5: _emberData.default.attr(),
    budget6: _emberData.default.attr(),
    budget7: _emberData.default.attr(),
    budget8: _emberData.default.attr(),
    budget9: _emberData.default.attr(),
    budget10: _emberData.default.attr(),
    budget11: _emberData.default.attr(),
    budget12: _emberData.default.attr(),
    controlId: _emberData.default.attr(),
    normalBalance: _emberData.default.attr(),
    categoryDescription: _emberData.default.attr(),
    ledgerCategory: _emberData.default.attr(),
    division: _emberData.default.belongsTo('division'),
    preventDirectPosting: _emberData.default.attr(),
    ledgerDistribution: _emberData.default.belongsTo('ledger-distribution', {
      async: true
    }),
    controlAccount: _emberData.default.belongsTo('ledger-control-account', {
      async: true
    }),
    balance: Ember.computed(function () {
      var balance = 0;

      for (var i = 1; i < 13; i++) {
        balance += parseFloat(this.get('amount' + i));
      }

      return balance.toFixed(2);
    }),
    glDetails: _emberData.default.hasMany('ledger-detail', {
      async: true
    }),
    label: Ember.computed(function () {
      return this.get('ledgerId') + ' - ' + this.get('description');
    })
  });

  _exports.default = _default;
});