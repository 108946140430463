define("client/helpers/epoch-to-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.epochToDate = epochToDate;

  function epochToDate(params
  /*, hash*/
  ) {
    if (params[1] === true) {
      // format user friendly
      return moment.unix(params[0]).format('MM/DD/YY');
    } else if (params[1] === "fancy") {
      return moment.unix(params[0]).format('MMM Do, YYYY, h:mm A');
    } else {
      return moment.unix(params[0]).format('YYYY-MM-DD');
    }
  }

  var _default = Ember.Helper.helper(epochToDate);

  _exports.default = _default;
});