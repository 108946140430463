define("client/templates/pr-employee-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UgFLlRQ7",
    "block": "{\"statements\":[[1,[33,[\"pr-employee-create\"],null,[[\"selectEmployee\"],[[33,[\"route-action\"],[\"transition\",\"pr-employee-view\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/pr-employee-create.hbs"
    }
  });

  _exports.default = _default;
});