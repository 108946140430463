define("client/components/tree-left-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TNbBICoG",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"data\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[33,[\"tree-row\"],null,[[\"data\",\"depth\",\"displayPath\",\"childrenPath\"],[[28,[\"row\"]],1,[28,[\"displayPath\"]],[28,[\"childrenPath\"]]]]],false],[0,\"\\n\"]],\"locals\":[\"row\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/tree-left-block/template.hbs"
    }
  });

  _exports.default = _default;
});