define("client/components/inv-reprice-material/component", ["exports", "client/components/react-component/component", "client/react/utilities/inv-reprice-material"], function (_exports, _component, _invRepriceMaterial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.reactRender(React.createElement(_invRepriceMaterial.default, {
        onClose: function onClose() {
          return _this.sendAction('onClose');
        }
      }));
    }
  });

  _exports.default = _default;
});