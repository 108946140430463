define("client/helpers/report-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reportDate = reportDate;

  function reportDate(params
  /*, hash*/
  ) {
    return params[0] ? moment(params[0], 'MMDDYY').format("YYYY-MM-DD") : params[0];
  }

  var _default = Ember.Helper.helper(reportDate);

  _exports.default = _default;
});