define("client/components/animated-page/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    tagName: 'ember-animatable',
    position: null,
    classNameBindings: ['emberSelected'],
    emberSelected: computed('selected', function () {
      return this.get('selected') === this.get('position');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('parent')) {
        this.get('parent').register(this); // let length = this.get('parent.children.length');
        // this.set('position', length);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('parent')) {
        this.get('parent').unregister(this);
      }
    }
  });

  _exports.default = _default;
});