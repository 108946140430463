define("client/components/cash-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dUWi4+2/",
    "block": "{\"statements\":[[6,[\"paper-autocomplete\"],null,[[\"searchText\",\"triggerClass\",\"selected\",\"labelPath\",\"matcher\",\"label\",\"options\",\"onSelectionChange\",\"onSearchTextChange\"],[[28,[\"searchText\"]],\"flex\",[28,[\"selected\"]],\"gl\",[28,[\"matcher\"]],[28,[\"label\"]],[28,[\"options\"]],[33,[\"action\"],[[28,[null]],\"selectCash\"],null],[33,[\"perform\"],[[28,[\"changeSearchText\"]]],null]]],{\"statements\":[[0,\"\\t\"],[1,[33,[\"paper-autocomplete-highlight\"],null,[[\"label\",\"searchText\",\"flags\"],[[33,[\"concat\"],[[28,[\"cash\",\"gl\"]],\" - \",[28,[\"cash\",\"name\"]]],null],[28,[\"select\",\"searchText\"]],\"i\"]]],false],[0,\"\\n\"]],\"locals\":[\"cash\",\"select\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/cash-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});