define("client/helpers/tag-definition-allowance-descriptors", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.tagDefinitionAllowanceDescriptors = tagDefinitionAllowanceDescriptors;
  var FRIENDLY_MAP = Ember.Object.create({
    allowCustomer: "Customer",
    allowSite: "Site",
    allowJob: "Job",
    allowWorkOrder: "Work Order",
    allowLead: "Lead",
    allowCommunication: "Communication",
    allowEquipment: "Equipment",
    allowContract: "Contract",
    allowEmployee: "Employee",
    allowInvoice: "Invoice",
    allowVendor: "Vendor",
    allowPurchaseOrder: "Purchase Order",
    allowInventoryItem: "Inventory",
    allowContractSolicitation: "Contract Solicitation",
    allowTask: "Task",
    allowServiceEstimate: "Service Estimate",
    allowAttachment: "Attachment"
  });

  function tagDefinitionAllowanceDescriptors(params
  /*, hash*/
  ) {
    var definition = params[0],
        keys = Object.keys(FRIENDLY_MAP),
        vals = keys.map(function (itm) {
      if (definition.get(itm)) {
        return FRIENDLY_MAP.get(itm);
      }
    });
    return vals.filter(function (itm) {
      if (itm) {
        return true;
      }
    }).sort().join(", ");
  }

  var _default = Ember.Helper.helper(tagDefinitionAllowanceDescriptors);

  _exports.default = _default;
});