define("client/models/ledger-detail", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    glCode: _emberData.default.attr(),
    glCode2: _emberData.default.attr(),
    period: _emberData.default.attr(),
    period2: _emberData.default.attr(),
    date: _emberData.default.attr(),
    date2: _emberData.default.attr(),
    key: _emberData.default.attr(),
    documentNumber: _emberData.default.attr(),
    reference: _emberData.default.attr(),
    reference2: _emberData.default.attr(),
    addlRef: _emberData.default.attr(),
    amount: _emberData.default.attr('number'),
    ledger: _emberData.default.belongsTo('ledger-account', {
      async: true
    }),
    fullDocumentNumber: _emberData.default.attr()
  });

  _exports.default = _default;
});