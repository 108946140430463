define("client/components/product-category-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Um5Efc0E",
    "block": "{\"statements\":[[6,[\"paper-autocomplete\"],null,[[\"searchText\",\"triggerClass\",\"selected\",\"labelPath\",\"matcher\",\"label\",\"options\",\"onSelectionChange\",\"onSearchTextChange\"],[[28,[\"searchText\"]],\"flex\",[28,[\"selected\"]],\"catId\",[28,[\"matcher\"]],[28,[\"label\"]],[28,[\"options\"]],[33,[\"action\"],[[28,[null]],\"selectCategory\"],null],[33,[\"perform\"],[[28,[\"changeSearchText\"]]],null]]],{\"statements\":[[0,\"\\t\"],[1,[33,[\"paper-autocomplete-highlight\"],null,[[\"label\",\"searchText\",\"flags\"],[[33,[\"concat\"],[[28,[\"category\",\"catId\"]],\" - \",[28,[\"category\",\"description\"]]],null],[28,[\"select\",\"searchText\"]],\"i\"]]],false],[0,\"\\n\"]],\"locals\":[\"category\",\"select\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/product-category-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});