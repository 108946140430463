define("client/models/bank", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    accountNumber: _emberData.default.attr(),
    routingNumber: _emberData.default.attr(),
    routingNumber2: _emberData.default.attr(),
    dglCode1: _emberData.default.attr(),
    dglCode2: _emberData.default.attr(),
    dglCode3: _emberData.default.attr(),
    dglCode4: _emberData.default.attr(),
    dglCode5: _emberData.default.attr(),
    dglCode6: _emberData.default.attr(),
    dglCode7: _emberData.default.attr(),
    dglCode8: _emberData.default.attr(),
    dglCode9: _emberData.default.attr(),
    dglCode10: _emberData.default.attr(),
    row: _emberData.default.attr(),
    checkNum1: _emberData.default.attr(),
    checkNum2: _emberData.default.attr(),
    checkNum3: _emberData.default.attr(),
    checkNum4: _emberData.default.attr(),
    checkNum5: _emberData.default.attr(),
    checkNum6: _emberData.default.attr(),
    checkNum7: _emberData.default.attr(),
    checkNum8: _emberData.default.attr(),
    checkNum9: _emberData.default.attr(),
    checkNum10: _emberData.default.attr(),
    glDescription1: _emberData.default.attr(),
    glDescription2: _emberData.default.attr(),
    glDescription3: _emberData.default.attr(),
    glDescription4: _emberData.default.attr(),
    glDescription5: _emberData.default.attr(),
    glDescription6: _emberData.default.attr(),
    glDescription7: _emberData.default.attr(),
    glDescription8: _emberData.default.attr(),
    glDescription9: _emberData.default.attr(),
    glDescription10: _emberData.default.attr(),
    active: _emberData.default.attr()
  });

  _exports.default = _default;
});