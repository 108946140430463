define("client/helpers/uncamel-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uncamelCase = uncamelCase;

  function uncamelCase(params
  /*, hash*/
  ) {
    var string = params[0];
    string = string.replace(/([A-Z])/g, ' $1');
    return string.replace(/^([a-z])/g, function (x) {
      return x.toUpperCase();
    });
  }

  var _default = Ember.Helper.helper(uncamelCase);

  _exports.default = _default;
});