define("client/react/components/alt/form-currency-text-field", ["exports", "client/react/utilities/use-form", "@ibarrick/material-ui-currency-textfield", "react"], function (_exports, _useForm, _materialUiCurrencyTextfield, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["zeroIfEmpty"],
      _excluded2 = ["formProps"];

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function FormCurrencyTextField(_ref) {
    var _fieldState$error;

    var zeroIfEmpty = _ref.zeroIfEmpty,
        props = _objectWithoutProperties(_ref, _excluded);

    var formProps = props.formProps,
        restProps = _objectWithoutProperties(props, _excluded2);

    var _useController = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    }),
        field = _useController.field,
        fieldState = _useController.fieldState; // make sure the input value is synced to zero
    // since autonumeric thinks '' === 0 and won't
    // keep the input up to date


    var currencyRef = (0, _react.useRef)(null);
    return React.createElement(_materialUiCurrencyTextfield.default, _extends({
      error: fieldState.invalid,
      helperText: !!props.hideHelperText ? null : (_fieldState$error = fieldState.error) === null || _fieldState$error === void 0 ? void 0 : _fieldState$error.message
    }, restProps, {
      inputRef: field.ref,
      modifyValueOnWheel: false,
      onChange: function onChange(e) {
        // had to do this nonsense because sometimes the value would come in as a string when the implied outputFormat to the CTF is number
        // which caused all sorts of issues (for example, typing 5 in the field would immediately populate 5.00 and future typing wouldn't work)
        // switching to string then prevents us from using number on YUP
        var valFromInput = e.target.value;

        if (valFromInput === '-') {
          return;
        }

        var isString = !!props.outputFormat && props.outputFormat === 'string';
        var isNullOrUndefined = valFromInput === undefined || valFromInput === null; // noinspection JSObjectNullOrUndefined

        var formattedVal = isNullOrUndefined ? '' : isString ? valFromInput.toString() : isNaN(parseFloat(valFromInput.replace(/([^\d.-])/g, ''))) ? '' : parseFloat(valFromInput.replace(/([^\d.-])/g, ''));
        field.onChange(formattedVal);

        if (restProps.onChange) {
          restProps.onChange(e, formattedVal);
        }
      },
      onBlur: function onBlur(e) {
        var needToZero = zeroIfEmpty && !!!e.target.value;

        if (needToZero) {
          var _currencyRef$current, _currencyRef$current$;

          field.onChange(0);
          e.target.value = '0';

          if ((_currencyRef$current = currencyRef.current) !== null && _currencyRef$current !== void 0 && (_currencyRef$current$ = _currencyRef$current.autonumeric) !== null && _currencyRef$current$ !== void 0 && _currencyRef$current$.set) {
            currencyRef.current.autonumeric.set('0.00');
          }
        }

        if (restProps.onBlur) {
          restProps.onBlur(e);
        }

        field.onBlur();
      },
      value: field.value
    }));
  }

  var _default = FormCurrencyTextField;
  _exports.default = _default;
});