define("client/transforms/telephone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized || !serialized.trim()) return "";
      serialized = "(" + serialized.substr(0, 3) + ") " + serialized.substr(3, 3) + "-" + serialized.substr(6, 4);
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        var d = deserialized.replace(/(\(|\)|\-|\s)/g, function (letter) {
          return "";
        });
        return d;
      } else {
        return deserialized;
      }
    }
  });

  _exports.default = _default;
});