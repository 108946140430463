define("client/react/data/transforms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assocLabel = assocLabel;
  _exports.assocName = assocName;
  _exports.jsDateToSqlDate = jsDateToSqlDate;

  // this file will host the re-usable transform logic for the transforms spec'ed out in api.js
  function assocLabel(idField, nameField) {
    return function (model) {
      model['label'] = "(".concat(model[idField], ") ").concat(model[nameField]);
      return model;
    };
  }

  function assocName(first, last) {
    return function (model) {
      model['name'] = "".concat(model[last], ", ").concat(model[first]);
      return model;
    };
  }

  function jsDateToSqlDate(date) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        convertToUTC = _ref.convertToUTC,
        includeTime = _ref.includeTime;

    if (date) {
      var localizedDate = !convertToUTC ? date : new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
      var dateAsString = localizedDate.toISOString();
      return !includeTime ? dateAsString.substr(0, 10) : "".concat(dateAsString.substr(0, 10), " ").concat(dateAsString.substr(11, 8));
    } else {
      return null;
    }
  }
});