define("client/models/check-rec-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    glCode: _emberData.default.attr(),
    checkNumber: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    date: _emberData.default.attr(),
    payee: _emberData.default.attr('capitalize'),
    amount: _emberData.default.attr(),
    flag: _emberData.default.attr('asterisk-to-tf'),
    source: _emberData.default.attr(),
    depositCode: Ember.computed('seq', function () {
      if (this.get('seq').charAt(0) === '*' && /^[a-zA-Z()]$/.test(this.get('seq').charAt(1))) {
        return this.get('seq').charAt(1);
      } else if (this.get('seq').charAt(0) === 'V') {
        return 'V';
      } else {
        return '';
      }
    }),
    initialFlag: Ember.computed(function () {
      return this.get('flag');
    })
  });

  _exports.default = _default;
});