define("client/react/payroll/payroll-message-dialog", ["exports", "@mui/material", "@mui/styles/makeStyles", "clsx", "react", "client/react/utilities/styles"], function (_exports, _material, _makeStyles, _clsx, _react, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _makeStyles.default)(function (theme) {
    return _objectSpread({}, _styles.styles);
  });

  function PayrollMessageDialog(_ref) {
    var onClose = _ref.onClose,
        onComplete = _ref.onComplete;
    var classes = useStyles();

    var _useState = (0, _react.useState)(true),
        _useState2 = _slicedToArray(_useState, 2),
        isOpen = _useState2[0],
        setIsOpen = _useState2[1];

    var _useState3 = (0, _react.useState)(''),
        _useState4 = _slicedToArray(_useState3, 2),
        message = _useState4[0],
        setMessage = _useState4[1];

    var isRunningRef = (0, _react.useRef)(false);

    function submit() {
      if (isRunningRef.current) {
        return;
      } else {
        isRunningRef.current = true;
        onComplete(message);
      }
    }

    return React.createElement(_material.Dialog, {
      open: isOpen,
      fullWidth: true,
      TransitionProps: {
        onExited: function onExited() {
          onClose();
        }
      }
    }, React.createElement(_material.DialogTitle, null, "Payroll Message"), React.createElement(_material.DialogContent, {
      className: classes.column
    }, React.createElement(_material.TextField, {
      value: message,
      onChange: function onChange(e) {
        setMessage(e.target.value);
      },
      multiline: true,
      rows: 5,
      label: "Message"
    })), React.createElement(_material.DialogActions, {
      className: classes.alignEnd
    }, React.createElement(_material.Button, {
      color: "inherit",
      onClick: function onClick() {
        setIsOpen(false);
      }
    }, "Close"), React.createElement(_material.Button, {
      color: "primary",
      onClick: function onClick() {
        return onComplete(message);
      }
    }, "Run Payroll")));
  }

  var _default = PayrollMessageDialog;
  _exports.default = _default;
});