define("client/react/components/filter", ["exports", "@mui/material", "@mui/styles", "react", "client/react/utilities/styles", "react-redux", "clsx", "client/react/components/spacer", "@mui/icons-material", "@ibarrick/material-ui-currency-textfield", "@mui/x-date-pickers-pro", "client/react/custom-dashboards/dynamic-input-dialog", "mustache", "client/react/data/reducer", "lodash", "client/react/utilities/helpers", "client/react/components/alt/model-autocomplete", "client/react/utilities/filter-utils"], function (_exports, _material, _styles, _react, _styles2, _reactRedux, _clsx, _spacer, _iconsMaterial, _materialUiCurrencyTextfield, _xDatePickersPro, _dynamicInputDialog, _mustache, _reducer, _lodash, _helpers, _modelAutocomplete, _filterUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _styles.makeStyles)(function () {
    return _objectSpread(_objectSpread({}, _styles2.styles), {}, {
      autocomplete: {
        minWidth: 150
      },
      datePicker: {
        maxWidth: 150
      }
    });
  });

  function FilterBar(_ref) {
    var _ref$valueFieldStyle = _ref.valueFieldStyle,
        valueFieldStyle = _ref$valueFieldStyle === void 0 ? {} : _ref$valueFieldStyle,
        _ref$level = _ref.level,
        level = _ref$level === void 0 ? 0 : _ref$level,
        node = _ref.node,
        setters = _ref.setters,
        path = _ref.path,
        fields = _ref.fields;
    var appendGroup = setters.appendGroup,
        appendCondition = setters.appendCondition,
        removeNode = setters.removeNode;
    var classes = useStyles();

    var _useReducer = (0, _react.useReducer)(function (x) {
      return x + 1;
    }, 0),
        _useReducer2 = _slicedToArray(_useReducer, 2),
        _ = _useReducer2[0],
        forceRender = _useReducer2[1];

    var inputRef = (0, _react.useRef)(null);

    var _useState = (0, _react.useState)(null),
        _useState2 = _slicedToArray(_useState, 2),
        dynamicInputsField = _useState2[0],
        setDynamicInputsField = _useState2[1];

    var _useState3 = (0, _react.useState)(null),
        _useState4 = _slicedToArray(_useState3, 2),
        renderLabel = _useState4[0],
        setRenderLabel = _useState4[1];

    var costCentersById = (0, _reactRedux.useSelector)(function (state) {
      return state.data.serviceGlSetting.byId;
    });
    var costCentersByCode = (0, _reactRedux.useSelector)(function (state) {
      return state.data.serviceGlSetting.byCode;
    });
    var techniciansByEmployeeNumber = (0, _reactRedux.useSelector)(function (state) {
      return state.data.technician.byEmployeeNumber;
    });
    var technicians = Object.values(techniciansByEmployeeNumber);
    var workOrderStatusesById = (0, _reactRedux.useSelector)(function (state) {
      return state.data.workOrderStatus.byId;
    });
    var workOrderStatuses = Object.values(workOrderStatusesById);
    var contractTypesByCode = (0, _reactRedux.useSelector)(function (state) {
      return state.data.contractType.byCode;
    });
    var contractTypes = (0, _lodash.sortBy)(Object.values(contractTypesByCode), ['code']);
    var costCenters = (0, _lodash.sortBy)(Object.values(costCentersById), ['key']);
    var children = node.filters;
    var selectedField = fields.find(function (x) {
      return x.name === node.field;
    }); // necessary to default to genericModel because we want model types to use same operators

    var operators = _filterUtils.operatorsMap[(0, _filterUtils.operatorsSelector)(selectedField === null || selectedField === void 0 ? void 0 : selectedField.type)];

    var valueComponent;

    switch (selectedField === null || selectedField === void 0 ? void 0 : selectedField.type) {
      case 'money':
        valueComponent = _react.default.createElement(_materialUiCurrencyTextfield.default, {
          value: node.value || null,
          onChange: function onChange(e, v) {
            node.value = v;
            forceRender();
          },
          style: valueFieldStyle,
          label: "Value",
          className: "flex"
        });
        break;

      case 'percent':
      case 'float':
      case 'number':
        valueComponent = _react.default.createElement(_materialUiCurrencyTextfield.default, {
          value: node.value || null,
          onChange: function onChange(e, v) {
            node.value = v;
            forceRender();
          },
          currencySymbol: '',
          style: valueFieldStyle,
          label: "Value",
          className: "flex"
        });
        break;

      case 'string':
        valueComponent = _react.default.createElement(_material.TextField, {
          value: node.value || null,
          onChange: function onChange(e, v) {
            node.value = e.target.value;
            forceRender();
          },
          label: "Value",
          style: valueFieldStyle,
          className: "flex"
        });
        break;

      case 'date':
        valueComponent = _react.default.createElement(_xDatePickersPro.DesktopDatePicker, {
          className: 'flex',
          animateYearScrolling: true,
          autoOk: true,
          inputFormat: "MM/DD/YYYY",
          openTo: "day",
          variant: "inline",
          label: "Value",
          onChange: function onChange(date, value) {
            node.value = date === null || date === void 0 ? void 0 : date.format('YYYY-MM-DD');
            forceRender();
          },
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, params);
          },
          style: valueFieldStyle,
          value: node.value ? moment(node.value, 'YYYY-MM-DD') : null
        });
        break;

      case 'dateTime':
        valueComponent = _react.default.createElement(_xDatePickersPro.DesktopDateTimePicker, {
          className: 'flex',
          style: valueFieldStyle,
          autoOk: true,
          mask: '__/__/____ __:__ _m',
          inputFormat: "MM/DD/YYYY hh:mm a",
          label: "Value",
          onChange: function onChange(date, value) {
            node.value = date === null || date === void 0 ? void 0 : date.format('YYYY-MM-DD HH:mm');
            forceRender();
          },
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, params);
          },
          value: node.value ? moment(node.value) : null
        });
        break;

      case 'citronTime':
        valueComponent = _react.default.createElement(_xDatePickersPro.DesktopDateTimePicker, {
          className: 'flex',
          style: valueFieldStyle,
          autoOk: true,
          mask: '__:__ _m',
          inputFormat: "hh:mm a",
          label: "Value",
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, params);
          },
          onChange: function onChange(date, value) {
            node.value = date === null || date === void 0 ? void 0 : date.format('HHmm');
            forceRender();
          },
          value: node.value ? moment(node.value, 'HHmm') : null
        });
        break;

      case 'monthYear':
        valueComponent = _react.default.createElement(_xDatePickersPro.DesktopDatePicker, {
          className: 'flex',
          views: ["year", "month"],
          animateYearScrolling: true,
          autoOk: true,
          inputFormat: "MM/YYYY",
          openTo: "month",
          variant: "inline",
          label: "Value",
          onChange: function onChange(date, value) {
            node.value = date === null || date === void 0 ? void 0 : date.format('YYYY-MM');
            forceRender();
          },
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, params);
          },
          style: valueFieldStyle,
          value: node.value ? moment(node.value, 'YYYY-MM') : null
        });
        break;

      case 'year':
        valueComponent = _react.default.createElement(_xDatePickersPro.DesktopDatePicker, {
          className: 'flex',
          views: ["year"],
          animateYearScrolling: true,
          autoOk: true,
          inputFormat: "YYYY",
          openTo: "year",
          variant: "inline",
          label: "Value",
          onChange: function onChange(date, value) {
            node.value = date === null || date === void 0 ? void 0 : date.format('YYYY');
            forceRender();
          },
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, params);
          },
          style: valueFieldStyle,
          value: node.value ? moment(node.value, 'YYYY') : null
        });
        break;

      case 'costCenter':
        valueComponent = _react.default.createElement(_material.Autocomplete, {
          className: 'flex',
          options: costCenters,
          getOptionLabel: function getOptionLabel(option) {
            return "".concat((0, _helpers.getCostCenterLabel)(option));
          },
          value: costCentersByCode[node.value] || null,
          onChange: function onChange(_, value) {
            var _value$id, _value$id$toString;

            node.value = value === null || value === void 0 ? void 0 : (_value$id = value.id) === null || _value$id === void 0 ? void 0 : (_value$id$toString = _value$id.toString()) === null || _value$id$toString === void 0 ? void 0 : _value$id$toString.padStart(2, '0');
            forceRender();
          },
          onBlur: function onBlur(e) {
            var input = e.target.value;

            if (input) {
              var match = null;
              var inputAsNumber = parseInt(input);
              match = costCenters.find(function (c) {
                return isNaN(inputAsNumber) ? (0, _helpers.getCostCenterLabel)(c) === input : inputAsNumber === c.id;
              });

              if (match) {
                node.value = match.id.toString().padStart(2, '0');
              } else {
                node.value = null;
              }
            } else {
              node.value = null;
            }

            forceRender();
          },
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, _extends({}, params, {
              style: valueFieldStyle,
              label: "Value"
            }));
          }
        });
        break;

      case 'status':
        valueComponent = _react.default.createElement(_material.Autocomplete, {
          className: 'flex',
          options: workOrderStatuses,
          getOptionLabel: function getOptionLabel(option) {
            return "".concat((0, _helpers.getStatusLabel)(option));
          },
          value: workOrderStatusesById[node.value] || null,
          onChange: function onChange(_, value) {
            node.value = value === null || value === void 0 ? void 0 : value.id;
            forceRender();
          },
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, _extends({}, params, {
              style: valueFieldStyle,
              label: "Value"
            }));
          }
        });
        break;

      case 'technician':
        valueComponent = _react.default.createElement(_material.Autocomplete, {
          className: 'flex',
          options: technicians,
          getOptionLabel: function getOptionLabel(option) {
            return "".concat((0, _helpers.getTechLabel)(option));
          },
          value: techniciansByEmployeeNumber[node.value] || null,
          onChange: function onChange(_, value) {
            node.value = value === null || value === void 0 ? void 0 : value.employeeNumber;
            forceRender();
          },
          onBlur: function onBlur(e) {
            var input = e.target.value;

            if (input) {
              var match = null;
              var inputAsNumber = parseInt(input);
              match = technicians.find(function (t) {
                return isNaN(inputAsNumber) ? (0, _helpers.getTechLabel)(t) === input : inputAsNumber === parseInt(t.employeeNumber);
              });

              if (match) {
                node.value = match.employeeNumber;
              } else {
                node.value = null;
              }
            } else {
              node.value = null;
            }

            forceRender();
          },
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, _extends({}, params, {
              style: valueFieldStyle,
              className: "flex",
              label: "Value"
            }));
          }
        });
        break;

      case 'cycle':
        valueComponent = _react.default.createElement(_material.Autocomplete, {
          className: 'flex',
          options: _filterUtils.cycles,
          getOptionLabel: function getOptionLabel(option) {
            return "".concat(option.label);
          },
          value: _filterUtils.cyclesByKey[node.value] || null,
          onChange: function onChange(_, value) {
            node.value = value === null || value === void 0 ? void 0 : value.key;
            forceRender();
          },
          onBlur: function onBlur(e) {
            var input = e.target.value;

            if (input) {
              var match = null;
              match = _filterUtils.cycles.find(function (c) {
                return c.label === input || c.key.toLowerCase() === input.toLowerCase();
              });

              if (match) {
                node.value = match.key;
              } else {
                node.value = null;
              }
            } else {
              node.value = null;
            }

            forceRender();
          },
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, _extends({}, params, {
              style: valueFieldStyle,
              label: "Value"
            }));
          }
        });
        break;

      case 'contractType':
        valueComponent = _react.default.createElement(_material.Autocomplete, {
          className: 'flex',
          options: contractTypes,
          getOptionLabel: function getOptionLabel(option) {
            return "".concat((0, _helpers.getContractTypeLabel)(option));
          },
          value: contractTypesByCode[node.value] || null,
          onChange: function onChange(_, value) {
            node.value = value === null || value === void 0 ? void 0 : value.key;
            forceRender();
          },
          onBlur: function onBlur(e) {
            var input = e.target.value;

            if (input) {
              var match = null;
              match = contractTypes.find(function (c) {
                return (0, _helpers.getContractTypeLabel)(c) === input || c.code.toLowerCase() === input.toLowerCase();
              });

              if (match) {
                node.value = match.code;
              } else {
                node.value = null;
              }
            } else {
              node.value = null;
            }

            forceRender();
          },
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, _extends({}, params, {
              style: valueFieldStyle,
              label: "Value"
            }));
          }
        });
        break;

      case 'customer':
        valueComponent = _react.default.createElement(_modelAutocomplete.default, {
          searchProps: {
            searchModel: 'customer',
            keyField: 'custId',
            searchParam: 'query',
            staticParams: {
              offset: 0,
              type: ''
            }
          },
          className: 'flex',
          getOptionLabel: function getOptionLabel(option) {
            return node.value || (0, _helpers.getCustomerLabel)(option);
          },
          renderOption: function renderOption(props, option) {
            return _react.default.createElement("li", props, "`$", (0, _helpers.getCustomerLabel)(option), "`");
          },
          inputValue: node.value || '',
          flatValue: true,
          onChange: function onChange(e, v, r) {
            node.value = v;
            forceRender();
          },
          onInputChange: function onInputChange(e, v, r) {
            if (r !== 'reset') {
              node.value = v;
            }

            forceRender();
          },
          renderInput: function renderInput(params) {
            return _react.default.createElement(_material.TextField, _extends({}, params, {
              style: valueFieldStyle,
              label: "Value"
            }));
          }
        });
        break;

      default:
        valueComponent = _react.default.createElement("span", null);
        break;
    }

    function getLabelWidget(_ref2) {
      var _node$meta;

      var fields = _ref2.fields,
          node = _ref2.node;
      var field = fields.find(function (x) {
        return x.name === node.field && x.aggregationFn === node.aggregationFn;
      });
      return field ? field.name === "Customer has Contract of Type:" && (_node$meta = node.meta) !== null && _node$meta !== void 0 && _node$meta.contractType ? field.label.concat(node.meta.contractType) : field.label || '' : '';
    }

    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("li", {
      className: level === 0 ? 'k-filter-group-main' : 'k-filter-item'
    }, _react.default.createElement("div", {
      className: "k-filter-toolbar"
    }, _react.default.createElement("div", {
      className: (0, _clsx.default)("k-widget", "k-toolbar")
    }, !node.logic ? _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_material.Autocomplete, {
      renderInput: function renderInput(params) {
        return _react.default.createElement(_material.TextField, _extends({}, params, {
          style: {
            minWidth: 250
          },
          value: renderLabel || getLabelWidget({
            fields: fields,
            node: node
          }),
          inputRef: inputRef,
          label: "Field"
        }));
      },
      className: classes.autocomplete,
      options: fields.sort(function (a, b) {
        return a.label.localeCompare(b.label);
      }),
      disableClearable: true,
      getOptionLabel: function getOptionLabel(x, v) {
        return renderLabel || x.label;
      },
      renderOption: function renderOption(props, x, v) {
        return _react.default.createElement("li", props, x === null || x === void 0 ? void 0 : x.label);
      },
      inputValue: renderLabel || getLabelWidget({
        fields: fields,
        node: node
      }),
      value: fields.find(function (x) {
        return x.name === node.field && x.aggregationFn === node.aggregationFn;
      }) || null,
      onChange: function onChange(e, v, r) {
        // Doing this without setState because state is actually owned by parent
        setRenderLabel(null);

        if (v !== null && v !== void 0 && v['expression-params'] && v['expression-params'].some(function (it) {
          return !it.displayOnly;
        })) {
          setDynamicInputsField(v);
        }

        node.field = v === null || v === void 0 ? void 0 : v.name;
        node.value = (0, _filterUtils.getDefaultValue)(v === null || v === void 0 ? void 0 : v.type);
        node.aggregationFn = v === null || v === void 0 ? void 0 : v.aggregationFn;
        forceRender();
      }
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_material.Autocomplete, {
      renderInput: function renderInput(params) {
        return _react.default.createElement(_material.TextField, _extends({}, params, {
          label: "Operators"
        }));
      },
      disableClearable: true,
      className: classes.autocomplete,
      options: operators || [],
      getOptionLabel: function getOptionLabel(x) {
        return x.label;
      },
      value: (operators === null || operators === void 0 ? void 0 : operators.find(function (x) {
        return x.operator === node.operator;
      })) || null,
      onChange: function onChange(e, v, r) {
        // Doing this without setState because state is actually owned by parent
        node.operator = v === null || v === void 0 ? void 0 : v.operator;
        forceRender();
      }
    }), _react.default.createElement(_spacer.default, null), valueComponent, _react.default.createElement(_material.IconButton, {
      onClick: function onClick() {
        removeNode(path);
      }
    }, _react.default.createElement(_iconsMaterial.Delete, null))) : _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_material.ButtonGroup, {
      disableElevation: true,
      variant: "contained"
    }, _react.default.createElement(_material.Button, {
      onClick: function onClick() {
        // Doing this without setState because state is actually owned by parent
        node.logic = "and";
        forceRender();
      },
      color: node.logic === "and" ? "primary" : "inherit"
    }, "AND"), _react.default.createElement(_material.Button, {
      onClick: function onClick() {
        // Doing this without setState because state is actually owned by parent
        node.logic = "or";
        forceRender();
      },
      color: node.logic === "or" ? "primary" : "inherit"
    }, "OR")), _react.default.createElement(_material.Tooltip, {
      title: 'Add Expression'
    }, _react.default.createElement(_material.IconButton, {
      onClick: function onClick() {
        appendCondition(path);
      }
    }, _react.default.createElement("span", {
      className: "k-button-icon k-icon k-i-filter-add-expression"
    }))), _react.default.createElement(_material.Tooltip, {
      title: 'Add Group'
    }, _react.default.createElement(_material.IconButton, {
      onClick: function onClick() {
        appendGroup(path);
      }
    }, _react.default.createElement("span", {
      className: "k-button-icon k-icon k-i-filter-add-group"
    }))), level !== 0 && _react.default.createElement(_material.IconButton, {
      onClick: function onClick() {
        removeNode(path);
      }
    }, _react.default.createElement(_iconsMaterial.Delete, null))))), children && _react.default.createElement("ul", {
      className: "k-filter-lines"
    }, children.map(function (child, i) {
      return _react.default.createElement(FilterBar, {
        valueFieldStyle: {
          minWidth: 250
        },
        key: "".concat(level, "-").concat(i),
        fields: fields,
        node: child,
        level: level + 1,
        setters: setters,
        path: [].concat(_toConsumableArray(path), [i])
      });
    }))), !!dynamicInputsField && _react.default.createElement(_dynamicInputDialog.default, {
      filterDisplayOnly: true,
      params: dynamicInputsField['expression-params'],
      onSetParams: function onSetParams(params) {
        node.meta = params;
        inputRef.current.value = _mustache.default.render(dynamicInputsField['label-template'], params);
        setRenderLabel(_mustache.default.render(dynamicInputsField['label-template'], params));
        setDynamicInputsField(null);
      },
      onClose: function onClose() {
        setDynamicInputsField(null);
      }
    }));
  }

  function Filter(_ref3) {
    var fields = _ref3.fields,
        filters = _ref3.filters,
        onFilterChange = _ref3.onFilterChange;
    var classes = useStyles();

    var _useState5 = (0, _react.useState)(filters),
        _useState6 = _slicedToArray(_useState5, 2),
        filterTree = _useState6[0],
        setFilterTree = _useState6[1];

    var dispatch = (0, _reactRedux.useDispatch)();
    (0, _react.useEffect)(function () {
      dispatch((0, _reducer.fetchModels)('serviceGlSetting'));
      dispatch((0, _reducer.fetchModels)('technician'));
      dispatch((0, _reducer.fetchModels)('workOrderStatus'));
      dispatch((0, _reducer.fetchModels)('contractType')); //		dispatch(fetchModels('flrateCategory'))
    }, []);

    function appendGroup(path) {
      var node = filterTree;
      path.forEach(function (index) {
        node = node.filters[index];
      });
      node.filters.push({
        logic: "and",
        filters: []
      });
      setFilterTree(_objectSpread({}, filterTree));
    }

    function appendCondition(path) {
      var node = filterTree;
      path.forEach(function (index) {
        node = node.filters[index];
      });
      node.filters.push({
        field: "",
        operator: "",
        value: null
      });
      setFilterTree(_objectSpread({}, filterTree));
    }

    function removeNode(path) {
      var secondToLast = null;
      var node = filterTree;
      path.forEach(function (index) {
        secondToLast = node;
        node = node.filters[index];
      });
      secondToLast.filters.splice(path[path.length - 1], 1);
      setFilterTree(_objectSpread({}, filterTree));
    }

    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: (0, _clsx.default)("k-widget", "k-filter")
    }, _react.default.createElement("ul", {
      className: "k-filter-container"
    }, _react.default.createElement(FilterBar, {
      fields: fields,
      node: filterTree,
      setters: {
        appendGroup: appendGroup,
        appendCondition: appendCondition,
        removeNode: removeNode
      },
      path: []
    }))));
  }

  var _default = Filter;
  _exports.default = _default;
});