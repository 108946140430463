define("client/react/components/typed/grid-detail-toggle-button", ["exports", "react/jsx-runtime", "@mui/material", "@mui/icons-material"], function (_exports, _jsxRuntime, _material, _iconsMaterial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function GridDetailToggleButton(props) {
    var row = props.row,
        shouldDisable = props.shouldDisable,
        isExpanded = props.value;
    var disabled = shouldDisable(row);
    return disabled ? (0, _jsxRuntime.jsx)("span", {}) : (0, _jsxRuntime.jsx)(_material.IconButton, Object.assign({
      size: 'small',
      tabIndex: -1
    }, {
      children: isExpanded ? (0, _jsxRuntime.jsx)(_iconsMaterial.Remove, {
        sx: {
          height: 18,
          width: 18
        }
      }) : (0, _jsxRuntime.jsx)(_iconsMaterial.Add, {
        sx: {
          height: 18,
          width: 18
        }
      })
    }));
  }

  var _default = GridDetailToggleButton;
  _exports.default = _default;
});