define("client/components/technician-block/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['tech-box', 'flex', 'layout-row', 'layout-align-space-around'],
    attributeBindings: ['style'],
    auth: Ember.inject.service(),
    style: computed('width', function () {
      return "width: ".concat(this.get('width'), "; font-size:13.5px;font-family:Arial, Helvetica, sans-serif;");
    }),
    workOrdersFiltered: computed.filter('workOrders', function (workOrder) {
      return workOrder.get('tech.content') == this.get('technician') || workOrder.get('tech2.content') == this.get('technician');
    }).property('workOrders', 'workOrders.@each.tech.content', 'workOrder.@each.tech2.content', 'date', 'workOrders.@each.assignedDate'),
    count: computed.alias('workOrdersFiltered.length')
  });

  _exports.default = _default;
});