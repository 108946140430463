define("client/templates/pr-compute-payroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VUU/Z1KI",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"featureFlagsService\",\"featureFlags\",\"payrollRework\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,[\"pr-compute\"]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[1,[33,[\"pr-compute-payroll\"],null,[[\"employees\",\"payrollEntries\",\"hours\"],[[28,[\"model\",\"employees\"]],[28,[\"model\",\"payrollEntries\"]],[28,[\"model\",\"payrollHours\"]]]]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/pr-compute-payroll.hbs"
    }
  });

  _exports.default = _default;
});