define("client/models/appointment-confirmation-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr(),
    callerId: _emberData.default.attr('telephone'),
    phoneMessage: _emberData.default.attr(),
    emailMessage: _emberData.default.attr(),
    emailSubject: _emberData.default.attr(),
    emailFrom: _emberData.default.attr(),
    windowLength: _emberData.default.attr(),
    promptConfirmation: _emberData.default.attr(),
    techDefault: _emberData.default.attr()
  });

  _exports.default = _default;
});