define("client/components/lead-stage-management/component", ["exports", "client/react/utilities/snackbar"], function (_exports, _snackbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addStage: '',
    actions: {
      enterPressed: function enterPressed(e) {
        if (e.keyCode === 13) {
          var v = this.get('addStage').trim(),
              stages = this.get('stages');
          if (v === "") return;

          if (v.length > 50) {
            (0, _snackbar.showSnackbar)('Must be 50 characters or less', {
              variant: 'error'
            });
          }

          if (stages.any(function (itm) {
            return itm.toLowerCase() === v.toLowerCase();
          })) {
            (0, _snackbar.showSnackbar)('Already exists', {
              variant: 'error'
            });
          } else if (stages.get('length') === 8) {
            (0, _snackbar.showSnackbar)('Limit of 8 stages reached', {
              variant: 'error'
            });
          } else {
            var indexOfClosed = stages.indexOf("Closed");
            stages.insertAt(indexOfClosed, v);
            this.set('addStage', '');
          }
        }
      },
      updateOrder: function updateOrder(arr) {
        var newArr = Ember.A(["New"]);
        newArr.pushObjects(arr);
        newArr.pushObject("Closed");
        this.set('stages', newArr);
      },
      removeStage: function removeStage(val) {
        this.get('stages').removeObject(val);
      }
    }
  });

  _exports.default = _default;
});