define("client/components/better-file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uk9CBL9J",
    "block": "{\"statements\":[[11,\"input\",[]],[16,\"id\",[26,[\"for\"]],null],[15,\"type\",\"file\"],[16,\"accept\",[26,[\"accept\"]],null],[16,\"multiple\",[26,[\"multiple\"]],null],[16,\"disabled\",[26,[\"disabled\"]],null],[16,\"onchange\",[33,[\"action\"],[[28,[null]],\"change\"],[[\"value\"],[\"target.files\"]]],null],[15,\"style\",\"display: none;\"],[13],[14],[0,\"\\n\"],[18,\"default\",[[33,[\"action\"],[[28,[null]],\"openFileInput\"],null]]],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/better-file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});