define("client/react/utilities/typed/hooks/use-data-grid-background", ["exports", "tss-react/mui", "client/react/utilities/typed/styles/styles"], function (_exports, _mui, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var useStyles = (0, _mui.makeStyles)()(function (theme) {
    return Object.assign(Object.assign({}, _styles.styles), {
      detailsContainer: {
        backgroundColor: theme.palette.divider,
        padding: 25,
        height: '100%'
      },
      dataGrid: {
        backgroundColor: theme.palette.background.paper,
        height: '100%'
      }
    });
  });

  function useDataGridBackground() {
    var _useStyles = useStyles(),
        classes = _useStyles.classes;

    return {
      dataGrid: classes.dataGrid,
      detailsContainer: classes.detailsContainer
    };
  }

  var _default = useDataGridBackground;
  _exports.default = _default;
});