define("client/routes/ar-set-gls", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      return Ember.RSVP.hash({
        settings: this.store.findRecord('ar-setting', 1, {
          reload: true
        }),
        accounts: this.store.findAll('ledger-account', {
          reload: true
        })
      });
    }
  });

  _exports.default = _default;
});