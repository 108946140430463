define("client/components/smart-each/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SmartEachComponent = Ember.Component.extend({
    tagName: '',
    actions: {
      smartFocus: function smartFocus(idx, e) {
        var arr = this.get('arr');
        var length = arr ? arr.get('length') : 0;

        if (idx === length - 1) {
          this.sendAction('onFocusLast');
        }
      }
    }
  });
  SmartEachComponent.reopenClass({
    positionalParams: ['arr']
  });
  var _default = SmartEachComponent;
  _exports.default = _default;
});