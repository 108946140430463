define("client/components/ss-notification/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    className: ["notification", "layout-row", "layout-align-space-between"],
    classNameBindings: ["notification.seen::new", "false::notification", "false::layout-row", "false::layout-align-space-between"],
    // TODO: 2.15 change this to be router service instead of private API
    notifications: Ember.inject.service(),
    actions: {
      perform: function perform() {
        this.get('notifications.processAction').perform(this.get('notification'));
        this.sendAction('closeAction');
      }
    }
  });

  _exports.default = _default;
});