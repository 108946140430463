define("client/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    // attrs: {
    //   check: { deserialize: 'records' }
    // },
    extractArray: function extractArray(store, type, payload) {
      var arr = payload[Object.keys(payload)[0]]; //          var arr2 = Ember.A(arr);

      var i;
      var len;
      var tmp;
      var element; //          arr2.forEach(function(element) {

      for (var j = 0; j < arr.length; ++j) {
        //              element = arr[j];
        i = 0;

        if (arr[j] === undefined || arr[j] === null) {//arr[j] = [];
        }

        len = Object.keys(arr[j]).length;

        for (i = 0; i < len; i++) {
          if (arr[j][Object.keys(arr[j])[i]] instanceof Array || arr[j][Object.keys(arr[j])[i]] instanceof Object) {
            if (arr[j][Object.keys(arr[j])[i]] instanceof Object && !(arr[j][Object.keys(arr[j])[i]] instanceof Array)) {
              if (arr[j][Object.keys(arr[j])[i]].id && Object.keys(arr[j])[i] !== "costItem") {
                //&& arr[j][Object.keys(arr[j])[i]].id !== "") {
                arr[j][Object.keys(arr[j])[i]] = arr[j][Object.keys(arr[j])[i]].id;
              }
            } else {
              //                          arr[j][Object.keys(arr[j])[i]] = arr[j][Object.keys(arr[j])[i]];
              //                          tmp = Ember.A(tmp);
              if (Object.keys(arr[j])[i] !== "maintMonths" && Object.keys(arr[j])[i] !== "images") {
                for (var k = 0; k < arr[j][Object.keys(arr[j])[i]].length; ++k) {
                  arr[j][Object.keys(arr[j])[i]][k] = arr[j][Object.keys(arr[j])[i]][k].id;
                } //                                tmp = tmp.mapBy('id');

              } //                          arr[j][Object.keys(arr[j])[i]]= tmp;//.toArray();

            }
          }
        }
      } //          });


      payload[Object.keys(payload)[0]] = arr; //.toArray();

      return this._super(store, type, payload);
    },
    extractSingle: function extractSingle(store, primaryType, payload, recordId) {
      var record = payload[Object.keys(payload)[0]];

      if (record !== null) {
        for (var i = 0; i < Object.keys(record).length; i++) {
          var tmp = record[Object.keys(record)[i]];

          if (tmp instanceof Object) {
            if (tmp.id && Object.keys(record)[i] !== "costItem") {
              record[Object.keys(record)[i]] = tmp.id;
            }
          }

          if (tmp instanceof Array && Object.keys(record)[i] !== 'maintMonths' && Object.keys(record)[i] !== 'images') {
            tmp = Ember.A(tmp);
            tmp = tmp.mapBy('id');
            record[Object.keys(record)[i]] = tmp.toArray();
          }
        }

        payload[Object.keys(payload)[0]] = record;
      }

      return this._super(store, primaryType, payload, recordId);
    } // serializeBelongsTo: function(record, json, relationship) {
    //     return this._super(record,json,relationship);
    // },
    // extractRelationship (relationshipModelName, relationshipHash) {
    //   console.log(relationshipModelName);
    //   console.log(relationshipHash);
    //   this._super(relationshipModelName, relationshipHash);
    // }

  });

  _exports.default = _default;
});