define("client/utils/clone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = clone;

  function clone(obj) {
    var json = JSON.parse(JSON.stringify(obj));

    if (json['descriptors']) {
      json['descriptors'] = JSON.parse(json['descriptors']);
    }

    if (json['parameters']) {
      json['parameters'] = JSON.parse(json['parameters']);
    }

    function parseJson(value) {
      if (value instanceof Array) {
        for (var i = 0; i < value.length; i++) {
          value[i] = parseJson(value[i]);
        }

        return Ember.A(value);
      } else if (value instanceof Object) {
        var keys = Object.keys(value);

        for (var _i = 0; _i < keys.length; _i++) {
          value[keys[_i]] = parseJson(value[keys[_i]]);
        }

        return Ember.Object.create(value);
      } else {
        return value;
      }
    }

    return parseJson(json);
  }
});