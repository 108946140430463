define("client/templates/srvc-contract-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d0vqhKk8",
    "block": "{\"statements\":[[1,[33,[\"srvc-contract-edit\"],null,[[\"model\",\"contract\",\"onSave\",\"close\"],[[28,[\"model\"]],[33,[\"changeset\"],[[28,[\"model\"]]],null],[33,[\"route-action\"],[\"transition\",\"srvc-contract-view\"],null],[33,[\"route-action\"],[\"back\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/srvc-contract-edit.hbs"
    }
  });

  _exports.default = _default;
});