define("client/components/list-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TQaWay/L",
    "block": "{\"statements\":[[0,\"\\t\"],[11,\"div\",[]],[15,\"class\",\"panel-heading\"],[13],[11,\"strong\",[]],[13],[1,[26,[\"title\"]],false],[14],[0,\"\\n\\t\"],[14],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"panel-body\"],[13],[0,\"\\n\\t\\t\"],[11,\"div\",[]],[15,\"style\",\"height: 90%\"],[13],[0,\"\\n\\t\\t\\t\"],[11,\"ul\",[]],[15,\"class\",\"list-group\"],[15,\"style\",\"margin: 0;\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"arr\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[11,\"li\",[]],[15,\"class\",\"list-group-item\"],[15,\"style\",\"width: 100%; padding: 0; margin-bottom: 0;\"],[13],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,[\"item\"]],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[11,\"span\",[]],[15,\"class\",\"glyphicon glyphicon-remove\"],[15,\"style\",\"float: right;\"],[5,[\"action\"],[[28,[null]],\"remove\",[28,[\"item\"]]]],[13],[14],[0,\"\\n\\t\\t\\t\\t\"],[14],[0,\"\\n\"]],\"locals\":[\"item\"]},null],[0,\"\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"input-group\"],[13],[0,\"\\n\\t\\t\\t\"],[1,[33,[\"simple-select\"],null,[[\"prompt\",\"content\",\"value\",\"class\"],[[28,[\"prompt\"]],[28,[\"select\"]],[28,[\"value\"]],\"form-control\"]]],false],[0,\"\\n\\t\\t\\t\"],[11,\"span\",[]],[15,\"class\",\"input-group-addon glyphicon glyphicon-plus\"],[5,[\"action\"],[[28,[null]],\"add\"]],[13],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/list-edit/template.hbs"
    }
  });

  _exports.default = _default;
});