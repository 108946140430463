define("client/routes/select-checks", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    checksArr: null,
    actions: {
      refresh: function refresh() {
        this.refresh();
      },
      transition: function transition(vendor) {
        this.transitionTo('vendor-checks', vendor);
      }
    }
  });

  _exports.default = _default;
});