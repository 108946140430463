define("client/helpers/increment-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.incrementIndex = incrementIndex;

  function incrementIndex(value) {
    return Number(value) + 1;
  }

  var _default = Ember.Helper.helper(incrementIndex);

  _exports.default = _default;
});