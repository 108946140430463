define("client/components/time-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    classNames: ['inline-element'],
    style: 'display: inline-block;position: relative;',
    didInsertElement: function didInsertElement() {
      this.$().children().first().children('input').timepicker();
    },
    output: ""
  });

  _exports.default = _default;
});