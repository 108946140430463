define("client/templates/gl-file-maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t3QI6B1a",
    "block": "{\"statements\":[[6,[\"ember-wormhole\"],null,[[\"to\"],[\"main-toolbar\"]],{\"statements\":[[0,\"\\t\"],[11,\"h4\",[]],[13],[0,\"\\n\\t\\tGeneral Ledger File Maintenance\\n\\t\"],[14],[0,\"\\n\\t\"],[11,\"span\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},null],[11,\"div\",[]],[15,\"style\",\"overflow-y: auto; height: 100%;overflow-x:hidden;background-color: #f5f5f5;\"],[13],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"layout-row\"],[13],[0,\"\\n\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"flex-100\"],[13],[0,\"\\n\\t\\t\\t\"],[1,[33,[\"ledger-account-crud\"],null,[[\"accounts\",\"categories\"],[[28,[\"model\",\"accounts\"]],[28,[\"model\",\"categories\"]]]]],false],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/gl-file-maintenance.hbs"
    }
  });

  _exports.default = _default;
});