define("client/services/create-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Takes the name of a model and a generic ember object
  // and converts all of the object's keys into the keys of 
  // the new instance of the model specified.
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    create: function create(modelName, obj) {
      if (!modelName || !obj) return null;
      var newRecord = this.get('store').createRecord(modelName);
      var keys = Object.keys(obj);

      for (var i = 0; i < keys.length; i++) {
        var keyToUse = keys[i];
        newRecord.set(keyToUse, obj.get(keyToUse));
      }

      return newRecord;
    },
    uppercase: function uppercase(obj) {
      if (!obj) return null;
      var keys = Object.keys(obj);

      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];

        if (typeof obj.get(key) === "string") {
          obj.set(key, obj.get(key).toUpperCase().trim());
        }
      }

      return obj;
    }
  });

  _exports.default = _default;
});