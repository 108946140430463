define("client/helpers/no-screen-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.noScreenChecked = noScreenChecked;

  function noScreenChecked(params
  /*, hash*/
  ) {
    var screen = params[0];
    return !screen.get('permissions').any(function (x) {
      return x.get('enabled');
    });
  }

  var _default = Ember.Helper.helper(noScreenChecked);

  _exports.default = _default;
});