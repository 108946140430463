define("client/models/dynamic-invoice-value", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tenant: _emberData.default.attr(),
    complaintNumber: _emberData.default.attr(),
    srvcOrdId: _emberData.default.attr(),
    filename: _emberData.default.attr(),
    landscape: _emberData.default.attr(),
    name: _emberData.default.attr(),
    xobject: _emberData.default.attr(),
    pages: _emberData.default.attr(),
    dynamicFields: _emberData.default.hasMany('dynamic-field-value', {
      async: true
    }),
    parentInvoice: _emberData.default.attr(),
    mergeParts: _emberData.default.attr(),
    solutionLength: _emberData.default.attr(),
    extendedPartDescriptions: _emberData.default.attr(),
    dfTotal: _emberData.default.attr(),
    value: _emberData.default.attr('configuration'),
    invoiceNumber: _emberData.default.attr()
  });

  _exports.default = _default;
});