define("client/components/mobile-created-unlinked-work-orders/component", ["exports", "client/react/utilities/snackbar"], function (_exports, _snackbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    utilities: Ember.inject.service(),
    filteredCheckups: Ember.computed.filter('checkups', function (c) {
      return !c.get('workOrderId');
    }),
    sortedCheckups: Ember.computed.sort('filteredCheckups', function (a, b) {
      var aMonthYear = a ? a.get('monthYear') : null,
          bMonthYear = b ? b.get('monthYear') : null,
          formattedA = aMonthYear ? aMonthYear.substring(3, 7) + "/" + aMonthYear.substring(0, 2) : null,
          formattedB = bMonthYear ? bMonthYear.substring(3, 7) + "/" + bMonthYear.substring(0, 2) : null;

      if (formattedA === null) {
        return 99999;
      } else if (formattedB === null) {
        return -99999;
      }

      if (formattedA <= formattedB) {
        return -1;
      } else if (formattedA > formattedB) {
        return 1;
      } else {
        return 0;
      }
    }).property('filteredCheckups', 'filteredCheckups.@each.monthYear'),
    actions: {
      openWorkOrder: function openWorkOrder(wo) {
        this.get('utilities').openInNewTab('view-work-order', wo.id);
      },
      linkToCheckup: function linkToCheckup(checkup) {
        this.sendAction('onLink', Ember.Object.create({
          workOrderId: this.get('woToLink').id,
          checkupMonthYear: checkup.get('monthYear')
        }));
        this.sendAction('close');
      },
      cannotLink: function cannotLink() {
        (0, _snackbar.showSnackbar)('Cannot link to checkup that already has a linked work order', {
          variant: 'error'
        });
      },
      noLink: function noLink() {
        this.sendAction('onNoLink');
        this.sendAction('close');
      }
    }
  });

  _exports.default = _default;
});