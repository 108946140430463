define("client/react/utilities/typed/yup-extended", ["exports", "yup"], function (_exports, yup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  yup.addMethod(yup.string, 'integer', function () {
    return this.matches(/^(\d+|\s{0})$/, 'Must be numeric');
  });
  var _default = yup;
  _exports.default = _default;
});