define("client/models/permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tenant: _emberData.default.attr(),
    parentName: _emberData.default.attr(),
    moduleName: _emberData.default.attr(),
    order: _emberData.default.attr(),
    suborder: _emberData.default.attr(),
    title: _emberData.default.attr(),
    description: _emberData.default.attr(),
    authorization: _emberData.default.attr('json2'),
    authorizationMeta: _emberData.default.attr('json2')
  });

  _exports.default = _default;
});