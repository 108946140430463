define("client/react/utilities/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.styles = void 0;
  var styles = {
    row: {
      minWidth: 0,
      minHeight: 0,
      display: "flex",
      flexDirection: "row",
      "& > *": {
        flexShrink: 0
      },
      "& > .flex": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "100%"
      },
      "& > .flex5": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "5%",
        maxHeight: "100%"
      },
      "& > .flex10": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "10%",
        maxHeight: "100%"
      },
      "& > .flex15": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "15%",
        maxHeight: "100%"
      },
      "& > .flex20": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "20%",
        maxHeight: "100%"
      },
      "& > .flex25": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "25%",
        maxHeight: "100%"
      },
      "& > .flex30": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "30%",
        maxHeight: "100%"
      },
      "& > .flex33": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "33.33%",
        maxHeight: "100%"
      },
      "& > .flex35": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "35%",
        maxHeight: "100%"
      },
      "& > .flex40": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "40%",
        maxHeight: "100%"
      },
      "& > .flex45": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "45%",
        maxHeight: "100%"
      },
      "& > .flex50": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "50%",
        maxHeight: "100%"
      },
      "& > .flex55": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "55%",
        maxHeight: "100%"
      },
      "& > .flex60": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "60%",
        maxHeight: "100%"
      },
      "& > .flex65": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "65%",
        maxHeight: "100%"
      },
      "& > .flex70": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "70%",
        maxHeight: "100%"
      },
      "& > .flex75": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "75%",
        maxHeight: "100%"
      },
      "& > .flex80": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "80%",
        maxHeight: "100%"
      },
      "& > .flex85": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "85%",
        maxHeight: "100%"
      },
      "& > .flex90": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "90%",
        maxHeight: "100%"
      },
      "& > .flex95": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "95%",
        maxHeight: "100%"
      }
    },
    column: {
      minWidth: 0,
      minHeight: 0,
      display: "flex",
      flexDirection: "column",
      "& > *": {
        flexShrink: 0
      },
      "& > .flex": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "100%"
      },
      "& > .flex5": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "5%"
      },
      "& > .flex10": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "10%"
      },
      "& > .flex15": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "15%"
      },
      "& > .flex20": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "20%"
      },
      "& > .flex25": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "25%"
      },
      "& > .flex30": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "30%"
      },
      "& > .flex33": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "33.33%"
      },
      "& > .flex35": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "35%"
      },
      "& > .flex40": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "40%"
      },
      "& > .flex45": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "45%"
      },
      "& > .flex50": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "50%"
      },
      "& > .flex55": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "55%"
      },
      "& > .flex60": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "60%"
      },
      "& > .flex65": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "65%"
      },
      "& > .flex70": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "70%"
      },
      "& > .flex75": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "75%"
      },
      "& > .flex80": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "80%"
      },
      "& > .flex85": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "85%"
      },
      "& > .flex90": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "90%"
      },
      "& > .flex95": {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "100%",
        maxWidth: "100%",
        maxHeight: "95%"
      }
    },
    alignStart: {
      justifyContent: "flex-start",
      alignItems: "stretch"
    },
    alignStartStart: {
      justifyContent: "flex-start",
      alignItems: "flex-start"
    },
    alignStartEnd: {
      justifyContent: "flex-start",
      alignItems: "flex-end"
    },
    alignStartCenter: {
      justifyContent: "flex-start",
      alignItems: "center"
    },
    alignStartBaseline: {
      justifyContent: "flex-start",
      alignItems: "baseline"
    },
    alignEnd: {
      justifyContent: "flex-end",
      alignItems: "stretch"
    },
    alignEndStart: {
      justifyContent: "flex-end",
      alignItems: "flex-start"
    },
    alignEndEnd: {
      justifyContent: "flex-end",
      alignItems: "flex-end"
    },
    alignEndCenter: {
      justifyContent: "flex-end",
      alignItems: "center"
    },
    alignEndBaseline: {
      justifyContent: "flex-end",
      alignItems: "baseline"
    },
    alignCenter: {
      justifyContent: "center",
      alignItems: "stretch"
    },
    alignCenterStart: {
      justifyContent: "center",
      alignItems: "flex-start"
    },
    alignCenterEnd: {
      justifyContent: "center",
      alignItems: "flex-end"
    },
    alignCenterCenter: {
      justifyContent: "center",
      alignItems: "center"
    },
    alignCenterBaseline: {
      justifyContent: "center",
      alignItems: "baseline"
    },
    alignSpaceBetween: {
      justifyContent: "space-between",
      alignItems: "stretch"
    },
    alignSpaceBetweenStart: {
      justifyContent: "space-between",
      alignItems: "flex-start"
    },
    alignSpaceBetweenEnd: {
      justifyContent: "space-between",
      alignItems: "flex-end"
    },
    alignSpaceBetweenCenter: {
      justifyContent: "space-between",
      alignItems: "center"
    },
    alignSpaceBetweenBaseline: {
      justifyContent: "space-between",
      alignItems: "baseline"
    },
    alignSpaceAround: {
      justifyContent: "space-around",
      alignItems: "stretch"
    },
    alignSpaceAroundStart: {
      justifyContent: "space-around",
      alignItems: "flex-start"
    },
    alignSpaceAroundEnd: {
      justifyContent: "space-around",
      alignItems: "flex-end"
    },
    alignSpaceAroundCenter: {
      justifyContent: "space-around",
      alignItems: "center"
    },
    alignSpaceAroundBaseline: {
      justifyContent: "space-around",
      alignItems: "baseline"
    },
    alignSpaceEvenly: {
      justifyContent: "space-evenly",
      alignItems: "stretch"
    },
    alignSpaceEvenlyStart: {
      justifyContent: "space-evenly",
      alignItems: "flex-start"
    },
    alignSpaceEvenlyEnd: {
      justifyContent: "space-evenly",
      alignItems: "flex-end"
    },
    alignSpaceEvenlyCenter: {
      justifyContent: "space-evenly",
      alignItems: "center"
    },
    alignSpaceEvenlyBaseline: {
      justifyContent: "space-evenly",
      alignItems: "baseline"
    },
    noShrink: {
      flexShrink: 0
    },
    fullWidth: {
      width: "100%"
    },
    fullHeight: {
      height: "100%"
    },
    capitalize: {
      textTransform: 'capitalize'
    },
    hideScrollbar: {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      "&::-webkit-scrollbar": {
        display: 'none'
      }
    },
    relativePosition: {
      position: 'relative'
    },
    flexWrap: {
      flexWrap: 'wrap'
    },
    overflowXAuto: {
      overflowX: 'auto'
    },
    overflowXHidden: {
      overflowX: 'hidden'
    },
    overflowYAuto: {
      overflowY: 'auto'
    },
    overflowYHidden: {
      overflowY: 'hidden'
    },
    noMaxHeight: {
      maxHeight: 'none'
    },
    noMaxWidth: {
      maxWidth: 'none'
    },
    white: {
      color: 'white'
    },
    whiteBackground: {
      backgroundColor: 'white'
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    clickableXGridRow: {
      '& .MuiDataGrid-row': {
        cursor: 'pointer'
      }
    },
    marginNormal: {
      marginTop: 16,
      marginBottom: 8
    },
    inputTextColorNormal: {
      '& > .MuiInput-root': {
        color: 'rgba(0, 0, 0, 0.87)'
      },
      '& > .MuiInputLabel-root': {
        color: 'rgba(0, 0, 0, 0.54)'
      }
    },
    inputTextAlignRight: {
      '& > .MuiInput-root > input': {
        textAlign: 'right'
      }
    },
    normalAutocompleteOptionPadding: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 6,
      paddingBottom: 6
    },
    noAutocompleteOptionPadding: {
      padding: 0
    },
    fabBottomRight: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      margin: 20
    }
  };
  _exports.styles = styles;
});