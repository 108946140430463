define("client/react/payroll/time-sheet-overtime-dialog", ["exports", "@mui/material", "@mui/styles/makeStyles", "clsx", "react", "react-redux", "client/react/utilities/styles", "client/react/utils", "client/react/components/alt/form-date-text-field", "client/react/components/alt/form-date-picker", "client/react/components/spacer"], function (_exports, _material, _makeStyles, _clsx, _react, _reactRedux, _styles, _utils, _formDateTextField, _formDatePicker, _spacer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _makeStyles.default)(function (theme) {
    return _objectSpread({}, _styles.styles);
  });

  function TimesheetOvertimeDialog(_ref) {
    var onClose = _ref.onClose,
        _ref$formProps = _ref.formProps,
        control = _ref$formProps.control,
        errors = _ref$formProps.errors;
    var classes = useStyles();

    var _useState = (0, _react.useState)(true),
        _useState2 = _slicedToArray(_useState, 2),
        isOpen = _useState2[0],
        setIsOpen = _useState2[1];

    return _react.default.createElement(_material.Dialog, {
      open: isOpen,
      TransitionProps: {
        onExited: function onExited() {
          return onClose();
        }
      }
    }, _react.default.createElement(_material.DialogTitle, null, "Overtime Date Range"), _react.default.createElement(_material.DialogContent, {
      className: classes.column
    }, _react.default.createElement("span", null, "Please select the date range used for computing overtime"), _react.default.createElement("div", {
      className: classes.row
    }, _react.default.createElement(_formDatePicker.default, {
      formProps: {
        name: 'overtimeStartDate',
        control: control
      },
      label: 'Overtime Start Date',
      required: true,
      className: "flex",
      margin: "normal"
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_formDatePicker.default, {
      formProps: {
        name: 'overtimeEndDate',
        control: control
      },
      label: 'Overtime End Date',
      required: true,
      className: "flex",
      margin: "normal"
    }))), _react.default.createElement(_material.DialogActions, {
      className: (0, _clsx.default)(classes.row, classes.alignEnd)
    }, _react.default.createElement(_material.Button, {
      color: "primary",
      onClick: function onClick() {
        return setIsOpen(false);
      }
    }, "SAVE")));
  }

  var _default = TimesheetOvertimeDialog;
  _exports.default = _default;
});