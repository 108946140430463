define("client/react/utilities/typed/helpers", ["exports", "moment/moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.formatDate = formatDate;
  _exports.formatDateTime = formatDateTime;
  _exports.formatGlCurrency = formatGlCurrency;
  _exports.formatPercent = formatPercent;
  _exports.formatPhone = formatPhone;
  _exports.formatProductCurrency = formatProductCurrency;
  _exports.formatProductQuantity = formatProductQuantity;
  _exports.getBankLabel = getBankLabel;
  _exports.getCustomerLabel = getCustomerLabel;
  _exports.getEmployeeLabel = getEmployeeLabel;
  _exports.getEmployeeName = getEmployeeName;
  _exports.getJobLabel = getJobLabel;
  _exports.getLedgerAccountLabel = getLedgerAccountLabel;
  _exports.getPayrollLabel = getPayrollLabel;
  _exports.getVendorLabel = getVendorLabel;
  _exports.numberToString = numberToString;
  _exports.serializeDate = serializeDate;

  function formatDate(date) {
    if (date instanceof Date) {
      return (0, _moment.default)(date).format('MM/DD/YYYY');
    } else {
      return date ? (0, _moment.default)(date).format('MM/DD/YYYY') : '';
    }
  }

  function formatDateTime(dateTime) {
    if (dateTime instanceof Date) {
      return (0, _moment.default)(dateTime).format('MM/DD/YYYY hh:mm A');
    } else {
      return dateTime ? (0, _moment.default)(dateTime).format('MM/DD/YYYY hh:mm A') : '';
    }
  }

  function getBankLabel(bank) {
    return bank ? "".concat(bank.name, " - ").concat(bank.ledger) : '';
  }

  function getCustomerLabel(customer) {
    return customer ? "".concat(customer.custId, " - ").concat(customer.billingName) : '';
  }

  function getJobLabel(job) {
    return job ? "".concat(job.jobId, " - ").concat(job.projectName) : '';
  }

  function getLedgerAccountLabel(ledgerAccount) {
    return ledgerAccount ? "".concat(ledgerAccount.ledgerId, " - ").concat(ledgerAccount.description) : '';
  }

  function getVendorLabel(vendor) {
    return vendor ? "".concat(vendor.vendorId, " - ").concat(vendor.name) : '';
  }

  function getEmployeeLabel(employee) {
    return employee ? "".concat(employee.lastName, ", ").concat(employee.firstName) : '';
  }

  function getEmployeeName(employee) {
    return employee ? "".concat(employee.lastName, ", ").concat(employee.firstName) : '';
  }

  function numberToString(value) {
    var tempValue = value;
    var numStr = String(tempValue);

    if (Math.abs(tempValue) < 1.0) {
      var e = parseInt(tempValue.toString().split('e-')[1], 10);

      if (e) {
        var negative = tempValue < 0;

        if (negative) {
          tempValue *= -1;
        }

        tempValue *= Math.pow(10, e - 1); // tslint:disable-next-line:prefer-array-literal

        numStr = '0.' + new Array(e).join('0') + tempValue.toString().substring(2);

        if (negative) {
          numStr = '-' + numStr;
        }
      }
    } else {
      var _e = parseInt(tempValue.toString().split('+')[1], 10);

      if (_e > 20) {
        _e -= 20;
        tempValue /= Math.pow(10, _e); // tslint:disable-next-line:prefer-array-literal

        numStr = tempValue.toString() + new Array(_e + 1).join('0');
      }
    }

    return numStr;
  }

  function formatCurrency(val, returnNull) {
    var ret = null;

    if (!val) {
      if (returnNull) {
        ret = null;
      } else {
        ret = 0 .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
      }
    } else {
      ret = val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      });
    }

    return ret === '-$0.00' ? '$0.00' : ret;
  }

  function formatGlCurrency(val, returnNull) {
    if (!val) {
      if (returnNull) {
        return null;
      } else {
        return 0 .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          currencySign: 'accounting'
        }).replace('$', '');
      }
    } else {
      return val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        currencySign: 'accounting'
      }).replace('$', '');
    }
  }

  function formatPercent(val, places, returnNull) {
    if (!val) {
      if (returnNull) {
        return null;
      } else {
        return "".concat(0 .toFixed(places || 2), "%");
      }
    } else {
      return "".concat(val.toFixed(places || 2), "%");
    }
  }

  function formatPhone(val) {
    if (!val) {
      return "";
    } else {
      if (val.length !== 10) {
        return "Invalid phone format";
      } else {
        return "(".concat(val.substring(0, 3), ") ").concat(val.substring(3, 6), "-").concat(val.substring(6));
      }
    }
  }

  function formatProductQuantity(val) {
    return val ? val.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3
    }) : "0.000";
  }

  function formatProductCurrency(val, returnNull) {
    var ret = null;

    if (!val) {
      if (returnNull) {
        ret = null;
      } else {
        ret = 0 .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 3,
          maximumFractionDigits: 3
        });
      }
    } else {
      ret = val.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      });
    }

    return ret === '-$0.00' ? '$0.00' : ret;
  }

  function serializeDate(val) {
    if (val) {
      return (0, _moment.default)(val).format('YYYY-MM-DD');
    } else {
      return null;
    }
  }

  function getPayrollLabel(payroll) {
    return payroll ? "".concat(payroll.startDate, " - ").concat(payroll.endDate, " ").concat(payroll.description) : '';
  }
});