define("client/components/push-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "83iukin1",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"push-notification\"],[13],[0,\"\\n\"],[6,[\"if\"],[[29,\"default\"]],null,{\"statements\":[[0,\"\\t\\t\"],[18,\"default\"],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"unless\"],[[29,\"default\"]],null,{\"statements\":[[0,\"\\t\"],[11,\"p\",[]],[15,\"style\",\"padding: 10px; max-width: 400px;\"],[13],[0,\"\\n\\t\\t\\t\"],[1,[28,[\"message\",\"message\"]],false],[0,\"\\n\\t\"],[14],[0,\"\\n\"]],\"locals\":[]},null],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/push-notification/template.hbs"
    }
  });

  _exports.default = _default;
});