define("client/react/components/alt/form-time-picker", ["exports", "@mui/material", "@mui/x-date-pickers-pro", "client/react/utilities/use-form"], function (_exports, _material, _xDatePickersPro, _useForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["label", "className", "formProps", "includeSeconds"];

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function FormTimePicker(_ref) {
    var label = _ref.label,
        className = _ref.className,
        formProps = _ref.formProps,
        includeSeconds = _ref.includeSeconds,
        restProps = _objectWithoutProperties(_ref, _excluded);

    var _useController = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    }),
        field = _useController.field,
        fieldState = _useController.fieldState;

    return React.createElement(_xDatePickersPro.DesktopTimePicker, _extends({
      views: includeSeconds ? ['hours', 'minutes', 'seconds'] : ['hours', 'minutes'],
      mask: includeSeconds ? '__:__:__ _M' : '__:__ _M',
      inputFormat: includeSeconds ? 'hh:mm:ss A' : 'hh:mm A',
      OpenPickerButtonProps: {
        tabIndex: -1
      }
    }, restProps, {
      onChange: function onChange(date, value) {
        field.onChange(date && date.toDate ? date.toDate() : null);

        if (restProps.onChange) {
          restProps.onChange(date, value);
        }
      },
      value: field.value,
      renderInput: function renderInput(params) {
        var _fieldState$error;

        return React.createElement(_material.TextField, _extends({}, params, {
          label: label,
          className: className,
          error: fieldState.invalid,
          margin: restProps.margin,
          helperText: (_fieldState$error = fieldState.error) === null || _fieldState$error === void 0 ? void 0 : _fieldState$error.message,
          variant: 'standard',
          placeholder: includeSeconds ? '12:00:00 AM' : '12:00 AM',
          onBlur: function onBlur(e) {
            if (restProps.onBlur) {
              restProps.onBlur(e);
            }

            field.onBlur();
          }
        }));
      }
    }));
  }

  var _default = FormTimePicker;
  _exports.default = _default;
});