define("client/components/employee-email-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sxMYtKW7",
    "block": "{\"statements\":[[6,[\"paper-form\"],null,[[\"submit\",\"tagName\",\"onSubmit\"],[null,\"\",[33,[\"perform\"],[[28,[\"save\"]]],null]]],{\"statements\":[[6,[\"paper-dialog\"],null,null,{\"statements\":[[6,[\"paper-toolbar\"],null,null,{\"statements\":[[6,[\"paper-toolbar-tools\"],null,null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[11,\"h4\",[]],[13],[0,\"Edit Employee Email\"],[14],[0,\"\\n\\t\\t\\t\\t\"],[11,\"span\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n\\t\\t\\t\\t\"],[6,[\"paper-button\"],null,[[\"iconButton\",\"onClick\",\"disabled\"],[true,[28,[\"close\"]],[28,[\"save\",\"isRunning\"]]]],{\"statements\":[[1,[33,[\"paper-icon\"],[\"close\"],null],false]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"paper-dialog-content\"],null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"layout-column\"],[15,\"style\",\"width:400px;\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"save\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[26,[\"paper-progress-linear\"]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[33,[\"paper-input\"],null,[[\"label\",\"value\",\"onChange\",\"maxlength\"],[\"Email Address\",[28,[\"email\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"email\"]]],null]],null],59]]],false],[0,\"\\n\"]],\"locals\":[]}],[0,\"\\t\\t\\t\"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[6,[\"paper-dialog-actions\"],null,null,{\"statements\":[[6,[\"unless\"],[[28,[\"save\",\"isRunning\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[33,[\"paper-button\"],null,[[\"label\",\"onClick\"],[\"Close\",[28,[\"close\"]]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[1,[33,[\"paper-button\"],null,[[\"label\",\"onClick\"],[\"Save\",[28,[\"form\",\"onSubmit\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[\"form\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/employee-email-edit/template.hbs"
    }
  });

  _exports.default = _default;
});