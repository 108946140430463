define("client/helpers/srvc-id-from-tran", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.srvcIdFromTran = srvcIdFromTran;

  function srvcIdFromTran(params
  /*, hash*/
  ) {
    var note = params[0];
    return note.substr(8).trim();
  }

  var _default = Ember.Helper.helper(srvcIdFromTran);

  _exports.default = _default;
});