define("client/react/components/alt/form-autocomplete", ["exports", "@mui/material", "client/react/utilities/use-form", "client/react/utilities/autocomplete"], function (_exports, _material, _useForm, _autocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["allowCreate", "onCreate", "formProps", "renderInputParams", "immuneValueKeys", "fullClearOnBlur", "onFullClear"];

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function FormAutocomplete(_ref) {
    var allowCreate = _ref.allowCreate,
        onCreate = _ref.onCreate,
        formProps = _ref.formProps,
        renderInputParams = _ref.renderInputParams,
        immuneValueKeys = _ref.immuneValueKeys,
        fullClearOnBlur = _ref.fullClearOnBlur,
        onFullClear = _ref.onFullClear,
        restProps = _objectWithoutProperties(_ref, _excluded);

    var autocompleteDefaults = (0, _autocomplete.useDefaults)();

    var _useController = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    }),
        field = _useController.field;

    var createObject = {
      _createNew: true
    };
    var renderInput = restProps.renderInput || (0, _autocomplete.formAutocompleteRenderInput)(_objectSpread(_objectSpread({}, renderInputParams), {}, {
      control: formProps.control,
      onBlur: function onBlur(e) {
        if (fullClearOnBlur && restProps.options) {
          var match = restProps.options.find(function (itm) {
            return (restProps.getOptionLabel ? restProps.getOptionLabel(itm) : itm) === e.target.value;
          });

          if (!match) {
            if (onFullClear) {
              onFullClear();
            } else {
              formProps.control.setValue(formProps.name, null);
              formProps.control.setValue(renderInputParams.name, '');
            }
          }
        }

        field.onBlur();

        if (renderInputParams.onBlur) {
          renderInputParams.onBlur(e);
        }
      }
    }));
    return React.createElement(_material.Autocomplete, _extends({}, autocompleteDefaults, restProps, {
      options: !allowCreate ? restProps.options : [createObject].concat(_toConsumableArray(restProps.options)),
      getOptionLabel: !allowCreate ? restProps.getOptionLabel : function (v) {
        if (v['_createNew']) {
          return "Create New";
        } else {
          return restProps.getOptionLabel(v);
        }
      },
      renderOption: !allowCreate ? restProps.renderOption : function (v, s) {
        if (v['_createNew']) {
          return "Create New";
        } else if (restProps.renderOption) {
          return restProps.renderOption(v, s);
        }
      },
      ref: field.ref,
      value: field.value,
      onChange: function onChange(e, v, r) {
        if (allowCreate && v && v['_createNew'] && onCreate) {
          onCreate(e, v, r);
          return;
        } // we use this so we can add arbitrary options via filterOptions for things like creating new objects that don't match an actual option
        // and can have their own unique structure


        var hasImmuneKey = !immuneValueKeys ? false : immuneValueKeys.find(function (k) {
          return v ? !!v[k] : false;
        });

        if (!v || !!!hasImmuneKey) {
          field.onChange(v);
        }

        if (restProps.onChange) {
          restProps.onChange(e, v, r);
        }
      },
      renderInput: renderInput
    }));
  }

  var _default = FormAutocomplete;
  _exports.default = _default;
});