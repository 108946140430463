define("client/components/extended-customer-note-pad/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    newNote: "",
    store: Ember.inject.service(),
    auth: Ember.inject.service(),
    operator: "",
    editNoteText: "",
    editNoteRecord: null,
    editing: false,
    willDestroyElement: function willDestroyElement() {
      if (this.get('editNoteRecord')) {
        this.set('editNoteRecord.editing', false);
      }

      this.set('editing', false);
    },
    actions: {
      closeNote: function closeNote() {
        this.get('close')();
      },
      enterDownEdit: function enterDownEdit(item) {
        var note = item;

        if (note.get('note').trim()) {
          note.set('note', note.get('note').trim().toUpperCase());
          note.save();
          note.set('editing', false);
        } else {
          note.destroyRecord();
        }
      },
      enterDown: function enterDown() {
        if (this.get('newNote').trim()) {
          var note = this.get('store').createRecord('extended-customer-note', {});
          var work = this.get('newNote').toUpperCase();
          note.set('note', work);
          note.get('customer').then(function (cust) {
            note.set('customer', this.get('customer'));
            note.set('custId', this.get('customer.id'));
            note.save();
            this.set('newNote', "");
          }.bind(this));
        }
      },
      delete: function _delete(item) {
        item.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});