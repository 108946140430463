define("client/helpers/utc-dt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.utcDt = utcDt;

  function utcDt(params
  /*, hash*/
  ) {
    var date = params[0],
        format = params[1];

    if (!date) {
      return "../../..";
    } else {
      if (format) {
        if (format === "date") {
          return moment.utc(date).local().format('MM/DD/YY');
        } else if (format === "time") {
          return moment.utc(date).local().format('h:mm A');
        } else {
          return moment.utc(date).local().format('MMM Do, YYYY, h:mm A');
        }
      } else {
        return moment.utc(date).local().format('MMM Do, YYYY, h:mm A');
      }
    }
  }

  var _default = Ember.Helper.helper(utcDt);

  _exports.default = _default;
});