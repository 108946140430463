define("client/utils/pricing-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pricingInfo;

  function pricingInfo(customer, contracts, costCenter) {
    if (!customer) {
      return Ember.Object.create({
        defaultLaborRate: 120,
        specialLaborRate: 0,
        defaultPreferred: false,
        laborDiscount: 0,
        partsDiscount: 0,
        preferredDiscount: 0
      });
    }

    var defaultLaborRate = 120,
        specialLaborRate = customer.get('discountSpecialPrice') ? customer.get('discountSpecialPrice') : 0,
        preferred = false,
        laborDiscount = 0,
        partsDiscount = 0,
        preferredDiscount = 0;

    if (contracts) {
      preferred = contracts.any(function (item) {
        var effective = item.get('effectiveDate'),
            expiration = item.get('expirationDate'),
            cancellation = item.get('cancellationDate'),
            preferredCustomer = item.get('preferredCustomer');
        var isPreferred = preferredCustomer === "Y",
            inBetween = moment().isSameOrBefore(expiration, "day") && moment().isSameOrAfter(effective, "day"),
            notCancelled = !cancellation || moment().isBefore(cancellation, "day");
        var isExcluded = item.get('excludedCostCenters').any(function (itm) {
          return parseInt(itm) === parseInt(costCenter);
        });
        return isPreferred && inBetween && notCancelled && !isExcluded;
      });
      partsDiscount = contracts.reduce(function (prev, curr) {
        var cancellation = curr.get('cancellationDate'),
            notCancelled = !cancellation || moment().isBefore(cancellation, "day"),
            active = moment().isSameOrBefore(curr.get('expirationDate'), "day") && moment().isSameOrAfter(curr.get('effectiveDate'), "day") && notCancelled,
            isExcluded = curr.get('excludedCostCenters').any(function (itm) {
          return parseInt(itm) === parseInt(costCenter);
        });
        var discount = !Ember.isBlank(curr.get('partsDiscount')) ? parseFloat(curr.get('partsDiscount')) : 0;
        discount = isNaN(discount) ? 0 : discount;

        if (active && discount && !isExcluded) {
          return discount > prev ? discount : prev;
        }

        return prev;
      }, 0);
      laborDiscount = contracts.reduce(function (prev, curr) {
        var cancellation = curr.get('cancellationDate'),
            notCancelled = !cancellation || moment().isBefore(cancellation, "day"),
            active = moment().isSameOrBefore(curr.get('expirationDate'), "day") && moment().isSameOrAfter(curr.get('effectiveDate'), "day") && notCancelled,
            isExcluded = curr.get('excludedCostCenters').any(function (itm) {
          return parseInt(itm) === parseInt(costCenter);
        });
        var discount = !Ember.isBlank(curr.get('laborDiscount')) ? parseFloat(curr.get('laborDiscount')) : 0;
        discount = isNaN(discount) ? 0 : discount;

        if (active && discount && !isExcluded) {
          return discount > prev ? discount : prev;
        }

        return prev;
      }, 0);
      preferredDiscount = contracts.reduce(function (prev, curr) {
        var cancellation = curr.get('cancellationDate'),
            notCancelled = !cancellation || moment().isBefore(cancellation, "day"),
            active = moment().isSameOrBefore(curr.get('expirationDate'), "day") && moment().isSameOrAfter(curr.get('effectiveDate'), "day") && notCancelled,
            isExcluded = curr.get('excludedCostCenters').any(function (itm) {
          return parseInt(itm) === parseInt(costCenter);
        });
        var discount = !Ember.isBlank(curr.get('preferredDiscount')) ? parseFloat(curr.get('preferredDiscount')) : 0;
        discount = isNaN(discount) ? 0 : discount;

        if (active && discount && !isExcluded) {
          return discount > prev ? discount : prev;
        }

        return prev;
      }, 0);
    }

    return Ember.Object.create({
      defaultLaborRate: defaultLaborRate,
      specialLaborRate: specialLaborRate,
      defaultPreferred: preferred,
      laborDiscount: laborDiscount,
      partsDiscount: partsDiscount,
      preferredDiscount: preferredDiscount
    });
  }
});