define("client/models/job-rate", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    jobId: _emberData.default.attr('trim'),
    seq: _emberData.default.attr('trim'),
    rate: _emberData.default.attr(),
    fringe: _emberData.default.attr(),
    job: _emberData.default.belongsTo('job', {
      async: true
    })
  });

  _exports.default = _default;
});