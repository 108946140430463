define("client/models/vendor-email", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    vendor: _emberData.default.belongsTo('vendor'),
    name: _emberData.default.attr('capitalize'),
    custId: _emberData.default.attr(),
    email: _emberData.default.attr(),
    type: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    businessPhone: _emberData.default.attr('telephone'),
    homePhone: _emberData.default.attr('telephone'),
    faxNumber: _emberData.default.attr('telephone'),
    cellPhone: _emberData.default.attr('telephone'),
    title: _emberData.default.attr(),
    address: _emberData.default.attr('capitalize'),
    flags: _emberData.default.attr(),
    ext: _emberData.default.attr(),
    flags2: _emberData.default.attr(),
    invoiceFlag: Ember.computed('flags', {
      get: function get(key) {
        return this.get('flags').includes('I');
      },
      set: function set(key, value) {
        if (value) this.set('flags', this.get('flags').replace(' ', 'I'));else this.set('flags', this.get('flags').replace('I', ' '));
        return value;
      }
    }),
    orderFlag: Ember.computed('flags', {
      get: function get(key) {
        return this.get('flags').includes('O');
      },
      set: function set(key, value) {
        if (value) this.set('flags', this.get('flags').replace(' ', 'O'));else this.set('flags', this.get('flags').replace('O', ' '));
        return value;
      }
    }),
    statementFlag: Ember.computed('flags', {
      get: function get(key) {
        return this.get('flags').includes('S');
      },
      set: function set(key, value) {
        if (value) this.set('flags', this.get('flags').replace(' ', 'S'));else this.set('flags', this.get('flags').replace('S', ' '));
        return value;
      }
    }),
    shippingFlag: Ember.computed('flags', {
      get: function get(key) {
        return this.get('flags').includes('U');
      },
      set: function set(key, value) {
        if (value) this.set('flags', this.get('flags').replace(' ', 'U'));else this.set('flags', this.get('flags').replace('U', ' '));
        return value;
      }
    }),
    serviceFlag: Ember.computed('flags', {
      get: function get(key) {
        return this.get('flags').includes('X');
      },
      set: function set(key, value) {
        if (value) this.set('flags', this.get('flags').replace(' ', 'X'));else this.set('flags', this.get('flags').replace('X', ' '));
        return value;
      }
    })
  });

  _exports.default = _default;
});