define("client/components/ledger-description/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hCjz+CYb",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"words\"]]],null,{\"statements\":[[6,[\"if\"],[[28,[\"word\",\"solicitation\"]]],null,{\"statements\":[[6,[\"link-to\"],[\"srvc-contract-solicitation-view\",[28,[\"record\",\"contractSolicitation\"]]],[[\"target\"],[\"_blank\"]],{\"statements\":[[0,\"            \"],[1,[28,[\"word\",\"word\"]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[28,[\"word\",\"contract\"]]],null,{\"statements\":[[6,[\"link-to\"],[\"srvc-contract-view\",[28,[\"record\",\"contract\"]]],[[\"target\"],[\"_blank\"]],{\"statements\":[[0,\"            \"],[1,[28,[\"word\",\"word\"]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},{\"statements\":[[0,\"        \"],[1,[28,[\"word\",\"word\"]],false],[0,\"\\n    \"]],\"locals\":[]}]],\"locals\":[]}]],\"locals\":[\"word\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/ledger-description/template.hbs"
    }
  });

  _exports.default = _default;
});