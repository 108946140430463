define("client/models/paid-pj-invoice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    checkNumber: _emberData.default.attr(),
    vendId: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr('capitalize'),
    jobId: _emberData.default.attr(),
    date: _emberData.default.attr(),
    amtPaid: _emberData.default.attr(),
    discAmt: _emberData.default.attr(),
    autoPay: _emberData.default.attr(),
    datePaid: _emberData.default.attr(),
    source: _emberData.default.attr(),
    hasPjTrans: _emberData.default.attr(),
    notes: _emberData.default.attr(),
    pjTrans: _emberData.default.hasMany('purchase-journal-transaction', {
      async: true
    }),
    vendor: _emberData.default.belongsTo('vendor', {
      async: true
    })
  });

  _exports.default = _default;
});