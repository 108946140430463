define("client/components/color-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inValue: "",
    didInsertElement: function didInsertElement() {
      this.$(".c-picker").colorpicker().on('changeColor.colorpicker', function (event) {
        this.set('inValue', event.color.toHex());
      }.bind(this));
    }
  });

  _exports.default = _default;
});