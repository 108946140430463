define("client/initializers/email", ["exports", "client/config/environment", "ember-data"], function (_exports, _environment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var AbortError = _emberData.default.AbortError,
      NotFoundError = _emberData.default.NotFoundError;

  function initialize(application) {
    // application.inject('route', 'foo', 'service:foo');
    if (_environment.default.environment === "development") {
      return;
    }

    Ember.onerror = function (error) {
      // console.error(error);
      if (error.message && error.message.trim() !== "Adapter operation failed") {
        if (error.errors && error.errors[0] && (error.errors[0].status == "408" || error.errors[0].status == "401")) {
          return;
        } //new conditional just for the sake of not having huge line for condition


        if (error instanceof AbortError || error.errors && error.errors[0] && error.errors[0].status == "404") {
          return;
        }

        console.log(application.__deprecatedInstance__.__container__.lookup('router:main').get('location'));

        if (error.errors && error.errors[0] && error.errors[0].status == "403") {
          Ember.$.ajax(_environment.default.APP.endpoint + "/fsm/error", {
            type: 'POST',
            data: {
              user: application.__deprecatedInstance__.__container__.lookup('controller:login').get('auth.activeUser'),
              error: error.stack,
              otherInformation: error.message + " route: " + application.__deprecatedInstance__.__container__.lookup('router:main').get('location.lastSetURL')
            },
            headers: {
              "X-Auth-Tenant": 0
            },
            dataType: 'json'
          });
        } else {
          window.sourceMappedStackTrace.mapStackTrace(error.stack, function (stack) {
            Ember.$.ajax(_environment.default.APP.endpoint + "/fsm/error", {
              type: 'POST',
              data: {
                user: application.__deprecatedInstance__.__container__.lookup('controller:login').get('auth.activeUser'),
                error: stack.join("\n"),
                otherInformation: error.message + " route: " + application.__deprecatedInstance__.__container__.lookup('router:main').get('location.lastSetURL')
              },
              headers: {
                "X-Auth-Tenant": 0
              },
              dataType: 'json'
            });
          }, {
            cacheGlobally: true
          });
        }
      }

      if (error instanceof Error) {
        throw error;
      } else {
        // literally have no clue what the hell Ember wants here...
        console.log(error); // throw(new Error(error));
      }
    };

    document.addEventListener('visibilitychange', function () {// document.title = document.hidden; // change tab text for demo
      // if (!document.hidden) {
      // 	location.reload();
      // }
    }); // Ember.RSVP.on('error', function(error) {
    //   Ember.Logger.assert(false, error);
    // });
  }

  var _default = {
    name: 'email',
    initialize: initialize
  };
  _exports.default = _default;
});