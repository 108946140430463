define("client/components/prod-cat-management/component", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    auth: Ember.inject.service(),
    creating: false,
    imageLinks: Ember.A([]),
    newCatTitle: '',
    deleteCategory: function deleteCategory() {
      this.get('deletingCategory').destroyRecord();
    },
    actions: {
      close: function close() {
        this.get('cancel')();
      },
      saveNew: function saveNew() {
        if (!this.get('newCatTitle')) return;
        var ts = Date.now();
        Ember.$.ajax({
          type: 'post',
          url: _environment.default.APP.endpoint + "/fsm/uploadS3",
          data: {
            timestamp: ts,
            data: this.get('image')
          },
          success: function success(response) {},
          error: function error(response) {},
          dataType: 'json',
          headers: {
            "X-Auth-Tenant": this.get('auth.tenantId'),
            "X-Auth-Token": this.get('auth.access_token')
          }
        });
        this.set('imageLink', "https://s3.amazonaws.com/pcatalog" + this.get('auth.tenantId') + "/" + ts + ".jpg");
        var newEntry = this.get('store').createRecord('prod-cat', {
          title: this.get('newCatTitle'),
          coverImage: this.get('imageLink')
        });
        console.log(newEntry);
      },
      saveOld: function saveOld() {}
    }
  });

  _exports.default = _default;
});