define("client/models/purchase-order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var PurchaseOrder = _emberData.default.Model.extend({
    vendorName: _emberData.default.attr('capitalize'),
    poNumber: _emberData.default.attr(),
    vendId: _emberData.default.attr(),
    vendorName2: _emberData.default.attr('capitalize'),
    vendorAddress: _emberData.default.attr('capitalize'),
    vendorCityStateZip: _emberData.default.attr('capitalize'),
    shipToName: _emberData.default.attr('capitalize'),
    shipToName2: _emberData.default.attr('capitalize'),
    shipToAddress: _emberData.default.attr('capitalize'),
    shipToCityStateZip: _emberData.default.attr('capitalize'),
    dueDate: _emberData.default.attr(),
    terms: _emberData.default.attr('capitalize'),
    shipVia: _emberData.default.attr('capitalize'),
    telephone: _emberData.default.attr('telephone'),
    totalPieces: _emberData.default.attr(),
    totalCube: _emberData.default.attr(),
    totalWeight: _emberData.default.attr(),
    totalAmount: _emberData.default.attr(),
    category: _emberData.default.attr(),
    buyer: _emberData.default.attr('capitalize'),
    fob: _emberData.default.attr('capitalize'),
    taxable: _emberData.default.attr(),
    confirmTo: _emberData.default.attr('capitalize'),
    remarks: _emberData.default.attr('capitalize'),
    freightTerms: _emberData.default.attr('capitalize'),
    dropShip: _emberData.default.attr(),
    warehouse: _emberData.default.attr(),
    vendorPhone: _emberData.default.attr('telephone'),
    vendorFax: _emberData.default.attr('telephone'),
    faxFlag: _emberData.default.attr(),
    salesOrderNumber: _emberData.default.attr(),
    stockCode: _emberData.default.attr(),
    poDate: _emberData.default.attr(),
    jobNumber: _emberData.default.attr(),
    notes: _emberData.default.attr(),
    workOrder: _emberData.default.belongsTo('work-order', {
      async: true
    }),
    items: _emberData.default.hasMany('purchase-order-item', {
      async: true
    }),
    vendor: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    })
  });

  var _default = PurchaseOrder;
  _exports.default = _default;
});