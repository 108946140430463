define("client/transforms/trim2", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized.trim();
    },
    serialize: function serialize(deserialized) {
      while (deserialized.length < 15) {
        deserialized += " ";
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});