define("client/templates/jc-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WQPHmjB/",
    "block": "{\"statements\":[[1,[33,[\"jc-types\"],null,[[\"model\"],[[28,[\"model\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/jc-types.hbs"
    }
  });

  _exports.default = _default;
});