define("client/components/work-order-popover/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    utilities: Ember.inject.service(),
    didRender: function didRender() {
      this.sendAction('onRender');
    }
  });

  _exports.default = _default;
});