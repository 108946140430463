define("client/models/dynamic-field-value", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dynamicInvoiceValue: _emberData.default.belongsTo('dynamic-invoice-value'),
    serviceEstimate: _emberData.default.belongsTo('service-estimate'),
    description: _emberData.default.attr(),
    formula: _emberData.default.attr(),
    enabled: _emberData.default.attr('bit-tf')
  });

  _exports.default = _default;
});