define("client/models/product-addition", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    productItem: _emberData.default.belongsTo('product-item', {
      async: true
    }),
    part: _emberData.default.belongsTo('part-item', {
      async: true
    }),
    prodId: _emberData.default.attr(),
    name: _emberData.default.attr(),
    defaultEnabled: _emberData.default.attr(),
    discountable: _emberData.default.attr()
  });

  _exports.default = _default;
});