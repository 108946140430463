define("client/components/check-report-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8c6EUO13",
    "block": "{\"statements\":[[6,[\"paper-dialog\"],null,[[\"origin\",\"onClose\"],[\".check-report\",[33,[\"action\"],[[28,[null]],\"close\"],null]]],{\"statements\":[[6,[\"paper-dialog-content\"],null,[[\"class\"],[\"layout-column\"]],{\"statements\":[[0,\"        \"],[11,\"h2\",[]],[15,\"class\",\"md-title\"],[13],[0,\"Check Report\"],[14],[0,\"\\n        \"],[1,[33,[\"paper-input\"],null,[[\"value\",\"onChange\",\"label\"],[[28,[\"printer\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"printer\"]]],null]],null],\"Printer\"]]],false],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"layout-row\"],[13],[0,\"\\n            \"],[11,\"div\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n  \\t\\t    \"],[6,[\"paper-checkbox\"],null,[[\"value\",\"onChange\"],[[28,[\"doubleSpaced\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"doubleSpaced\"]]],null]],null]]],{\"statements\":[[0,\"Double Spaced?\"]],\"locals\":[]},null],[0,\"\\n            \"],[11,\"div\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"paper-dialog-actions\"],null,[[\"class\"],[\"layout-row\"]],{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n        \"],[6,[\"paper-button\"],null,[[\"onClick\"],[[33,[\"action\"],[[28,[null]],\"close\"],null]]],{\"statements\":[[0,\"Cancel\"]],\"locals\":[]},null],[0,\"\\n\\t\\t\"],[6,[\"paper-button\"],null,[[\"onClick\",\"primary\"],[[33,[\"action\"],[[28,[null]],\"submit\",true,\"servisuite\"],null],true]],{\"statements\":[[0,\"RUN\"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/check-report-modal/template.hbs"
    }
  });

  _exports.default = _default;
});