define("client/components/print-checks-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      close: function close() {
        this.get('close')();
      },
      submit: function submit() {
        this.get('printChecks')();
      }
    }
  });

  _exports.default = _default;
});