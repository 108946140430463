define("client/templates/ledger-eop-processing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Z6Y/V4i",
    "block": "{\"statements\":[[1,[33,[\"ledger-eop-processing\"],null,[[\"settings\",\"currentYearGlCount\",\"toDocumentPosting\"],[[28,[\"model\",\"settings\"]],[28,[\"model\",\"currentYearGlCount\"]],[33,[\"route-action\"],[\"toDocumentPosting\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/ledger-eop-processing.hbs"
    }
  });

  _exports.default = _default;
});