define("client/components/order-entry-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    utilities: Ember.inject.service(),
    selectedTab: 0,
    measurementCodes: Ember.Object.create({
      E: "Each (1)",
      P: "Pair (2)",
      D: "Dozen (12)",
      M: "Thousand (1000)",
      C: "Hundred (100)",
      T: "Ton (2000)",
      G: "Gross (144)"
    }),
    menuItems: Ember.A([Ember.Object.create({
      title: "Search Order",
      icon: "search",
      action: 'close'
    }), Ember.Object.create({
      title: "Create Order",
      icon: "add",
      action: 'create'
    }), Ember.Object.create({
      title: "Edit Order",
      icon: "create",
      action: 'edit'
    }), Ember.Object.create({
      title: "Delete Order",
      icon: "delete",
      action: 'delete'
    })]),
    productVersion: Ember.computed(function () {
      return !this.get('utilities.configFlags').findBy('seq', "169").get('flag');
    }),
    lineItemDiscounts: Ember.computed(function () {
      return this.get('utilities.configFlags').findBy('seq', "005").get('flag');
    }),
    actions: {
      close: function close() {
        this.sendAction('close');
      },
      create: function create() {
        this.sendAction('create');
      },
      edit: function edit() {
        this.sendAction('edit', this.get('order'));
      },
      delete: function _delete() {
        this.sendAction('delete', this.get('order'));
      }
    }
  });

  _exports.default = _default;
});