define("client/helpers/to-float", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toFloat = toFloat;

  function toFloat(params
  /*, hash*/
  ) {
    return parseFloat(params[0]);
  }

  var _default = Ember.Helper.helper(toFloat);

  _exports.default = _default;
});