define("client/models/purchase-order-history", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    vendorName: _emberData.default.attr(),
    poNumber: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    vendId: _emberData.default.attr(),
    vendorName2: _emberData.default.attr(),
    vendorAddress: _emberData.default.attr(),
    vendorCityStateZip: _emberData.default.attr(),
    shipToName: _emberData.default.attr(),
    shipToName2: _emberData.default.attr(),
    shipToAddress: _emberData.default.attr(),
    shipToState: _emberData.default.attr(),
    dueDate: _emberData.default.attr(),
    terms: _emberData.default.attr(),
    shipVia: _emberData.default.attr(),
    shipToPhone: _emberData.default.attr('telephone'),
    totPieces: _emberData.default.attr(),
    totCube: _emberData.default.attr(),
    totWeight: _emberData.default.attr(),
    totValue: _emberData.default.attr(),
    category: _emberData.default.attr(),
    buyer: _emberData.default.attr(),
    fob: _emberData.default.attr(),
    taxable: _emberData.default.attr(),
    confirmTo: _emberData.default.attr(),
    remarks: _emberData.default.attr(),
    freight: _emberData.default.attr(),
    dropShipFlag: _emberData.default.attr(),
    warehouse: _emberData.default.attr(),
    vendorPhone: _emberData.default.attr('telephone'),
    vendorFax: _emberData.default.attr(),
    faxFlag: _emberData.default.attr(),
    salesOrderNumber: _emberData.default.attr(),
    code: _emberData.default.attr(),
    dateRecd: _emberData.default.attr(),
    totFreight: _emberData.default.attr(),
    totFreightAllocated: _emberData.default.attr(),
    allocationType: _emberData.default.attr(),
    totAmountRcd: _emberData.default.attr(),
    jobNumber: _emberData.default.attr(),
    extraFlag: _emberData.default.attr(),
    notes: _emberData.default.attr(),
    workOrder: _emberData.default.belongsTo('work-order', {
      async: true
    }),
    items: _emberData.default.hasMany('purchase-order-history-item', {
      async: true
    }),
    vendor: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    shipToCityStateZip: Ember.computed.alias('shipToState')
  });

  _exports.default = _default;
});