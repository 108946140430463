define("client/helpers/money-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.moneyHelper = moneyHelper;

  function moneyHelper(params
  /*, hash*/
  ) {
    var input = params[0];
    if (input !== 0 && !input) return "0.00";
    if (Math.abs(input) < 0.01) return "0.00";

    function CommaFormatted(amount) {
      var delimiter = ","; // replace comma if desired

      var a = amount.split('.', 2);
      var d = a[1];
      var i = parseInt(a[0]);

      if (isNaN(i)) {
        return '';
      }

      var minus = '';

      if (parseFloat(amount) < 0) {
        minus = '-';
      }

      i = Math.abs(i);
      var n = new String(i);
      var a = [];

      while (n.length > 3) {
        var nn = n.substr(n.length - 3);
        a.unshift(nn);
        n = n.substr(0, n.length - 3);
      }

      if (n.length > 0) {
        a.unshift(n);
      }

      n = a.join(delimiter);

      if (d.length < 1) {
        amount = n;
      } else {
        amount = n + '.' + d;
      }

      amount = minus + amount;
      return amount;
    }

    return CommaFormatted(parseFloat(input).toFixed(2));
  }

  var _default = Ember.Helper.helper(moneyHelper);

  _exports.default = _default;
});