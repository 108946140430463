define("client/react/utilities/typed/animations", ["exports", "react/jsx-runtime", "react-transition-group"], function (_exports, _jsxRuntime, _reactTransitionGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RotateTransitionIcons = RotateTransitionIcons;
  var DURATION = 100;
  var defaultStyle1 = {
    transition: "opacity ".concat(DURATION, "ms linear, transform ").concat(DURATION, "ms linear")
  };
  var transitionStyles1 = {
    entering: {
      opacity: 0,
      transform: "rotate(90deg)"
    },
    entered: {
      opacity: 0,
      transform: "rotate(90deg)"
    },
    exiting: {
      opacity: 1
    },
    exited: {
      opacity: 1
    }
  };
  var defaultStyle2 = {
    transition: "opacity ".concat(DURATION, "ms linear, transform ").concat(DURATION, "ms linear"),
    marginLeft: -24
  };
  var transitionStyles2 = {
    entering: {
      opacity: 1
    },
    entered: {
      opacity: 1
    },
    exiting: {
      opacity: 0,
      transform: "rotate(-90deg)"
    },
    exited: {
      opacity: 0,
      transform: "rotate(-90deg)"
    }
  };

  function RotateTransitionIcons(_ref) {
    var inProp = _ref.inProp,
        Icon1 = _ref.Icon1,
        Icon2 = _ref.Icon2,
        customStyles1 = _ref.customStyles1,
        customStyles2 = _ref.customStyles2;
    return (0, _jsxRuntime.jsx)(_reactTransitionGroup.Transition, Object.assign({
      in: inProp,
      timeout: DURATION
    }, {
      children: function children(state) {
        return [(0, _jsxRuntime.jsx)("div", Object.assign({
          style: Object.assign(Object.assign(Object.assign({}, defaultStyle1), transitionStyles1[state]), customStyles1)
        }, {
          children: Icon1
        }), "icon1"), (0, _jsxRuntime.jsx)("div", Object.assign({
          style: Object.assign(Object.assign(Object.assign({}, defaultStyle2), transitionStyles2[state]), customStyles2)
        }, {
          children: Icon2
        }), "icon2")];
      }
    }));
  }
});