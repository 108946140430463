define("client/models/contract-solicitation-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('capitalize'),
    length: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    cycle: _emberData.default.attr(),
    label: Ember.computed('type', 'length', 'amount', function () {
      return "".concat(this.get('type'), " - ").concat(this.get('length'), " - $").concat(this.get('amount'));
    })
  });

  _exports.default = _default;
});