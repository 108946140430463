define("client/react/custom-dashboards/pie-chart-config", ["exports", "react", "yup", "@mui/styles/makeStyles", "client/react/utilities/styles", "client/react/custom-dashboards/use-widget-config", "client/react/components/spacer", "clsx", "client/react/components/alt/form-switch", "client/react/utilities/yup-validation-helpers", "client/react/components/alt/form-currency-text-field"], function (_exports, _react, yup, _makeStyles, _styles, _useWidgetConfig2, _spacer, _clsx, _formSwitch, _yupValidationHelpers, _formCurrencyTextField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _makeStyles.default)(function (_) {
    return _objectSpread({}, _styles.styles);
  });

  function PieChartConfig(_ref) {
    var dimensions = _ref.dimensions,
        metrics = _ref.metrics,
        saveRef = _ref.saveRef,
        config = _ref.config,
        onSave = _ref.onSave;
    var classes = useStyles();

    var _useWidgetConfig = (0, _useWidgetConfig2.default)({
      config: config,
      metricsList: metrics,
      dimensionsList: dimensions,
      onSave: onSave,
      saveRef: saveRef,
      dimensions: {
        dimension: {
          label: 'Dimension',
          required: true
        }
      },
      metrics: {
        metric: {
          label: 'Metric',
          required: true
        }
      },
      meta: {
        enableLegend: {
          defaultValue: false,
          schema: yup.boolean()
        },
        marginTop: {
          defaultValue: 50,
          schema: yup.number().typeError((0, _yupValidationHelpers.numberTypeError)())
        },
        marginRight: {
          defaultValue: 30,
          schema: yup.number().typeError((0, _yupValidationHelpers.numberTypeError)())
        },
        marginBottom: {
          defaultValue: 20,
          schema: yup.number().typeError((0, _yupValidationHelpers.numberTypeError)())
        },
        marginLeft: {
          defaultValue: 30,
          schema: yup.number().typeError((0, _yupValidationHelpers.numberTypeError)())
        }
      }
    }),
        components = _useWidgetConfig.components,
        control = _useWidgetConfig.form.control;

    return _react.default.createElement(_react.default.Fragment, null, components.title, components.description, _react.default.createElement("div", {
      className: classes.row
    }, components.dimension.autocomplete, _react.default.createElement(_spacer.default, null), components.metric.autocomplete), _react.default.createElement("div", {
      className: classes.row
    }, components.metric.aggregationFnAutocomplete), _react.default.createElement("div", {
      className: (0, _clsx.default)(classes.alignSpaceAround, classes.row)
    }, _react.default.createElement(_formCurrencyTextField.default, {
      formProps: {
        name: 'marginTop',
        control: control
      },
      label: 'Margin Top',
      margin: "normal",
      className: 'flex',
      decimalPlaces: 2,
      currencySymbol: '',
      zeroIfEmpty: true
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_formCurrencyTextField.default, {
      formProps: {
        name: 'marginRight',
        control: control
      },
      label: 'Margin Right',
      margin: "normal",
      className: 'flex',
      decimalPlaces: 2,
      currencySymbol: '',
      zeroIfEmpty: true
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_formCurrencyTextField.default, {
      formProps: {
        name: 'marginBottom',
        control: control
      },
      label: 'Margin Bottom',
      margin: "normal",
      className: 'flex',
      decimalPlaces: 2,
      currencySymbol: '',
      zeroIfEmpty: true
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_formCurrencyTextField.default, {
      formProps: {
        name: 'marginLeft',
        control: control
      },
      label: 'Margin Left',
      margin: "normal",
      className: 'flex',
      decimalPlaces: 2,
      currencySymbol: '',
      zeroIfEmpty: true
    })), _react.default.createElement("div", {
      className: (0, _clsx.default)(classes.alignSpaceAround, classes.row)
    }, _react.default.createElement(_formSwitch.default, {
      formProps: {
        name: 'enableLegend',
        control: control
      },
      label: 'Legend Enabled'
    })), _react.default.createElement(_spacer.default, null), components.filter, components.dynamicInputsDialog);
  }

  var _default = PieChartConfig;
  _exports.default = _default;
});