define("client/components/item-array-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sHKBoTnI",
    "block": "{\"statements\":[[11,\"ul\",[]],[15,\"class\",\"list-group\"],[15,\"style\",\"height: 75%; overflow-y: auto; margin-bottom: 10px;\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"style\",\"height: 100%; background-color: white; overflow:\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"itemList\"]]],null,{\"statements\":[[0,\"            \"],[11,\"li\",[]],[15,\"class\",\"list-group-item\"],[13],[1,[28,[\"item\"]],false],[6,[\"if\"],[[28,[\"canDelete\"]]],null,{\"statements\":[[11,\"span\",[]],[15,\"style\",\"float: right; color: red; cursor: default;\"],[5,[\"action\"],[[28,[null]],\"deleteItem\",[28,[\"item\"]]]],[13],[0,\"X\"],[14]],\"locals\":[]},null],[14],[0,\"\\n\"]],\"locals\":[\"item\"]},null],[0,\"        \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"input-group\"],[13],[0,\"\\n    \"],[1,[33,[\"input\"],null,[[\"type\",\"value\",\"class\",\"placeholder\",\"disabled\"],[\"text\",[28,[\"item\"]],\"form-control\",\"Enter additional items here\",[28,[\"disabled\"]]]]],false],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"input-group-btn\"],[13],[0,\"\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn btn-default\"],[16,\"disabled\",[26,[\"disabled2\"]],null],[5,[\"action\"],[[28,[null]],\"addItem\"]],[13],[0,\"Add\"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/item-array-list/template.hbs"
    }
  });

  _exports.default = _default;
});