define("client/react/components/typed/form-select", ["exports", "react/jsx-runtime", "@mui/material", "@Harris-Barrick-Software/use-form"], function (_exports, _jsxRuntime, _material, _useForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function FormSelect(_a) {
    var formProps = _a.formProps,
        children = _a.children,
        restProps = __rest(_a, ["formProps", "children"]);

    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });

    if (!controller) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field;
    return (0, _jsxRuntime.jsx)(_material.Select, Object.assign({}, restProps, {
      inputProps: Object.assign(Object.assign({}, restProps.inputProps), {
        onBlur: function onBlur(e) {
          field.onBlur();

          if (restProps.onBlur) {
            restProps.onBlur(e);
          }
        }
      }),
      onChange: function onChange(e, child) {
        field.onChange(e.target.value);

        if (restProps.onChange) {
          restProps.onChange(e, child);
        }
      },
      value: field.value
    }, {
      children: children
    }));
  }

  var _default = FormSelect;
  _exports.default = _default;
});