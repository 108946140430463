define("client/services/dnd-service", ["exports", "client/utils/time-to-row", "client/utils/row-to-time", "client/utils/sort-by-number", "client/utils/set-overlaps"], function (_exports, _timeToRow, _rowToTime, _sortByNumber, _setOverlaps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.ControllerMixin, {
    /*
        each technician get's a 'bucket' of workOrders
        the assignedToday array is observed and when changed the new workOrders go into their buckets
    */
    dispatch: Ember.inject.controller(),
    application: Ember.inject.controller(),
    hashTable: null,
    registration: false,
    init: function init() {
      // this.set('hashTable',Ember.Object.create({}));
      this.set('hashTable', {}); //  this.set('dispatch.dndService',this);
    },
    registerList: function registerList(list) {
      var exists = false;
      var table = this.get('hashTable');
      var arr = Object.keys(table);

      if (table[list.get('technician')]) {
        exists = true;
      }

      if (!exists) {
        table[list.get('technician')] = list;
        console.log('REGISTERED');
        this.set('registration', true);
        list.set('workOrders', Ember.A([]));
        Ember.run.once(this, this.get('dispatch').assignedObserver, 500); //      Ember.run.once(this,this.assignedTodayObserver);
      } else {
        table[list.get('technician')] = list;
        list.set('workOrders', Ember.A([]));
        Ember.run.once(this, this.assignedTodayObserver);
      }
    },
    assignedTodayObserver: function assignedTodayObserver() {
      var workOrders = this.get('dispatch.assignedToday');
      var start = new Date().getTime();
      workOrders.forEach(function (workOrder) {
        workOrder.get('tech').then(function (tech) {
          return workOrder.get('tech2').then(function (tech2) {
            var hashTable = this.get('hashTable');

            if (tech && tech.get('id') == "10552") {
              console.log('here');
            }

            if (workOrder.get('id') == 9025) {
              console.log('here');
            }

            if (hashTable[tech]) {
              if (this.get('dispatch.filteredTechnicians').includes(tech) && !hashTable[tech].get('isDestroying') && !hashTable[tech].get('isDestroyed')) {
                hashTable[tech].get('workOrders').addObject(workOrder);
                Ember.$("#" + hashTable[tech].elementId).get().offsetHeight;
              }
            }

            if (hashTable[tech2]) {
              if (this.get('dispatch.filteredTechnicians').includes(tech2) && !hashTable[tech2].get('isDestroying') && !hashTable[tech2].get('isDestroyed')) {
                hashTable[tech2].get('workOrders').addObject(workOrder);
                Ember.$("#" + hashTable[tech2].elementId).get().offsetHeight;
              }
            }
          }.bind(this));
        }.bind(this));
      }.bind(this));
      var end = new Date().getTime();
      console.log(end - start);
      Ember.run.schedule('afterRender', this, function () {
        (0, _setOverlaps.default)();
      });
    },
    correctObserver: function () {
      Ember.run.once(this, this.assignedTodayObserver);
    }.observes('currentDateChange').on('init'),
    updateLists: function updateLists(wo) {
      var wo = this.get('hateThis');
      var arr = Object.keys(this.get('hashTable'));

      for (var i = 0; i < arr.length; i++) {
        this.get('hashTable')[arr[i]].get('workOrders').removeObject(wo);
      }
    },
    hateThis: null,
    currentDateChange: false
  });

  _exports.default = _default;
});