define("client/components/md-month-picker/component", ["exports", "ember-paper/components/paper-input", "ember-concurrency"], function (_exports, _paperInput, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      run = Ember.run,
      defineProperty = Ember.defineProperty;

  var _default = _paperInput.default.extend({
    monthNames: Ember.A(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']),
    left: "",
    top: "",
    width: "",
    dropdownWidth: 256,
    year: moment().format('YYYY'),
    classNames: ['md-show-clear-button'],
    click: function click(e) {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();
      return false;
    },
    open: function open() {
      this.set('isOpen', true);
      this.calculatePosition(); //	run.next( () => this.startObservingDomMutations());
    },
    init: function init() {
      this._super.apply(this, arguments);

      var value = this.get('value');

      if (value) {
        if (this.get('fullYear')) {
          var year = moment(value, 'MM/YYYY').format('YYYY');
          var month = moment(value, 'MM/YYYY').format('MM');
          this.set('selectedMonth', parseInt(month));
          this.set('selectedYear', parseInt(year));
          this.set('year', parseInt(year));
          console.log(this.get('selectedMonth'));
        } else {
          var _year = moment(value, 'MM/YY').format('YYYY');

          var _month = moment(value, 'MM/YY').format('MM');

          this.set('selectedMonth', parseInt(_month));
          this.set('selectedYear', parseInt(_year));
          this.set('year', parseInt(_year));
          console.log(this.get('selectedMonth'));
        }
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var value = this.get('value');

      if (value) {
        if (this.get('fullYear')) {
          var year = moment(value, 'MM/YYYY').format('YYYY');
          var month = moment(value, 'MM/YYYY').format('MM');
          this.set('selectedMonth', parseInt(month));
          this.set('selectedYear', parseInt(year));
          console.log(this.get('selectedMonth'));
        } else {
          var _year2 = moment(value, 'MM/YY').format('YYYY');

          var _month2 = moment(value, 'MM/YY').format('MM');

          this.set('selectedMonth', parseInt(_month2));
          this.set('selectedYear', parseInt(_year2));
          console.log(this.get('selectedMonth'));
        }
      }
    },
    close: function close() {
      //	this.stopObservingDomMutations();
      if (this.get('searchProxy.lastSuccessful')) {
        this.set('searchProxy.lastSuccessful.value', Ember.A([]));
      }

      this.set('isOpen', false);
    },
    calculatePosition: function calculatePosition() {
      if (this.get('isDestroying')) return;

      var _this$element$getBoun = this.element.getBoundingClientRect(),
          left = _this$element$getBoun.left,
          top = _this$element$getBoun.top,
          width = _this$element$getBoun.width,
          height = _this$element$getBoun.height;

      var input = this.$('input')[0];
      var $window = $(window);
      var $suggestions = $('.md-autocomplete-suggestions-container');
      var sHeight = $suggestions.height();
      var scroll = {
        left: $window.scrollLeft(),
        top: $window.scrollTop()
      };

      var _input$getBoundingCli = input.getBoundingClientRect(),
          iHeight = _input$getBoundingCli.height,
          iTop = _input$getBoundingCli.top;

      var viewportBottom = scroll.top + window.innerHeight;
      var triggerTopWithScroll = iTop + scroll.top;
      var enoughRoomBelow = triggerTopWithScroll + iHeight + 225.5 < viewportBottom;
      var adjustedTop = enoughRoomBelow ? top + iHeight + 2 : top - sHeight;

      if (this.get('dropdownWidth')) {
        left = left;
        width = this.get('dropdownWidth');
      }

      this.setProperties({
        left: "".concat(left, "px"),
        top: "".concat(adjustedTop, "px"),
        width: "".concat(width, "px")
      });
    },
    actions: {
      setMonth: function setMonth(i) {
        this.set('selectedMonth', i);
        this.set('selectedYear', this.get('year'));

        if (this.get('fullYear')) {
          this.sendAction('onChange', "".concat(i < 10 ? "0" + i : i.toString(), "/").concat(this.get('selectedYear')));
        } else {
          this.sendAction('onChange', moment("".concat(this.get('selectedYear'), "-").concat(this.get('selectedMonth') > 9 ? this.get('selectedMonth') : "0" + this.get('selectedMonth')), 'YYYY-MM').format('MM/YY'));
        }

        this.close();
      },
      incrementYear: function incrementYear() {
        this.set('year', parseInt(this.get('year')) + 1);
      },
      decrementYear: function decrementYear() {
        this.set('year', parseInt(this.get('year')) - 1);
      },
      onFocus2: function onFocus2(e) {
        var _this = this;

        this.open(); //let {left, top, width, height} = this.element.getBoundingClientRect();
        //let input = this.$('input')[0];
        //let { height: iHeight } = input.getBoundingClientRect();
        //let adjustedTop = top + iHeight + 2;
        //this.setProperties({left: `${left}px`, top: `${adjustedTop}px`, width: `${width}px`});

        run.next(function () {
          if (_this.get('isDestroyed')) return;

          _this.calculatePosition();
        });
        this.$('.md-autocomplete-suggestions-container').on('click', function (e) {
          return e.stopPropagation();
        });
        run.later(function () {
          if (!_this.get('isDestroyed')) _this.set('highlightedElement', _this.get('results.firstObject'));
        }, 100);
        this.sendAction('onFocus', e);
      },
      handleKeyDown: function handleKeyDown(e) {
        if (e.keyCode === 9) {
          this.close(); //this.set('isOpen', false);
        } else {
          this.open(); //this.set('isOpen', true);
        }

        this.sendAction('onKeyDown', e);
      },
      handleInput: function handleInput(e) {
        var _this2 = this;

        run.next(function () {
          return _this2.calculatePosition();
        });
        var val = e.target.value;

        if (this.get('fullYear')) {
          if (/[0-1][0-9]\/[0-9][0-9][0-9][0-9]/.test(val)) {
            this.sendAction('onChange', val);
          } else if (/[1-9]\/[0-9][0-9][0-9][0-9]/.test(val)) {
            this.sendAction('onChange', "0" + val);
          }
        } else {
          if (/[0-1][0-9]\/[0-9][0-9]/.test(val)) {
            this.sendAction('onChange', val);
          } else if (/[1-9]\/[0-9][0-9]/.test(val)) {
            this.sendAction('onChange', "0" + val);
          } else if (/[0-1][0-9][0-9][0-9]/.test(val)) {
            this.sendAction('onChange', val.substr(0, 2) + "/" + val.substr(2, 2));
          }
        }

        this.sendAction('onSearchTextChange', e.target.value);
        var inputElement = this.$('input').get(0);
        this.set('isNativeInvalid', inputElement && inputElement.validity && inputElement.validity.badInput);
        this.notifyValidityChange();
      },
      clear: function clear() {
        this.sendAction('onChange', null);
      }
    }
  });

  _exports.default = _default;
});