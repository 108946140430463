define("client/components/paper-money-input/component", ["exports", "ember-paper/components/paper-input"], function (_exports, _paperInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperInput.default.extend({
    sanitize: function sanitize(value2) {
      var value = value2;
      var wholeNumber = /^(-?\d+)$/;
      var justDecimal = /^(-?\d+\.)$/;
      var onePlace = /^(-?\d+\.\d)$/;
      var twoPlaces = /^(-?\d+\.\d{2})$/;
      var moreThanTwo = /^(-?\d+\.\d{3,})$/;
      var oneCent = /^(\.\d{1})$/;

      if (wholeNumber.test(value)) {
        value = value + ".00";
      } else if (justDecimal.test(value)) {
        value = value + "00";
      } else if (onePlace.test(value) || oneCent.test(value)) {
        value = value + "0";
      } else if (twoPlaces.test(value)) {} else if (moreThanTwo.test(value)) {
        value = parseFloat(value).toFixed(2);
      } else {
        value = "0.00";
      }

      return value;
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('value', this.sanitize(this.get('value')));
    },
    actions: {
      handleBlur: function handleBlur(e) {
        this.sendAction('onBlur', e);
        var value = this.sanitize(this.get('value'));
        this.set('isTouched', true);
        this.sendAction('onChange', value);
        this.notifyValidityChange();
      },
      handleFocus: function handleFocus(e) {
        this.setValue("");
        this.sendAction('onFocus', e);
      }
    }
  });

  _exports.default = _default;
});