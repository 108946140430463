define("client/routes/inv-product-search", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    actions: {
      openView: function openView(record) {
        this.transitionTo('inv-product-view', record.get('id'));
      },
      openCreate: function openCreate() {
        this.transitionTo('inv-product-create');
      },
      openEdit: function openEdit(record) {
        this.transitionTo('inv-product-edit', record.get('id'));
      }
    }
  });

  _exports.default = _default;
});