define("client/models/third-warehouse-part", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    prodId: _emberData.default.attr(),
    openingBalanceQ: _emberData.default.attr('numberalize'),
    openingBalanceR: _emberData.default.attr('numberalize'),
    openingBalanceS: _emberData.default.attr('numberalize'),
    openingBalanceT: _emberData.default.attr('numberalize'),
    openingBalanceU: _emberData.default.attr('numberalize'),
    openingBalanceV: _emberData.default.attr('numberalize'),
    openingBalanceW: _emberData.default.attr('numberalize'),
    openingBalanceX: _emberData.default.attr('numberalize'),
    receivedYtdQ: _emberData.default.attr('numberalize'),
    receivedYtdR: _emberData.default.attr('numberalize'),
    receivedYtdS: _emberData.default.attr('numberalize'),
    receivedYtdT: _emberData.default.attr('numberalize'),
    receivedYtdU: _emberData.default.attr('numberalize'),
    receivedYtdV: _emberData.default.attr('numberalize'),
    receivedYtdW: _emberData.default.attr('numberalize'),
    receivedYtdX: _emberData.default.attr('numberalize'),
    usedYtdQ: _emberData.default.attr('numberalize'),
    usedYtdR: _emberData.default.attr('numberalize'),
    usedYtdS: _emberData.default.attr('numberalize'),
    usedYtdT: _emberData.default.attr('numberalize'),
    usedYtdU: _emberData.default.attr('numberalize'),
    usedYtdV: _emberData.default.attr('numberalize'),
    usedYtdW: _emberData.default.attr('numberalize'),
    usedYtdX: _emberData.default.attr('numberalize'),
    allocatedQ: _emberData.default.attr('numberalize'),
    allocatedR: _emberData.default.attr('numberalize'),
    allocatedS: _emberData.default.attr('numberalize'),
    allocatedT: _emberData.default.attr('numberalize'),
    allocatedU: _emberData.default.attr('numberalize'),
    allocatedV: _emberData.default.attr('numberalize'),
    allocatedW: _emberData.default.attr('numberalize'),
    allocatedX: _emberData.default.attr('numberalize'),
    product: _emberData.default.belongsTo('part-item', {
      async: true
    })
  });

  _exports.default = _default;
});