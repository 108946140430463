define("client/components/job-type-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nJb8zf94",
    "block": "{\"statements\":[[6,[\"md-autocomplete\"],null,[[\"searchText\",\"autoSort\",\"triggerClass\",\"selected\",\"labelPath\",\"searchField\",\"matcher\",\"label\",\"options\",\"onSelectionChange\",\"onSearchTextChange\"],[[28,[\"searchText\"]],true,\"flex\",[28,[\"selected\"]],\"key\",\"key\",[28,[\"matcher\"]],[28,[\"label\"]],[28,[\"options\"]],[33,[\"action\"],[[28,[null]],\"selectJobType\"],null],[33,[\"perform\"],[[28,[\"changeSearchText\"]]],null]]],{\"statements\":[[0,\"\\t\"],[1,[33,[\"paper-autocomplete-highlight\"],null,[[\"label\",\"searchText\",\"flags\"],[[33,[\"concat\"],[[28,[\"jobType\",\"key\"]],\" - \",[28,[\"jobType\",\"description\"]]],null],[28,[\"select\",\"searchText\"]],\"i\"]]],false],[0,\"\\n\"]],\"locals\":[\"jobType\",\"select\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/job-type-autocomplete/template.hbs"
    }
  });

  _exports.default = _default;
});