define("client/utils/citron-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = citronDate;

  function citronDate(date) {
    var year = moment(date, 'YYYY-MM-DD').format('YYYY');
    var citronYear = parseInt(year) > 2000 ? String.fromCharCode(0x41 + (parseInt(year) % 2000 - parseInt(year) % 2000 % 10) / 10) + year.substring(3, 4) : year.substring(2, 4);
    var citronDate = moment(date, 'YYYY-MM-DD').format('MMDD');
    return citronDate + citronYear;
  }
});