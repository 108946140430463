define("client/components/smart-each/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mLLJ9OfO",
    "block": "{\"statements\":[[6,[\"each\"],[[28,[\"arr\"]]],null,{\"statements\":[[0,\"\\t\"],[18,\"default\",[[28,[\"item\"]],[33,[\"action\"],[[28,[null]],\"smartFocus\",[28,[\"index\"]]],null],[28,[\"index\"]]]],[0,\"\\n\"]],\"locals\":[\"item\",\"index\"]},null]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/smart-each/template.hbs"
    }
  });

  _exports.default = _default;
});