define("client/helpers/array-not-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayNotEmpty = arrayNotEmpty;
  _exports.default = void 0;

  function arrayNotEmpty(params
  /*, hash*/
  ) {
    var obj = params[0];

    if (Array.isArray(obj)) {
      return obj.length;
    } else {
      return true;
    }
  }

  var _default = Ember.Helper.helper(arrayNotEmpty);

  _exports.default = _default;
});