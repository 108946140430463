define("client/helpers/reverse-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reverseDate = reverseDate;

  function reverseDate(params
  /*, hash*/
  ) {
    var date = params[0];
    if (!date) return "";
    var mmdd = date.substr(2, 4);
    var decade = date.substr(0, 1);
    var cpoin = decade.charCodeAt(0);
    cpoin = cpoin - 0x41;
    var year = 1900 + (cpoin >= 0 ? 100 : 0) + (cpoin >= 0 ? cpoin * 10 + parseInt(date.substr(1, 1)) : parseInt(date.substr(0, 2)));
    return moment(year + mmdd, 'YYYYMMDD').format('YYYY-MM-DD');
  }

  var _default = Ember.Helper.helper(reverseDate);

  _exports.default = _default;
});