define("client/transforms/price-column", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      switch (serialized) {
        case "R":
          return 1;

        case "C":
          return 7;

        case "1":
          return 2;

        case "2":
          return 3;

        case "3":
          return 4;

        case "4":
          return 5;

        case "5":
          return 6;

        default:
          return 2;
      }
    },
    serialize: function serialize(deserialized) {
      switch (deserialized) {
        case 1:
          return "R";

        case 7:
          return "C";

        case 2:
          return "1";

        case 3:
          return "2";

        case 4:
          return "3";

        case 5:
          return "4";

        case 6:
          return "5";

        default:
          return "1";
      }
    }
  });

  _exports.default = _default;
});