define("client/models/contract", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Contract = _emberData.default.Model.extend({
    contractNumber: _emberData.default.attr('capitalize'),
    type: _emberData.default.attr(),
    description: _emberData.default.attr('capitalize'),
    effectiveDate: _emberData.default.attr(),
    expirationDate: _emberData.default.attr(),
    lastMaintDate: _emberData.default.attr(),
    nextMaintDate: _emberData.default.attr(),
    month1: _emberData.default.attr('asterisk-to-tf'),
    month2: _emberData.default.attr('asterisk-to-tf'),
    month3: _emberData.default.attr('asterisk-to-tf'),
    month4: _emberData.default.attr('asterisk-to-tf'),
    month5: _emberData.default.attr('asterisk-to-tf'),
    month6: _emberData.default.attr('asterisk-to-tf'),
    month7: _emberData.default.attr('asterisk-to-tf'),
    month8: _emberData.default.attr('asterisk-to-tf'),
    month9: _emberData.default.attr('asterisk-to-tf'),
    month10: _emberData.default.attr('asterisk-to-tf'),
    month11: _emberData.default.attr('asterisk-to-tf'),
    month12: _emberData.default.attr('asterisk-to-tf'),
    name: _emberData.default.attr('capitalize'),
    custName: _emberData.default.attr(),
    custName2: _emberData.default.attr(),
    custPhone: _emberData.default.attr(),
    custCity: _emberData.default.attr(),
    custState: _emberData.default.attr(),
    custZip: _emberData.default.attr(),
    custId: _emberData.default.attr(),
    custAddress: _emberData.default.attr('capitalize'),
    shipToId: _emberData.default.attr(),
    phone: _emberData.default.attr('telephone'),
    ext: _emberData.default.attr(),
    slm: _emberData.default.attr(),
    commissionRate: _emberData.default.attr(),
    cancelationDate: _emberData.default.attr(),
    cancellationDate: Ember.computed.alias('cancelationDate'),
    refund: _emberData.default.attr(),
    prepaidFlag: _emberData.default.attr('yn-tf'),
    cycle: _emberData.default.attr(),
    contractAmount: _emberData.default.attr(),
    contact: _emberData.default.attr(),
    billedThrough: _emberData.default.attr(),
    option1: _emberData.default.attr(),
    option2: _emberData.default.attr(),
    option3: _emberData.default.attr(),
    option4: _emberData.default.attr(),
    option5: _emberData.default.attr(),
    option6: _emberData.default.attr(),
    option7: _emberData.default.attr(),
    option8: _emberData.default.attr(),
    option9: _emberData.default.attr(),
    option10: _emberData.default.attr(),
    option11: _emberData.default.attr(),
    option12: _emberData.default.attr(),
    option13: _emberData.default.attr(),
    option14: _emberData.default.attr(),
    option15: _emberData.default.attr(),
    option16: _emberData.default.attr(),
    option17: _emberData.default.attr(),
    option18: _emberData.default.attr(),
    option19: _emberData.default.attr(),
    option20: _emberData.default.attr(),
    poNumber: _emberData.default.attr(),
    priceColumn: _emberData.default.attr(),
    lastContractId: _emberData.default.attr(),
    nextContractId: _emberData.default.attr(),
    note: _emberData.default.attr('capitalize'),
    addlOption1: _emberData.default.attr(),
    addlOption2: _emberData.default.attr(),
    addlOption3: _emberData.default.attr(),
    addlOption4: _emberData.default.attr(),
    addlOption5: _emberData.default.attr(),
    addlOption6: _emberData.default.attr(),
    addlOption7: _emberData.default.attr(),
    addlOption8: _emberData.default.attr(),
    addlOption9: _emberData.default.attr(),
    addlOption10: _emberData.default.attr(),
    addlOption11: _emberData.default.attr(),
    addlOption12: _emberData.default.attr(),
    addlOption13: _emberData.default.attr(),
    addlOption14: _emberData.default.attr(),
    addlOption15: _emberData.default.attr(),
    addlOption16: _emberData.default.attr(),
    addlOption17: _emberData.default.attr(),
    addlOption18: _emberData.default.attr(),
    addlOption19: _emberData.default.attr(),
    addlOption20: _emberData.default.attr(),
    building: _emberData.default.attr('capitalize'),
    masterContract: _emberData.default.attr(),
    note2: _emberData.default.attr('capitalize'),
    originalDate: _emberData.default.attr(),
    preferredCustomer: _emberData.default.attr(),
    partsDiscount: _emberData.default.attr(),
    laborDiscount: _emberData.default.attr(),
    preferredDiscount: _emberData.default.attr(),
    typeDescription: _emberData.default.attr('capitalize'),
    excludedCostCenters: _emberData.default.attr('csv'),
    siteName: _emberData.default.attr('capitalize'),
    siteStreetAddress: _emberData.default.attr('capitalize'),
    siteApartmentNumber: _emberData.default.attr(),
    siteZip: _emberData.default.attr(),
    siteCity: _emberData.default.attr('capitalize'),
    siteState: _emberData.default.attr(),
    customPricingInfo: _emberData.default.attr(),
    serviceLocation: _emberData.default.attr(),
    preferredTech: _emberData.default.belongsTo('technician', {
      async: true
    }),
    equipment: _emberData.default.hasMany('equipment-item', {
      async: true
    }),
    customer: _emberData.default.belongsTo('customer', {
      async: true,
      inverse: null
    }),
    siteAddress: _emberData.default.belongsTo('site-address', {
      async: true,
      inverse: null
    }),
    contractType: _emberData.default.belongsTo('contract-type', {
      async: true
    }),
    filters: _emberData.default.hasMany('contract-filter', {
      async: true
    }),
    serviceDues: _emberData.default.hasMany('contract-service-due', {
      async: true
    }),
    checkups: _emberData.default.hasMany('contract-checkup', {
      async: true
    }),
    options: _emberData.default.hasMany('linked-contract-option', {
      async: true
    }),
    billings: _emberData.default.hasMany('contract-billing', {
      async: true
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    isExpired: Ember.computed('expirationDate', 'cancelationDate', function () {
      var expirationDate = this.get('expirationDate');
      if (!expirationDate) return true;
      return moment().isAfter(this.get('expirationDate')) || moment().isAfter(this.get('cancelationDate'));
    }),
    months: Ember.computed('month1', 'month2', 'month3', 'month4', 'month5', 'month6', 'month7', 'month8', 'month9', 'month10', 'month11', 'month12', function () {
      var arr = Ember.A([Ember.Object.create({
        month: "January",
        short: "Jan",
        modelKey: "month1",
        value: this.get('month1')
      }), Ember.Object.create({
        month: "February",
        short: "Feb",
        modelKey: "month2",
        value: this.get('month2')
      }), Ember.Object.create({
        month: "March",
        short: "Mar",
        modelKey: "month3",
        value: this.get('month3')
      }), Ember.Object.create({
        month: "April",
        short: "Apr",
        modelKey: "month4",
        value: this.get('month4')
      }), Ember.Object.create({
        month: "May",
        short: "May",
        modelKey: "month5",
        value: this.get('month5')
      }), Ember.Object.create({
        month: "June",
        short: "Jun",
        modelKey: "month6",
        value: this.get('month6')
      }), Ember.Object.create({
        month: "July",
        short: "Jul",
        modelKey: "month7",
        value: this.get('month7')
      }), Ember.Object.create({
        month: "August",
        short: "Aug",
        modelKey: "month8",
        value: this.get('month8')
      }), Ember.Object.create({
        month: "September",
        short: "Sep",
        modelKey: "month9",
        value: this.get('month9')
      }), Ember.Object.create({
        month: "October",
        short: "Oct",
        modelKey: "month10",
        value: this.get('month10')
      }), Ember.Object.create({
        month: "November",
        short: "Nov",
        modelKey: "month11",
        value: this.get('month11')
      }), Ember.Object.create({
        month: "December",
        short: "Dec",
        modelKey: "month12",
        value: this.get('month12')
      })]);
      return arr;
    }),
    maintMonths: Ember.computed('month1', 'month2', 'month3', 'month4', 'month5', 'month6', 'month7', 'month8', 'month9', 'month10', 'month11', 'month12', function () {
      var _this = this;

      var months = Ember.A([Ember.Object.create({
        key: 'month1',
        value: 'Jan'
      }), Ember.Object.create({
        key: 'month2',
        value: 'Feb'
      }), Ember.Object.create({
        key: 'month3',
        value: 'Mar'
      }), Ember.Object.create({
        key: 'month4',
        value: 'Apr'
      }), Ember.Object.create({
        key: 'month5',
        value: 'May'
      }), Ember.Object.create({
        key: 'month6',
        value: 'Jun'
      }), Ember.Object.create({
        key: 'month7',
        value: 'Jul'
      }), Ember.Object.create({
        key: 'month8',
        value: 'Aug'
      }), Ember.Object.create({
        key: 'month9',
        value: 'Sep'
      }), Ember.Object.create({
        key: 'month10',
        value: 'Oct'
      }), Ember.Object.create({
        key: 'month11',
        value: 'Nov'
      }), Ember.Object.create({
        key: 'month12',
        value: 'Dec'
      })]);
      var mapped = months.map(function (item) {
        if (_this.get(item.get('key'))) {
          return item.get('value');
        }
      });
      return mapped.filter(function (item) {
        return item;
      });
    }),
    label: Ember.computed(function () {
      var name = this.get('siteName') === '' ? this.get('name') : this.get('siteName');
      return this.get('contractNumber') + ' - ' + this.get('type') + ' - ' + name;
    })
  });

  var _default = Contract;
  _exports.default = _default;
});