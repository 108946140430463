define("client/controllers/tenant-configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    tenantSettings: Ember.computed.alias('application.tenantSettings'),
    techList: Ember.A([]),
    allTechs: Ember.A([]),
    techSearch: "",
    userAssignment: Ember.A([]),
    techDelete: null,
    techsActive: true,
    groupsActive: false,
    alteredGroups: false,
    nameSort: true,
    numberSort: false,
    actions: {
      updateSortOrder: function updateSortOrder(indexes) {
        Ember.$('#isLoading').show();
        var v = Object.keys(indexes);
        var t = [];

        for (var key in indexes) {
          t.push(indexes[key].toString());
        }

        this.get('techList').clear();

        for (var i = 0; i < t.length; i++) {
          this.get('techList').addObject(this.get('allTechs').findBy('tech.employeeNumber', t[i]));
        }

        var arr = this.get('techList').mapBy('tech.id');
        this.set('userAssignment.techs', arr);
        this.get('userAssignment').save().then(function () {
          this.set('application.userAssignment', this.get('userAssignment'));
          Ember.$('#isLoading').hide();
        }.bind(this));
      },
      checkTechnician: function checkTechnician(tech) {
        this.get('allTechs').findBy('tech', tech).toggleProperty('checked');
      },
      selectAll: function selectAll() {
        this.get('allTechs').forEach(function (tech) {
          tech.set('checked', true);
        });
      },
      addTechnicians: function addTechnicians() {
        this.get('techList').removeObjects(this.get('allTechs').filterBy('checked', false));
        this.get('techList').addObjects(this.get('allTechs').filterBy('checked', true)); // this.set('techList', this.get('techList').sortBy('tech.name'));

        var arr = this.get('techList').mapBy('tech.id');
        this.set('userAssignment.techs', arr);
        this.get('userAssignment').save(); //.then(function() {

        this.set('application.userAssignment', this.get('userAssignment'));
        this.send('removeModal'); // }.bind(this));
      },
      deleteTechnician: function deleteTechnician(tech) {
        this.set('techDelete', tech);
        var arr = this.get('userAssignment.techs');
        var id = this.get('techDelete.tech.id');
        arr = arr.filter(function (item) {
          if (item === id.toString()) {
            return false;
          } else {
            return true;
          }
        }.bind(this));
        this.set('userAssignment.techs', arr);
        this.get('userAssignment').save(); //.then(function() {

        this.get('techList').removeObject(this.get('techDelete')); // this.set('techList', this.get('techList').sortBy('tech.name'));

        this.set('techDelete', null);
        this.send('removeModal');
      },
      deleteTechnicianConfirm: function deleteTechnicianConfirm(tech) {
        var arr = this.get('userAssignment.techs');
        var id = this.get('techDelete.tech.id');
        arr = arr.filter(function (item) {
          if (item === id.toString()) {
            return false;
          } else {
            return true;
          }
        }.bind(this));
        this.set('userAssignment.techs', arr);
        this.get('userAssignment').save(); //.then(function() {

        this.get('techList').removeObject(this.get('techDelete')); // this.set('techList', this.get('techList').sortBy('tech.name'));

        this.set('techDelete', null);
        this.send('removeModal'); // }.bind(this));
      },
      open: function open() {
        for (var l = 0; l < this.get('allTechs.length'); l++) {
          this.get('allTechs').objectAt(l).set('checked', false);
        }

        for (var i = 0; i < this.get('techList.length'); i++) {
          var hash = this.get('techList').objectAt(i);
          this.get('allTechs').findBy('tech', hash.get('tech')).set('checked', true);
        }

        this.set('alteredGroups', false);
        this.send('openModal', 'tech-modal');
      },
      close: function close() {
        this.send('removeModal');

        for (var i = 0; i < this.get('allTechs.length'); i++) {
          this.get('allTechs').objectAt(i).set('checked', false);
        }
      },
      changeTab: function changeTab(tab) {
        if (tab === "T") {
          this.set('groupsActive', false);
          this.set('techsActive', true);
        } else {
          this.set('techsActive', false);
          this.set('groupsActive', true);
        }
      },
      changeSort: function changeSort(type) {
        if (type === "name") {
          this.set('numberSort', false);
          this.set('nameSort', true);
        } else {
          this.set('nameSort', false);
          this.set('numberSort', true);
        }
      },
      sortByNames: function sortByNames() {
        Ember.$('#isLoading').show();
        var sortedTechs = this.get('techList').sort(function (a, b) {
          var name1 = a.get('tech.name');
          var name2 = b.get('tech.name');
          if (name1 < name2) return -1;
          if (name1 > name2) return 1;
          return 0;
        }.bind(this));
        var arr = sortedTechs.mapBy('tech.id');
        this.set('userAssignment.techs', arr);
        this.get('userAssignment').save().then(function () {
          this.set('techList', sortedTechs);
          this.get('techList').pushObject('t');
          this.get('techList').removeObject('t');
          this.set('application.userAssignment', this.get('userAssignment'));
          Ember.$('#isLoading').hide();
        }.bind(this));
      },
      sortByNumbers: function sortByNumbers() {
        Ember.$('#isLoading').show();
        var sortedTechs = this.get('techList').sort(function (a, b) {
          var num1 = Number(a.get('tech.employeeNumber'));
          var num2 = Number(b.get('tech.employeeNumber'));
          num1 === NaN ? num1 = -1000000 : "";
          num2 === NaN ? num2 = -1000000 : "";
          return num1 - num2;
        }.bind(this));
        var arr = sortedTechs.mapBy('tech.id');
        this.set('userAssignment.techs', arr);
        this.get('userAssignment').save().then(function () {
          this.set('techList', sortedTechs);
          this.get('techList').pushObject('t');
          this.get('techList').removeObject('t');
          this.set('application.userAssignment', this.get('userAssignment'));
          Ember.$('#isLoading').hide();
        }.bind(this));
      }
    },
    groups: Ember.computed.mapBy('allTechs', 'tech.category'),
    filteredGroups: Ember.computed.filter('groups', function (group) {
      group = group.trim();
      if (group) return true;else return false;
    }),
    uniqueGroups: Ember.computed.uniq('filteredGroups'),
    technicianSearch: Ember.computed.filter('allTechs', function (tech) {
      var searchTerm = this.get('techSearch').toLowerCase();

      if (searchTerm === "") {
        return true;
      }

      if (tech.get('tech.name').toLowerCase().indexOf(searchTerm) > -1) {
        return true;
      }

      if (tech.get('tech.employeeNumber').trim().indexOf(searchTerm) > -1) {
        return true;
      }

      return false;
    }).property('allTechs', 'techSearch'),
    sortedTechNames: Ember.computed.sort('technicianSearch', function (a, b) {
      var name1 = a.get('tech.name');
      var name2 = b.get('tech.name');
      if (name1 < name2) return -1;
      if (name1 > name2) return 1;
      return 0;
    }),
    sortedTechNumbers: Ember.computed.sort('technicianSearch', function (a, b) {
      var num1 = Number(a.get('tech.employeeNumber'));
      var num2 = Number(b.get('tech.employeeNumber'));
      num1 === NaN ? num1 = -1000000 : "";
      num2 === NaN ? num2 = -1000000 : "";
      return num1 - num2;
    })
  });

  _exports.default = _default;
});