define("client/models/pay-term", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    glCode1: _emberData.default.attr(),
    glCode2: _emberData.default.attr(),
    glCode3: _emberData.default.attr(),
    glCode4: _emberData.default.attr(),
    glDesc1: _emberData.default.attr(),
    glDesc2: _emberData.default.attr(),
    glDesc3: _emberData.default.attr(),
    glDesc4: _emberData.default.attr(),
    cutoff85: _emberData.default.attr(),
    addedMonths85: _emberData.default.attr(),
    onDay85: _emberData.default.attr(),
    cutoff86: _emberData.default.attr(),
    addedMonths86: _emberData.default.attr(),
    onDay86: _emberData.default.attr(),
    cutoff87: _emberData.default.attr(),
    addedMonths87: _emberData.default.attr(),
    onDay87: _emberData.default.attr(),
    cutoff88: _emberData.default.attr(),
    addedMonths88: _emberData.default.attr(),
    onDay88: _emberData.default.attr(),
    cutoff89: _emberData.default.attr(),
    addedMonths89: _emberData.default.attr(),
    onDay89: _emberData.default.attr(),
    cutoff90: _emberData.default.attr(),
    addedMonths90: _emberData.default.attr(),
    onDay90: _emberData.default.attr(),
    cutoff91: _emberData.default.attr(),
    addedMonths91: _emberData.default.attr(),
    onDay91: _emberData.default.attr(),
    cutoff92: _emberData.default.attr(),
    addedMonths92: _emberData.default.attr(),
    onDay92: _emberData.default.attr(),
    cutoff93: _emberData.default.attr(),
    addedMonths93: _emberData.default.attr(),
    onDay93: _emberData.default.attr(),
    cutoff94: _emberData.default.attr(),
    addedMonths94: _emberData.default.attr(),
    onDay94: _emberData.default.attr(),
    cutoff95: _emberData.default.attr(),
    addedMonths95: _emberData.default.attr(),
    onDay95: _emberData.default.attr(),
    cutoff96: _emberData.default.attr(),
    addedMonths96: _emberData.default.attr(),
    onDay96: _emberData.default.attr(),
    cutoff97: _emberData.default.attr(),
    addedMonths97: _emberData.default.attr(),
    onDay97: _emberData.default.attr(),
    cutoff98: _emberData.default.attr(),
    addedMonths98: _emberData.default.attr(),
    onDay98: _emberData.default.attr(),
    cutoff99: _emberData.default.attr(),
    addedMonths99: _emberData.default.attr(),
    onDay99: _emberData.default.attr()
  });

  _exports.default = _default;
});