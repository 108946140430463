define("client/components/md-site-search/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    loading: false,
    searchTerm: '',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('loading', true);
      this.get('customer.siteAddresses').then(function (sites) {
        _this.set('sites', sites);

        _this.set('loading', false);
      });
    },
    sortParam: ['shipId'],
    filteredSites: Ember.computed.filter('sites', function (item) {
      if (this.get('searchTerm') === '') return true;
      return (item.get('siteName').toLowerCase() + ' ' + item.get('streetAddress').toLowerCase()).indexOf(this.get('searchTerm').toLowerCase()) > -1;
    }).property('sites', 'searchTerm'),
    sortedResults: Ember.computed.sort('filteredSites', 'sortParam'),
    actions: {
      createSite: function createSite() {
        var site = this.get('store').createRecord('site-address', {
          siteName: '',
          streetAddress: '',
          shipId: '',
          custId: '',
          apartmentNumber: '',
          city: '',
          zip: '',
          state: '',
          homePhone: '',
          cellPhone: '',
          altPhone: '',
          salesTaxJurisdiction: '',
          siteEmail: ''
        });
        this.set('siteNotes', '');
        this.set('creatingSite', site);
      },
      cancelCreate: function cancelCreate() {
        this.get('creatingSite').destroyRecord();
        this.set('creatingSite', null);
      },
      saveCreate: function saveCreate() {
        var _this2 = this;

        this.set('loading', true);
        var site = this.get('creatingSite');
        site.set('siteName', site.get('siteName').toUpperCase());
        site.set('streetAddress', site.get('streetAddress').toUpperCase());
        site.set('apartmentNumber', site.get('apartmentNumber').toUpperCase());
        site.set('city', site.get('city').toUpperCase());
        site.set('zip', site.get('zip').toUpperCase());
        site.set('state', site.get('state').toUpperCase());
        site.set('salesTaxJurisdiction', site.get('salesTaxJurisdiction').toUpperCase());
        site.set('custId', this.get('customer.id'));
        site.set('customer', this.get('customer'));
        site.save().then(function (s) {
          _this2.set('creatingSite', null);

          var notesToSave = [];

          var notes = _this2.get('siteNotes').toUpperCase();

          var iterations = notes.length > 120 ? 3 : notes.length > 60 ? 2 : 1;

          if (notes.length > 0) {
            for (var i = 0; i < iterations; i++) {
              var note = _this2.get('store').createRecord('site-note', {
                note: notes.substr(60 * i, 60),
                custId: s.get('custId'),
                shipId: s.get('shipId'),
                site: s
              });

              notesToSave.push(note.save());
            }

            Ember.RSVP.all(notesToSave).then(function (nothing) {
              _this2.send('setSite', s);
            });
          } else {
            _this2.send('setSite', s);
          }
        }, function (r) {
          _this2.set('loading', false);
        });
      },
      setSite: function setSite(site) {
        this.get('setSite')(site);
      },
      setSiteToBilling: function setSiteToBilling() {
        this.get('closeWithoutSite')();
      },
      close: function close() {
        this.get('close')();
      }
    }
  });

  _exports.default = _default;
});