define("client/templates/document-posting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xf4KYNDB",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"featureFlags\",\"realtimeLedger\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,[\"gl-document-posting\"]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[1,[33,[\"document-posting-component\"],null,[[\"model\",\"docOneCount\"],[[28,[\"model\",\"entries\"]],[28,[\"model\",\"count\"]]]]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/document-posting.hbs"
    }
  });

  _exports.default = _default;
});