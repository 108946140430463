define("client/react/components/typed/sales-tax-jurisdiction-form-autocomplete", ["exports", "react/jsx-runtime", "client/react/components/helpers", "client/react/components/typed/form-autocomplete"], function (_exports, _jsxRuntime, _helpers, _formAutocomplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function SalesTaxJurisdictionFormAutocomplete(props) {
    return (0, _jsxRuntime.jsx)(_formAutocomplete.default, Object.assign({
      getOptionLabel: function getOptionLabel(x) {
        return (0, _helpers.getSalesTaxJurisdictionLabel)(x);
      },
      fullClearOnBlur: true
    }, props));
  }

  var _default = SalesTaxJurisdictionFormAutocomplete;
  _exports.default = _default;
});