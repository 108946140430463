define("client/templates/vendor/vendor-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6C58RKoe",
    "block": "{\"statements\":[[1,[33,[\"vendor-edit-modal\"],null,[[\"vendor\",\"onCancel\"],[[28,[\"model\"]],[33,[\"action\"],[[28,[null]],\"closeModal\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/vendor/vendor-edit.hbs"
    }
  });

  _exports.default = _default;
});