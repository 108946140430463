define("client/react/components/typed/form-date-time-picker", ["exports", "react/jsx-runtime", "@mui/material", "@mui/x-date-pickers-pro", "@Harris-Barrick-Software/use-form", "moment"], function (_exports, _jsxRuntime, _material, _xDatePickersPro, _useForm, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  function FormDateTimePicker(_a) {
    var formProps = _a.formProps,
        includeSeconds = _a.includeSeconds,
        restProps = __rest(_a, ["formProps", "includeSeconds"]);

    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });

    if (!controller) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field,
        fieldState = controller.fieldState;
    return (0, _jsxRuntime.jsx)(_xDatePickersPro.DesktopDateTimePicker, Object.assign({
      views: includeSeconds ? ['month', 'day', 'year', 'hours', 'minutes', 'seconds'] : ['month', 'day', 'year', 'hours', 'minutes'],
      // mask={includeSeconds ? '__/__/____ __:__:__ _M' : '__/__/____ __:__ _M'}
      inputFormat: includeSeconds ? 'MM/DD/YYYY hh:mm:ss A' : 'MM/DD/YYYY hh:mm A',
      showToolbar: true,
      OpenPickerButtonProps: {
        tabIndex: -1
      }
    }, restProps, {
      onChange: function onChange(date, value) {
        field.onChange(date);

        if (restProps.onChange) {
          restProps.onChange(date, value);
        }
      },
      renderInput: function renderInput(params) {
        var _a;

        return (0, _jsxRuntime.jsx)(_material.TextField, Object.assign({}, params, {
          placeholder: includeSeconds ? '01/01/1900 12:00:00 AM' : '01/01/1900 12:00 AM',
          className: restProps.className,
          size: restProps.size ? restProps.size : 'small',
          margin: restProps.margin,
          helperText: !!restProps.hideHelperText ? null : (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message,
          error: fieldState.invalid,
          onBlur: function onBlur(e) {
            var inputFormat = includeSeconds ? 'MM/DD/YYYY hh:mm:ss A' : 'MM/DD/YYYY hh:mm A';
            var valAsMoment = e.target.value && e.target.value.length === inputFormat.length + 1 ? (0, _moment.default)(e.target.value, inputFormat) : null;
            console.log(e.target.value);
            console.log(valAsMoment);

            if (restProps.onBlur) {
              restProps.onBlur(e, valAsMoment);
            }

            field.onBlur();
          }
        }));
      },
      value: field.value
    }));
  }

  var _default = FormDateTimePicker;
  _exports.default = _default;
});