define("client/components/tree-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IewTQ0SG",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"ember-table-table-row ember-table-row-style-\",[26,[\"depth\"]]]]],[15,\"style\",\"flex: 1;\"],[13],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-cell ember-table-tree-cell text-align-left\"],[15,\"style\",\"width: 100%;\"],[13],[0,\"\\n\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"ember-table-cell-container\"],[16,\"style\",[34,[\"padding-left: \",[33,[\"multiply\"],[20,[28,[\"depth\"]]],null],\"px;\"]]],[13],[0,\"\\n\\t\\t\\t\"],[11,\"span\",[]],[16,\"class\",[34,[\"ember-table-toggle-span \",[33,[\"if\"],[[33,[\"get\"],[[33,[\"get\"],[[28,[\"data\"]],[28,[\"childrenPath\"]]],null],\"firstObject.display\"],null],\"ember-table-toggle\"],null],\" ember-table-collapse\"]]],[5,[\"action\"],[[28,[null]],\"toggleExpand\"]],[13],[0,\"\\n\\t\\t\\t\\t\"],[11,\"i\",[]],[16,\"class\",[34,[\"glyphicon glyphicon-chevron-\",[33,[\"if\"],[[28,[\"isExpanded\"]],\"down\",\"right\"],null],\" ember-table-toggle-icon\"]]],[13],[14],[0,\"\\n\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\\t\"],[11,\"span\",[]],[15,\"class\",\"ember-table-content\"],[13],[0,\"\\n\\t\\t\\t\\t\"],[1,[33,[\"get\"],[[28,[\"data\"]],[28,[\"displayPath\"]]],null],false],[0,\"\\n\\t\\t\\t\"],[14],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\"],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"isExpanded\"]]],null,{\"statements\":[[6,[\"each\"],[[33,[\"get\"],[[28,[\"data\"]],[28,[\"childrenPath\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[33,[\"tree-row\"],null,[[\"depth\",\"data\",\"displayPath\",\"childrenPath\"],[[33,[\"inc\"],[[28,[\"depth\"]]],null],[28,[\"child\"]],[28,[\"displayPath\"]],[28,[\"childrenPath\"]]]]],false],[0,\"\\n\"]],\"locals\":[\"child\"]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/tree-row/template.hbs"
    }
  });

  _exports.default = _default;
});