define("client/transforms/csv2", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized !== null) {
        var items = serialized.split(",");

        if (items.length === 1 && items[0] === "") {
          return Ember.A([]);
        }

        items = items.map(function (item) {
          return item.trim();
        });
        return Ember.A([]).pushObjects(items);
      } else {
        return Ember.A([]);
      }
    },
    serialize: function serialize(deserialized) {
      return deserialized.reduce(function (prev, curr, index) {
        if (index === deserialized.get('length') - 1) {
          return prev += curr;
        } else {
          return prev += curr + ", ";
        }
      }, "");
    }
  });

  _exports.default = _default;
});