define("client/models/job-journal-two", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    key: _emberData.default.attr(),
    postingDate: _emberData.default.attr(),
    date: _emberData.default.attr(),
    source: _emberData.default.attr(),
    jobId: _emberData.default.attr(),
    catId: _emberData.default.attr(),
    employeeId: _emberData.default.attr(),
    debitGl: _emberData.default.attr(),
    debitAmount: _emberData.default.attr('number'),
    creditGl: _emberData.default.attr(),
    creditAmount: _emberData.default.attr('number'),
    prodId: _emberData.default.attr()
  });

  _exports.default = _default;
});