define("client/templates/inv-allocate-inventory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BLYkTcWY",
    "block": "{\"statements\":[[1,[26,[\"inv-allocate-inventory\"]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/inv-allocate-inventory.hbs"
    }
  });

  _exports.default = _default;
});