define("client/components/bootstrap-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onStyle: "primary",
    offStyle: "default",
    size: 'small',
    attributeBindings: ['style'],
    style: '',
    toggleStyle: '',
    valueToggle: false,
    value: false,
    label: '',
    didInsertElement: function didInsertElement() {
      var onText = this.get('onText');
      var offText = this.get('offText');
      var onStyle = this.get('onStyle');
      var offStyle = this.get('offStyle');
      var selector = this.$(':checkbox');
      this.$(':checkbox').bootstrapToggle({
        on: onText,
        off: offText,
        onstyle: onStyle,
        offstyle: offStyle
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$(':checkbox').bootstrapToggle('destroy');
    },
    click: function click() {
      this.toggleProperty('valueToggle');
    },
    valueObserver: Ember.observer('valueToggle', function () {
      this.set('value', !this.$(':checkbox')[0].checked);
    })
  });

  _exports.default = _default;
});