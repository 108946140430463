define("client/components/modal-dialog-xl/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "47C4Q+xp",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"modal-new fade-new in-new\"],[16,\"style\",[26,[\"height\"]],null],[13],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"modal-dialog-new\"],[16,\"style\",[26,[\"width\"]],null],[13],[0,\"\\n\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"modal-content-new\"],[13],[0,\"\\n\\t\\t\\t\"],[18,\"default\"],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\"],[14],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"modal-backdrop-new\"],[5,[\"action\"],[[28,[null]],\"close\"]],[13],[0,\"\\n\\t\"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/modal-dialog-xl/template.hbs"
    }
  });

  _exports.default = _default;
});