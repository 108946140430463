define("client/controllers/equipment-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['searchTerm'],
    actions: {
      back: function back() {
        window.history.back();
      },
      edit: function edit(e) {
        this.transitionToRoute('equipment-edit', e);
      },
      view: function view(e) {
        this.transitionToRoute('equipment-view', e);
      },
      delete: function _delete(e) {},
      create: function create() {}
    }
  });

  _exports.default = _default;
});