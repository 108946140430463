define("client/models/alt-labor-rate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    rateId: _emberData.default.attr('capitalize'),
    description: _emberData.default.attr(),
    rate: _emberData.default.attr('currency'),
    label: Ember.computed('description', 'rate', function () {
      return this.get('description') + ' - ' + this.get('rate');
    })
  });

  _exports.default = _default;
});