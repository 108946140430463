define("client/react/utilities/typed/hooks/smart-form", ["exports", "@Harris-Barrick-Software/use-form"], function (_exports, _useForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function useSmartForm(_ref) {
    var schema = _ref.schema,
        defaultValues = _ref.defaultValues,
        disabledDateCoercionFields = _ref.disabledDateCoercionFields,
        profile = _ref.profile;

    if (!schema) {
      throw new Error("A schema must be provided to useSmartForm");
    }

    return (0, _useForm.useForm)({
      mode: "all",
      criteriaMode: "all",
      resolver: (0, _useForm.yupResolver)(schema),
      defaultValues: defaultValues,
      disabledDateCoercionFields: disabledDateCoercionFields,
      profile: profile
    });
  }

  var _default = useSmartForm;
  _exports.default = _default;
});