define("client/components/extended-modal-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c+dwuK7i",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"modal-expanded-view\"],[15,\"style\",\"padding-right: 45px;\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"modal-dialog\"],[15,\"style\",\"max-height: 80vh;width:900px;\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"modal-content\"],[15,\"style\",\"max-height: 80vh;width:900px;\"],[13],[0,\"\\n\\t    \\t\"],[18,\"default\"],[0,\"\\n\\t    \"],[14],[0,\"\\n    \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"overlay\"],[15,\"style\",\"bottom: 0px;\"],[5,[\"action\"],[[28,[null]],\"close\"]],[13],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/extended-modal-component/template.hbs"
    }
  });

  _exports.default = _default;
});