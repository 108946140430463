define("client/models/contract-checkup-complaint", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    updated: _emberData.default.attr(),
    checkup: _emberData.default.belongsTo('contract-checkup', {
      async: true
    }),
    description: _emberData.default.attr(),
    equipment: _emberData.default.belongsTo('equipment-item', {
      async: true
    }),
    materials: _emberData.default.hasMany('checkup-material', {
      async: true
    })
  });

  _exports.default = _default;
});