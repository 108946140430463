define("client/models/part-history", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    prodId: _emberData.default.attr('capitalize'),
    date: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    name: _emberData.default.attr('capitalize'),
    qty: _emberData.default.attr('number'),
    source: _emberData.default.attr('capitalize'),
    invoiceNumber: _emberData.default.attr('capitalize'),
    vendId: _emberData.default.attr('capitalize'),
    jobNumber: _emberData.default.attr('capitalize'),
    warehouse: _emberData.default.attr('capitalize'),
    amount: _emberData.default.attr('number'),
    part: _emberData.default.belongsTo('part-item', {
      async: true
    })
  });

  _exports.default = _default;
});