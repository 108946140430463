define("client/models/work-order-flrate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    srvcId: _emberData.default.attr(),
    srvcAgreement: _emberData.default.attr('yn-tf'),
    seq: _emberData.default.attr(),
    sell: _emberData.default.attr('currency'),
    cost: _emberData.default.attr('currency'),
    qty: _emberData.default.attr('number'),
    primary: _emberData.default.attr('primary-flrate'),
    laborCost: _emberData.default.attr('currency'),
    labor: _emberData.default.attr('currency'),
    flrateId: _emberData.default.attr('trim2'),
    description: _emberData.default.attr(),
    complaintNumber: _emberData.default.attr(),
    afterHours: _emberData.default.attr('yn-tf'),
    notes: _emberData.default.attr(),
    costItem: _emberData.default.attr('object'),
    complaint: _emberData.default.belongsTo('work-order-info', {
      async: true
    })
  });

  _exports.default = _default;
});