define("client/models/ap-history", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    vendId: _emberData.default.attr(),
    year: _emberData.default.attr(),
    purchases: _emberData.default.attr(),
    payments: _emberData.default.attr(),
    vendor: _emberData.default.belongsTo('vendor', {
      async: true
    })
  });

  _exports.default = _default;
});