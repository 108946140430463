define("client/components/telephone-input/component", ["exports", "ember-paper/components/paper-input"], function (_exports, _paperInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperInput.default.extend({
    tempValue: '',
    oldLength: 0,
    actions: {
      handleInput: function handleInput(e) {
        var input = e.target.value;
        input = input.replace(/[^\d]/g, '');
        input = input.substr(0, 10);
        var length = input.length;

        if (input.length < this.get('oldLength') || input.length === this.get('oldLength')) {
          switch (true) {
            case length === 0:
              input = '';
              break;

            case length > 0 && length < 4:
              input = '(' + input;
              break;

            case length > 3 && length < 7:
              input = '(' + input.substr(0, 3) + ') ' + input.substr(3);
              break;

            case length > 6:
              input = '(' + input.substr(0, 3) + ') ' + input.substr(3, 3) + '-' + input.substr(6);
              break;
          }
        } else {
          switch (true) {
            case length === 0:
              input = '';
              break;

            case length === 1 || length === 2:
              input = '(' + input;
              break;

            case length === 3:
              input = '(' + input + ') ';
              break;

            case length > 3 && length < 7:
              var first = input.substr(0, 3);
              var second = input.substr(3);
              input = '(' + first + ') ' + second;
              break;

            case length > 6:
              var first = input.substr(0, 3);
              var second = input.substr(3, 3);
              var third = input.substr(6);
              input = '(' + first + ') ' + second + '-' + third;
              break;
          }
        }

        this.set('oldLength', length);
        this.sendAction('onChange', input); // setValue below ensures that the input value is the same as this.value

        this.setValue(input);
        this.growTextarea();
        var inputElement = this.$('input').get(0);
        this.set('isNativeInvalid', inputElement && inputElement.validity && inputElement.validity.badInput);
        this.notifyValidityChange();
      } // changeValue: function(e, input) {
      // 	input = input.replace(/[^\d]/g, '');
      // 	input = input.substr(0, 10);
      // 	var length = input.length;
      // 	if (input.length < this.get('oldLength')) {
      // 		switch (true) {
      // 			case (length === 0):
      // 				input = '';
      // 				break;
      // 			case (length > 0 && length < 4):
      // 				input = '(' + input;
      // 				break;
      // 			case (length > 3 && length < 7):
      // 				input = '(' + input.substr(0,3) + ') ' + input.substr(3);
      // 				break;
      // 			case (length > 6):
      // 				input = '(' + input.substr(0,3) + ') ' + input.substr(3,3) + '-' + input.substr(6);
      // 				break;
      // 		}
      // 		this.set('tempValue', input);
      // 	} else {
      // 		switch (true) {
      // 			case (length === 0):
      // 				input = '';
      // 				break;
      // 			case (length === 1 || length === 2):
      // 				input = '(' + input;
      // 				break;
      // 			case (length === 3):
      // 				input = '(' + input + ') ';
      // 				break;
      // 			case (length === 4 || length === 5):
      // 				input = '(' + input.substr(0,3) + ') ' + input.subtr(3);
      // 				break;
      // 			case (length === 6):
      // 				input = '(' + input.substr(0,3) + ') ' + input.substr(3) + '-';
      // 				break;
      // 			case (length > 6):
      // 				input = '(' + input.substr(0,3) + ') ' + input.substr(3,3) + '-' + input.substr(6);
      // 				break;
      // 		}
      // 		this.set('tempValue', input);
      // 		e.target.value = input;
      // 	}
      // 	this.set('oldLength', this.get('tempValue.length'));
      // 	this.get('onChange')(this.get('tempValue').replace(/[\(\) -]/g, ''));
      // }

    }
  });

  _exports.default = _default;
});