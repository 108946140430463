define("client/react/components/spacer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function Spacer(_ref) {
    var width = _ref.width,
        height = _ref.height,
        vertical = _ref.vertical;
    return React.createElement("span", {
      style: {
        display: height || vertical ? 'block' : 'inline-block',
        width: width ? width : 12,
        height: height ? height : 12
      }
    });
  }

  var _default = Spacer;
  _exports.default = _default;
});