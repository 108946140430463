define("client/components/search-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    optionPath: "",
    showDropdown: false,
    prompt: "",
    searchTerm: "",
    data: Ember.A([Ember.Object.create({
      name: "test",
      value: 5
    }), Ember.Object.create({
      name: "newTest",
      value: 6
    })]),
    defaultValue: Ember.computed('optionPath', 'prompt', function () {
      var obj = Ember.Object.create({});
      obj[this.get('optionPath')] = this.get('prompt');
      return obj;
    }),
    results: Ember.computed.filter('data', function (item) {
      return item.get(this.get('optionPath')).toLowerCase().indexOf(this.get('searchTerm').toLowerCase()) > -1;
    }).property('searchTerm'),
    actions: {
      selectItem: function selectItem(item) {
        this.set('selection', item);
        this.get('change')(item);
        this.set('showDropdown', false);
      },
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('showDropdown');
      },
      preventClick: function preventClick() {}
    },
    clickOff: function clickOff() {
      this.set('showDropdown', false);
    },
    clickObserver: function () {
      if (this.get('showDropdown')) {
        Ember.$(document).on('click', this.clickOff.bind(this));
      } else {
        Ember.$(document).off('click');
      }
    }.observes('showDropdown'),
    selectionObserver: function () {
      if (!this.get('selection.' + this.get('optionPath'))) {
        this.set('selection', this.get('defaultValue'));
      }
    }.observes('selection').on('init')
  });

  _exports.default = _default;
});