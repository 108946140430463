define("client/models/purchase-order-history-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    poNumber: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    note: _emberData.default.attr(),
    noteNumber: _emberData.default.attr(),
    po: _emberData.default.belongsTo('purchase-order-history', {
      async: true
    })
  });

  _exports.default = _default;
});