define("client/components/technician-table/component", ["exports", "client/utils/time-to-row", "client/utils/row-to-time", "client/utils/rows-to-time-string", "client/utils/time-string-to-rows"], function (_exports, _timeToRow, _rowToTime, _rowsToTimeString, _timeStringToRows) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    classNames: ['flex', 'technician-table'],
    height: 0,
    timeOfDay: "top: 0px;",
    showCurrentTime: true,
    nextRun: null,
    timeOfDayInit: Ember.on('init', function () {
      this.send('calculateTime');
    }),
    willDestroy: function willDestroy() {
      if (this.get('nextRun')) {
        Ember.run.cancel(this.get('nextRun'));
      }
    },
    times: function () {
      var startTime = "0:00";
      var numPeriods = 2; //this.get('tenantSettings').get('dispatchPeriods');

      var endTime = "24:00";
      var num = (0, _timeToRow.default)(endTime, startTime, numPeriods);
      var temp;
      var arr = Ember.A([]);

      for (var i = 1; i < num; i++) {
        arr.pushObject({
          time: moment((0, _rowToTime.default)(i, startTime, numPeriods), 'hh:mm A').format('HH:mm'),
          row: 'row-' + i
        });
      }

      return arr;
    }.property('tenantSettings'),
    timesEven: function () {
      var arr = Ember.A([]);

      for (var i = 0; i < this.get('times').get('length'); i++) {
        if (i % 2 === 0) {
          arr.pushObject(this.get('times').objectAt(i));
        }
      }

      return arr;
    }.property('times'),
    didInsertElement: function didInsertElement() {
      this.$().scroll(function () {
        this.sendAction('onChangeScrollLeft', this.$().scrollLeft());
        this.sendAction('onChangeScrollTop', this.$().scrollTop());
        this.$(".t-times-col").css({
          'left': this.$().scrollLeft() - 1
        });
        this.$(".testzzz").css({
          'left': this.$().scrollLeft()
        });
        this.$('#technician-headers').css({
          'top': this.$().scrollTop()
        });
      }.bind(this));
      var element = this.get('element');

      if (!this.get('scrollTop') && this.get('date') == moment().format('YYYY-MM-DD') && $('#current-time-bar')[0]) {
        var scrollDistance = $('#current-time-bar')[0].offsetTop - element.offsetHeight / 2;
        this.$().scrollTop(scrollDistance);
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.$() && this.get('scrollLeft')) {
        this.$().scrollLeft(this.get('scrollLeft'));
      }

      if (this.$() && this.get('scrollTop')) {
        this.$().scrollTop(this.get('scrollTop'));
      }
    },
    style: "height: 100%; white-space:nowrap; overflow: auto;position: relative;",
    actions: {
      calculateTime: function calculateTime() {
        var earliestTime = moment('07:00', 'HH:mm');
        var currentTime = moment();
        var sameDate = currentTime.format('YYYY-MM-DD') === earliestTime.format('YYYY-MM-DD');
        var difference = currentTime.diff(earliestTime, 'seconds') / 60;
        var pixelsPerMinute = 2 / 3;
        var position = 0;

        if (difference >= 0 && sameDate) {
          position = Math.round(difference) * pixelsPerMinute;
          this.set('timeOfDay', 'top: ' + position.toFixed(2) + 'px;');
          this.set('showCurrentTime', true);
        } else {
          this.set('showCurrentTime', false);
        }

        var nextRun = Ember.run.later(this, function () {
          this.send('calculateTime');
        }, 30000);
        this.set('nextRun', nextRun);
      }
    }
  });

  _exports.default = _default;
});