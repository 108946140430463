define("client/components/md-chip/component", ["exports", "client/components/react-component/component", "@mui/material"], function (_exports, _component, _material) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('onDelete')) {
        this.reactRender(React.createElement(_material.Chip, {
          style: {
            backgroundColor: this.get('color'),
            color: this.get('textColor')
          },
          label: this.get('label'),
          onDelete: function onDelete() {
            return _this.sendAction('onDelete');
          }
        }));
      } else {
        this.reactRender(React.createElement(_material.Chip, {
          style: {
            backgroundColor: this.get('color'),
            color: this.get('textColor')
          },
          label: this.get('label')
        }));
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this2 = this;

      if (this.get('onDelete')) {
        this.reactRender(React.createElement(_material.Chip, {
          style: {
            backgroundColor: this.get('color'),
            color: this.get('textColor')
          },
          label: this.get('label'),
          onDelete: function onDelete() {
            return _this2.sendAction('onDelete');
          }
        }));
      } else {
        this.reactRender(React.createElement(_material.Chip, {
          style: {
            backgroundColor: this.get('color'),
            color: this.get('textColor')
          },
          label: this.get('label')
        }));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.unmountReactElement();
    }
  });

  _exports.default = _default;
});