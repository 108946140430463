define("client/routes/srvc-billing-search", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    actions: {
      openWorkOrder: function openWorkOrder(wo) {
        if (wo.get('archived')) {
          this.transitionTo('srvc-billing-credit', wo);
        } else {
          this.transitionTo('srvc-billing', wo.get('id'));
        }
      }
    }
  });

  _exports.default = _default;
});