define("client/helpers/all-auth-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.allAuthChecked = allAuthChecked;
  _exports.default = void 0;

  function allAuthChecked(params
  /*, hash*/
  ) {
    var module = params[0];
    return !module.get('screens').any(function (x) {
      return x.get('permissions').any(function (y) {
        return !y.get('enabled');
      });
    });
    return params;
  }

  var _default = Ember.Helper.helper(allAuthChecked);

  _exports.default = _default;
});