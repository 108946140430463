define("client/react/utilities/yup-validation-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateTypeError = dateTypeError;
  _exports.emailTypeError = emailTypeError;
  _exports.maxLengthError = maxLengthError;
  _exports.minLengthError = minLengthError;
  _exports.numberTypeError = numberTypeError;
  _exports.phoneNumberError = phoneNumberError;
  _exports.requiredError = requiredError;
  _exports.timeTypeError = timeTypeError;

  function maxLengthError(maxLength) {
    return "Must be ".concat(maxLength, " characters or less");
  }

  function minLengthError(minLength) {
    return "Must be ".concat(minLength, " or more characters");
  }

  function requiredError() {
    return "This is required";
  }

  function timeTypeError() {
    return "Must be a valid time";
  }

  function dateTypeError() {
    return "Must be a valid date";
  }

  function numberTypeError() {
    return "Must be a valid number";
  }

  function emailTypeError() {
    return "Must be a valid email";
  }

  function phoneNumberError() {
    return 'Must be a valid phone number';
  }
});