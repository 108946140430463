define("client/helpers/citron-to-normal-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.citronToNormalTime = citronToNormalTime;
  _exports.default = void 0;

  function citronToNormalTime(params
  /*, hash*/
  ) {
    if (params.objectAt(0)) {
      return moment(params.objectAt(0), 'HHmm').format('hh:mm a');
    } else {
      return '--:--';
    }
  }

  var _default = Ember.Helper.helper(citronToNormalTime);

  _exports.default = _default;
});