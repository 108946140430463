define("client/components/md-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ajSve5+M",
    "block": "{\"statements\":[[18,\"default\",[[33,[\"hash\"],null,[[\"isValid\",\"form\",\"isInvalid\",\"isTouched\",\"isInvalidAndTouched\",\"input\",\"submit-button\",\"select\",\"autocomplete\",\"onSubmit\"],[[28,[\"isValid\"]],[28,[null]],[28,[\"isInvalid\"]],[28,[\"isTouched\"]],[28,[\"isInvalidAndTouched\"]],[33,[\"component\"],[[28,[\"inputComponent\"]]],[[\"parentComponent\",\"onValidityChange\"],[[28,[null]],[33,[\"action\"],[[28,[null]],\"onValidityChange\"],null]]]],[33,[\"component\"],[[28,[\"submitButtonComponent\"]]],[[\"type\"],[\"submit\"]]],[33,[\"component\"],[[28,[\"selectComponent\"]]],[[\"parentComponent\",\"onValidityChange\"],[[28,[null]],[33,[\"action\"],[[28,[null]],\"onValidityChange\"],null]]]],[33,[\"component\"],[[28,[\"autocompleteComponent\"]]],[[\"parentComponent\",\"onValidityChange\"],[[28,[null]],[33,[\"action\"],[[28,[null]],\"onValidityChange\"],null]]]],[33,[\"action\"],[[28,[null]],\"onSubmit\"],null]]]]]],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/md-form/template.hbs"
    }
  });

  _exports.default = _default;
});