define("client/components/form-validation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: [],
    attributeBindings: ['style'],
    style: "width:100%",
    numberOfInvalids: 0,
    isValid: Ember.computed('numberOfInvalids', function () {
      return this.get('numberOfInvalids') === 0 ? true : false;
    }),
    sendToParent: Ember.on('init', Ember.observer('isValid', function () {
      this.get('parentAction')(this.get('isValid'));
    })),
    actions: {
      onInvalid: function onInvalid(status) {
        if (status) {
          this.set('numberOfInvalids', this.get('numberOfInvalids') + 1);
        } else {
          if (this.get('numberOfInvalids') === 0) {
            return;
          }

          this.set('numberOfInvalids', this.get('numberOfInvalids') - 1);
        }
      }
    }
  });

  _exports.default = _default;
});