define("client/templates/failure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q1nFVKjY",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"login-background\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"absolute-center col-lg-3 form-group\"],[15,\"style\",\"height:100vh; width:584px;margin-top:30vh;\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"style\",\"background-image: url('g3.png');background-size: contain;width: 584px;height: 127.5px;background-repeat: no-repeat;background-position: center;\"],[13],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"style\",\"width:100%;margin-top:15px;\"],[13],[0,\"\\n        \\t\"],[11,\"div\",[]],[15,\"style\",\"display:inline-block;width:49.5%;text-align:center;\"],[13],[0,\" \\n        \\t\"],[14],[0,\"\\n        \\t\"],[11,\"div\",[]],[15,\"style\",\"display:inline-block;width:49.5%;text-align:center;\"],[13],[0,\" \\n        \\t\"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[6,[\"paper-dialog\"],null,null,{\"statements\":[[6,[\"paper-dialog-content\"],null,null,{\"statements\":[[0,\"\\t\\tThe URL you accessed doesn't correspond to a valid tenant.\"],[11,\"br\",[]],[13],[14],[0,\" Check your URL and ensure that you have specified a company \"],[11,\"br\",[]],[13],[14],[0,\" e.g. companyx.servisuite.com where \\\"companyx\\\" is the code corresponding to your company\\n\"]],\"locals\":[]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/failure.hbs"
    }
  });

  _exports.default = _default;
});