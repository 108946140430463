define("client/models/job", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    alphaKey: _emberData.default.attr(),
    jobId: _emberData.default.attr('capitalize'),
    ownersName: _emberData.default.attr('capitalize'),
    ownersName2: _emberData.default.attr('capitalize'),
    ownersAddress: _emberData.default.attr('capitalize'),
    ownersCityStateZip: _emberData.default.attr('capitalize'),
    architectName: _emberData.default.attr('capitalize'),
    architectName2: _emberData.default.attr('capitalize'),
    architectAddress: _emberData.default.attr('capitalize'),
    architectCityStateZip: _emberData.default.attr('capitalize'),
    projectName: _emberData.default.attr('capitalize'),
    projectName2: _emberData.default.attr('capitalize'),
    projectAddress: _emberData.default.attr('capitalize'),
    projectCityStateZip: _emberData.default.attr('capitalize'),
    contractDate: _emberData.default.attr(),
    startDate: _emberData.default.attr(),
    lastChgDate: _emberData.default.attr(),
    lastInvDate: _emberData.default.attr(),
    contact: _emberData.default.attr('capitalize'),
    contactPhone: _emberData.default.attr('telephone'),
    contractAmt: _emberData.default.attr(),
    changeOrders: _emberData.default.attr(),
    totalContract: _emberData.default.attr(),
    totalBilled: _emberData.default.attr(),
    estimateAmt: _emberData.default.attr(),
    paidToDate: _emberData.default.attr(),
    estimatedHours: _emberData.default.attr(),
    taxable: _emberData.default.attr(),
    custId: _emberData.default.attr(),
    supervisor: _emberData.default.attr('capitalize'),
    slm: _emberData.default.attr('capitalize'),
    closed: _emberData.default.attr(),
    retention: _emberData.default.attr(),
    eeoFlag: _emberData.default.attr(),
    certifiedFlag: _emberData.default.attr(),
    projectState: _emberData.default.attr(),
    rates: _emberData.default.hasMany('job-rate', {
      async: true
    }),
    customer: _emberData.default.belongsTo('customer', {
      async: true,
      inverse: null
    }),
    jobType: _emberData.default.belongsTo('job-gl-config', {
      async: true
    }),
    jobCostEntries: _emberData.default.hasMany('job-cost-entry', {
      async: true
    }),
    jobContracts: _emberData.default.hasMany('job-contract', {
      async: true
    }),
    vendorContracts: _emberData.default.hasMany('vendor-contract', {
      async: true
    }),
    jobEstimates: _emberData.default.hasMany('job-estimate', {
      async: true
    }),
    jobInvoices: _emberData.default.hasMany('job-billing-history', {
      async: true
    }),
    history: _emberData.default.hasMany('customer-history-item', {
      async: true
    }),
    transactions: _emberData.default.hasMany('customer-transaction', {
      async: true
    }),
    hours: _emberData.default.hasMany('payroll-hours', {
      async: true
    }),
    contractType: _emberData.default.attr(),
    totalTickets: _emberData.default.attr(),
    wrapUpFlag: _emberData.default.attr('yn-tf'),
    proposalsToDate: _emberData.default.attr(),
    expediter: _emberData.default.attr(),
    lastDatePaid: _emberData.default.attr('date'),
    lastPaidAmt: _emberData.default.attr(),
    contactFax: _emberData.default.attr('telephone'),
    closeDate: _emberData.default.attr('date-as-int'),
    burden: _emberData.default.attr(),
    commission: _emberData.default.attr('currency'),
    custPoNumber: _emberData.default.attr(),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    communications: _emberData.default.hasMany('communication', {
      async: true
    }),
    label: Ember.computed(function () {
      return this.get('jobId').trim() + '-' + this.get('projectName');
    })
  });

  _exports.default = _default;
});