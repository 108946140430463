define("client/react/components/typed/form-telephone-input", ["exports", "react/jsx-runtime", "@mui/material", "@Harris-Barrick-Software/use-form", "react-text-mask", "react"], function (_exports, _jsxRuntime, _material, _useForm, _reactTextMask, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.conformedPhoneNumber = conformedPhoneNumber;
  _exports.default = void 0;

  var __rest = void 0 && (void 0).__rest || function (s, e) {
    var t = {};

    for (var p in s) {
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
    }

    if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
    }
    return t;
  };

  var TextMask = (0, _react.forwardRef)(function (_a, inputRef) {
    var other = __rest(_a, []);

    return (0, _jsxRuntime.jsx)(_reactTextMask.default, Object.assign({}, other, {
      ref: function ref(_ref) {
        inputRef(_ref ? _ref.inputElement : null);
      },
      guide: false,
      mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      placeholderChar: "\u2000",
      showMask: true
    }));
  });

  function conformedPhoneNumber(val) {
    return (0, _reactTextMask.conformToMask)(val, ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], {
      guide: false
    });
  }

  function FormTelephoneInput(_a) {
    var _b;

    var formProps = _a.formProps,
        restProps = __rest(_a, ["formProps"]);

    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });

    if (!controller) {
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }

    var field = controller.field,
        fieldState = controller.fieldState;
    return (0, _jsxRuntime.jsx)(_material.TextField, Object.assign({
      error: fieldState.invalid,
      helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message,
      size: 'small'
    }, restProps, {
      ref: field.ref,
      onChange: function onChange(e) {
        field.onChange(e.target.value);

        if (restProps.onChange) {
          restProps.onChange(e);
        }
      },
      onBlur: function onBlur(e) {
        if (restProps.onBlur) {
          restProps.onBlur(e);
        }

        field.onBlur();
      },
      value: field.value,
      InputProps: {
        inputComponent: TextMask
      }
    }));
  }

  var _default = FormTelephoneInput;
  _exports.default = _default;
});