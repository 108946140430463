define("client/helpers/lpad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lpad = lpad;

  function lpad(params
  /*, hash*/
  ) {
    var string = params[0] || "";
    var length = params[1] || 4;
    var filler = params[2];

    for (var i = 0; i < length; i++) {
      if (string.length === length) return string;
      string = filler + string;
    }

    return string;
  }

  var _default = Ember.Helper.helper(lpad);

  _exports.default = _default;
});