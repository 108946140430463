define("client/models/check", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr(),
    vendId: _emberData.default.attr(),
    jobId: _emberData.default.attr(),
    date: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    discAmount: _emberData.default.attr(),
    autoPay: _emberData.default.attr(),
    apTransaction: _emberData.default.belongsTo('ap-transaction', {
      async: true
    }),
    vendor: _emberData.default.belongsTo('vendor', {
      async: true
    })
  });

  _exports.default = _default;
});