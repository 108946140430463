define("client/react/components/local-backdrop", ["exports", "@mui/material", "@mui/styles/withStyles"], function (_exports, _material, _withStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LocalBackdropComponent = (0, _withStyles.default)({
    root: {
      position: 'absolute',
      zIndex: 1
    }
  })(_material.Backdrop);

  function LocalBackdrop(props) {
    return React.createElement(LocalBackdropComponent, props);
  }

  var _default = LocalBackdrop;
  _exports.default = _default;
});