define("client/utils/sort-by-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sortByNumber;

  function sortByNumber(array, attribute) {
    function merge(left, right, arr) {
      var a = 0;

      while (left.length && right.length) {
        arr[a++] = Number(right[0].get(attribute).trim()) < Number(left[0].get(attribute).trim()) ? right.shift() : left.shift();
      }

      while (left.length) {
        arr[a++] = left.shift();
      }

      while (right.length) {
        arr[a++] = right.shift();
      }
    }

    function mSort(arr, tmp, len) {
      if (len < 2) return;
      var m = Math.floor(len / 2),
          tmp_l = tmp.slice(0, m),
          tmp_r = tmp.slice(m);
      mSort(tmp_l, arr.slice(0, m), m);
      mSort(tmp_r, arr.slice(m), len - m);
      merge(tmp_l, tmp_r, arr);
    }

    function merge_sort(arr) {
      mSort(arr, arr.slice(), arr.length);
    }

    var arrayLiteral = array.toArray();
    merge_sort(arrayLiteral);
    return Ember.A(arrayLiteral);
  }
});