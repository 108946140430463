define("client/routes/user-settings", ["exports", "client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    auth: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        user: this.store.findRecord('user', this.get('auth.userId', {
          reload: true
        })),
        userAssigned: this.store.findAll('user-assigned', {
          reload: true
        }),
        techs: this.store.findAll('technician', {
          reload: true
        })
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        controller.set('initialScreen', null);
        controller.set('nylasLinkSuccess', false);
        controller.set('nylasLinkFail', false);
      }
    }
  });

  _exports.default = _default;
});