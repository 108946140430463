define("client/models/lead", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr(),
    tenant: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer', {
      async: true
    }),
    site: _emberData.default.belongsTo('site-address', {
      async: true
    }),
    email: _emberData.default.attr(),
    stage: _emberData.default.attr(),
    source: _emberData.default.attr(),
    creator: _emberData.default.attr(),
    notes: _emberData.default.attr(),
    department: _emberData.default.attr(),
    unqualifiedReason: _emberData.default.attr(),
    doNotDisturb: _emberData.default.attr(),
    converted: _emberData.default.attr(),
    salesman: _emberData.default.belongsTo('employee', {
      async: true
    }),
    // joined on fields for search -- not needed for create/edit
    custId: _emberData.default.attr(),
    custName: _emberData.default.attr(),
    custAddress: _emberData.default.attr(),
    custApartmentNumber: _emberData.default.attr(),
    custCity: _emberData.default.attr(),
    custState: _emberData.default.attr(),
    custZip: _emberData.default.attr(),
    siteName: _emberData.default.attr(),
    siteAddress: _emberData.default.attr(),
    siteApartmentNumber: _emberData.default.attr(),
    siteCity: _emberData.default.attr(),
    siteState: _emberData.default.attr(),
    siteZip: _emberData.default.attr(),
    // end of joined fields
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    workOrder: _emberData.default.belongsTo('work-order', {
      async: true
    }),
    jobContract: _emberData.default.belongsTo('job-contract', {
      async: true
    }),
    communications: _emberData.default.hasMany('communication', {
      async: true
    }),
    invoice: _emberData.default.belongsTo('invoice', {
      async: true
    }),
    label: Ember.computed('id', 'custName', 'stage', function () {
      var name = this.get('siteName') ? this.get('siteName') : this.get('custName');
      return "".concat(this.get('id'), " - ").concat(name, " - ").concat(this.get('stage'));
    })
  });

  _exports.default = _default;
});