define("client/components/push-notification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    click: function click(event) {
      if (this.get('message.requiresReload')) {
        location.reload();
      }

      this.get('notifications').removeObject(this.get('message'));
    }
  });

  _exports.default = _default;
});