define("client/models/service-estimate-flrate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    srvcAgreement: _emberData.default.attr('yn-tf'),
    sell: _emberData.default.attr(),
    cost: _emberData.default.attr(),
    qty: _emberData.default.attr(),
    primary: _emberData.default.attr('bit-tf'),
    laborCost: _emberData.default.attr(),
    labor: _emberData.default.attr(),
    flrateId: _emberData.default.attr(),
    description: _emberData.default.attr(),
    afterHours: _emberData.default.attr('yn-tf'),
    extendedDescription: _emberData.default.attr(),
    costItem: _emberData.default.attr('object'),
    estimate: _emberData.default.belongsTo('service-estimate', {
      async: true
    })
  });

  _exports.default = _default;
});