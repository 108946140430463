define("client/components/sortable-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    headers: Ember.A([]),
    propNames: Ember.A([]),
    attributeBindings: ['style'],
    style: "",
    sortProp: [],
    widths: Ember.A([]),
    widths2: Ember.A([]),
    sortedData: Ember.computed.sort('data', 'sortProp'),
    fixWidths: function fixWidths() {
      this.set('style', "max-height: " + this.$().parent().height() + "px");
      var widths = [];
      var widths2 = [];
      var tdWidths = [];
      this.$('th').each(function (index, x) {
        return console.log(Ember.$(x).css('width'));
      });
      this.$('th').each(function (index, x) {
        return widths.push(Ember.$(x).css('width'));
      }); //this.$('th').each( (index,x) => widths2.push(Ember.$(x).outerWidth()));

      for (var i = 0; i < widths.length; i++) {
        this.get('propNames').objectAt(i).set('width', widths[i]);
      } // this.$('td').each( (index,x) => tdWidths.push(Ember.$(x).width()));


      this.$('thead').css({
        'display': 'block'
      });
      this.$('tbody').css({
        'display': 'block',
        'max-height': this.$().parent().height() - this.$('thead').height() + 'px'
      }); // this.$('th').each( (index,x) => Ember.$(x).width(widths[index]));

      for (var _i = 0; _i < widths.length; _i++) {
        this.get('headers').objectAt(_i).set('width', widths[_i]);
      } // this.$('th').each( (index,x) => this.get('headers').objectAt(index).set('width',Ember.$(x).css('width')));


      this.set('widths', Ember.A(widths)); //this.set('widths2',Ember.A(widths2));
      // this.$('td').each( (index,x) => Ember.$(x).width(tdWidths[index]));
    },
    resetWidths: function resetWidths() {
      var widths = this.get('widths');

      for (var i = 0; i < widths.length; i++) {
        this.get('propNames').objectAt(i).set('width', widths.objectAt(i));
      } // this.$('td').each( (index,x) => tdWidths.push(Ember.$(x).width()));
      // this.$('thead').css({'display': 'block'});
      // this.$('tbody').css({'display': 'block', 'max-height': (this.$().parent().height() - this.$('thead').height()) + 'px'});


      this.$('th').each(function (index, x) {
        return Ember.$(x).css({
          'width': widths.objectAt(index)
        });
      });
    },
    init: function init() {
      this._super.apply(this, arguments); //This component should be passed an array of strings in the form HEADER:PROPNAME
      // this array should be parsed to create headers and get the properties inside


      var headerList = this.get('config');
      var headers = Ember.A([]);
      var propNames = Ember.A([]);

      for (var i = 0; i < headerList.get('length'); i++) {
        var temp = headerList.objectAt(i).split(":");
        var isNumber = temp[2] === 'money' || temp[2] === 'number';
        headers.pushObject(Ember.Object.create({
          width: 0,
          name: temp[0],
          asc: false,
          desc: false,
          active: false,
          isNumber: isNumber
        }));
        propNames.pushObject(Ember.Object.create({
          width: 0,
          value: temp[1],
          phone: temp[2] === 'phone',
          money: temp[2] === 'money',
          none: !temp[2],
          capitalize: temp[2] === 'capitalize',
          toFixed: temp[2] === "toFixed",
          isNumber: isNumber,
          isPercent: temp[2] === 'percent'
        }));
      }

      this.set('headers', headers);
      this.set('propNames', propNames);
    },
    didInsertElement: function didInsertElement() {
      //this.$('table').stickyTableHeaders();
      // this.fixWidths();
      this.$().scroll(function () {
        this.$('thead').css({
          'top': this.$().scrollTop()
        });
      }.bind(this));
    },
    didRender: function didRender() {// this.fixWidths();
    },
    actions: {
      collectionMounted: function collectionMounted() {
        this.fixWidths();
      },
      setActive: function setActive(header) {
        var widths = [];
        this.$('th').each(function (index, x) {
          return widths.push(Ember.$(x).css('width'));
        });
        var test = Ember.A(widths);

        if (header.get('active')) {
          header.toggleProperty('asc');
          header.toggleProperty('desc');
          var index = this.get('headers').indexOf(header);
          var prop = this.get('propNames').objectAt(index);

          if (header.get('asc')) {
            this.set('sortProp', [prop.get('value')]);
          } else {
            this.set('sortProp', [prop.get('value') + ':desc']);
          }
        } else {
          this.get('headers').forEach(function (head) {
            head.set('active', false);
            head.set('asc', false);
            head.set('desc', false);
          });
          var index = this.get('headers').indexOf(header);
          var prop = this.get('propNames').objectAt(index);
          header.set('active', true);
          header.set('asc', true);

          if (header.get('asc')) {
            this.set('sortProp', [prop.get('value')]);
          } else {
            this.set('sortProp', [prop.get('value') + ':desc']);
          }
        }
      },
      rowAction: function rowAction(row) {
        this.get('mainAction')(row);
      },
      actionButton: function actionButton(row, propName) {
        this.get(propName.get('value'))(row);
        this.set('widths', test); // this.resetWidths();
      }
    }
  });

  _exports.default = _default;
});