define("client/transforms/configuration", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return null;
      }

      if (typeof serialized === "string") {
        serialized = JSON.parse(serialized);
      }

      for (var i = 0; i < serialized.headings.length; i++) {
        var heading = serialized.headings[i];

        for (var j = 0; j < heading.rows.length; j++) {
          var row = heading.rows[j];

          for (var k = 0; k < row.items.length; k++) {
            var item = row.items[k];

            for (var m = 0; m < item.values.length; m++) {
              item.values[m] = Ember.Object.create(item.values[m]);
            }

            item.values = Ember.A(item.values);
            row.items[k] = Ember.Object.create(item);
          }

          row.items = Ember.A(row.items);
          heading.rows[j] = Ember.Object.create(row);
        }

        heading.rows = Ember.A(heading.rows);
        serialized.headings[i] = Ember.Object.create(serialized.headings[i]);
      }

      serialized.headings = Ember.A(serialized.headings);
      return Ember.Object.create(serialized);
    },
    serialize: function serialize(deserialized) {
      return JSON.stringify(deserialized);
    }
  });

  _exports.default = _default;
});