define("client/components/sticky-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MutObserver = self.window.MutationObserver || self.window.WebKitMutationObserver;

  var _default = Ember.Component.extend({
    classNames: ['sticky-header'],
    classNameBindings: ['checkbox:sticky-header-checkbox'],
    mutationObserver: null,
    didRender: function didRender() {
      this.calculateWidth();
      this.startObservingDomMutations();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.stopObservingDomMutations();
    },
    startObservingDomMutations: function startObservingDomMutations() {
      var _this = this;

      if (this.get('isDestroying')) return;
      var parentElement = this.parentView.element;
      var tbody = parentElement && parentElement.parentElement.parentElement.nextElementSibling;
      if (!tbody) return;
      this.set('mutationObserver', new MutObserver(function (mutations) {
        if (mutations[0].addedNodes.length || mutations[0].removedNodes.length) {
          _this.calculateWidth();
        }
      }));
      this.get('mutationObserver').observe(tbody, {
        childList: true,
        subtree: true
      });
    },
    stopObservingDomMutations: function stopObservingDomMutations() {
      if (this.get('mutationObserver')) {
        this.get('mutationObserver').disconnect();
        this.set('mutationObserver', null);
      }
    },
    calculateWidth: function calculateWidth() {
      var element = this.get('element');

      if (this.parentView) {
        var parentElement = this.parentView.element;

        if (!parentElement) {
          return;
        }

        var width = parentElement.clientWidth;
        var paddingRightString = window.getComputedStyle(parentElement).getPropertyValue("padding-right");
        var paddingLeftString = window.getComputedStyle(parentElement).getPropertyValue("padding-left");
        var paddingLeft = parseInt(paddingLeftString);
        var paddingRight = parseInt(paddingRightString);
        var effectiveWidth = width - paddingLeft - paddingRight;
        this.$().css('width', effectiveWidth + 'px');
        this.$().css('padding-left', this.get('paddingLeft') + 'px'); //			this.$().css('padding-right', paddingRightString);

        $(this.parentView.element).on('resize', function () {
          console.log('resized');
        });
      }
    }
  });

  _exports.default = _default;
});