define("client/templates/dispatch/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kc3Tf8cG",
    "block": "{\"statements\":[[1,[33,[\"dispatch/tech-message-modal\"],null,[[\"technician\",\"action\"],[[28,[\"messageTech\"]],\"closeMessageDialog\"]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/dispatch/message.hbs"
    }
  });

  _exports.default = _default;
});