define("client/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6hKQ3tRZ",
    "block": "{\"statements\":[[6,[\"ember-wormhole\"],null,[[\"to\"],[\"main-toolbar\"]],{\"statements\":[[0,\"    \"],[11,\"h4\",[]],[13],[0,\"\\n        Not Found\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"layout-row\"],[15,\"style\",\"height: 100%\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"layout-column flex layout-align-center-center\"],[13],[0,\"\\n        \"],[11,\"h3\",[]],[13],[0,\"Record Not Found!\"],[14],[0,\"\\n        \"],[11,\"span\",[]],[13],[0,\"Either the record was deleted or you do not have permissions to access this record\"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});