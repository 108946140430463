define("client/components/animated-pages/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var observes = Ember.observes;

  var _default = Ember.Component.extend({
    tagName: 'ember-animated-pages',
    children: Ember.A([]),
    register: function register(child) {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        _this.get('children').pushObject(child);
      });
    },
    unregister: function unregister(child) {
      this.get('children').removeObject(child);
    }
  });

  _exports.default = _default;
});