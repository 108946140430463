define("client/models/contract-solicitation-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    dateCreated: _emberData.default.attr('date2'),
    description: _emberData.default.attr(),
    newPhoneMessage: _emberData.default.attr(),
    phoneMessage: _emberData.default.attr(),
    phoneCallerId: _emberData.default.attr('telephone'),
    newMessage: _emberData.default.attr(),
    message: _emberData.default.attr(),
    newEmailMessage: _emberData.default.attr(),
    emailMessage: _emberData.default.attr(),
    fromAddress: _emberData.default.attr(),
    fromEmail: _emberData.default.attr(),
    emailSubject: _emberData.default.attr(),
    useLetterhead: _emberData.default.attr()
  });

  _exports.default = _default;
});