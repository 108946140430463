define("client/components/color-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2nXAWrVj",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"input-group c-picker\"],[15,\"style\",\"max-width: 170px;\"],[13],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"input-group-addon\"],[15,\"style\",\"font-weight:bold;width:39px;\"],[13],[1,[26,[\"title\"]],false],[14],[0,\"\\n  \"],[1,[33,[\"input\"],null,[[\"type\",\"value\",\"class\"],[\"text\",[28,[\"inValue\"]],\"form-control bigger-font\"]]],false],[0,\"\\n    \"],[11,\"span\",[]],[15,\"class\",\"input-group-addon\"],[13],[0,\"\\n\\t\"],[11,\"i\",[]],[13],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/components/color-picker/template.hbs"
    }
  });

  _exports.default = _default;
});