define("client/helpers/social-security", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.socialSecurity = socialSecurity;

  function socialSecurity(params
  /*, hash*/
  ) {
    var input = params[0];
    if (Ember.isEmpty(input)) return '';
    return input.substr(0, 3) + '-' + input.substr(3, 2) + '-' + input.substr(5, 4);
  }

  var _default = Ember.Helper.helper(socialSecurity);

  _exports.default = _default;
});