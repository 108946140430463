define("client/models/payroll-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    misc1: _emberData.default.attr('capitalize'),
    misc2: _emberData.default.attr('capitalize'),
    misc3: _emberData.default.attr('capitalize'),
    misc4: _emberData.default.attr('capitalize'),
    misc5: _emberData.default.attr('capitalize'),
    misc6: _emberData.default.attr('capitalize'),
    misc7: _emberData.default.attr('capitalize'),
    misc8: _emberData.default.attr('capitalize'),
    misc9: _emberData.default.attr('capitalize'),
    miscFlag1: _emberData.default.attr(),
    miscFlag2: _emberData.default.attr(),
    miscFlag3: _emberData.default.attr(),
    miscFlag4: _emberData.default.attr(),
    miscFlag5: _emberData.default.attr(),
    miscFlag6: _emberData.default.attr(),
    miscFlag7: _emberData.default.attr(),
    miscFlag8: _emberData.default.attr(),
    miscFlag9: _emberData.default.attr(),
    miscFlag0: _emberData.default.attr(),
    checkNumber: _emberData.default.attr(),
    periodEndDate: _emberData.default.attr(),
    checkDate: _emberData.default.attr(),
    glCode1: _emberData.default.attr(),
    glCode2: _emberData.default.attr(),
    glCode3: _emberData.default.attr(),
    glCode4: _emberData.default.attr(),
    glCode5: _emberData.default.attr(),
    glCode6: _emberData.default.attr(),
    glCode7: _emberData.default.attr(),
    glCode8: _emberData.default.attr(),
    glCode9: _emberData.default.attr(),
    glCode10: _emberData.default.attr(),
    glCode11: _emberData.default.attr(),
    glCode12: _emberData.default.attr(),
    glCode13: _emberData.default.attr(),
    glCode14: _emberData.default.attr(),
    glCode15: _emberData.default.attr(),
    glCode16: _emberData.default.attr(),
    glCode17: _emberData.default.attr(),
    glCode18: _emberData.default.attr(),
    glCode19: _emberData.default.attr(),
    glCode20: _emberData.default.attr(),
    glCode21: _emberData.default.attr(),
    glCode22: _emberData.default.attr(),
    glCode23: _emberData.default.attr(),
    glCode24: _emberData.default.attr(),
    glCode25: _emberData.default.attr(),
    glCode26: _emberData.default.attr(),
    glCode27: _emberData.default.attr(),
    glCode28: _emberData.default.attr(),
    glInclude1: _emberData.default.attr('yn-tf'),
    glInclude2: _emberData.default.attr('yn-tf'),
    glInclude3: _emberData.default.attr('yn-tf'),
    glInclude4: _emberData.default.attr('yn-tf'),
    glInclude5: _emberData.default.attr('yn-tf'),
    glInclude6: _emberData.default.attr('yn-tf'),
    glInclude7: _emberData.default.attr('yn-tf'),
    glInclude8: _emberData.default.attr('yn-tf'),
    glInclude9: _emberData.default.attr('yn-tf'),
    glInclude10: _emberData.default.attr('yn-tf'),
    glInclude11: _emberData.default.attr('yn-tf'),
    glInclude12: _emberData.default.attr('yn-tf'),
    glInclude13: _emberData.default.attr('yn-tf'),
    glInclude14: _emberData.default.attr('yn-tf'),
    glInclude15: _emberData.default.attr('yn-tf'),
    glInclude16: _emberData.default.attr('yn-tf'),
    glInclude17: _emberData.default.attr('yn-tf'),
    glInclude18: _emberData.default.attr('yn-tf'),
    glInclude19: _emberData.default.attr('yn-tf'),
    glInclude20: _emberData.default.attr('yn-tf'),
    glInclude21: _emberData.default.attr('yn-tf'),
    glInclude22: _emberData.default.attr('yn-tf'),
    glInclude23: _emberData.default.attr('yn-tf'),
    glInclude24: _emberData.default.attr('yn-tf'),
    glInclude25: _emberData.default.attr('yn-tf'),
    glInclude26: _emberData.default.attr('yn-tf'),
    glInclude27: _emberData.default.attr('yn-tf'),
    glInclude28: _emberData.default.attr('yn-tf'),
    payPeriods: _emberData.default.attr(),
    taxFreqMult: _emberData.default.attr('yn-tf'),
    defaultState: _emberData.default.attr('capitalize'),
    defaultCity: _emberData.default.attr('capitalize'),
    preNumberedChecks: _emberData.default.attr('yn-tf'),
    autoFlag: _emberData.default.attr(),
    drawFlag: _emberData.default.attr(),
    miscOther1: _emberData.default.attr(),
    miscOther2: _emberData.default.attr(),
    otherFlag1: _emberData.default.attr(),
    otherFlag2: _emberData.default.attr()
  });

  _exports.default = _default;
});