define("client/models/part-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    category: _emberData.default.attr('capitalize'),
    prodId: _emberData.default.attr('capitalize'),
    description: _emberData.default.attr('capitalize'),
    sell: _emberData.default.attr('currency'),
    cost: _emberData.default.attr('currency'),
    received: _emberData.default.attr('numberalize'),
    used: _emberData.default.attr('numberalize'),
    allocated: _emberData.default.attr('numberalize'),
    onOrder: _emberData.default.attr('numberalize'),
    avgCost: _emberData.default.attr('numberalize'),
    uom: _emberData.default.attr('trim'),
    reorderPart: _emberData.default.attr('numberalize'),
    maxOnHand: _emberData.default.attr('numberalize'),
    minimumPack: _emberData.default.attr('numberalize'),
    weight: _emberData.default.attr('numberalize'),
    purchasePack: _emberData.default.attr('numberalize'),
    stockCode: _emberData.default.attr('capitalize'),
    multiplier1: _emberData.default.attr('price-multiplier'),
    multiplier2: _emberData.default.attr('price-multiplier'),
    multiplier3: _emberData.default.attr('price-multiplier'),
    multiplier4: _emberData.default.attr('price-multiplier'),
    multiplier5: _emberData.default.attr('price-multiplier'),
    multiplier6: _emberData.default.attr('price-multiplier'),
    multiplier7: _emberData.default.attr('price-multiplier'),
    price1: _emberData.default.attr('numberalize'),
    price2: _emberData.default.attr('numberalize'),
    price3: _emberData.default.attr('numberalize'),
    price4: _emberData.default.attr('numberalize'),
    price5: _emberData.default.attr('numberalize'),
    price6: _emberData.default.attr('numberalize'),
    price7: _emberData.default.attr('numberalize'),
    gp1: _emberData.default.attr('numberalize'),
    gp2: _emberData.default.attr('numberalize'),
    gp3: _emberData.default.attr('numberalize'),
    gp4: _emberData.default.attr('numberalize'),
    gp5: _emberData.default.attr('numberalize'),
    gp6: _emberData.default.attr('numberalize'),
    gp7: _emberData.default.attr('numberalize'),
    lastCost: _emberData.default.attr('numberalize'),
    binLocation: _emberData.default.attr('capitalize'),
    glCode: _emberData.default.attr(),
    openingBalance: _emberData.default.attr('numberalize'),
    prodVendId: _emberData.default.attr(),
    breakQty1: _emberData.default.attr('numberalize'),
    breakQty2: _emberData.default.attr('numberalize'),
    breakQty3: _emberData.default.attr('numberalize'),
    breakQty4: _emberData.default.attr('numberalize'),
    taxable: _emberData.default.attr('yn-tf'),
    usedYtd1: _emberData.default.attr('part-usage'),
    usedYtd2: _emberData.default.attr('part-usage'),
    usedYtd3: _emberData.default.attr('part-usage'),
    usedYtd4: _emberData.default.attr('part-usage'),
    usedYtd5: _emberData.default.attr('part-usage'),
    usedYtd6: _emberData.default.attr('part-usage'),
    usedYtd7: _emberData.default.attr('part-usage'),
    usedYtd8: _emberData.default.attr('part-usage'),
    usedYtd9: _emberData.default.attr('part-usage'),
    usedYtd10: _emberData.default.attr('part-usage'),
    usedYtd11: _emberData.default.attr('part-usage'),
    usedYtd12: _emberData.default.attr('part-usage'),
    usedLastYr1: _emberData.default.attr('part-usage'),
    usedLastYr2: _emberData.default.attr('part-usage'),
    usedLastYr3: _emberData.default.attr('part-usage'),
    usedLastYr4: _emberData.default.attr('part-usage'),
    usedLastYr5: _emberData.default.attr('part-usage'),
    usedLastYr6: _emberData.default.attr('part-usage'),
    usedLastYr7: _emberData.default.attr('part-usage'),
    usedLastYr8: _emberData.default.attr('part-usage'),
    usedLastYr9: _emberData.default.attr('part-usage'),
    usedLastYr10: _emberData.default.attr('part-usage'),
    usedLastYr11: _emberData.default.attr('part-usage'),
    usedLastYr12: _emberData.default.attr('part-usage'),
    soldYtd1: _emberData.default.attr('part-usage'),
    soldYtd2: _emberData.default.attr('part-usage'),
    soldYtd3: _emberData.default.attr('part-usage'),
    soldYtd4: _emberData.default.attr('part-usage'),
    soldYtd5: _emberData.default.attr('part-usage'),
    soldYtd6: _emberData.default.attr('part-usage'),
    soldYtd7: _emberData.default.attr('part-usage'),
    soldYtd8: _emberData.default.attr('part-usage'),
    soldYtd9: _emberData.default.attr('part-usage'),
    soldYtd10: _emberData.default.attr('part-usage'),
    soldYtd11: _emberData.default.attr('part-usage'),
    soldYtd12: _emberData.default.attr('part-usage'),
    soldLastYr1: _emberData.default.attr('part-usage'),
    soldLastYr2: _emberData.default.attr('part-usage'),
    soldLastYr3: _emberData.default.attr('part-usage'),
    soldLastYr4: _emberData.default.attr('part-usage'),
    soldLastYr5: _emberData.default.attr('part-usage'),
    soldLastYr6: _emberData.default.attr('part-usage'),
    soldLastYr7: _emberData.default.attr('part-usage'),
    soldLastYr8: _emberData.default.attr('part-usage'),
    soldLastYr9: _emberData.default.attr('part-usage'),
    soldLastYr10: _emberData.default.attr('part-usage'),
    soldLastYr11: _emberData.default.attr('part-usage'),
    soldLastYr12: _emberData.default.attr('part-usage'),
    costYtd1: _emberData.default.attr('part-usage'),
    costYtd2: _emberData.default.attr('part-usage'),
    costYtd3: _emberData.default.attr('part-usage'),
    costYtd4: _emberData.default.attr('part-usage'),
    costYtd5: _emberData.default.attr('part-usage'),
    costYtd6: _emberData.default.attr('part-usage'),
    costYtd7: _emberData.default.attr('part-usage'),
    costYtd8: _emberData.default.attr('part-usage'),
    costYtd9: _emberData.default.attr('part-usage'),
    costYtd10: _emberData.default.attr('part-usage'),
    costYtd11: _emberData.default.attr('part-usage'),
    costYtd12: _emberData.default.attr('part-usage'),
    costsLastYr1: _emberData.default.attr('part-usage'),
    costsLastYr2: _emberData.default.attr('part-usage'),
    costsLastYr3: _emberData.default.attr('part-usage'),
    costsLastYr4: _emberData.default.attr('part-usage'),
    costsLastYr5: _emberData.default.attr('part-usage'),
    costsLastYr6: _emberData.default.attr('part-usage'),
    costsLastYr7: _emberData.default.attr('part-usage'),
    costsLastYr8: _emberData.default.attr('part-usage'),
    costsLastYr9: _emberData.default.attr('part-usage'),
    costsLastYr10: _emberData.default.attr('part-usage'),
    costsLastYr11: _emberData.default.attr('part-usage'),
    costsLastYr12: _emberData.default.attr('part-usage'),
    notes: _emberData.default.attr(),
    crossRefProdId: _emberData.default.attr('capitalize'),
    // Only present on searches
    vendor: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    history: _emberData.default.hasMany('part-history', {
      async: true
    }),
    warehouse: _emberData.default.belongsTo('warehouse-part', {
      async: true
    }),
    warehouse2: _emberData.default.belongsTo('second-warehouse-part', {
      async: true
    }),
    warehouse3: _emberData.default.belongsTo('third-warehouse-part', {
      async: true
    }),
    crossReferences: _emberData.default.hasMany('cross-reference', {
      async: true,
      inverse: "product"
    }),
    tags: _emberData.default.hasMany('tag', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachment', {
      async: true
    }),
    label: Ember.computed('prodId', 'description', function () {
      return this.get('prodId') + ' - ' + this.get('description');
    })
  });

  _exports.default = _default;
});