define("client/templates/pr-employee-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "znbo3jOG",
    "block": "{\"statements\":[[1,[33,[\"pr-employee-view\"],null,[[\"employee\",\"create\",\"edit\",\"delete\",\"postPrHistory\",\"voidPrCheck\",\"search\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"transitionNoParam\",\"pr-employee-create\"],null],[33,[\"route-action\"],[\"transition\",\"pr-employee-edit\"],null],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null]],null],[33,[\"route-action\"],[\"transitionNoParam\",\"pr-post-history\"],null],[33,[\"route-action\"],[\"transition\",\"pr-void-check\"],null],[33,[\"route-action\"],[\"transitionNoParam\",\"pr-employee-search\"],null]]]],false],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"isDeleting\"]]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"pr-employee-delete\"],null,[[\"employee\",\"onDelete\",\"onClose\"],[[28,[\"model\"]],[33,[\"route-action\"],[\"transitionNoParam\",\"pr-employee-search\"],null],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"isDeleting\"]]],null],null],null]]]],false],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/pr-employee-view.hbs"
    }
  });

  _exports.default = _default;
});