define("client/models/return", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Return = _emberData.default.Model.extend({
    quantity: _emberData.default.attr(),
    price: _emberData.default.attr(),
    notes: _emberData.default.attr(),
    trackingNumber: _emberData.default.attr(),
    shippingMethod: _emberData.default.attr(),
    creator: _emberData.default.attr(),
    date: _emberData.default.attr(),
    status: _emberData.default.attr(),
    purchaseOrder: _emberData.default.belongsTo('purchase-order', {
      async: true
    })
  });

  Return.reopenClass({
    FIXTURES: []
  });
  var _default = Return;
  _exports.default = _default;
});