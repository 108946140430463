define("client/models/purchase-order-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    prodId: _emberData.default.attr('capitalize'),
    poNumber: _emberData.default.attr(),
    seq: _emberData.default.attr(),
    poDate: _emberData.default.attr(),
    dueDate: _emberData.default.attr(),
    vendId: _emberData.default.attr(),
    qty: _emberData.default.attr(),
    description: _emberData.default.attr('capitalize'),
    acknowledged: _emberData.default.attr(),
    unitCost: _emberData.default.attr(),
    uom: _emberData.default.attr(),
    numericSeq: Ember.computed('seq', function () {
      return parseInt(this.get('seq'));
    }),
    purchaseOrder: _emberData.default.belongsTo('purchase-order', {
      async: true
    })
  });

  _exports.default = _default;
});