define("client/components/pdf-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'canvas',
    src: null,
    pdfObj: null,
    pageNumber: 1,
    attributeBindings: ['style'],
    didInsertElement: function didInsertElement() {
      this.set('isInserted', true);
    },
    getPdfObj: function () {
      if (this.get('isInserted') && this.get('src') !== "") {
        return PDFJS.getDocument(this.get('src')).then(function (pdf) {
          this.set('pdfObj', pdf);
        }.bind(this));
      }
    }.observes('src', 'isInserted').on('init'),
    renderPage: function () {
      var pdf = this.get('pdfObj'),
          self = this;
      if (!pdf) return false;
      pdf.getPage(this.get('pageNumber')).then(function (page) {
        var parentWidth = self.$().parent().width(),
            viewport = page.getViewport(parentWidth / page.getViewport(1.0).width),
            //viewport = page.getViewport(1),
        canvas = document.getElementById(self.get('elementId')),
            context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page.render({
          canvasContext: context,
          viewport: viewport
        });
      });
    }.observes('pdfObj', 'pageNumber'),
    style: ""
  });

  _exports.default = _default;
});