define("client/components/cascaded-each/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Cascaded = Ember.Component.extend({
    tagName: '',
    animation: null,
    didUpdateFired: false,
    hasFired: false,
    didReceiveAttrs: function didReceiveAttrs(newAttrs, oldAttrs) {
      this.attrs.items = this.get('items');

      if (oldAttrs && !oldAttrs['items'] && newAttrs['items']) {
        this.set('didUpdateFired', true);
      }
    },
    didRender: function didRender() {
      if (!this.get('hasFired') && !this.get('didUpdateFired')) {
        var children = [];
        var currentNode = Ember.ViewUtils.getViewBounds(this).firstNode; // this._renderNode.firstNode;

        var lastNode = Ember.ViewUtils.getViewBounds(this).lastNode;

        while (currentNode && currentNode !== lastNode) {
          if (currentNode.nextElementSibling) {
            children.push(currentNode.nextElementSibling);
            currentNode = currentNode.nextElementSibling;
          } else {
            currentNode = currentNode.nextSibling;
          }
        }

        for (var i = 0; i < children.length; i++) {
          var element = children[i];
          element.animate([{
            opacity: 0,
            transform: 'scale(0)'
          }, {
            opacity: 1,
            transform: 'scale(1)'
          }], {
            duration: 300,
            easing: 'ease-in-out',
            fill: 'both',
            delay: i * 50
          });
          this.set('hasFired', true);
        }

        console.log('animation stuff fired!');
      } //this.set('didUpdateFired', false);

    }
  });
  Cascaded.reopenClass({
    positionalParams: ['items']
  });
  var _default = Cascaded;
  _exports.default = _default;
});