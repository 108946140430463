define("client/models/second-warehouse-part", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    prodId: _emberData.default.attr(),
    openingBalanceI: _emberData.default.attr('numberalize'),
    openingBalanceJ: _emberData.default.attr('numberalize'),
    openingBalanceK: _emberData.default.attr('numberalize'),
    openingBalanceL: _emberData.default.attr('numberalize'),
    openingBalanceM: _emberData.default.attr('numberalize'),
    openingBalanceN: _emberData.default.attr('numberalize'),
    openingBalanceO: _emberData.default.attr('numberalize'),
    openingBalanceP: _emberData.default.attr('numberalize'),
    receivedYtdI: _emberData.default.attr('numberalize'),
    receivedYtdJ: _emberData.default.attr('numberalize'),
    receivedYtdK: _emberData.default.attr('numberalize'),
    receivedYtdL: _emberData.default.attr('numberalize'),
    receivedYtdM: _emberData.default.attr('numberalize'),
    receivedYtdN: _emberData.default.attr('numberalize'),
    receivedYtdO: _emberData.default.attr('numberalize'),
    receivedYtdP: _emberData.default.attr('numberalize'),
    usedYtdI: _emberData.default.attr('numberalize'),
    usedYtdJ: _emberData.default.attr('numberalize'),
    usedYtdK: _emberData.default.attr('numberalize'),
    usedYtdL: _emberData.default.attr('numberalize'),
    usedYtdM: _emberData.default.attr('numberalize'),
    usedYtdN: _emberData.default.attr('numberalize'),
    usedYtdO: _emberData.default.attr('numberalize'),
    usedYtdP: _emberData.default.attr('numberalize'),
    allocatedI: _emberData.default.attr('numberalize'),
    allocatedJ: _emberData.default.attr('numberalize'),
    allocatedK: _emberData.default.attr('numberalize'),
    allocatedL: _emberData.default.attr('numberalize'),
    allocatedM: _emberData.default.attr('numberalize'),
    allocatedN: _emberData.default.attr('numberalize'),
    allocatedO: _emberData.default.attr('numberalize'),
    allocatedP: _emberData.default.attr('numberalize'),
    product: _emberData.default.belongsTo('part-item', {
      async: true
    })
  });

  _exports.default = _default;
});