define("client/services/hotkey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    hotkeys: Ember.A([Ember.Object.create({
      title: "Next Input",
      shortcut: "Tab"
    }), Ember.Object.create({
      title: "Previous Input",
      shortcut: "Shift + Tab"
    }), Ember.Object.create({
      title: "Copy",
      shortcut: "Ctrl + c"
    }), Ember.Object.create({
      title: "Cut",
      shortcut: "Ctrl + x"
    }), Ember.Object.create({
      title: "Paste",
      shortcut: "Ctrl + v"
    }), Ember.Object.create({
      title: "Select All",
      shortcut: "Ctrl + a"
    }), Ember.Object.create({
      title: "Help",
      shortcut: "F2"
    }), Ember.Object.create({
      title: "Previous Tab",
      shortcut: "Ctrl + Left Arrow"
    }), Ember.Object.create({
      title: "Next Tab",
      shortcut: "Ctrl + Right Arrow"
    })])
  });

  _exports.default = _default;
});