define("client/react/utilities/typed/yup-validation-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateTypeError = dateTypeError;
  _exports.emailError = emailError;
  _exports.maxLengthError = maxLengthError;
  _exports.maxNumberError = maxNumberError;
  _exports.minNumberError = minNumberError;
  _exports.numberTypeError = numberTypeError;
  _exports.phoneNumberError = phoneNumberError;
  _exports.requiredError = requiredError;
  _exports.stringTypeError = stringTypeError;
  _exports.timeTypeError = timeTypeError;

  /**
   * Shorthand for expressing a max length error
   * @param {number} maxLength The max length applied
   * @returns {string} Helper error text
   */
  function maxLengthError(maxLength) {
    return "Must be ".concat(maxLength, " characters or less");
  }
  /**
   * Shorthand for expressing a required error
   * @returns {string} Helper error text
   */


  function requiredError() {
    return "This is required";
  }
  /**
   * Shorthand for expressing a time type error
   * @returns {string} Helper error text
   */


  function timeTypeError() {
    return "Must be a valid time";
  }
  /**
   * Shorthand for expressing a date type error
   * @returns {string} Helper error text
   */


  function dateTypeError() {
    return "Must be a valid date";
  }
  /**
   * Shorthand for expressing a number type error
   * @returns {string} Helper error text
   */


  function numberTypeError() {
    return "Must be a valid number";
  }

  function stringTypeError() {
    return "Must be a valid string";
  }

  function minNumberError(number) {
    return "Must be ".concat(number, " or greater");
  }

  function maxNumberError(number) {
    return "Must be ".concat(number, " or less");
  }

  function emailError() {
    return 'Must be a valid email';
  }

  function phoneNumberError() {
    return 'Must be a valid phone number';
  }
});