define("client/helpers/substring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.substring = substring;

  function substring(params
  /*, hash*/
  ) {
    var string = params[0];
    var start = params[1];
    var length = params[2];

    if (length) {
      return string ? string.substr(start, length) : '';
    } else {
      return string ? string.substr(start) : '';
    }
  }

  var _default = Ember.Helper.helper(substring);

  _exports.default = _default;
});