define("client/components/tech-table-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: [],
    attributeBindings: ['style'],
    style: '',
    didInsertElement: function didInsertElement() {
      this.$().scroll(function () {
        Ember.$('#times-bar').css({
          'left': this.$().scrollLeft()
        });
        Ember.$('#assigned-count').css({
          'left': this.$().scrollLeft()
        });
      }.bind(this));
    },
    observeResize: function () {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        var height = _this.$().parent().height();

        height = height - Ember.$("#top-bar").outerHeight(true);
      });
    }.on('didInsertElement'),
    adjustLayout: function adjustLayout(h) {
      var nextHeight = h - 560 - 55 + "px;";
      h = h + "px;"; // this.set('height', 'margin-bottom:' + nextHeight);
      //            this.set('style', "height:" + h);
    }
  });

  _exports.default = _default;
});