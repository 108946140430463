define("client/controllers/srvc-archive-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['searchTerm'],
    searchTerm: "",
    actions: {
      back: function back() {
        window.history.back();
      },
      view: function view(wo) {
        this.transitionToRoute('srvc-archive-view', wo);
      }
    }
  });

  _exports.default = _default;
});