define("client/react/utilities/typed/hooks/use-hotkeys", [], function () {
  "use strict"; // import {createContext, ReactNode, RefObject, useCallback, useContext, useEffect, useRef} from "react";
  // import {uuidv4} from "../general";
  // import {HotkeyMappings, UseHotkeyOptions} from "./use-hotkeys.types";
  // import hotkeys from 'hotkeys-js';
  // import {forEachObj, keys} from "remeda";
  // const HotkeyContext = createContext<RefObject<HotkeyMappings>>({current: {}});
  // export function HotkeyProvider({ children }: { children: ReactNode }) {
  // 	const stateRef = useRef<HotkeyMappings>({});
  // 	return (
  // 		<HotkeyContext.Provider value={stateRef}>
  // 			{children}
  // 		</HotkeyContext.Provider>
  // 	);
  // }
  // export function useHotkey(hotkey: string, options: UseHotkeyOptions) {
  // 	const context = useContext(HotkeyContext);
  // 	const uuidRef = useRef(uuidv4());
  // 	const parents = useRef<Record<string,boolean>>({});
  // 	const cb = useCallback(options.callback, options.deps || []);
  // 	hotkeys.filter = () => true;
  // 	useEffect(() => {
  // 		if (!context.current![uuidRef.current]) {
  // 		// If shouldOverride we will unbind handlers of any parents that are currently enabled
  // 			if (options.shouldOverride) {
  // 				forEachObj.indexed(context.current!,(mapping, key) => {
  // 					if (mapping.hotkey === hotkey && mapping.enabled) {
  // 						// have to log the parents so we know who to re-enable when we clean up
  // 						parents.current[key] = true;
  // 						hotkeys.unbind(hotkey, mapping.callback);
  // 						mapping.enabled = false;
  // 					}
  // 				});
  // 			}
  // 			hotkeys(hotkey, cb);
  // 			context.current![uuidRef.current] = {
  // 				enabled: true,
  // 				hotkey: hotkey,
  // 				callback: cb
  // 			};
  // 		} else {
  // 			// I'm not convinced this block would ever execute since I read somewhere that the useEffect cleanup routine was run everytime before a new effect happens
  // 			hotkeys.unbind(hotkey, context.current![uuidRef.current].callback)
  // 			context.current![uuidRef.current].callback = cb;
  // 			hotkeys.bind(hotkey, cb);
  // 		}
  // 		return () => {
  // 			// must clean up an re-enable parents if they are present
  // 			// It's possible they aren't present because parents are cleaned up before children
  // 			// So if a whole subtree containing parent and child is removed at once, the parent
  // 			// will already have destroyed by the time the child attempts
  // 			if (options.shouldOverride) {
  // 				keys(parents.current).forEach((key) => {
  // 					if (context.current![key] && context.current![key].enabled === false) {
  // 						// if the parent is still there
  // 						const parent = context.current![key];
  // 						parent.enabled = true;
  // 						hotkeys(parent.hotkey, parent.callback);
  // 					}
  // 				});
  // 			}
  // 			hotkeys.unbind(hotkey, context.current![uuidRef.current].callback)
  // 			delete context.current![uuidRef.current];
  // 		}
  // 	}, [cb, ...options.deps]);
  // }
});