define("client/transforms/month-year", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return serialized.substring(5, 7) + "/" + serialized.substring(0, 4);
      } else {
        return null;
      }
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return deserialized.substring(3, 7) + "/" + deserialized.substring(0, 2);
      } else {
        return null;
      }
    }
  });

  _exports.default = _default;
});