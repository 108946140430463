define("client/components/h-form/component", ["exports", "ember-paper/components/paper-form"], function (_exports, _paperForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run;

  var _default = _paperForm.default.extend({
    submit: null,
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('registration', Ember.Object.create({}));
    },
    registerForm: function registerForm(name, component) {
      var _this = this;

      run.scheduleOnce('afterRender', function () {
        _this.get('registration').set(name, component);
      });
    }
  });

  _exports.default = _default;
});