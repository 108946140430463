define("client/templates/gl-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mWjRAMYL",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"featureFlags\",\"realtimeLedger\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,[\"gl-reports\"]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"    \"],[1,[33,[\"reports-screen\"],null,[[\"module\"],[\"GL\"]]],false],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/gl-reports.hbs"
    }
  });

  _exports.default = _default;
});