define("client/templates/ledger-std-entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pqcEGbiu",
    "block": "{\"statements\":[[6,[\"ember-wormhole\"],null,[[\"to\"],[\"main-toolbar\"]],{\"statements\":[[0,\"\\t\"],[11,\"h4\",[]],[13],[0,\"\\n\\t\\tGeneral Ledger Standard Entries\\n\\t\"],[14],[0,\"\\n\\t\"],[11,\"span\",[]],[15,\"class\",\"flex\"],[13],[14],[0,\"\\n\"]],\"locals\":[]},null],[11,\"div\",[]],[15,\"style\",\"overflow-y: auto; height: 100%;overflow-x:hidden;background-color: #f5f5f5;\"],[13],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"layout-row\"],[15,\"style\",\"justify-content:center;\"],[13],[0,\"\\n\\t\\t\"],[11,\"div\",[]],[15,\"class\",\"flex-50\"],[13],[0,\"\\n\\t\\t\\t\"],[1,[33,[\"ledger-std-entries\"],null,[[\"entries\",\"glAccounts\"],[[28,[\"model\",\"entries\"]],[28,[\"model\",\"glAccounts\"]]]]],false],[0,\"\\n\\t\\t\"],[14],[0,\"\\n\\t\"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/ledger-std-entries.hbs"
    }
  });

  _exports.default = _default;
});