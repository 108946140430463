define("client/models/work-order-labor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var WorkOrderLabor = _emberData.default.Model.extend({
    srvcOrdId: _emberData.default.attr(),
    lineNumber: _emberData.default.attr(),
    complaintNumber: _emberData.default.attr(),
    date: _emberData.default.attr(),
    laborType: _emberData.default.attr(),
    tech: _emberData.default.attr(),
    timeOn: _emberData.default.attr('citron-time'),
    timeOff: _emberData.default.attr('citron-time'),
    regHours: _emberData.default.attr('currency'),
    otHours: _emberData.default.attr('currency'),
    laborCost: _emberData.default.attr('currency'),
    techName: _emberData.default.attr(),
    workOrder: _emberData.default.belongsTo('work-order', {
      async: true
    }),
    numericLineNumber: Ember.computed('lineNumber', function () {
      return parseInt(this.get('lineNumber'));
    })
  });

  var _default = WorkOrderLabor;
  _exports.default = _default;
});