define("client/templates/dispatch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cUOlS4Lj",
    "block": "{\"statements\":[[1,[33,[\"dispatch-board\"],null,[[\"scrollTop\",\"onChangeScrollTop\",\"scrollLeft\",\"onChangeScrollLeft\",\"colors\",\"noDates\",\"workOrders\",\"allTechs\",\"technicians\",\"userAssignment\",\"date\",\"onChangeDate\"],[[28,[\"scrollTop\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"scrollTop\"]]],null]],null],[28,[\"scrollLeft\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"scrollLeft\"]]],null]],null],[28,[\"colors\"]],[28,[\"noDates\"]],[28,[\"workOrders\"]],[28,[\"technicians\"]],[28,[\"filteredTechnicians\"]],[28,[\"userAssignment\"]],[28,[\"date\"]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"date\"]]],null]],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "client/templates/dispatch.hbs"
    }
  });

  _exports.default = _default;
});